import { Component, Inject, OnInit, Output, Input, EventEmitter, ViewChild, NgZone } from "@angular/core";
import { ShareService } from '../../../services/share.service';
import { CommonfunctionService } from '../../../../services/commonfunction.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { UnitMapComponent } from '../unit-map.component';
import { NotificationService } from '../../../services/notification.service';

@Component(
    {
      selector: 'unit-paste-group',
      templateUrl: './unit-paste.component.html',
      styleUrls: ['./unit-paste.component.css']
    })
  
  
  export class UnitPasetComponent {
    parent: any;
    group_list: any;
    show_child: any;
    selected_group_id = [];
    noSelectedunit: number = 0;
    constructor(public dialogRef: MatDialogRef<UnitMapComponent>, 
        @Inject(MAT_DIALOG_DATA) public data: any, private share: ShareService, private notify : NotificationService) {
      this.parent = this.data.pObj;
      this.group_list = this.parent.share.block0_obj.group_custom_list_unit;
     // this.noSelectedunit = this.share.floor_map_obj.unitGisUidsForPaste.length;
    }
    ngOnInit() {
  
    }
    isActive(id) {
      if (this.show_child == id) {
        this.show_child = null;
        return;
      }
      this.show_child = id;
    }
    selectGroup(e, v) {
      if (e.checked) {
        this.selected_group_id.push(v);
      } else {
        this.selected_group_id.splice(this.selected_group_id.indexOf(v), 1);
      }
    }
    pasteUnit() {
      if(this.selected_group_id.length > 0){
        this.parent.paste(this.selected_group_id);
        this.closeDialog();
      }else{
        this.notify.notify("please select attribute group", 'warn');
        return 
      }
     
    }
    closeDialog() {
      this.dialogRef.close();
    }
  }