import { moveItemInArray } from "@angular/cdk/drag-drop";
import {
  Component,
  OnInit,
  Output,
  HostListener,
  Input,
  EventEmitter,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { DndDropEvent } from "ngx-drag-drop";
import { Subscription } from "rxjs";
import { NotificationService } from "../../../services/notification.service";
import { ShareService } from "../../../services/share.service";
import { ApiserviceService } from "../../../../apiservice.service";
import { CommonfunctionService } from "../../../../services/commonfunction.service";
import { ShuffleConfirmationDialog } from "../Shuffle-Confirmation-Dialog/shuffle-confirmation-dialog.component";
import { Options } from "sortablejs";
import * as $ from "jquery";

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_module_type_entity_attributes_patch</li>
 * <li>estapi_module_type_entity_attributegroups_patch</li>
 * </ol>
 *
 */
@Component({
  selector: "group-list",
  templateUrl: "admin-grouplist.component.html",
  styleUrls: ["./admin-grouplist.component.scss"],
})
export class GroupFieldsList implements OnInit {
  @Input("list") grouplist;
  // @Input('data') data;
  @Input("assignment") selected_assignment;
  @Input("module") module;
  @Input("entity") selected_entity_type;
  @Input("group_access") attributegroups_access;
  @Input("attributes_access") attributes_access;
  @Output()
  eventtrigger: EventEmitter<{}> = new EventEmitter<{}>();
  @Output()
  listFields: EventEmitter<{}> = new EventEmitter<{}>();
  @Output()
  triggerswipe: EventEmitter<{}> = new EventEmitter<{}>();
  selected: any;
  sub_selected: any;
  popwindow: number = -1;
  popsubwindow: number = -1;
  currentitem: any;
  activeToggle: boolean = false;
  attrCount: number = 0;
  dropData: any;
  field_group: any = [];
  confirm: boolean = false;
  dependent_list: any;
  // groupoptions: SortablejsOptions = {
  //   draggable: '.rowse',
  //   handle: '.draghandle',
  //   onUpdate: (event: any) => this.sortGroup()
  // };
  subgroupoptions: Options = {
    draggable: ".subdraghandle",
    onUpdate: (event: any) => this.sortSubGroup(event),
  };
  mobilemasterpopup = false;
  mobilemasterpopupslideout = false;

  submasterlist: Array<{}> = [];
  mobilegroupoptions: Options = {
    draggable: ".mobilerowslist",
    handle: ".draghandle",
  };
  showListHeader: boolean;
  dragDisabled: boolean = true;
  quick_count_uns: Subscription;
  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private api: ApiserviceService,
    private notify: NotificationService,
    private fnc: CommonfunctionService,
    private share: ShareService,
    public dialog: MatDialog
  ) {
    this.share.gpObj = this;
    // setTimeout(() => {
    //this.sortGroup();
    // }, 5000);
    this.quick_count_uns = this.share.quick_edit_count.subscribe((count) => {
      this.attrCount = count;
    });
  }
  ngOnDestroy(): void {
    if (this.quick_count_uns) this.quick_count_uns.unsubscribe();
  }
  groupoptions(event) {
    this.dragDisabled = true;
    moveItemInArray(this.grouplist, event.previousIndex, event.currentIndex);
    if (event.previousIndex != event.currentIndex) {
      this.sortGroup(this.grouplist);
    }
  }
  //Drag and drop handling....
  openDialog() {
    let confimDialog = this.dialog.open(ShuffleConfirmationDialog, {
      width: "300px",
      panelClass: "create-master-panel",
      data: { parent: this },
    });
    confimDialog.afterClosed().subscribe((result) => {});
  }
  onDrop(event: DndDropEvent) {
    this.dropData = event.data;
    this.addToQuickAction();
  }
  quickAction() {
    this.share.fieldObj.selected_group = "quick";
    this.activeToggle = true;
    this.share.fieldObj.resetpagination();
    this.share.fieldObj.searchAttribute(true, true);
  }
  addToQuickAction() {
    let dropData = this.dropData;
    this.field_group = [];
    let val = {};
    val = {
      updated_by: this.api.user_id,
      attribute_id: dropData.id,
      quick_edit: true,
      assignment_id: this.share.fieldObj.selected_assignment,
      move_linked_attributes: this.confirm,
    };
    this.field_group.push(val);
    let url =
      this.share.fieldObj.selected_module +
      "/" +
      this.share.fieldObj.selected_entity_type +
      "/attributes";
    this.api.patchEmsData(url, this.field_group).subscribe({
      next: (data: any) => {
        // this.share.fieldObj.getFields('sort_order', 10, 0, search, true);
        this.notify.notify(
          "Attributes successfully assigned to Quick Edit!",
          "success"
        );
        this.share.fieldObj.searchAttribute(true, true);
      },
      error: (err) => {
        // this.notify.notify(this.fnc.getErrorMessage(err), 'error');
        if (err.status == 500 && !this.confirm) {
          let end,
            dependent_string = "";
          let msg = this.fnc.getErrorMessage(err);
          let list = msg.split("{");
          list.forEach((element, k) => {
            if (k != 0) {
              end = element.indexOf("}");
              if (end != -1) {
                dependent_string += element.substring(0, end) + "|";
              }
            }
          });
          this.dependent_list = dependent_string;
          this.openDialog();
          this.confirm = false;
        }
      },
      complete: () => {
        this.confirm = false;
      },
    });
  }

  //drag and drop end...
  ngAfterViewInit() {}

  sortGroup(grouplist) {
    let groups = [];
    let val = {};

    if (!this.attributegroups_access.PATCH) {
      this.notify.notify("You are not authorize to update Attributes", "warn");
      return;
    }

    grouplist.forEach((v, k) => {
      if (v) {
        val = {
          group_id: v.id,
          sort_order: k + 1,
          updated_by: this.api.user_id,
          assignment_id: this.selected_assignment,
        };
        groups.push(val);
      }
    });

    let url =
      this.module + "/" + this.selected_entity_type + "/attributegroups";
    this.api.patchEmsData(url, groups).subscribe({
      next: (data: any) => {
        //this.notify.notify('Groups order updated','Dismiss');
      },
      error: (err) =>
        this.notify.notify(this.fnc.getErrorMessage(err), "error"),
    });
  }

  sortSubGroup(e) {
    //let list = this.grouplist[this.selected].childrens;
    let list = this.fnc.getArrayValue("id", this.selected, this.grouplist);
    list = list.childrens;
    let groups = [];
    let val = {};

    if (!this.attributegroups_access.PATCH) {
      this.notify.notify("You are not authorize to update group", "warn");
      return;
    }

    list.forEach((v, k) => {
      val = {
        group_id: v.id,
        sort_order: k,
        updated_by: this.api.user_id,
        assignment_id: this.selected_assignment,
      };

      groups.push(val);
    });
    let url =
      this.module + "/" + this.selected_entity_type + "/attributegroups";
    this.api.patchEmsData(url, groups).subscribe({
      next: (data: any) => {
        //this.notify.notify('Groups order updated','Dismiss');
      },
      error: (err) =>
        this.notify.notify(this.fnc.getErrorMessage(err), "error"),
    });
  }

  showsmallpopmobile(event, classname: string, index: number, item: Array<{}>) {
    setTimeout(() => (this.popwindow = index), 0);
    this.submasterlist = item["childrens"];
    this.currentitem = item;
    this.positionpopup(event, classname);
  }

  positionpopup(event, classname) {
    $("." + classname).css({
      top: event.y - 80,
      left: event.x + 20,
    });
  }

  ngOnInit() {
    // this.quickAction(true);
    // if (this.data && !this.data.class) {
    //   this.data.class = '';
    // }
  }

  editgroup(event) {
    this.eventtrigger.emit(event);
  }

  listAttributes(item) {
    this.activeToggle = false;
    this.sub_selected = item.id;
    this.listFields.emit(item);
  }

  isActive(item) {
    if (item && !this.activeToggle) {
      return this.selected == item;
    } else if (!item) {
      return this.activeToggle;
    }
  }
  isActiveSub(item) {
    if (item == this.sub_selected) {
      return true;
    } else {
      return false;
    }
  }
  select(item) {
    this.selected = this.selected === item ? null : item;
    // this.selected = item;
  }

  @HostListener("document:click", ["$event"])
  handleClick(event: Event) {
    if (
      !$(event.target).hasClass("mastergroup_menuactions") &&
      !$(event.target).hasClass("add-b")
    ) {
      this.hideMobilePopover();
    }
  }

  hideMobilePopover() {
    this.popwindow = -1;
    this.popsubwindow = -1;
  }

  showsmallpop(event, index: number) {
    this.popwindow = index;
  }

  swiped() {
    this.triggerswipe.emit({ state: true });
  }

  loadGroup() {
    this.triggerswipe.emit({ state: true });
    this.hideMobilePopover();
  }

  showsmallsubpop(event, classname: string, index: number, position?: boolean) {
    setTimeout(() => (this.popsubwindow = index), 0);
    this.popwindow = null;
    if (position) {
      this.positionsubpopup(event, classname);
    }
  }

  positionsubpopup(event, classname) {
    $("." + classname).css({
      top: event.y - 80,
      left: event.x - $(".mobilesubmastergroups").outerWidth(),
    });
  }
}
