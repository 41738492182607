import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatErrorStateMatcher } from "../../../admin-error-state-macher";
import { NotificationService } from "../../../services/notification.service";
import { ApiserviceService } from "../../../../apiservice.service";
import { CommonfunctionService } from "../../../../services/commonfunction.service";

@Component({
  selector: "field-shuffle-confirmation-dialog",
  templateUrl: "../../subelements/field-shuffle-confirmation.component.html",
  styleUrls: ["./shuffle-confirmation-dialog.component.scss"],
})
export class ShuffleConfirmationDialog {
  matcher = new MatErrorStateMatcher();
  dependent_attribute_list = [];
  constructor(
    public dialogRef: MatDialogRef<ShuffleConfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiserviceService,
    private fnc: CommonfunctionService,
    private notify: NotificationService
  ) {
    this.setAttributeList();
  }

  setAttributeList() {
    let list: any;
    //this.dependent_attribute_list = this.data.parent.dependent_list.split('|');
    let attr = this.data.parent.dependent_list.split("|");
    attr.forEach((v, k) => {
      if (v) {
        list = v.split(",");
        this.dependent_attribute_list[k] = list;
      }
    });
    //console.log(this.dependent_attribute_list);
  }

  confirm() {
    this.data.parent.confirm = true;
    if (this.data.parent.callFrom == "adminfield") {
      this.data.parent.removeFromQuickAction();
    } else {
      if (this.data.parent.quickAction) {
        this.data.parent.addToQuickAction();
      } else {
        this.data.parent.shuffleFields();
      }
    }

    this.closeDialog();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
