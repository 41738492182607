<div class="creategroup">
    <span></span>
    <div>Property in other assignment</div>
    <span (click)="close()">X</span>
</div>
<div class="download-property">
    <span>There is 100 property is showing, To view complete list, please download CSV.</span>
    <button>Download CSV</button>
</div>
<div class="example-container">
    <table class="table" cellpadding="0px" cellspacing="0px">
       <thead>
        <tr>
            <th>Property UID</th>
            <th>Assignment Name</th>
            <th>Added Date</th>
            <th>Submitted Date</th>
        </tr>
       </thead>
        <tbody>
            <tr *ngFor="let obj of property_list">
                <td>obj?.property_uid</td>
                <td>obj?.assignemnt_name</td>
                <td>getDate(obj?.added_date)</td>
                <td>getDate(obj?.submitted_date)</td>
            </tr>
        </tbody>
    </table>
</div>
<div class="max-list-count">
    Max list count 100
</div>