<div class="app estater-theme fixed-header" id="app" dir="ltr">
  <mat-sidenav-container>
    <mat-sidenav #sidenav class="sidenav" *ngIf="!showside" (closedStart)="sidenavClose()" mode="over">
      <admin-sidebar size="normal"></admin-sidebar>
    </mat-sidenav>
    <mat-sidenav #sidenavsmall *ngIf="showside && !mobileview" class="sidenavsmall" mode="side">
      <admin-sidebar size="small"></admin-sidebar>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar class="toptoolbar" [ngClass]="{'mobilehead' : mobileview}">
        <div fxLayout="row" class="w-100" fxLayoutAlign="space-between center">
          <div fxLayout="row" fxFlex fxLayoutAlign="start center">
            <button mat-icon-button (click)="openMenu()">
              <mat-icon aria-label="Toggle menu">menu</mat-icon>
            </button>
          </div>
          <!-- <ng-container *ngIf="!isMobile"> <admin-header fxFlex="50%"></admin-header></ng-container> -->
          <div fxLayout="row" fxFlex fxLayoutAlign="end center" style="position: relative;">
            <app-in-progress-tooltip></app-in-progress-tooltip>
            <button mat-button  [matMenuTriggerFor]="LanguageMenu">
                <mat-icon class="cit">translate</mat-icon>
                {{language_name}}
            </button>
            <button mat-button [routerLink]="['/admin/country']">
                <mat-icon class="cit">location_city</mat-icon>
              {{getCity()}}
            </button>
            <span class="v-bar"></span>
            <mat-menu #LanguageMenu="matMenu" [overlapTrigger]="false" xPosition="before" yPosition="above" class="mat-menu-user">
              <ng-template matMenuContent>
                <app-language (valueChange)="selectedLanguage($event)"></app-language>
              </ng-template>
            </mat-menu>

            <button mat-icon-button class="notifybutton" (click)="togglelist()">
              <mat-icon aria-label="Toggle menu" svgIcon="bell-icon"></mat-icon>
              <b *ngIf="notifycounter > 0" class="badge bg-back up">{{notifycounter}}</b>
            </button>
            <div class="overlay" id="overlay" (click)="overlay_off()"></div>
            <div class="notify-container" *ngIf="toggleList">
              <span class="arrows-notify"></span>
              <app-notification></app-notification>
            </div>

            <span class="v-bar"></span>
            <button mat-button class="user-button" [matMenuTriggerFor]="UserMenu">
              <div fxLayout="row" class="w-100" fxLayoutAlign="space-around center">
                <img alt="user-image" *ngIf="profile_image && profile_image != 'null'" class="rounded mat-elevation-z3 user-image"
                  width="30" height="30" [src]="sanitizer.bypassSecurityTrustResourceUrl(profile_image)"
                  onerror="this.onerror=null;this.src='assets/images/user.png'">
                <span *ngIf="!profile_image || profile_image == 'null'" class="avatar-t cp">{{getInitials(username)}}</span>
                <span class="cp" fxHide="true" fxShow.gt-sm="true" class="user_name">{{username}}</span>
                <mat-icon fxHide.xs="true" aria-label="Toggle menu">keyboard_arrow_down</mat-icon>
              </div>
            </button>
            <mat-menu #UserMenu="matMenu" [overlapTrigger]="false" xPosition="before" yPosition="above" class="mat-menu-user">
              <ng-template matMenuContent>
                <app-usermenu></app-usermenu>
              </ng-template>
            </mat-menu>
          </div>
        </div>
      </mat-toolbar>
      <div class="middle-content" #viewHeightDiv>
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

</div>
