import { Component, ElementRef, Inject } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiserviceService } from '../../../../apiservice.service';
import { NotificationService } from '../../../../admin/services/notification.service';
import { CommonfunctionService } from '../../../../services/commonfunction.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-edit-master-value',
  templateUrl: './edit-master-value.component.html',
  styleUrls: ['./edit-master-value.component.scss']
})
export class EditMasterValueComponent {

  languages: Array<any>=[];
  language_valueset: Array<{}> = [];
  masterValueForm: any;
  status: any = true;
  formData: FormData = new FormData();
  file: File;
  masterImage: string = '';
  imageUrl: string = null;
  loader: boolean;

  constructor(public dialogRef: MatDialogRef<EditMasterValueComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  private _fb: FormBuilder, private api:ApiserviceService,
  private notify: NotificationService,
  private fnc: CommonfunctionService,
  private elementRef: ElementRef){

    this.languages = this.api.language_list;

    this.masterValueForm = this._fb.group({
      master_name: [{value: this.data.value.master_value_name, disabled: true}, [Validators.required,Validators.pattern('^[a-zA-Z0-9_+]*$')]],
      language: this._fb.array([])
    })

    setTimeout(() => {
      if (this.data.value && this.data.value.image_url) {
        this.masterImage = this.data.value.image_url;
        this.imageUrl = this.data.value.image_url;
      }else{
        this.masterImage = 'assets/images/banned.png';
      }
    }, 100);

      // let master_data = this.fnc.getArrayValue('id',)
      this.masterValueForm = null;
      this.masterValueForm = this._fb.group({
        master_name: [{ value: '', disabled: true }],
        language: this._fb.array([])
      });
      let labels: any = [];
      let index = 0;
      this.masterValueForm.patchValue({ master_name: this.data.value.master_value_name });
      this.status = this.data.value.status;
      for (let prop in this.data.value.master_value_label) {
        labels[index] = { language: +prop, value: this.data.value.master_value_label[prop] };  // +prop to convert into number
        this.addRow();
        index++
      }
      this.masterValueForm.patchValue({ language: labels });
  }

  uploadImage(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let pattern = /image-*/;
      if (!file.type.match(pattern)) {
        return;
      }
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event: any) => {
        this.masterImage = event.target.result;
      }
      this.file = file;
    }
  }

  openExplorer(type) {
    if (type == 'image') {
      let e = $(this.elementRef.nativeElement);
      e.find("#image_input").click();
    }
  }

  initLanguageFields() {
    return this._fb.group({
      language: ['', Validators.required],
      value: ['', Validators.required]
    });
  }



  removeEleArray(key, arr) {
    const arr1: any = [];
    arr.forEach(element => {
      if (element === key.toString()) {
      } else {
        arr1.push(element);
      }
    });
    return this.removeDups(arr1);
  }
  removeDups(names) {
    const unique = {};
    names.forEach(function (i) {
      if (!unique[i]) {
        unique[i] = true;
      }
    });
    return Object.keys(unique);
  }

  /**
   * This is a function to upload image
   * @param formValue
   * @param event
   * @returns
   */
  uploadMasterFile(formValue, event) {
    //upload image
    // const value = formValue.value;
    if (this.file != undefined || this.file != null) {
      let fileName = this.file.name;
      let Extension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
      if (Extension == "gif" || Extension == "png" || Extension == "jpeg" || Extension == "jpg") {

      } else {
        this.notify.notify("Upload 'gif, png, jpeg, jpg' file only", 'warn');
        return;
      }
      fileName = ((this.data.selected_master.master_name).trim()).replace(/ /g, '_') + '_' + this.data.value.master_value_name.trim() + '.' + Extension;

      this.formData.append('file', this.file, fileName);
      this.loader =true;
      this.api.postFileUms('uploads/images?folder_name=property_image&city-code=' + this.api.city_id, this.formData, 'application/json')
        .subscribe({next: (data: any) => {
          this.loader =false;
          data = JSON.parse(data);
          this.masterImage = data.data;
          this.imageUrl = data.data;
          this.formData = new FormData();
          this.addMasterValue(formValue, event);

        },
          error:(err) => {
            this.loader =false;
            this.formData = new FormData();
            console.log(err)
          }}
        );
    } else {
      this.addMasterValue(formValue, event);
    }
  }

  /**
   * Update Master Value function
   * @param val
   * @param e
   */
  addMasterValue(val, e) {
    const value = val.value;
    let value_label={}
    value.language.forEach((v, k) => {
      value_label[v.language] = v.value;
    });
   let body = {
    added_by: this.api.user_id,
    master_id: this.data.selected_master.master_id,
    master_value_label: value_label,
    master_value_name: this.data.value.master_value_name,
    parent_master_value_id: null,
    property_type_ids: this.data.property_type_id ? [this.data.property_type_id]: [null],
    sort_order: 0,
    status: 0
   }

    if (this.status) {
      body['status'] = 1;
    } else {
      body['status'] = 0;
    }
    //update or add master value

    body['image_url'] = this.imageUrl;
    body['updated_by'] = this.api.user_id;
    // this.loader = true;
    let url = `${this.data.entity}/masters/${this.data.selected_master.master_id}/values/${this.data.value.master_value_id}`;

    this.api.patchEmsData(url, body)
      .subscribe( {
        next:(data) => {
          this.loader =false;
          this.notify.notify('Master Value Updated', 'success');
          this.dialogRef.close(true);
        },
        error : (err) => {
          this.loader =false;
          this.notify.notify(err, 'error')
        }
      });
  }



  closeDial(e): void {
    e.preventDefault();
    this.dialogRef.close();
  }


  addRow() {
    if (this.language_valueset?.length == this.languages?.length) {
      this.notify.notify("No more Languages Available to Add",'warn',4000);
      return;
    }
    this.language_valueset.push({ language: '', value: '' });
    /* #Form */
    let dd = <FormArray>this.masterValueForm.controls.language;
    dd.push(this.initLanguageFields());
    /* End - #Form */
  }

  removeRow(key) {
    if (this.language_valueset.length > 1) {
      this.language_valueset.splice(key, 1);
      let dd = <FormArray>this.masterValueForm.controls.language;
      dd.removeAt(key);
    }
  }

}
