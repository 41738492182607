<div class="align-grid" *ngIf="cardsList.length>0">
  <ng-container *ngFor="let card of cardsList">
    <!-- mat card start -->
    <mat-card appearance="outlined" class="card-{{getIndex(card.index)}} card-shadow">
      <mat-card-header class="d-flex justify-space-between">
        <mat-card-title class="cp d-flex">{{card.title}}   </mat-card-title>
        <button [disabled]="load && selectedCardId == card.index" class="refresh-btn" style="background-color: #fff;" (click)="refreshData(card)"
          mat-tooltip="Refresh snapshot">
          <mat-icon [ngClass]="{'rotation': load && (selectedCardId == card.index)}" class="rotation">sync</mat-icon>
          <!-- <i class="fa fa-spinner fa-spin"></i> -->
        </button>
        <!-- <mat-card-subtitle>Last Sync: {{card.last_sync | date}}</mat-card-subtitle> -->
        <mat-progress-bar *ngIf="load && selectedCardId == card.index" class="progress" [color]="'primary'"
          mode="indeterminate">
        </mat-progress-bar>
      </mat-card-header>

      <mat-card-content>
        <p title="{{card.description}}">
          {{card.description}}
        </p>
      </mat-card-content>
      <div style="padding: 1rem;">
        <!-- <button mat-button>HIDE CARD</button> -->
        <!-- <button mat-button >REFRESH</button> -->
        <div class="flex-apart">
          <span *ngIf="card.last_sync" class="sync-time">
            <span *ngIf="card.time_spent">Last Synchronized <b>{{card.time_spent}}</b> on
              <b>{{ card.last_sync }}</b></span>
            <span *ngIf="!card.time_spent">Last Synchronized on <b>{{ card.last_sync }}</b></span>
          </span>
        </div>

      </div>
    </mat-card>
    <!-- mat card end -->
  </ng-container>
</div>

<div class="loader_view" *ngIf="cardsList.length == 0">
  <div class="load-cont">
    <h1 class="mat-h1">Loading...</h1>
    <mat-progress-bar [color]="'primary'" mode="indeterminate">
    </mat-progress-bar>
  </div>
</div>
