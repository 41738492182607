<div class="dialog-container">
    <div>
        <mat-toolbar class="w-100 clr" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <div class="pad10">
                <span>Reject {{current_entity_label}}</span>
            </div>
        </mat-toolbar>
        <div class="h50" fxLayout="row" fxLayoutAlign="center center">
            <span>Please select the type of rejection for selected {{current_entity_label}} </span>
        </div>
        <mat-progress-bar *ngIf="(progress > 0) && (progress < 100)" class="w-100" mode="determinate" [value]="progress"></mat-progress-bar>
        <div class="margt">
            <mat-radio-group (change)="rejectionType($event)">
                <mat-radio-button class="radio_text align" *ngFor="let option of reject_options"
                    [value]="option.value">
                    <span class="fnt-md">
                        {{option.label}}
                    </span>
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div class="bg2">
            <div class="pad10">
                <mat-form-field floatLabel="never" class="w-100 bg2">
                    <textarea class="resnone" [(ngModel)]="user_comment" matInput placeholder="Type comment here..."
                        rows="3"></textarea>
                </mat-form-field>
            </div>
        </div>
        <div class="h50">
            <div class="ml10 grid50ph-nogap">
                <button mat-button type="button" (click)="reject()" class="orange-button w-100 br0">Reject</button>
                <button mat-button type="button" (click)="closeDialog()" class="grey-button w-100 br0">Cancel</button>
            </div>
        </div>
        <!-- </form> -->
    </div>