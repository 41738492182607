import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss']
})
export class ImageEditorComponent implements OnInit {
    type:string;
  constructor(
    public dialogRef: MatDialogRef<ImageEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.imageChangedEvent = this.data.info;
    this.type = this.data.type;
   }

  ngOnInit(): void {
  }
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

  cancel(){
    this.dialogRef.close(null);
    this.imageChangedEvent ='';
  }
/**
 * This is a function that can be use to convert edited base64 image file to file object for passing to api 
 */
  saveImage(){
    const file = base64ToFile(this.croppedImage);
    let formdata: FormData= new FormData();
    const fileBeforeCrop = this.imageChangedEvent.target.files[0];
    let fileToUpload:File= new File([file], `${new Date().getTime()}_${fileBeforeCrop.name}`, {type: fileBeforeCrop.type})
    formdata.append('file', fileToUpload);
    this.dialogRef.close(formdata);
  }

  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
      
  }

  imageLoaded() {
      this.showCropper = true;
      
  }

  cropperReady(sourceImageDimensions: Dimensions) {
      
  }

  loadImageFailed() {
      
  }

  rotateLeft() {
      this.canvasRotation--;
      this.flipAfterRotate();
  }

  rotateRight() {
      this.canvasRotation++;
      this.flipAfterRotate();
  }

  private flipAfterRotate() {
      const flippedH = this.transform.flipH;
      const flippedV = this.transform.flipV;
      this.transform = {
          ...this.transform,
          flipH: flippedV,
          flipV: flippedH
      };
  }


  flipHorizontal() {
      this.transform = {
          ...this.transform,
          flipH: !this.transform.flipH
      };
  }

  flipVertical() {
      this.transform = {
          ...this.transform,
          flipV: !this.transform.flipV
      };
  }

  resetImage() {
      this.scale = 1;
      this.rotation = 0;
      this.canvasRotation = 0;
      this.transform = {};
  }

  zoomOut() {
      this.scale -= .1;
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
  }

  zoomIn() {
      this.scale += .1;
      this.transform = {
          ...this.transform,
          scale: this.scale
      };
  }

  toggleContainWithinAspectRatio() {
      this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation() {
      this.transform = {
          ...this.transform,
          rotate: this.rotation
      };
  }
}
