/**
*
* <strong>List of API using</strong>
* <ol>
* <li>estapi_entity_masters_master_id_values_master_value_id_patch</li>
* </ol>
*
*/

import { Component, Inject, ElementRef } from "@angular/core";
import { FormArray, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { ApiserviceService } from "../../../../apiservice.service";
import { CommonfunctionService } from "../../../../services/commonfunction.service";
import { MatErrorStateMatcher } from "../../../admin-error-state-macher";
import { NotificationService } from "../../../services/notification.service";
import * as $ from 'jquery';

@Component({
  selector: 'master-value-dialog',
  templateUrl: 'admin-createvalue.component.html',
  styleUrls: ['admin-create-update-master-value.scss']
})
export class CreateMasterValueDialog {

  groups: any;
  masters: any;
  languages: Array<any>=[];
  language_valueset: Array<{}> = [];
  property_types: any;
  masterValueForm: any;
  status: any = true;
  status_more: boolean = false;
  isAddMore: boolean = false;
  isChecked = false;
  property_types_ids: any = [];
  public add_master_value = {
    added_by: this.api.user_id,
    master_id: 0,
    master_value_label: {},
    master_value_name: "val",
    parent_master_value_id: null,
    property_type_ids: [],
    sort_order: 0,
    status: 0
  };
  formData: FormData = new FormData();
  file: File;
  masterImage: string = '';
  imageUrl: string = null;
  loader: boolean;
  duplicateLang: any;
  get masterValueName(){
    return this.masterValueForm.get('master_name');
  }


  matcher = new MatErrorStateMatcher();
  constructor(
    public iconRegistry: MatIconRegistry,
    public sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<CreateMasterValueDialog>, @Inject(MAT_DIALOG_DATA) public data: any, private api: ApiserviceService,
    private fnc: CommonfunctionService, private notify: NotificationService, private _fb: FormBuilder, private elementRef: ElementRef) {
    this.groups = this.data.groups;
    this.languages = this.api.language_list;
    this.property_types = this.api.property_type_list;
    this.property_types_ids.push(this.data.pObj.selected_property_type);
    //set image
    this.masterValueForm = this._fb.group({
      master_name: ['', [Validators.required,Validators.pattern('^[a-zA-Z0-9_+]*$')]],
      master_id: [this.data.pObj.selected_master.id, Validators.required],
      group_id: [this.data.pObj.selected_item, Validators.required],
      property_type: [this.property_types_ids, Validators.compose([this.conditionalValidation('property')])],
      language: this._fb.array([])
    })
    this.masterImage = 'assets/images/banned.png';
    setTimeout(() => {
      let e = { value: this.data.pObj.selected_item }
      this.changeMaster(e);
    }, 100);
    // if edit
    if (this.data.edit) {

      if (this.data.edit = true) {
        this.isAddMore = true;
      } else {
        this.isAddMore = false;
      }
      // let master_data = this.fnc.getArrayValue('id',)
      this.masterValueForm = null;
      this.masterValueForm = this._fb.group({
        master_name: [{ value: '', disabled: true }],
        language: this._fb.array([])
      });
      let labels: any = [];
      let index = 0;
      this.masterValueForm.patchValue({ master_name: this.data.master_val.name });
      this.status = this.data.master_val.status;
      for (let prop in this.data.master_val.labels) {
        labels[index] = { language: +prop, value: this.data.master_val.labels[prop] };  // +prop to convert into number
        this.addRow();
        index++
      }
      this.masterValueForm.patchValue({ language: labels });
      this.add_master_value.master_id = this.data.master_val.id;
      this.add_master_value.property_type_ids = [this.data.master_val.property_type_id];
      this.add_master_value.master_value_name = this.data.master_val.name;

      if (this.data.pObj.selected_master_value && this.data.pObj.selected_master_value.image_url && this.data.edit) {
        this.masterImage = this.data.pObj.selected_master_value.image_url;
        this.imageUrl = this.data.pObj.selected_master_value.image_url;
      }
    } else {
      this.addRow();
      if(this.languages){
        this.masterValueForm.patchValue({ 'language': [{ language: this.languages[0].language_id, value: '' }] });
      }
    }

  }

  public getImage() {

  }
  uploadImage(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let pattern = /image-*/;
      if (!file.type.match(pattern)) {
        return;
      }
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event: any) => {
        this.masterImage = event.target.result;
      }
      this.file = file;
    }
  }
  openExplorer(type) {
    if (type == 'image') {
      let e = $(this.elementRef.nativeElement);
      e.find("#image_input").click();
    }
  }

  selectAll(e) {
    if (e.source) {
      if (e.source.value.indexOf('000') > -1) {
        this.property_types.forEach(v => {
          this.property_types_ids.push(v.property_type_id);
          this.property_types_ids.push('000');
        });
        this.masterValueForm.patchValue({
          property_type: this.property_types_ids
        });
      }
      if (e.source.value.indexOf('000') > -1 && e.source.value.length > 2) {
        this.masterValueForm.patchValue({
          property_type: e.source.value
        });
      }
      if (e.source.value.indexOf('000') < 0) {
        this.masterValueForm.patchValue({
          property_type: e.source.value
        });
      }
      // if (e.source.value.length === this.property_types.length) {
      //   this.masterValueForm.patchValue({
      //     property_type: []
      //   });
      // } else{
      //     this.masterValueForm.patchValue({
      //       property_type: e.source.value
      //     });
      //  }
    }

  }

  conditionalValidation(field) {
    if (this.data.pObj.is_property_dependent) {
      return Validators.required;
    } else {
      return Validators.nullValidator;
    }
  }

  initLanguageFields() {
    return this._fb.group({
      language: ['', Validators.required],
      value: ['', Validators.required]
    });
  }
  getCheck(e) {
    if (e) {
      this.status_more = e.checked;
    }
  }

  removeEleArray(key, arr) {
    const arr1: any = [];
    arr.forEach(element => {
      if (element === key.toString()) {
      } else {
        arr1.push(element);
      }
    });
    return this.removeDups(arr1);
  }
  removeDups(names) {
    const unique = {};
    names.forEach(function (i) {
      if (!unique[i]) {
        unique[i] = true;
      }
    });
    return Object.keys(unique);
  }

  uploadMasterFile(formValue, event) {
    //upload image
    const value = formValue.value;
    if (this.file != undefined || this.file != null) {
      let fileName = this.file.name;
      let Extension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
      if (Extension == "gif" || Extension == "png" || Extension == "jpeg" || Extension == "jpg") {
      } else {
        this.notify.notify("Upload 'gif, png, jpeg, jpg' file only", 'warn');
        return;
      }
      // add time in image name
      if (this.data.edit) {
        fileName = ((this.data.pObj.selected_master.title).trim()).replace(/ /g, '_') + '_' + new Date().getTime()+'_'+this.data.master_val.name.trim() + '.' + Extension;
      } else {
        fileName = ((this.data.pObj.selected_master.title).trim()).replace(/ /g, '_') + '_' + new Date().getTime()+'_'+value.master_name.trim().replace(/\ /gi, '_') + '.' + Extension
      }
      this.formData.append('file', this.file, fileName);
      this.loader =true;
      this.api.postFileUms('uploads/images?folder_name=property_image&city-code=' + this.api.city_id, this.formData, 'application/json')
        .subscribe({next: (data: any) => {
          this.loader =false;
          data = JSON.parse(data);
          this.masterImage = data.data;
          this.imageUrl = data.data;
          this.formData = new FormData();
          this.addMasterValue(formValue, event);
        },
          error:(err) => {
            this.loader =false;
            this.formData = new FormData();
            console.log(err)
          }}
        );
    } else {
      this.addMasterValue(formValue, event);
    }
  }
   // selected duplicate language
   selectedLang(languageList){
    languageList.forEach(lang => {
      this.duplicateLang = languageList.filter((val) => val.language === lang.language); // filtering duplicate languages list
    });
}
  addMasterValue(val, e) {
    let langList = this.masterValueForm.value.language; // selected language list
    this.selectedLang(langList)
    let isEnglishSelected = langList.find(ele => ele.language == 1); // finding english language selected or not
    if(this.duplicateLang.length > 1){
      this.notify.notify('You have selected same language multiple time', 'warn'); // if selected same language multiple time
      return
     }
    if(!isEnglishSelected){
      this.notify.notify('English language is required', 'warn'); // if english language not selected
      return
    }
    const value = val.value;
    value.language.forEach((v, k) => {
      this.add_master_value.master_value_label[v.language] = v.value;
    });
    if (this.status) {
      this.add_master_value.status = 1;
    } else {
      this.add_master_value.status = 0;
    }
    //update or add master value
    if (this.data.edit) {
      this.add_master_value['image_url'] = this.imageUrl;
      this.add_master_value['updated_by'] = this.api.user_id;
      this.loader = true;
      this.api.patchEmsData(this.data.pObj.selected_entity_type + '/masters/' +
        this.data.pObj.selected_master.id + '/values/' + this.data.master_val.id, this.add_master_value)
        .subscribe(
          { next :(data:any) => {
            this.loader =false;
            this.notify.notify('Master Value Updated', 'success');
            this.data.pObj.searchMasterValue(false, true);
            this.closeDialog(e);
          },
          error:(err) => {
            this.loader = false;
            this.notify.notify(err, 'error')
        }}
        );

    } else {
      let re = /\ /gi;
      this.add_master_value.master_value_name = value.master_name.trim().replace(re, '_');
      this.add_master_value.property_type_ids = null;
      if (this.data.pObj.is_property_dependent) {
        this.add_master_value.property_type_ids = this.removeEleArray('000', value.property_type);
      }
      this.add_master_value['image_url'] = this.imageUrl;
      this.add_master_value.master_id = value.master_id;
      this.loader = true;
      this.api.postEmsData(this.data.pObj.selected_entity_type + '/masters/' + this.add_master_value.master_id + '/values', this.add_master_value)
        .subscribe(
         { next:(data:any) => {
            this.loader =false;
            this.notify.notify('Master Value Created', 'success');
            this.data.pObj.searchMasterValue(false, true);
            this.masterValueForm.patchValue({ 'master_name': null, 'language': [{ language:'', value: '' }] })
            if(this.status_more){
              this.masterImage = 'assets/images/banned.png';
              this.masterValueForm.controls['master_name'].reset();
              this.masterValueForm.controls['language'].reset();
              this.masterValueForm.patchValue({'language': [{language: this.languages[0].language_id}] })
            }
            this.closeDialog(e);
          },
         error: (err) => {
            this.loader =false;
            this.notify.notify(this.fnc.getErrorMessage(err), 'error')
        }}
        );

    }

  }
  changeMaster(e: any) {
    let value = e.value;
    let child = this.fnc.getArrayValue('id', value, this.groups);
    //console.log(child);
    this.masters = child.childrens;
  }

  closeDialog(e): void {
    e.preventDefault();
    if (!this.status_more) {
      this.dialogRef.close();
    }else{
      this.status = true;
    }
  }

  closeDial(e): void {
    e.preventDefault();
    this.dialogRef.close();
  }


  addRow() {
    if (this.language_valueset?.length == this.languages?.length) {
      this.notify.notify("No more Languages Available to Add",'warn',4000);
      return;
    }
    this.language_valueset.push({ language: '', value: '' });
    /* #Form */
    let dd = <FormArray>this.masterValueForm.controls.language;
    dd.push(this.initLanguageFields());
    /* End - #Form */
  }

  removeRow(key) {
    if (this.language_valueset.length > 1) {
      this.language_valueset.splice(key, 1);
      let dd = <FormArray>this.masterValueForm.controls.language;
      dd.removeAt(key);
    }
  }

}
