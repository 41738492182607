<div class="container">
  <mat-toolbar  class="matpopup-header">
    <div>
      <b>Deletion Request Summary</b>
    </div>
    <button type="button" (click)="close()">
      <img src="assets/icon/Cross.svg" alt="">
    </button>
  </mat-toolbar>
  <div class="table-wrapper">
    <table mat-table [dataSource]="dataSource" class="">
      <!-- s_no Column -->
      <ng-container matColumnDef="s_no">
        <th mat-header-cell *matHeaderCellDef> S No</th>
        <td mat-cell *matCellDef="let element; let i = index"> {{i + 1}} </td>
      </ng-container>

      <!-- id Column -->
      <ng-container matColumnDef="attribute_name">
        <th mat-header-cell *matHeaderCellDef> Attribute Name </th>
        <td mat-cell *matCellDef="let element" [matTooltip]="element.attribute_name.length > 25 ? element.attribute_name:''"> {{element.attribute_name.length > 25 ? (element.attribute_name | slice:0:25) + '...':element.attribute_name}} </td>
      </ng-container>

       <!-- id Column -->
       <ng-container matColumnDef="entity_type">
        <th mat-header-cell *matHeaderCellDef> Entity Type </th>
        <td mat-cell *matCellDef="let element"> {{element.entity_type}} </td>
      </ng-container>

      <!-- added_by Column -->
      <ng-container matColumnDef="assignment_name">
        <th mat-header-cell *matHeaderCellDef> Assignment Name</th>
        <td mat-cell *matCellDef="let element" [matTooltip]="element.assignment_name.length > 25 ? element.assignment_name : ''"> {{element.assignment_name.length > 25 ? (element.assignment_name | slice:0:25)+"...": element.assignment_name}} </td>
      </ng-container>
      <!-- added_by Column -->
      <ng-container matColumnDef="log_id">
        <th mat-header-cell *matHeaderCellDef> Log Id</th>
        <td mat-cell *matCellDef="let element"> {{element.log_id}} </td>
      </ng-container>
      <!-- submitted_date  Column -->
      <ng-container matColumnDef="new_value">
        <th mat-header-cell *matHeaderCellDef> New Value </th>
        <td mat-cell *matCellDef="let element"> {{element.new_value}} </td>
      </ng-container>

    <!-- comment  Column -->
    <ng-container matColumnDef="property_uid">
      <th mat-header-cell *matHeaderCellDef> Property Uid </th>
      <td mat-cell *matCellDef="let element"> {{element.property_uid}} </td>
    </ng-container>

      <!-- submitted_date  Column -->
      <ng-container matColumnDef="submitted_date">
        <th mat-header-cell *matHeaderCellDef> Submitted Date </th>
        <td mat-cell *matCellDef="let element"> {{element.submitted_date}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"
          (click)="selection.toggle(row)">
      </tr>
    </table>
    <!-- <div class="not-data-found" *ngIf="!total">
      Choose options and click 'Apply' to load the data. {{dataSource.length}}
    </div> -->
    <!-- Paginator for the table -->

  </div>
  <mat-paginator [length]="totalCount" showFirstLastButtons [pageSizeOptions]="pageSizeOptions" (page)="pageAction($event)">
  </mat-paginator>
</div>
