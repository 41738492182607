import { Component, Input } from "@angular/core";
import { CommonfunctionService } from "../../../../services/commonfunction.service";

@Component({
  selector: 'master-contextmenu',
  templateUrl: '../../subelements/master-menu.component.html',
  styleUrls: ['master-context.component.scss']
})
export class MasterContextMenu {
  @Input('item') grouplist;
  @Input('openDialog') openDialog;
  @Input('mastergroups_access') mastergroups;
  @Input('masters_access') masters;

  mastergroups_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  }
  masters_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  }



  constructor(private fnc: CommonfunctionService) {

  }

  ngOnInit() {
    if (this.mastergroups_access && this.masters) {
      this.mastergroups_access = this.mastergroups;
      this.masters_access = this.masters;
    }
    //console.log(this.masters_access)
  }


}
