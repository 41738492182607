import { Component, OnInit } from "@angular/core"
import { ApiserviceService } from '../../apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { NotificationService } from '../services/notification.service';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { ViewReportComponent } from './view-report/view-report.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sync-property-log',
  templateUrl: './sync-property-log.component.html',
  styleUrls: ['./sync-property-log.component.css']
})
export class SyncPropertyLogComponent implements OnInit {
  status: any[] = [
    { status_code: -1, value: 'Invalid' },
    { status_code: 1, value: 'Valid' },
    { status_code: 0, value: 'Started' },
    { status_code: 2, value: 'Accepted' },
    { status_code: -2, value: 'Rejected' },

  ];
  sync_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  dataSource = []
  loader = false;
  loader1 = false;
  offset = 0;
  limit = 10;
  totalCount: number;
  pageSizeOptions: number[] = [5, 10, 25, 50];
  pageEvent: PageEvent;
  //  = ["sno",
  //   "Added Date",
  //   "Existing Count",
  //   "Parameter",
  //   "Process Log",
  //   "Process Name",
  //   "Success Count",
  //   "Target Count",
  //   "UID",
  //   "Updated Date",
  //   "Status",
  //   "Actions"]
  displayedColumns = ["sno",
    "Process Name",
    "Added Date",
    "Updated Date",
    "Existing Count",
    "New Count",
    "Process Log",
    "Status",]

  returnVal(element, cols) {
    switch (cols) {
      case 'Added Date': return this.getdateForm(element.added_date);
      case 'Existing Count': return element.existing_count;
      case 'Process Name': return element.process_name;
      case 'New Count': return element.new_count;
      case 'Target Count': return element.target_count;
      case 'Name': return element.uid;
      case 'Updated Date': return this.getdateForm(element.updated_date);
      case 'Status': return element.status;
    }
  }
  constructor(private api: ApiserviceService,
    private menu: AdminMenusService,
    private fnc: CommonfunctionService,
    private notify: NotificationService,
    public dialog: MatDialog,
    private route: Router) {
    this.menu.setActive('sync-property-log');
    this.getAllowResourcesApi();
  }

  // getting allow resources
  getAllowResourcesApi() {
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body)
      .subscribe((res: any) => {
        this.api.allowResources = res.data;
        this.sync_access = this.fnc.checkResourceAccess('calculateSyncProcess', false);
      }, err => {
      })
  }
  // getAllowResourcesApi() {
  //   let body = "place_id=" + this.api.city_id;
  //   this.api.postUmsData('user/resource', body)
  //     .subscribe(
  //       (data: any) => {
  //         this.api.allowResources = data;
  //         this.sync_access = this.fnc.checkResourceAccess('calculateSyncProcess', false);
  //       },
  //       err => {
  //       }
  //     )
  // }

  sync(type, uId) {
    if (!this.sync_access.POST) {
      this.notify.notify("You are not authorized to RUN process for Property Attribute Values", "warn",500);
      return;
    }
    this.notify.notify("Request has been submitted to server", 'success',5000);
    let url = `update-property-log`;
    let action;
    let uploadId;
    // if (type == 'validate') {
    //   action = type;
    //   uploadId = 0
    //   console.log(action);
    // }
    // if (type == 'accept') {
    //   action = type;
    //   uploadId = uId;
    //   console.log(action);
    // }
    var body = {
      "action": "validate",
      "isRefresh": true,
      "type": "attribute_value",
      "upload_id": uploadId,
      "user_id": this.api.user_id,
    }
    this.loader = true;
    this.api.postEpsData(url, body).subscribe((res: any) => {
      this.loader = false;
    })
  }

  getReports() {
    if (!this.sync_access.GET) {
      this.notify.notify("You are not authorized to GET Report for Sync Process", "warn",5000);
      return;
    }
    let url = `sync-calculate-process?layer=attribute&process_name=attribute_value&sort_asc=false&limit=${this.limit}&offset=${this.offset}`
    this.loader1 = true;
    this.api.getEpsData(url).subscribe((res: any) => {
      this.loader1 = false;
      if (res && res.length) {
        this.dataSource = res;
        this.totalCount = res.length;
      }
      else {
        this.notify.notify(res.message, 'success',5000);
      }

    })
  }

  getStatus(val) {
    return this.fnc.getArrayValue('status_code', val, this.status).value;
  }

  getdateForm(date: any) {
    if (date) {
      date = new Date(date);
      return this.fnc.formatDateUTC(date);
    }else {
      return '';
    }
  }
  openView(type: any, data: any = null) {
    if (type) {
      var _data = {
        type: type,
        data: data,
        pdata: this,
      }
    }
    const dialogRef = this.dialog.open(ViewReportComponent, {
      width: "400px",
      panelClass: "view-report",
      data: _data,
    })
    dialogRef.afterClosed().subscribe(result => {
    });
  }


  handlePagination(e) {
    this.limit = e.pageSize;
    if (e.previousPageIndex !== e.pageIndex) {
      this.offset = (e.pageSize * e.pageIndex);
    }
    this.getReports();
  }

  ngOnInit() {
  }

}
