import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'; 
import { AdminLoginComponent } from '../admin/admin-login/admin-login.component';
import { SelectCityComponent } from '../admin/city-select/city-select-component';

@NgModule({
  imports: [
    RouterModule.forRoot([
      { path: '',
        redirectTo: '/admin/login',
        pathMatch: 'full'
      },
      {path : 'admin/login', component : AdminLoginComponent},
      {path : 'admin/country', component : SelectCityComponent}

    ],{ enableTracing: false })
  ],
  declarations: [],
  exports : [RouterModule]
})
export class AppRoutingModule { }
