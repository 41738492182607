import { Component, Inject } from '@angular/core';
import { ApiserviceService } from '../../../../../../src/app/apiservice.service';
import { MatTableDataSource } from '@angular/material/table';
import { AttributeDeletionConfirmationComponent } from '../attribute-deletion-confirmation/attribute-deletion-confirmation.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-attribute-history-view',
  templateUrl: './attribute-history-view.component.html',
  styleUrls: ['./attribute-history-view.component.scss']
})
export class AttributeHistoryViewComponent {
  displayedColumns: string[] = ['s_no', 'attribute_name', 'assignment_name', 'entity_type', 'log_id', 'new_value', 'property_uid', 'submitted_date'];
  dataSource: any;
  ELEMENT_DATA:any
  deletedHistory: any;
  defaultPageSize: number = 10;
  pageSizeOptions;
  sizeOptions: string = "10, 15, 25, 50, 100";
  pageEvent: PageEvent;
  pageIndex = 0;
  limit: number = 10;
  offset: number = 0;
  totalCount:number
  constructor(
    private api : ApiserviceService,
    public dialogRef: MatDialogRef<AttributeDeletionConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){
    this.pageSizeOptions = this.sizeOptions.split(",").map((str) => +str);
  }
ngOnInit(): void {
this.getDeletedData()
}

tableInIt(){
    this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
}
close() {
  this.dialogRef.close();
}

/**
 * Handles the page change event for pagination.
 *
 * This method is triggered when the user interacts with the pagination controls.
 * It updates the `pageIndex`, `limit`, and `offset` values based on the current
 * page index and page size. If the page size (number of items per page) changes,
 * the offset is reset to 0. Otherwise, the offset is calculated based on the
 * current page index and page size.
 *
 * After updating the pagination values, the method calls `deleteRequestHistory()`
 * to fetch or update the data based on the new pagination settings.
 *
 * @param $event - The event object containing pagination data, including:
 *   - `pageIndex`: The current page index (0-based).
 *   - `pageSize`: The number of items per page (i.e., the page size).
 */
pageAction($event) {
  // Update the current page index.
  this.pageIndex = $event.pageIndex;

  // Check if the page size has changed.
  if (this.limit != $event.pageSize) {
    // If the page size has changed, update the limit and reset the offset to 0.
    this.limit = $event.pageSize;
    this.offset = 0;
  } else {
    // If the page size has not changed, calculate the new offset based on the page index.
    this.offset = this.pageIndex * $event.pageSize;
  }

  // Fetch or update the data based on the new pagination settings.
  this.getDeletedData();
}

getDeletedData(){
  let url = `delete-request-data?id=${this.data.view_id}&limit=${this.limit}&offset=${this.offset}&is_count=true`;
  this.api.getEpsData(url).subscribe({next:(res)=>{
    let result:any = res;
    if(result.status == 200){
      this.ELEMENT_DATA = result.data;
      if(this.offset == 0){
        this.totalCount = result.count;
      }
      this.tableInIt();
    }
  },
  error(err){}})
}
}
