import { Component, Inject, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '../../../../../../src/app/admin/services/notification.service';
import { ApiserviceService } from '../../../../../../src/app/apiservice.service';

@Component({
  selector: 'app-copy-attribute-across-cities',
  templateUrl: './copy-attribute-across-cities.component.html',
  styleUrls: ['./copy-attribute-across-cities.component.scss']
})
export class CopyAttributeAcrossCitiesComponent {
  @Input() selectedAttributeList:any;
  options: { value: string; viewValue: string }[] = [];
  copyAttributesGroup: FormGroup;
  citieslist: { value: string; viewValue: string }[] = [];
  filteredCities: { value: string; viewValue: string }[] = []; // Add this property for filtered cities
  isAllSelected = false;

  constructor(
    public dialogRef: MatDialogRef<CopyAttributeAcrossCitiesComponent>,
    private api: ApiserviceService,
    private fb: FormBuilder,
    private notify: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.copyAttributesGroup = this.fb.group({
      city_selection: [[]], // Initialize with an empty array
    });
    this.getCityList();
    console.log(this.api.city_id, "city id");


  }

  getCityList() {
    this.api.getUmsData("country-with-city").subscribe({
      next: (res: any) => {
        this.citieslist = this.formatCityList(res.data);
        this.filteredCities = this.citieslist; // Initialize filteredCities
      },
      error: (err) => {
        console.error("Error fetching city list:", err);
      },
    });
  }

  formatCityList(data: any[]): { value: string; viewValue: string }[] {
    let cityList = [];
    data.forEach((element) => {
      cityList.push({
        value: element.place_id,
        viewValue: element.place_name,
      });
      if (element.child_data) {
        element.child_data.forEach((child) => {
          cityList.push({
            value: child.place_id,
            viewValue: `${child.place_name} (${element.place_name})`,
          });
        });
      }
    });
    return cityList;
  }

  filterCities(searchTerm: string) {
    searchTerm = searchTerm.toLowerCase(); // Normalize the search term
    this.filteredCities = this.citieslist.filter(city =>
      city.viewValue.toLowerCase().includes(searchTerm) // Filter based on the search term
    );
  }

  onCityRemoved(city: { value: string; viewValue: string }) {
    this.isAllSelected = false;
    const cities = this.copyAttributesGroup.value.city_selection as { value: string; viewValue: string }[];
    this.removeFirst(cities, city);
    this.copyAttributesGroup.get("city_selection")?.setValue(cities);
    this.checkAllSelected(false);
  }

  public removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  submit() {
    let selectedCityIds;
    let selectedAttribute;
    const selectedCities = this.copyAttributesGroup.get("city_selection")?.value || [];
    if (!this.isAllSelected) {
      selectedCityIds = selectedCities.map(
        (city: { value: number; viewValue: string }) => city.value
      );
    }
    // selectedAttribute = this.data.map(
    //   (attributeList: { attribute_id: number }) => attributeList.attribute_id
    // );
    let url = `copy-attributes-cross-db`;
    let body = {
      selected_attr: this.selectedAttributeList,
      target_cities: selectedCityIds,
      copy_to_all: this.isAllSelected,
      user_id:this.api.user_id,
    };
    this.api.postEmsData(url, body).subscribe({
      next: (res: any) => {
        this.notify.notify(res.message, "success");
        this.dialogRef.close(true);
      },
      error: (err) => {},
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  selectAll(checked: boolean) {
    this.isAllSelected = checked; // Update the isAllSelected state
    if (checked) {
      const allCities = this.citieslist.map((city: any) => city); // Get all cities as objects
      this.copyAttributesGroup.get("city_selection")?.setValue(allCities);
    } else {
      this.copyAttributesGroup.get("city_selection")?.setValue([]);
    }
  }

  checkAllSelected(checked) {
    const selectedCities = this.copyAttributesGroup.get("city_selection")?.value || [];
    this.isAllSelected = selectedCities.length === this.citieslist.length; // Update isAllSelected based on selected cities
  }

  selectionChange() {
    if (this.copyAttributesGroup.value.city_selection.length == this.citieslist.length) {
      this.isAllSelected = true;
    } else {
      this.isAllSelected = false;
    }
  }
    // Called when the dropdown is opened/closed
    onDropdownOpened(opened: boolean, searchInput: HTMLInputElement) {
      if (!opened) {
        // Clear the search input when the dropdown is closed
        searchInput.value = '';
        this.filterCities(''); // Reset the filtered cities if needed
      }
    }
}
