<div class="login-wrapper" *ngIf="isLocalServe || isMobile">
  <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
  <div class="login-middle" [ngClass]="{'forgotpassword': showForgot}">
    <div class="logo-contain">
      <img src="/assets/images/logo.svg" class="center logo" />
    </div>
    <div class="login-container" *ngIf="!showForgot">
          <div class="header pt-2 pb-3">
            <mat-card-title class="pt-2">Admin Login</mat-card-title>
          </div>
          <form [formGroup]="loginForm" (ngSubmit)="doLogin(loginForm.value)">
            <div class="inside-content">
              <mat-form-field floatLabel="never" class="w-100">
                <mat-icon matPrefix class="mat-icons">email</mat-icon>
                <input matInput type="email" placeholder="Enter your email address" required formControlName="email" autocomplete="address-level1" [class.is-invalid]="email.invalid && email.touched">

              </mat-form-field>
              <mat-form-field floatLabel="never" class="w-100">
                <mat-icon matPrefix class="mat-icons">lock_outline</mat-icon>
                <input type="password" matInput placeholder="Enter your password" required formControlName="password" autocomplete="new-password" [class.is-invalid]="password.invalid && password.touched"
                  [type]="hide ? 'password' : 'text'">
                <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
              </mat-form-field>

              <!-- <div class="catp-d">
                      <div class="cap-img">
                        <re-captcha required (resolved)="resolved($event)" siteKey="6LcrU60UAAAAACWPSjhzScna9MxRym1WiSNzEFky"></re-captcha>
                      </div>
                    </div> -->
              <div *ngIf="error" class="login-err">{{error}}</div>
              <div class="button-row">
                <button [disabled]="loginForm.invalid" class="login-btn">
                  <span *ngIf="!login_action">Login</span>
                  <span *ngIf="login_action">Logging in...</span>
                  <mat-icon *ngIf="!login_action" class="mat-icons-sm">arrow_forward</mat-icon>
                </button>
              </div>
            </div>
          </form>
          <div class="forgot-pass-btn" (click)="jumpForgot()">
            Forgot Password?
          </div>

    </div>

    <div class="forgot-container" *ngIf="showForgot">
      <div class="inside-content">
        <div class="tab_header">Forgot Password</div>
        <p class="text">Enter your email. We'll email instructions on how to reset your password</p>
        <form [formGroup]="forgetForm" (ngSubmit)="forgetPassword(forgetForm.value)">
        <mat-form-field floatLabel="never" class="w-100">
          <mat-icon matPrefix class="mat-icons">email</mat-icon>
          <input formControlName="email" matInput placeholder="Enter your email address" type="email" [class.is-invalid]="forgotemail.invalid && forgotemail.touched">
        </mat-form-field>
       <div style="text-align: center; padding: 10px;">
        <span *ngIf="f_error" class="forget-err">{{forget_message}}</span>
        <span *ngIf="!f_error" class="forget-success">{{forget_message}}</span>
       </div>
        <div class="button-row">
          <button type="button" mat-button color="primary" (click)="jumpForgot()">
            <mat-icon class="mat-icons-sm">arrow_backward</mat-icon> Go back
          </button>
          <button [disabled]="forgetForm.invalid || loader" class="login-btn" type="submit">Submit
            <mat-icon class="mat-icons-sm">arrow_forward</mat-icon>
          </button>
        </div>
      </form>
      </div>
    </div>

  </div>
</div>
