import { Component, Inject, OnInit } from "@angular/core";
import { MatErrorStateMatcher } from "../../admin-error-state-macher";
import { FormArray, FormBuilder, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
// import { ApiserviceService } from "src/app/apiservice.service";
import { NotificationService } from "../../services/notification.service";
import { CommonfunctionService } from "../../../services/commonfunction.service";
import { ApiserviceService } from "../../../apiservice.service";

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_propertytypes_property_type_id_patch</li>
 * <li>estapi_propertytypes_post</li>
 * </ol>
 *
 */



@Component({
  selector: "createproperty",
  templateUrl: "./admin-createproperty.component.html",
  styleUrls: ["./admin-createproperty.component.scss"],
})
export class CreatePropertyTypeDialogComponent implements OnInit {
  languages: Array<any>;
  language_valueset: Array<{}> = [];
  propertyForm: any = [];
  status: boolean = true;
  add_property_type = {
    added_by: this.api.user_id,
    property_type_label: {},
    property_type_name: "",
    sort_order: 0,
    status: 1,
  };
  duplicateLang: any;
  get propertyTypeName() {
    return this.propertyForm.get("property_type_name");
  }
  matcher = new MatErrorStateMatcher();
  constructor(
    public dialogRef: MatDialogRef<CreatePropertyTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: ApiserviceService,
    private fb: FormBuilder,
    private notify: NotificationService,
    private fnc: CommonfunctionService
  ) {
    this.languages = this.api.language_list;

    this.propertyForm = this.fb.group({
      property_type_name: [
        "",
        [Validators.required, Validators.pattern("^[a-zA-Z0-9_]*$")],
      ],
      language: this.fb.array([]),
    });
    if (this.data.edit) {
      this.propertyForm = this.fb.group({
        property_type_name: [{ value: "", disabled: true }],
        language: this.fb.array([]),
      });
      let labels = [];
      let index = 0;
      for (let prop in this.data.val.languages) {
        labels[index] = {
          language: +prop,
          value: this.data.val.languages[prop],
        }; // +prop to convert into number
        this.addRow();
        index++;
      }
      this.status = this.data.val.status;
      setTimeout(() => {
        this.propertyForm.patchValue({
          property_type_name: this.data.val.name,
          language: labels,
        });
      });
      // this.propertyForm.patchValue({ 'property_type_name': this.data.val.name, 'language': labels });
    } else {
      this.addRow();
      this.propertyForm.patchValue({
        language: [{ language: this.languages[0].language_id, value: "" }],
      });
    }
  }

  ngOnInit() {

  }
  // selected duplicate language
  selectedLang(languageList) {
    languageList.forEach((lang) => {
      this.duplicateLang = languageList.filter(
        (val) => val.language === lang.language
      ); // filtering duplicate languages list
    });
  }
  savePropertyType(val) {
    let langList = this.propertyForm.value.language; // selected language list
    this.selectedLang(langList);
    let isEnglishSelected = langList.find((ele) => ele.language == 1); // finding english language selected or not
    if (this.duplicateLang.length > 1) {
      this.notify.notify(
        "You have selected same language multiple time",
        "warn"
      ); // if selected same language multiple time
      return;
    }
    if (!isEnglishSelected) {
      this.notify.notify("English language is required", "warn"); // if english language not selected
      return;
    }
    let value = val.value;
    let re = /\ /gi;
    this.add_property_type.property_type_name =
      this.propertyForm.controls.property_type_name.value.replace(re, "_");
    value.language.forEach((v, k) => {
      this.add_property_type.property_type_label[v.language] = v.value;
    });
    if (this.status) {
      this.add_property_type.status = 1;
    } else {
      this.add_property_type.status = 0;
    }
    if (this.data.edit) {
      // console.log(this.data.val.id);
      this.add_property_type["updated_by"] = this.api.user_id;
      this.api
        .patchEmsData(
          "propertytypes/" + this.data.val.id,
          this.add_property_type
        )
        .subscribe(
          (data: any) => {
            this.notify.notify("Property type updated", "success");
            // this.data.pObj.getPropertyType('property_type_name', 10000, 0,'0,1');
          },
          (err) => {
            this.notify.notify(this.fnc.getErrorMessage(err), "error");
          },
          () => this.closeDialog(true)
        );
    } else {
      this.api.postEmsData("propertytypes", this.add_property_type).subscribe(
        (data: any) => {
          this.notify.notify("Property type added", "success");
          // this.data.pObj.getPropertyType('property_type_name', 10000, 0, '0,1');
        },
        (err) => this.notify.notify(this.fnc.getErrorMessage(err), "error"),
        () => this.closeDialog(true)
      );
    }
  }

  initLanguageFields() {
    return this.fb.group({
      language: ["", Validators.required],
      value: ["", Validators.required],
    });
  }
  closeDialog(val): void {
    this.dialogRef.close(val);
  }

  addRow() {
    if (this.language_valueset?.length == this.languages?.length) {
      this.notify.notify("No more languages available to add", "warn", 4000);
      return;
    }
    this.language_valueset.push({ language: "", value: "" });
    /* #Form */
    let dd = <FormArray>this.propertyForm.controls.language;
    dd.push(this.initLanguageFields());
    /* End - #Form */
  }

  removeRow(key) {
    if (this.language_valueset.length > 1) {
      this.language_valueset.splice(key, 1);
      let dd = <FormArray>this.propertyForm.controls.language;
      dd.removeAt(key);
    }
  }
}
