<form [formGroup]="labelValueForm" (ngSubmit)="addLabel(labelValueForm, $event)">
    <mat-toolbar class="creategroup">
        <div>Add New Geometry</div>
    </mat-toolbar>
    <mat-dialog-content>
        <div formArrayName="language">
            <div *ngFor="let languagevalues of language_valueset;let kk = index">
                <div [formGroupName]="kk" fxLayout="row" fxLayoutAlign="space-between start" style="gap: 10px;">
                    <mat-form-field floatLabel="never" fxFlex="40%">
                        <mat-select placeholder="Language" formControlName="language">
                            <mat-option *ngFor="let language of languages" [value]="language.language_id">
                                {{ language.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field floatLabel="never" fxFlex="40%">
                        <input matInput type="text" placeholder="Label" formControlName="value">
                        <!--<mat-hint>Hint message goes here</mat-hint>-->
                        <mat-error>Label is required</mat-error>
                    </mat-form-field>

                    <div fxFlex="10%">
                        <button type="button" mat-icon-button (click)="removeRow(kk)">
                            <mat-icon>remove</mat-icon>
                        </button>
                    </div>
                    <div>
                        <button type="button" mat-icon-button (click)="addRow()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="dialog-actions">
        <button [disabled]="labelValueForm.invalid" mat-button class="blue-button" style="min-width: 90px;">Save</button>
        <button mat-raised-button type="button" (click)="close();">Cancel</button>
    </mat-dialog-actions>
</form>