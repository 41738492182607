import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiserviceService } from "../../../apiservice.service";

/**
*
* <strong>List of API using</strong>
* <ol>
* <li>estapi_attribute_datatypes_get</li>
* </ol>
*
*/

@Component({
    selector: 'info-dialog',
    templateUrl: './info-dialog.component.html',
    styleUrls: ['./info-dialog.component.css']
  })
  
  export class InfoDialog {
    dataTypesList: any = [];
    loader: boolean = false;
    constructor(public dialogRef: MatDialogRef<InfoDialog>,
      @Inject(MAT_DIALOG_DATA) public data: any, private api: ApiserviceService) {
      this.getDataTypes();
    }
  
    getDataTypes() {
      this.loader = true;
      this.api.getEmsData("/attribute/datatypes").subscribe({
        next: (data) => {
          this.dataTypesList = data;
          this.loader = false;
        },
        error: () => {
          this.loader = false;
        },
      });
    }
    ngOninit() { }
  }
  