<mat-toolbar class="matpopup-header">
  <div>Copy  Attributes</div>
</mat-toolbar>
<div class="wrapper">
  <mat-radio-group [(ngModel)]="copySelected">
    <mat-radio-button value="entity">Copy in Entity</mat-radio-button>
    <mat-radio-button value="cities">Copy in Cities</mat-radio-button>
  </mat-radio-group>
  <ng-container *ngIf="!getBooleanValue()">
    <div class="">
      <div class="search-bar">
        <mat-form-field class="">
        <mat-select [(ngModel)]="selected_entity_type" (selectionChange)="getEntity($event)" class=""
          placeholder="Entity">
          <mat-option *ngFor="let ent of api.entity_type_list" [value]="ent.entity_name">{{ent.entity_name}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="" *ngIf="is_property_dependent">
        <mat-select  [(ngModel)]="selected_property_types" multiple class=""
          placeholder="Property Type">
          <mat-option *ngFor="let prp of api.property_type_list;"
            [value]="prp.property_type_id">{{prp.property_type_label[api.language]}}</mat-option>
        </mat-select>
      </mat-form-field>
      </div>
    </div>
    <div class="center">
      <button mat-button class="paste-btn" (click)="pasteAttributes()"
        [disabled]="!selected_entity_type || (is_property_dependent == true && selected_entity_type == 'property_unit' && selected_property_types.length == 0)"
        [ngClass]="{'dis': !selected_entity_type || (is_property_dependent == true && selected_entity_type == 'property_unit' && selected_property_types.length == 0)}">
        Paste
      </button>
      <button mat-button class="cancel-btn" (click)="close()">
        Cancel
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="getBooleanValue()">
    <app-copy-attribute-across-cities [selectedAttributeList]="selected_attr_list"></app-copy-attribute-across-cities>
  </ng-container>
</div>
