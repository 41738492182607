import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiserviceService } from '../../../apiservice.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-copy-assignment-dialog',
  templateUrl: './copy-assignment-dialog.component.html',
  styleUrls: ['./copy-assignment-dialog.component.css']
})
export class CopyAssignmentDialogComponent implements OnInit {

  assignmentForm: FormGroup;
  languages: any=[];
  language_valueset: any=[];
  yearList: any=[];
  search_year:string='';
  duplicateLang: any;
  get assignmentName(): AbstractControl{
      return this.assignmentForm.get('new_assignment_name');
  }
  get Year(): AbstractControl{
    return this.assignmentForm.get('year');
}

  assignment_name:any;
  constructor(public dialogRef: MatDialogRef<CopyAssignmentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb:FormBuilder, private api: ApiserviceService,
    private alert: NotificationService,  private notify: NotificationService,) {
    this.assignment_name = this.data?.selectAssign.name;
    this.getYears();
console.log(data);

    this.getLanguages();

    this.assignmentForm = this.fb.group({
        new_assignment_name: ['',[Validators.required, Validators.maxLength(55), Validators.minLength(3) ,Validators.pattern('^[a-zA-Z0-9_]*$')]],
        language: this.fb.array([]),
        with_property_details:[true],
        with_user_list:[true],
        year: ['', Validators.required]
    })
    let labels: any = [];
      let index = 0;
      this.assignmentForm.patchValue({ new_assignment_name: this.data?.selectAssign.name+'_copy' });
      this.assignmentForm.patchValue({ year: this.data?.selectAssign?.year.toString() });
      for (let prop in this.data?.selectAssign.lang) {
        labels[index] = { language: prop, assignment_label: this.data?.selectAssign.lang[prop]+' copy' };
        this.addRow();
        index++
      }
      setTimeout(() => {
        this.assignmentForm.patchValue({ language: labels });
      }, 100);
  }

  closeMatSelectEvent(event){
    if(!event){
      this.search_year = '';
    }
  }

    //create year dropdown
    getYears() {
      const currentYear = new Date().getFullYear()
      const startYear  = 2009;
      for (var i = currentYear; i >= startYear; i--) {
        this.yearList.push({ val: (i).toString() });
      }
    }

  ngOnInit(): void {
  }
  // get Languages
  getLanguages() {
      if (!this.api.language_list) {
        this.api.getUmsData('language')
          .subscribe(
            (res: any) => {
              if (res.data.length) {
                res.data.forEach((v) => {
                  let lang = {
                    id: v.language_id,
                    name: v.name
                  }
                  this.languages.push(lang);
                });
              }
            }
          );
      } else {
        this.api.language_list.forEach((v) => {
          let lang = {
            id: v.language_id,
            name: v.name
          }
          this.languages.push(lang);
        });
      }
    }


  //init Language Fields
  initLanguageFields() {
    return this.fb.group({
      language: [this.languages[0].id, Validators.required],
      assignment_label: ['', Validators.required]
    })
  }
  addRow() {
    if (this.language_valueset?.length == this.languages?.length) {
       this.alert.notify("No more Languages Available to Add",'warn',4000);
      return;
    }
    this.language_valueset.push({ language: '', value: '' });
    let dd = <FormArray>this.assignmentForm.controls.language;
    dd.push(this.initLanguageFields());
  }
  removeRow(key) {
    if (this.language_valueset.length > 1) {
      this.language_valueset.splice(key, 1);
      let dd = <FormArray>this.assignmentForm.controls.language;
      dd.removeAt(key);
    }
  }
     // selected duplicate language
     selectedLang(languageList){
      languageList.forEach(lang => {
        this.duplicateLang = languageList.filter((val) => val.language === lang.language); // filtering duplicate languages list
      });
  }
  // CreateAssignment
  CreateAssignment(formData){
    let langList = this.assignmentForm.value.language; // selected language list
    this.selectedLang(langList)
    let isEnglishSelected = langList.find(ele => ele.language == 1); // finding english language selected or not
    if(this.duplicateLang.length > 1){
      this.notify.notify('You have selected same language multiple time', 'warn'); // if selected same language multiple time
      return
     }
    if(!isEnglishSelected){
      this.notify.notify('English language is required', 'warn'); // if english language not selected
      return
    }
    if(this.data?.selectAssign?.id){
    let labelJson={};
      formData.language.map((ele:any)=>{
        labelJson[ele.language] = ele.assignment_label;
      })
      let body = {
          "action_by": this.api.user_id,
          "from_assignment_id": this.data?.selectAssign?.id,
          "assignment_label": labelJson,
          "assignment_name": formData.new_assignment_name,
          "with_properties": formData.with_property_details,
          "with_users": formData.with_user_list,
          "year": formData.year
      }

      this.api.postEmsData(`copy-assignment`, body).subscribe({next:(res:any)=>{
        if(res?.assignment_id){
          this.alert.notify('Copy Assignment Successful', 'success', 5000);
          this.dialogRef.close(true);
        }else{
          this.alert.notify('Assignment Copy Failed', 'warn', 5000);
        }
      }, error: (err)=>{
        // this.alert.notify(err.error.message, 'error', 8000);
      }})
    }else{
      this.alert.notify('Selected assignment id not found', 'warn', 5000);
    }

  }
  closeDialog(){
    this.dialogRef.close(false);
  }
}
