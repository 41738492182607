<form [formGroup]="masterForm" (ngSubmit)="createMaster(masterForm)">
    <mat-toolbar class="creategroup">
        <div *ngIf="!data.edit">Create Master</div>
        <div *ngIf="data.edit">Update Master</div>
    </mat-toolbar>
    <mat-dialog-content>
        <mat-form-field floatLabel="never" class="w-100">
            <input matInput [errorStateMatcher]="matcher" type="text" placeholder="Master Name" maxlength="64" #master_name formControlName="master_name">
            <mat-hint>Enter master name here</mat-hint>
            <mat-hint align="end">{{master_name.value.length}}/64</mat-hint>
            <mat-error *ngIf="masterName.hasError('required')">Master name is required</mat-error>
            <mat-error *ngIf="masterName.hasError('pattern')"> Special Characters are not allowed </mat-error>
        </mat-form-field>
        <div formArrayName="language">
            <div *ngFor="let languagevalues of language_valueset;let master = index">
                <div [formGroupName]="master" fxLayout="row" fxLayoutAlign="space-between start">
                    <mat-form-field floatLabel="never" fxFlex="30%">
                        <mat-select placeholder="Language" formControlName="language">
                            <mat-option *ngFor="let language of languages" [value]="language.language_id">
                                {{ language.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field floatLabel="never" fxFlex="40%">
                        <input matInput type="text" [errorStateMatcher]="matcher" maxlength="64" placeholder="Label" #master_label formControlName="value">
                        <!--<mat-hint>Hint message goes here</mat-hint>-->
                        <mat-hint align="end">{{master_label.value.length}}/64</mat-hint>
                        <mat-error>Label is required</mat-error>
                    </mat-form-field>
                    <div class="addbtn">
                      <div (click)="removeRow(master)" class="border iconTick-1">
                          <mat-icon>remove</mat-icon>
                      </div>
                      <div (click)="addRow()" class="border iconTick-1">
                          <mat-icon>add</mat-icon>
                      </div>
                  </div>
                </div>
            </div>
        </div>
        <mat-form-field floatLabel="never" class="w-100" *ngIf="!data.sub_master">
            <mat-select placeholder="Parent Master" [errorStateMatcher]="matcher" formControlName="parent_master">
                <mat-option *ngFor="let mst of masters" [value]="mst.id">{{mst.title}}</mat-option>
            </mat-select>
            <!-- <mat-error>Group is required</mat-error> -->
        </mat-form-field>

    </mat-dialog-content>
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="dialog-actions">
        <button mat-raised-button type="submit" [disabled]="masterForm.invalid" class="blue-button">Submit</button>
        <button type="button" mat-raised-button (click)="closeDialog();">Cancel</button>
    </mat-dialog-actions>
</form>
