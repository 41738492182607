<form [formGroup]="masterValueForm" autocomplete="off" novalidate>
  <div *ngIf="loader" class='loader-div'>
      <ngx-loading [show]="loader"></ngx-loading>
  </div>
  <mat-toolbar class="creategroup">

      <div>Edit Master Value</div>
  </mat-toolbar>
  <mat-dialog-content>
      <mat-form-field floatLabel="never" class="w-100">
          <input matInput type="text" [disabled]="true" placeholder="Master Value Name" formControlName="master_name">

      </mat-form-field>
      <div formArrayName="language">
          <div *ngFor="let languagevalues of language_valueset;let kk = index">
              <div [formGroupName]="kk" fxLayout="row" fxLayoutAlign="space-between start">
                  <mat-form-field floatLabel="never" fxFlex="30%">
                      <mat-select placeholder="Language" formControlName="language">
                          <mat-option *ngFor="let language of languages" [value]="language.language_id">
                              {{ language.name }}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
                  <mat-form-field floatLabel="never" fxFlex="40%">
                      <input [errorStateMatcher]="matcher" matInput type="text" placeholder="Label" formControlName="value">
                      <!--<mat-hint>Hint message goes here</mat-hint>-->
                      <mat-error>Label is required</mat-error>
                  </mat-form-field>
                  <div class="addbtn">
                    <div (click)="removeRow(kk)" class="border iconTick-1">
                        <mat-icon>remove</mat-icon>
                    </div>
                    <div (click)="addRow()" class="border iconTick-1">
                        <mat-icon>add</mat-icon>
                    </div>
                </div>
              </div>
          </div>
      </div>

      <input id="image_input" (change)="uploadImage($event)" class="hidden" accept="image/jpeg,image/gif,image/png,image/jpg" type="file" />
      <div class="searchbar" fxLayout="row" fxLayoutAlign="space-between center">
          <button (click)="openExplorer('image')" mat-stroked-button color="primary" class="cent">
          <i class="material-icons mr-1">cloud_upload</i> Upload Icon
      </button>
          <!-- <mat-icon id="pPic" *ngIf="masterImage_svg" svgIcon="master_image"></mat-icon> -->
          <img *ngIf="masterImage" id="pPic" src="{{masterImage}}" alt="Picture">
      </div>
      <div class="searchbar">
          <mat-slide-toggle class="example-margin" [checked]="status" [(ngModel)]="status" [ngModelOptions]="{standalone: true}">
              Status
          </mat-slide-toggle>
       </div>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="dialog-actions">
      <button mat-raised-button [disabled]="masterValueForm.invalid" class="blue-button" (click)="uploadMasterFile(masterValueForm,$event)">Submit</button>
      <button mat-raised-button type="button" (click)="closeDial($event)">Cancel</button>
  </mat-dialog-actions>
</form>
