import { Component, OnInit, HostListener, ViewChild} from '@angular/core';
import { ApiserviceService } from '../../apiservice.service';
import { NotificationService } from '../services/notification.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { AdminBreadcrumbService } from '../admin-breadcrumb/services/admin-breadcrumb.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { Router } from '@angular/router';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { ShareService } from '../services/share.service';
import { environment } from "../../../environments/environment";
import { ReportsDialog } from './reports/report-dialog.component';
import { InfoDialog } from './info-dialog/info-dialog.component';


/**
*
* <strong>List of API using</strong>
* <ol>
* <li>umsapi_language_get</li>
* <li>umsapi_user_resource_post</li>
* <li>estapi_assignments_get</li>
* <li>estapi_moduletypes_get</li>
* <li>estapi_entities_get</li>
* <li>estapi_propertytypes_get</li>
* <li>estapi_mastergroups_get</li>
* <li>estapi_upload_type_uploads_csv_post</li>
* <li>estapi_upload_type_uploads_template_get</li>
* <li>estapi_upload_type_uploads_status_get</li>
* <li>estapi_upload_type_uploads_upload_id_accept_post</li>
* <li>estapi_upload_type_uploads_upload_id_reject_post</li>
* </ol>
*
*/

@Component({
  selector: 'app-csv-upload',
  templateUrl: './csv-upload.component.html',
  styleUrls: ['./csv-upload.component.css']
})
export class CsvUploadComponent implements OnInit {
  loading: boolean = false;
  fileToUpload: any;
  selected_lang: any;
  language_list: any = [];
  is_property_dependent: boolean;
  is_master_dependent: boolean;
  selected_entity_type: string;
  selected_property_type: any;
  selected_File_Type: string;
  selected_master_group: string;
  selected_module_type: any;
  selected_assignment: any;
  upload_type_list: any;
  show_entity: boolean;
  removable: boolean = true;
  upload_type: any;
  conflict_id: any;
  searchType = ''
  searchText = '';
  search_unittype = '';
  searchAssignment = '';
  refresh: boolean = true;
  selectable: boolean = true;
  addOnBlur: boolean = true;
  showConflict: boolean = false;

  upload_report: any = [];
  masterGroupList: any[];
  moduleTypeList: any = [];
  assignmentList: any = [];

  pages: any = [];
  limit: number = 10;
  offset: number = 0;
  total: number;
  sizeOptions: string = '5, 10, 15, 25';
  pageSizeOptions: any;
  defaultPageSize: number = 10;
  pageEvent: PageEvent;
  pageIndex = 0;
  download = "Download Template";
  height: any;
  loader: boolean = false;
  fileName: string = '';

  upload_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  fileStatus: any = {
    '0': 'Uploaded',
    '1': 'Valid',
    '-1': 'Invalid',
    '2': 'Accepted',
    '-2': 'Rejected',
    '3': 'Processing',
    '-3': 'Duplicate Property',
    '4' : 'In Progress'
  }

  fileStatusForMaster: any= {
    '0': 'Uploaded',
    '1': 'Valid',
    '-1': 'Invalid',
    '2': 'Accepted',
    '-2': 'Rejected',
    '3': 'Processing',
    '-3': 'Accept Invalid',
    '4' : 'In Progress'
  }

  useNewApi: boolean = true;
  @ViewChild(MatPaginator) paginator: MatPaginator
  download_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  @ViewChild('myFileInput') myFileInput;
  showChunk:boolean=false;
  constructor(private api: ApiserviceService,
    private fnc: CommonfunctionService,
    private menus: AdminMenusService,
    private _crumbs: AdminBreadcrumbService,
    private notify: NotificationService,
    private route: Router,
    public dialog: MatDialog,
    private share: ShareService) {
    this.share.csv_obj = this;
    this.pageSizeOptions = this.sizeOptions.split(',').map(str => +str);
    this.menus.setActive('csv-upload');
    this.onResize();
    this.getEntityType();
    this.getAllowResourcesApi();
    this.getLangs();

    this.upload_type_list = [
      {
        master_label: 'Masters',
        master_value: 'masters',
        is_entity_dependent: true
      },
      {
        master_label: 'Unlink Master Value',
        master_value: 'unlink_master_value',
        is_entity_dependent: true
      },
      {
        master_label: 'Properties',
        master_value: 'properties',
        is_entity_dependent: false
      },
      {
        master_label: 'Attributes',
        master_value: 'attributes',
        is_entity_dependent: true
      }
    ]
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.height = (window.innerHeight - 56);
  }
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification(event) {
    if (this.loading) {
      if (confirm("File upload is still under progress! If you leave, your changes will be lost.")) {
        return true;
      } else {
        return false;
      }
    }
  }
  resolveConflict(id?) {
    this.showConflict = !this.showConflict;
    this.getUploadStatus();
    if (id) {
      this.conflict_id = id;
    }
  }

  changeApi(e) {
    // this.useNewApi = !e.checked;
    this.loader = false;
    this.limit = 10;
    this.offset = 0;
    this.getUploadStatus();
  }

  //get language list
  getLangs() {
    this.api.getUmsData("language").subscribe({
      next: (res: any) => {
        if (res && res.data) {
          res.data.forEach((element) => {
            if (element.status == 1) {
              this.language_list.push(element);
            }
          });
        }
      },
      error: (err) => {
      },
    });
    // this.api.getUmsData('language')
    //   .subscribe(
    //     (data: any) => {
    //       if (data.length > 0) {
    //         this.language_list = data;
    //         //  this.selected_lang = this.fnc.getArrayValue('language_id', this.api.language, this.language_list);
    //       }
    //     }
    //   );
  }

  //get all assignments
  getAssignments() {
    this.assignmentList = [];
    let url = 'assignments?sort_by=assignment_name&status=1';
    this.api.getEmsData(url).subscribe({
      next: (data: any) => {
        //change sort order
        data = this.fnc.assignmentSortOrder(data);
        this.assignmentList = data;
      },
      error: (err) => {
      },
    });
  }

  //get module types
  getModuleTypes() {
    this.moduleTypeList = [];
    let url = 'moduletypes';
    this.api.getEmsData(url).subscribe({
      next: (data: any) => {
        if(data?.length>0){
          data.forEach((v) => {
            this.moduleTypeList.push(v);
          });
        }
      },
      error: (err) => {
      },
    });
  }

  // getting list of entity type
  getEntityType() {
    this.api.getEmsData("entities").subscribe({
      next: (data: any) => {
        this.api.entity_type_list = data;
        this.getPropertyType();
      },
      error: (err) => {
      },
    });
  }

  // getting list of property type
  getPropertyType(sort_by = 'property_type_name', limit = 10000, offset = 0) {
    let url = "propertytypes?sort_by=" + sort_by + "&limit=" + limit + "&offset=" + offset + '&status=1';
    this.api.getEmsData(url).subscribe({
      next: (data: any) => {
        data.map((ele) => {
          ele["name"] = ele.property_type_label[this.api.language];
        });
        this.api.property_type_list = data;
      },
      error: (err) => {
      },
    });
  }

  onChangeEntity(val, e) {
    // console.log(val,e);
    if(this.pageIndex > 0) this.paginator.pageIndex = 0; // reset pagination values
    this.showConflict = false;
    let data = e.value;
    if (val == 'entity') {
      this.selected_property_type = null;
      let entity_info = this.fnc.getArrayValue('entity_name', this.selected_entity_type, this.api.entity_type_list);
      this.is_property_dependent = entity_info.is_property_type_dependent;
    }
    if (val == 'masters') {
      this.selected_module_type = null;
      this.selected_assignment = null;
      this.selected_entity_type = null;
      this.selected_property_type = null;
      this.selected_master_group = null;
      this.offset=0;
      this.getUploadStatus();
      if (data == 'masters') {
        this.is_master_dependent = true;
        this.is_property_dependent = false;
        let obj = this.fnc.getArrayValue('master_value', e.value, this.upload_type_list);
        if (obj.is_entity_dependent) this.show_entity = true;
        this.getMasterGroup();
        this.getModuleTypes();
        this.getAssignments();
      }
      // unlink master value
      if (data == 'unlink_master_value') {
        this.is_master_dependent = false;
        this.is_property_dependent = false;
        this.show_entity = true;
      }


      if (data == 'properties') {
        this.is_master_dependent = false;
        this.is_property_dependent = true;
        this.show_entity = false;
        this.getMasterGroup();
        this.getModuleTypes();
        this.getAssignments();
      }
      if (data == 'attributes') {
        this.is_master_dependent = false;
        this.is_property_dependent = true;
        this.show_entity = true;
        this.getMasterGroup();
        this.getModuleTypes();
        this.getAssignments();
      }
    }
    if (val == 'group') {

    }
    if (val == 'type') {

    }
  }
  getName(name) {
    return name[this.api.language];
  }
  getMasterGroup(sort_by = 'master_group_id', limit = 1000, offset = 0) {
    let url = "mastergroups?sort_by=" + sort_by + "&limit=" + limit + "&offset=" + offset;
    let i = 0;
    this.api.getEmsData(url).subscribe({
      next: (data: any) => {
        this.masterGroupList = data;
      },
      error: (err) => {
      },
    });
  }

  handleFileInput(event) {
    let fileList: FileList = event.target.files;
    let formData: FormData = new FormData();
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let pattern = /zip-*/;
      if (!file.type.match(pattern)) {
        this.notify.notify('Unsupported file type', 'warn');
        return;
      }
      this.fileToUpload = file;
      this.fileName = this.fileToUpload.name;
    }
  }
  validate(forDownload = false) {
    let flag = true;
    if (!this.selected_File_Type) {
      this.notify.notify("Please select Upload type", "warn");
      return false;
    }
    if (this.selected_File_Type == "masters") {
      let obj = this.fnc.getArrayValue('master_value', this.selected_File_Type, this.upload_type_list);
      if (obj.is_entity_dependent) {
        if (this.selected_entity_type) {
          if (this.selected_entity_type == 'property_unit') {
            if (!this.selected_property_type) {
              this.notify.notify("You must select a Unit type", "warn");
              return false;
            }
          }
        } else {
          this.notify.notify("You must select an Entity type", "warn");
          return false;
        }
      }
      if (!this.selected_master_group) {
        this.notify.notify("Please select a Master Group", "warn");
        return false;
      }
    }
    if (this.selected_File_Type == "unlink_master_value") {
      let obj = this.fnc.getArrayValue('master_value', this.selected_File_Type, this.upload_type_list);
      if (obj.is_entity_dependent) {
        if (this.selected_entity_type) {
          if (this.selected_entity_type == 'property_unit') {
            if (!this.selected_property_type) {
              this.notify.notify("You must select a Unit type", "warn");
              return false;
            }
          }
        } else {
          this.notify.notify("You must select an Entity type", "warn");
          return false;
        }
      }
    }
    if (this.selected_File_Type == "properties") {
      if (!this.selected_module_type) {
        this.notify.notify("You must select a Module type", "warn");
        return false;
      }
      if (!this.selected_assignment) {
        this.notify.notify("You must select an Assignment", "warn");
        return false;
      }
      if (!this.selected_property_type) {
        this.notify.notify("You must select a Unit type", "warn");
        return false;
      }
    }
    if (this.selected_File_Type == "attributes") {
      if (!this.selected_module_type) {
        this.notify.notify("You must select a Module type", "warn");
        return false;
      }
      if (!this.selected_assignment) {
        this.notify.notify("You must select an Assignment", "warn");
        return false;
      }
      if (this.selected_entity_type == 'property_unit') {
        if (!this.selected_property_type) {
          this.notify.notify("You must select a Unit type", "warn");
          return false;
        }
      }
    }


    if (!this.fileToUpload && !forDownload) {
      this.notify.notify("No file selected", "warn");
      return false;
    }

    return true;
  }

  uploadFile(val?) {
    if (!this.upload_access.POST) {
      this.notify.notify("You are not authorized to upload data", "warn");
      return;
    }
    if (!this.validate()) return;
    let param = {};
    if (this.selected_master_group) {
      param['master_group_id'] = this.selected_master_group.toString();
    }
    if (this.selected_entity_type) {
      param['entity'] = this.selected_entity_type;
    };
    if (this.selected_assignment) {
      param['assignment_id'] = this.selected_assignment;
    }
    if (this.selected_module_type) {
      param['module_type'] = this.selected_module_type;
    }
    if (this.selected_property_type) {
      param['property_type_ids'] = this.selected_property_type.toString();
    }
    if (this.selected_lang) {
      let lang_obj = this.fnc.getArrayValue('language_id', this.selected_lang, this.language_list);
      param['lang_id'] = this.selected_lang;
      param['lang_code'] = lang_obj.language_code;
    }

    let value = JSON.stringify(param);
    value = encodeURI(value);
    let formData = new FormData();
    formData.append('file', this.fileToUpload, this.fileToUpload.name);

    let ln = this.fileToUpload.size;

    if (this.useNewApi) {
      let nodeUrl: string = '';
      if (this.selected_File_Type == 'properties') {
        nodeUrl = `uploads/csv?upload_type=property&assignment_id=${param['assignment_id']}&module_type=${param['module_type']}&property_type_ids=${param['property_type_ids']}&user_id=${this.api.user_id}`;
      } else {
        nodeUrl = `uploads/master-attribute?upload_type=${this.selected_File_Type}&user_id=${this.api.user_id}`;
        if (this.selected_File_Type == 'masters') {
          if (this.selected_master_group) nodeUrl += `&master_group_id=${this.selected_master_group}`;
          if (this.selected_entity_type) nodeUrl += `&entity=${this.selected_entity_type}`;
          if (this.selected_property_type) nodeUrl += `&property_type_ids=${this.selected_property_type.toString()}`;
        } else if (this.selected_File_Type == 'attributes') {
          if (this.selected_module_type) nodeUrl += `&module_type=${this.selected_module_type}`;
          if (this.selected_assignment) nodeUrl += `&assignment_id=${this.selected_assignment}`;
          if (this.selected_entity_type) nodeUrl += `&entity=${this.selected_entity_type}`;
          if (this.selected_property_type) nodeUrl += `&property_type_ids=${this.selected_property_type.toString()}`;
        } else if(this.selected_File_Type == 'unlink_master_value'){
          if (this.selected_master_group) nodeUrl += `&master_group_id=${this.selected_master_group}`;
          if (this.selected_entity_type) nodeUrl += `&entity=${this.selected_entity_type}`;
          if (this.selected_property_type) nodeUrl += `&property_type_ids=${this.selected_property_type.toString()}`;       
        }

      }
      this.loading = true;
      this.fileToUpload = null;
      this.myFileInput.nativeElement.value = '';
      this.api.postFileNode(nodeUrl, formData).subscribe({
        next: (res: any) => {
          this.reset();
          if (res?.code === 1) {
            this.notify.notify(res.msg, 'success');
          } else if(res?.status == 1) {
            this.notify.notify(res.message, 'success');
          }if(res?.status == 401){
              this.notify.notify(res.message, 'warn');
              return;
          } else{
            this.notify.notify(res.message, 'warn');
          }
          this.getUploadStatus();
          this.loading = false;
        }, error:(err) => {
          this.notify.notify(this.fnc.getErrorMessage(err), 'error');
          this.fileName = '';
          this.loading = false;
        }
      })
      setTimeout(() => {
        this.getUploadStatus();
      }, 10000);
    }
  }

  downloadSample(includeData: number, status = null) {
    if (includeData != 0 && !this.download_access.GET) {
      this.notify.notify("User not Authorized to download Data", "warn");
      return;
    }
    if (this.selected_File_Type == 'properties') {
      this.createCsvSample(includeData, status)
      return;
    }
    else {
      if (this.download == "Downloading...") return;
      if (!this.validate(true)) return;
      this.download = "Downloading...";
      // console.log(this.selected_File_Type);
      let url = this.selected_File_Type + "/uploads/template?query=abc";
      if (this.selected_assignment) url += "&assignment_id=" + this.selected_assignment;
      if (this.selected_module_type) url += "&module_type=" + this.selected_module_type;
      if (this.selected_entity_type) url += "&entity_type=" + this.selected_entity_type;
      if (this.selected_File_Type == 'attributes' || this.selected_File_Type == 'masters') {
        if (this.selected_property_type) {
          url += "&property_type_ids=" + this.selected_property_type.toString()
        } else {
          url += "&property_type_ids=0"
        }
      }
      if (this.selected_property_type) url += "&property_type_ids=" + this.selected_property_type.toString();
      if (status) url += "&status=" + status; // download only under review property
      if (includeData == 0) {
        url += "&get_data=" + 0;
      } else {
        url += "&get_data=" + includeData;
      };
      if (this.api.user_id) url += "&user_id=" + this.api.user_id;
      this.api.getCsvData(url)
        .subscribe(
          {next: (data: any) => {
          if (data.url) {
            this.downloadCsvSample(data.url)
            this.download = "Download Template";
          }
        },
          error:(err) => {
            this.download = "Download Template";
            this.notify.notify(this.fnc.getErrorMessage(err), 'error');
          }});
    }

  }
  reset() {
    this.fileName = '';
    this.fileToUpload = null;
    this.refresh = false;
    setTimeout(() => {
      this.refresh = true;
    }, 100);
  }

  createCsvSample(includeData: number, status = null) {
    // console.log(includeData)
    if (includeData != 0 && !this.download_access.GET) {
      this.notify.notify("User not Authorized to download Data", "Dismiss")
      return;
    }
    // console.log("here");

    if (this.download == "Downloading...") return;
    // console.log("here");

    if (!this.validate(true)) return;
    // console.log("here");

    this.download = "Downloading...";
    let url = this.selected_File_Type + "/uploads/template?";
    // console.log(url);

    if (this.selected_module_type) url += "module_type=" + this.selected_module_type;
    if (this.selected_assignment) url += "&assignment_id=" + this.selected_assignment;
    if (this.selected_entity_type) url += "&entity_type=" + this.selected_entity_type;
    if (this.selected_property_type) url += "&property_type_ids=" + this.selected_property_type.toString();
    if (status) url += "&status=" + status; // download only under review property
    if (this.api.user_id) url += "&user_id=" + this.api.user_id;
    // console.log(includeData);
    url += "&get_data=" + includeData;
    // console.log(url);
    this.api.getCsvData(url)
      .subscribe({next:(data: any) => {
        this.download = "Download Template";
        if (data && data.url) {
          this.downloadCsvSample(data.url);

        }
        else {
          this.notify.notify(data.message, 'Dismiss', 5000)
        }

      },
        error:(err) => {
          this.download = "Download Template";
          this.notify.notify(this.fnc.getErrorMessage(err), 'Dismiss');
        }}
        );
  }

  downloadCsvSample(url: string) {
    if (!url) {
      this.notify.notify('No URL/Valid Url Found!', 'Dismiss', 5000);
      return;
    }
    else {
      let apiUrl = `${environment.csvUrl}download-template?city_id=${this.api.city_id}&file_url=${url}`;
      // console.log(apiUrl);

      window.open(apiUrl, "_blank");
    }
  }


  // getting allow resources
  getAllowResourcesApi() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body).subscribe({
      next: (res: any) => {
        this.api.allowResources = res.data;
        this.getAllowResource();
      },
      error: (err) => {
      },
    });
  }


  getAllowResource() {
    this.upload_access = this.fnc.checkResourceAccess('csv_upload', false);
    this.download_access = this.fnc.checkResourceAccess('property_data', false);
    if (!this.upload_access.GET) {
      this.route.navigateByUrl('/');
      return;
    }
  }
  ngOnInit() {
    this.selected_lang = 2;
  }

  getUploadStatus() {
    if (!this.selected_File_Type) return;

    //this.getPageCount();

    if (this.useNewApi) {
      let arr = [];
      let url = `get-upload-master?limit=${this.limit}&offset=${this.offset}&upload_type=${this.selected_File_Type}`;
      if(this.offset == 0){
        url += `&is_count=true`;
      }else{
        url += `&is_count=false`;
      }
      this.loader = true;
      this.api.getNodeData(url).subscribe(
        {
          next:  (res: any) => {
          this.loader = false;
          if(res.data){
            if(this.offset == 0){
              this.total = res?.totalRecord || 0;
            }
            res.data.map((item: any) => {
              arr.push({
                upload_id: (this.selected_File_Type == 'properties') ? item.id : item.upload_id,
                name: item.name,
                row_count: null,
                failure_counts: item?.failure_counts,
                success_counts: item?.success_counts,
                logfile_link: (this.selected_File_Type == 'properties') ? '' : item.logfile_link,
                errfile_link: (this.selected_File_Type == 'properties') ? '' : item.errfile_link,
                accept_link: (this.selected_File_Type == 'properties') ? '' : item.accept_link,
                status: item.status,
                added_by: item.added_by,
                updated_by: item.updated_by,
                added_date: item.added_date,
                updated_date: item.updated_date,
                property_count: item?.property_count,
                floor_count: item?.floor_count,
                unit_count: item?.unit_count,
                total_chunk : item?.total_chunk || 0,
                validated_chunk : item?.validated_chunk || 0,
                accepted_chunk: item?.accepted_chunk || 0,
                file_link: item?.file_link,
                assignment_name:item?.assignment_label?item?.assignment_label['1']:null,
                assignment_year: item?.assignment_year ? item?.assignment_year: ''
              })
            })
            this.upload_report = arr;
          }else{
            this.upload_report=[];
          }

        }, error:(err) => {
          this.upload_report=[];
          this.loader = false;
          this.notify.notify(this.fnc.getErrorMessage(err), "error");
        }
      });
    }
  }

  acceptUpload(id) {
    if (this.useNewApi) {
      this.loader = true;
      let url = `accept?upload_id=${id}&user_id=${this.api.user_id}&upload_type=${this.selected_File_Type}`;
      this.api.postNodeData(url, null).subscribe(
        {next: (data) => {
          setTimeout(() => {
            this.notify.notify('File accepted', 'success');
            this.getUploadStatus();
            this.loader = false;
          }, 200);
        }, error: (err) => {
          this.getUploadStatus();
          this.notify.notify(this.fnc.getErrorMessage(err), "error");
          this.loader = false;
        }});
    }
  }

  rejectUpload(id) {
    if (this.useNewApi) {
      this.loader = true;
      let url = `/reject-csv-upload?upload_id=${id}&status=-2&user_id=${this.api.user_id}&upload_type=${this.selected_File_Type}`;
      this.api.getNodeData(url).subscribe(
       { next: (data:any) => {
          this.loader = false;
          if(data.status == 200){
            this.notify.notify(data.message, 'warn');
            this.getUploadStatus();
          }else{
            this.notify.notify(data.message, 'error');
            this.getUploadStatus();
          }
        }, error:() => {
          this.getUploadStatus();
          this.loader = false;
        }});
    }

  }

  /**
   * This is a function to view file status
   * @param element
   * @returns
   */
  getFileStatus(element) {
    if(this.selected_File_Type == 'properties'){
      if(element.validated_chunk != 0 && element.validated_chunk < element.total_chunk && element.accepted_chunk == 0 && [-1,-2,-3].indexOf(element.status) == -1){
        return 'Partially validated';
      } else if(element.accepted_chunk < element.total_chunk && element.accepted_chunk != 0 && [-1,-2,-3].indexOf(element.status) == -1){
        return 'Partially accepted';
      } else {
        return this.fileStatus[element.status];
      }
    }else if(this.selected_File_Type == 'masters' || this.selected_File_Type == 'attributes' || this.selected_File_Type == 'unlink_master_value'){
      return this.fileStatusForMaster[element.status];
    }
  }

  hideAccept(status) {
    if ([2, 0, 3, -2, -1, -3].indexOf(status) > -1) {
      return true;
    }
    return false;
  }
  hideReject(status) {
    if ([2, -2, 3, 4, -3, -1].indexOf(status) > -1) {
      return true;
    }
    return false;
  }


  pageAction(e) {
    this.pageIndex = e.pageIndex;
    if (this.limit != e.pageSize) {
      this.limit = e.pageSize;
    } else {
      this.offset = this.pageIndex * e.pageSize;
    }
    this.getUploadStatus();
  }

  getDate(dt) {
    let d = new Date(dt);
    return this.fnc.formatDateUTC(d, true);
  }

  openDialog() {
    let dialogRef = this.dialog.open(InfoDialog, {
      width: '350px',
      panelClass: 'create-master-panel',
      data: { parent: this }//optional
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openReportsDialog(data) {
    let dialogRef = this.dialog.open(ReportsDialog, {
      width: '90vw',
      height: '90vh',
      panelClass: 'create-master-panel',
      data: { parent: this, selectedRow: data }, //optional
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

  downloadFile(element){
    if(element.file_link){
      let apiUrl = `${environment.csvUrl}download-template?city_id=${this.api.city_id}&upload_id=${element.upload_id}&file_url=${element.file_link}`;
      window.open(apiUrl, "_blank");
    }else{
      this.notify.notify(
        "File not found",
        "warn"
      );
    }
  }

  logDownload(url){
    window.open(`https://${url}`, "_blank");
  }
}
