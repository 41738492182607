<div class="mtc-header position-relation" fxLayout="row" fxLayoutAlign="center center">
  <div class="pt-3">
    <h3 *ngIf="mode=='process'">Process Log</h3>
    <h3 *ngIf="mode=='parameter'">Parameter Log</h3>
  </div>
</div>
<mat-dialog-content>
  <div>
    <div *ngFor="let item of logList">
      {{item}}
    </div>
  </div>
</mat-dialog-content>