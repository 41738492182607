<div class="download-value-wrapper">
  <mat-toolbar class="creategroup">
    <div>Download Values</div>
  </mat-toolbar>
  <div class="download-content">
    <form [formGroup]="downloadMasterGroup">
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Select entity type</mat-label>
        <mat-select placeholder="Select entity type" formControlName="entityType">
          <mat-option
            *ngFor="let ent of api.entity_type_list"
            [value]="ent.entity_id">{{ ent.entity_name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="always" *ngIf="downloadMasterGroup.value.entityType == 3">
        <mat-label>Select unit type</mat-label>
        <mat-select placeholder="Select Unit type" formControlName="entity_type_id">
          <mat-option
            *ngFor="let ent of property_type_list"
            [value]="ent.property_type_id"
            >{{ ent.property_type_label[1] }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" floatLabel="always">
        <mat-label>Select master value</mat-label>
        <mat-select placeholder="Select Master Value" formControlName="masterValue" (openedChange)="search_master=''">
          <div class="search_bar"><input [(ngModel)]="search_master" placeholder=" Type here to search..."
            (keydown)="$event.stopPropagation();"  [ngModelOptions]="{standalone: true}"/></div>
    <ng-container *ngFor="let ent of all_masters | searchfilter:'filterParm':search_master:all_masters">
      <mat-option *ngIf="ent != null"  [value]="ent.master_name">{{ ent.master_label["1"] ? ent.master_label["1"]:ent.master_label["2"]}} </mat-option>
    </ng-container>

        </mat-select>
      </mat-form-field>
    </form>
    <mat-dialog-actions
      fxLayout="row"
      fxLayoutAlign="center center"
      class="dialog-actions"
    >
      <button
        mat-raised-button [disabled]="downloadMasterGroup.invalid"
        class="blue-button"
        (click)="downloadMasterRelation()"
      >
        Download
      </button>
      <button mat-raised-button type="button" (click)="closeDial($event)">Cancel</button>
    </mat-dialog-actions>
  </div>
</div>
