<mat-card appearance="outlined" class="example-card">
    <div class="title" fxLayout="row" fxLayoutAlign="space-between center">
      <div>Sync Property Gis</div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <input class="searchInput form-control" [formControl]="searchField" placeholder="Search...">
        <div class="position-relative" fxLayout="row" fxLayoutAlign="space-between center">
          <input class="form-control dateinput" [matDatepicker]="picker1" [formControl]="selectedDate">
          <span class="dateinput-icon">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
             <mat-datepicker #picker1></mat-datepicker>
          </span>
        </div>
        <button mat-raised-button color="primary" class="btn_ex" (click)="scheduler()">Execute</button>
      </div>
    </div>
    <mat-card-content class="tabs">
        <div class="center"><h3>{{msg}}</h3></div>
        <div class="table-container mat-elevation-z8" *ngIf="syncPropertyData.length > 0">
            <table mat-table [dataSource]="syncPropertyData">

              <!-- Position Column -->
              <ng-container matColumnDef="scheduler_id">
                <th mat-header-cell *matHeaderCellDef> Scheduler id </th>
                <td mat-cell *matCellDef="let element"> {{element.scheduler_id}} </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="process_id">
                <th mat-header-cell *matHeaderCellDef> Process id </th>
                <td mat-cell *matCellDef="let element"> {{element.process_id}} </td>
              </ng-container>

              <!-- Weight Column -->
              <ng-container matColumnDef="process_name">
                <th mat-header-cell *matHeaderCellDef> Process name </th>
                <td mat-cell *matCellDef="let element"> {{convertCase(element.process_name)}} </td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="added_date">
                <th mat-header-cell *matHeaderCellDef> Added date </th>
                <td mat-cell *matCellDef="let element"> {{element.added_date}} </td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="completed_percent">
                <th mat-header-cell *matHeaderCellDef> Completed percent </th>
                <td mat-cell *matCellDef="let element"> {{element.completed_percent}} </td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="auto_run_count">
                <th mat-header-cell *matHeaderCellDef> Auto run count </th>
                <td mat-cell *matCellDef="let element"> {{element.auto_run_count}} </td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="manual_run_count">
                <th mat-header-cell *matHeaderCellDef> Manual run count </th>
                <td mat-cell *matCellDef="let element"> {{element.manual_run_count}} </td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="processed_record">
                <th mat-header-cell *matHeaderCellDef> Processed record </th>
                <td mat-cell *matCellDef="let element"> {{element.processed_record}} </td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="total_record">
                <th mat-header-cell *matHeaderCellDef> Total record </th>
                <td mat-cell *matCellDef="let element"> {{element.total_record}} </td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="run_date">
                <th mat-header-cell *matHeaderCellDef> Run date </th>
                <td mat-cell *matCellDef="let element"> {{element.run_date}} </td>
              </ng-container>

              <!-- Symbol Column -->
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element"> {{element.status}} </td>
              </ng-container>

               <!-- Symbol Column -->
               <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action </th>
                <td mat-cell *matCellDef="let element">
                    <button class="bordered prim" (click)="Execute(element)" mat-button>Execute</button> </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        <div *ngIf="loader" class='loader-div'>
          <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
        </div>
    </mat-card-content>
  </mat-card>
