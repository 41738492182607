<mat-toolbar *ngIf="!data.edit" class="matpopup-header">
  <div>Create Property Type</div>
</mat-toolbar>
<mat-toolbar *ngIf="data.edit" class="matpopup-header">
  <div>Edit Property Type</div>
</mat-toolbar>
<form [formGroup]="propertyForm" (ngSubmit)="savePropertyType(propertyForm)" autocomplete="off" novalidate>
  <mat-dialog-content>
      <mat-form-field floatLabel="never" class="w-100">
          <input matInput  [errorStateMatcher]="matcher" type="text" maxlength="64" placeholder="Property Type Name" #property_type_name formControlName="property_type_name">
          <!-- <mat-hint>Hint message goes here</mat-hint> -->
          <!-- <mat-error>Name is required</mat-error> -->
          <mat-hint align="end">{{property_type_name.value.length}}/64</mat-hint>
          <mat-error *ngIf="propertyTypeName.hasError('required')">
              Name is required</mat-error>
          <mat-error *ngIf="propertyTypeName.hasError('pattern')">
              Special Characters not Allowed!</mat-error>
      </mat-form-field>
      <div formArrayName="language">
          <div *ngFor="let languagevalues of language_valueset;let kk = index">
              <div [formGroupName]="kk" fxLayout="row" fxLayoutAlign="space-between start" class="gap-10">
                  <mat-form-field floatLabel="never" fxFlex="30%">
                      <mat-select placeholder="Language" formControlName="language">
                          <mat-option *ngFor="let language of languages" [value]="language.language_id">
                              {{ language.name }}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
                  <mat-form-field floatLabel="never" fxFlex="40%">
                      <input  [errorStateMatcher]="matcher" matInput type="text" placeholder="Label" maxlength="64" #property_lable formControlName="value">
                      <!--<mat-hint>Hint message goes here</mat-hint>-->
                      <mat-error>Label is required</mat-error>
                      <mat-hint align="end">{{property_lable.value.length}}/64</mat-hint>
                  </mat-form-field>
                  <div class="addbtn">
                    <div (click)="removeRow(kk)" class="border iconTick-1">
                        <mat-icon>remove</mat-icon>
                    </div>
                    <div (click)="addRow()" class="border iconTick-1">
                        <mat-icon>add</mat-icon>
                    </div>
                </div>
              </div>
          </div>
      </div>
      <mat-slide-toggle class="example-margin" [checked]="status" [(ngModel)]="status" [ngModelOptions]="{standalone: true}">
          Status
      </mat-slide-toggle>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="dialog-actions">
      <button mat-raised-button [disabled]="propertyForm.invalid" class="blue-button">Submit</button>
      <button type="button" mat-raised-button (click)="closeDialog(false)">Cancel</button>
  </mat-dialog-actions>
</form>
