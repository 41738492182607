<mat-toolbar class="matpopup-header"><div>List of dependent attributes</div></mat-toolbar>
<div style="
font-size: 12px;
text-align: center;
color: #f69230;
">* All dependent, mutually exlussive and parent attributes will be move in selected group</div>
<mat-dialog-content>
    <div fxLayout="column">
        
            <div class="grouplist"> 
                <div *ngFor="let item of dependent_attribute_list;" class="attribute-box">
                <div class="cst rowse" *ngFor="let subitem of item;let k = index;" fxLayout="column"  fxLayoutAlign="start left">
                    <button class="group-b"  mat-button >
                        <span class="w-100 title">{{subitem}}</span>
                        </button>
                </div>
                </div> 
            </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="dialog-actions">
    <button mat-raised-button type="button" (click)="confirm()" class="blue-button">Accept</button>
    <button  type="button"  mat-raised-button (click)="closeDialog()">Cancel</button>
</mat-dialog-actions>