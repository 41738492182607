import { Component, OnInit } from '@angular/core';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import * as $ from 'jquery';
import { ApiserviceService } from '../../apiservice.service';
import { Router } from '@angular/router';
import { AppNotificationService } from '../services/app-notification.service';


  /**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_assignments_get</li>
 * </ol>
 *
 */
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  loader: boolean = true;
  constructor(private menu: AdminMenusService, private api:ApiserviceService, private router: Router, private alert: AppNotificationService) {
  //  this.getAssignments()
    if(!this.api.user_id && !this.api.city_id){
      this.router.navigateByUrl('/admin/login');
    }
    this.menu.setActive('dashboard');
  }
  success(){
    this.loader = false;
  }
  ngOnInit() {
    this.alert.getCount();
  }
  getAssignments(){
    this.api.getEmsData('assignments?sort_by=assignment_name&status=1&assigned_to='+this.api.user_id)
    .subscribe(
      (data:any) => {
      },
      err => {
      })
  }
  ngAfterViewInit(){
    $(document).ready(function () {
      $('#frame').on('load', function () {

      });
  });
  }
}
