import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NotificationService } from "../../../../admin/services/notification.service";
import { ApiserviceService } from "../../../../apiservice.service";
import { CommonfunctionService } from "../../../../services/commonfunction.service";

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_deleteGroup_group_id_delete</li>
 * <li>umsapi_resource_get</li>
 * <li>umsapi_resource_delete</li>
 * </ol>
 *
 */
@Component({
  selector: "app-delete",
  templateUrl: "delete.component.html",
  styleUrls: ["../../admin-configuration.component.css"],
})
export class DeleteComponent {
  public group_id: any;
  public group_name: any;
  selected_group: any;
  progress: number = 0;
  message: string = "";
  group_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };
  constructor(
    public dialogRef: MatDialogRef<DeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fnc: CommonfunctionService,
    private api: ApiserviceService,
    private notify: NotificationService
  ) {
    this.selected_group = data.incomingData.selectedGroup;
    if (this.selected_group) {
      this.group_id = this.selected_group.id;
      this.group_name = this.selected_group.name;
    }
    this.group_access = this.fnc.checkResourceAccess("report_groups", false);
  }

  public deleteGroup(id) {
    if (!this.group_access.DELETE) {
      this.notify.notify("You are not authorized to delete report", "warn");
      return;
    }

    this.progress = 10;
    this.message = "Deleting group...";
    if (id) {
      if (id) {
        this.api
          .deleteData("deleteGroup/" + id + "?updated_by=" + this.api.user_id)
          .subscribe(
            (data) => {
              let type = this.fnc.getArrayValue(
                "id",
                this.data.parent.selectedTypeId,
                this.data.parent.reportType
              );
              if (type) {
                this.getReourceDetail(type.name + "_" + this.group_name);
              }
              this.notify.notify(
                "Group " + this.group_name + " is deleted",
                "success"
              );
              this.data.parent.getGroups(this.data.parent.selectedTypeId);
            },
            (err) => {
              this.notify.notify(this.fnc.getErrorMessage(err), "error");
            }
          );
      }
    }
  }
  getReourceDetail(res: string) {
    this.api.getUmsData("resource?name=" + res + "&match_exact=true").subscribe(
      (res: any) => {
        this.message = "Fetching resource...";
        this.progress = 50;
        let resource_details: any;
        if (res) resource_details = res[0];
        if (resource_details) {
          this.deleteResource(resource_details.resource_id);
        }
      },
      (err) => {
        this.notify.notify(this.fnc.getErrorMessage(err), "error");
        this.dialogRef.close();
      }
    );
  }

  deleteResource(id) {
    this.message = "Deleting resource...";
    this.progress = 70;
    this.api.deleteUmsData("resource/" + id).subscribe((res: any) => {
      this.message = "Completed";
      setTimeout(() => {
        this.progress = 100;
        this.close();
      }, 100);
    });
  }

  public close() {
    this.progress = 0;
    this.dialogRef.close();
  }

  ngOnInit() {}
}
