<div class="abs-subgroup1">
  <a *ngIf="group_access.PATCH == true" (click)="openDialog('UPDATE_GROUP',{'selectedGroup':item})" mat-menu-item
    class="mastergroup_menuactions">
    <mat-icon>edit</mat-icon>
    Edit Group
  </a>

  <div class="divider"></div>
  <a *ngIf="group_access.DELETE == true" (click)="openDialog('DELETE_GROUP',{})" mat-menu-item
    class="mastergroup_menuactions">
    <mat-icon>delete</mat-icon>
    Delete Group
  </a>
  <div class="divider"></div>
  <a *ngIf="report_access.POST == true" (click)="openDialog('ADD_REPORT',{})" mat-menu-item
    class="mastergroup_menuactions">
    <mat-icon>add</mat-icon>
    Add Report
  </a>
  <div class="arrows"><img src="/assets/images/arr.png" /></div>
</div>
