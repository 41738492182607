import { Component, OnInit } from "@angular/core"
import { ApiserviceService } from '../../apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { NotificationService } from '../services/notification.service';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';

import { MomentDateAdapter } from '@angular/material-moment-adapter';

// import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
// import { default as _rollupMoment, Moment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { debounceTime } from "rxjs";
// const moment = _rollupMoment || _moment;

export const YEAR_MODE_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-sync-property-gis',
  templateUrl: './sync-property-gis.component.html',
  styleUrls: ['./sync-property-gis.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: YEAR_MODE_FORMATS },
   ],
})
export class SyncPropertyGisComponent implements OnInit {

  public loader: boolean;
  public syncPropertyData: any = [];
  public process_name: string = null;
  public process_id : number = null;
  public searchField: FormControl;
  public msg = '';

  public limit: number = 1000;
  public offset: number = 0;
  public selectedDate = new FormControl((new Date()));
  public displayedColumns = ['scheduler_id','process_id','process_name', 'added_date', 'completed_percent', 'auto_run_count' , 'manual_run_count', 'processed_record', 'total_record', 'run_date', 'status', 'action'];
  sync_access=
   { GET: false, PATCH: false, POST: false, DELETE: false};
  constructor(private api: ApiserviceService,private menu: AdminMenusService,
    private fnc: CommonfunctionService,
    private notify: NotificationService) {
    this.menu.setActive('sync-property-gis');
    this.getAllowResourcesApi();
  }

  getAllowResourcesApi() {
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body)
      .subscribe((res: any) => {
        this.api.allowResources = res.data;
        this.sync_access = this.fnc.checkResourceAccess('sync_gis', false);
        this.getSyncProperties();

      }, err => {
      })
  }

  public getSyncProperties() {
    if (!this.sync_access.GET) {
      this.notify.notify("You don't have permission to view this page", "warn");
      return;
    }
    let url = 'syncScheduleStatus?schedule_date='+this.fnc.formatDate(this.selectedDate.value).toString();//+'&limit='+this.limit+'&offset='+this.offset;
    if(this.process_name){
      url += '&process_name='+this.process_name;
    }
    if(this.process_id){
      url += '&process_id='+this.process_id;
    }
    this.loader = true;
    this.api.getSyncData(url).subscribe((data:any) => {
      let reslist = [];
        this.loader = false;
      if (data.length) {
        this.msg = "";
        data.forEach(response => {
          let  res = {
            scheduler_id: response.scheduler_id,
            process_id: response.process_id,
            process_name: response.process_name,
            added_date: this.dateFormate(response.added_date),
            completed_percent: response.completed_percent,
            auto_run_count: response.auto_run_count,
            manual_run_count: response.manual_run_count,
            processed_record: response.processed_record,
            total_record: response.total_record,
            run_date: response.run_date ?  this.dateFormate(response.run_date) : '',
            status: response.status,
          }
          reslist.push(res);
        });

        this.syncPropertyData = reslist
      }else{
        this.syncPropertyData = reslist
        this.msg = "No record found";
        this.notify.notify("Data not found", "warn");
      }
    }, err => {
      this.loader = false;
      this.msg = "No record found";
      this.notify.notify("Data not found", "error");
    })
  }
  public convertCase(str) {
    if(str.includes('_')){
      str = str.split('_').join(' ');
      var lower = String(str).toLowerCase();
      return lower.replace(/(^| )(\w)/g, function(x) {
        return x.toUpperCase();
      });
    }else{
      return str.replace(/(^| )(\w)/g, function(x) {
        return x.toUpperCase();
      });
    }

  }

  public Execute(syncData:any){
    if (!this.sync_access.POST) {
      this.notify.notify("You don't have permission to execute this process", "warn");
      return;
    }
    if(syncData){
      let url = 'syncEstaterAndGis?city_id='+this.api.city_id;
      //let url = 'synchEstaterAndGis?city_id=32';
      if(syncData.scheduler_id){
        url += '&scheduler_id='+syncData.scheduler_id;
      }
      if(this.selectedDate.value){
        if(syncData.added_date){
          url += '&schedule_date='+this.fnc.formatDate(this.selectedDate.value).toString();
        }
      }else{
        this.notify.notify("Invailid date formate", "warn");
        return;
      }
      this.loader = true;
      this.api.getSyncData(url).subscribe((res:any)=>{
        this.loader = false;
        if(res.status === 1) {this.getSyncProperties()};
        this.notify.notify(res.message,"success")
      },
      err=>{
        this.loader = false;
        this.notify.notify("Execute process Failed","error")
      })
    }
  }
  public scheduler(){
    if (!this.sync_access.POST) {
      this.notify.notify("You don't have permission to execute this process", "warn");
      return;
    }
    let url = 'syncEstaterAndGis?city_id='+this.api.city_id+'&schedule_date='+this.fnc.formatDate(this.selectedDate.value).toString();
    this.loader = true;
      this.api.getSyncData(url).subscribe((res:any)=>{
        this.loader = false;
        this.getSyncProperties();
        if(res.status === 1) {this.getSyncProperties()};
        this.notify.notify(res.message,"success")
      },
      err=>{
        this.getSyncProperties();
        this.loader = false;
        this.notify.notify("Execute process Failed","error")
      })

  }
  public dateFormate(dt:string){
    if(dt){
      let d = new Date(dt);
      return this.fnc.formatDateUTC(d);
    }else{
      return '';
    }
  }

  ngOnInit() {
    this.searchField = new FormControl();
    this.searchField.valueChanges
      .pipe(debounceTime(800))
      .subscribe(term => {
        this.process_name = term.trim();
        this.getSyncProperties();
      });

    this.selectedDate.valueChanges
    .pipe(debounceTime(200))
      .subscribe(term => {
        if(term){
          this.getSyncProperties();
        }else{
          this.notify.notify("Invailid date formate", "warn");
          return;
        }
      });
   }
}
