import { Component, ElementRef, Inject, OnInit } from "@angular/core";
import { AdminBreadcrumbService } from "../admin-breadcrumb/services/admin-breadcrumb.service";
import { AdminMenusService } from "../admin-sidebar/admin-menus.service";
// import { SortablejsOptions } from 'ngx-sortablejs';
import { FormArray, FormBuilder, Validators } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import * as $ from "jquery";
import { Options } from "sortablejs";
import { ApiserviceService } from "../../apiservice.service";
import { CommonfunctionService } from "../../services/commonfunction.service";
import { MatErrorStateMatcher } from "../admin-error-state-macher";
import { NotificationService } from "../services/notification.service";
import { CreatePropertyTypeDialogComponent } from "./create-update-property-type-dialog/admin-createproperty.component";





/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>umsapi_user_resource_post</li>
 * <li>estapi_propertytypes_get</li>
 * </ol>
 *
 */
@Component({
  selector: "admin-property",
  templateUrl: "./admin-property.component.html",
  styleUrls: ["./admin-property.component.scss"],
})
export class AdminPropertyComponent implements OnInit {
  dataSource = [];
  displayedColumns = [
    "sno",
    "property_type",
    "property_label",
    "status",
    "actions",
  ];
  tableoptions: Options = {
    draggable: ".draggableset",
  };
  searchType: string = "";
  showsearchbar: boolean = false;
  showmobilesearchbar: boolean = false;
  loader: boolean;
  ready_load = false;
  properties_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };
  status_filter = "all";
  status_filter_values = [
    { value: "all", alias: "All" },
    { value: "active", alias: "Active" },
    { value: "inactive", alias: "Inactive" },
  ];
  total_property_records: number;

  constructor(
    public dialog: MatDialog,
    private menus: AdminMenusService,
    private _crumbs: AdminBreadcrumbService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private _el: ElementRef,
    private api: ApiserviceService,
    private fnc: CommonfunctionService,
    private route: Router,
    private notify: NotificationService
  ) {
    this.menus.setActive("property_settings");
    this._crumbs.clear();
    this._crumbs.addcrumb = { title: "Property Type" };
    this._crumbs.mobiletitle = "Property Type";
    this._crumbs.count = 1;
    iconRegistry
      .addSvgIcon(
        "edit-icon",
        sanitizer.bypassSecurityTrustResourceUrl(
          "assets/images/svgicons/edit.svg"
        )
      )
      .addSvgIcon(
        "delete-icon",
        sanitizer.bypassSecurityTrustResourceUrl(
          "assets/images/svgicons/delete.svg"
        )
      )
      .addSvgIcon(
        "move-icon",
        sanitizer.bypassSecurityTrustResourceUrl(
          "assets/images/svgicons/move.svg"
        )
      );
    this.getAllowResourcesApi();
  }

  // getting allow resources
  getAllowResourcesApi() {
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`;
    this.api.getUmsData(body).subscribe(
      (res: any) => {
        this.api.allowResources = res.data;
        this.getAllowResource();
        this.getPropertyType("property_type_name", 10000, 0, "0,1");
      },
      (err) => {
      }
    );
  }
  // getAllowResourcesApi(){
  //   let body = "place_id="+this.api.city_id;
  //  this.api.postUmsData('user/resource',body)
  //      .subscribe(
  //       (data:any) => {
  //          this.api.allowResources = data;
  //          setTimeout(()=>{    // waiting for language
  //           this.getAllowResource();
  //           this.getPropertyType('property_type_name',10000,0);
  //      },400);
  //        },
  //        err =>{
  //       }
  //      )
  // }

  ngOnInit() {}

  // getting property type
  getPropertyType(sort_by: string, limit: number, offset: number, status: any) {
    if (!this.properties_access.GET) {
      this.dataSource = [];
      return;
    }

    let url =
      "propertytypes?sort_by=" +
      sort_by +
      "&limit=" +
      limit +
      "&offset=" +
      offset +
      "&status=" +
      status;
    let ctr = 1;
    let find = true;
    let language: any;
    this.loader = true;
    // this.ready_load = true; // no use we can remove this
    this.api.getEmsData(url).subscribe(
      (data: any) => {
        this.loader = false;
        this.dataSource = [];
        if (data.length > 0) {
          data.forEach((element) => {
            let val = {
              sno: ctr,
              name: element.property_type_name,
              label:
                element.property_type_label[this.api.language] == undefined
                  ? element.property_type_name
                  : element.property_type_label[this.api.language],
              right_menu: [],
              languages: element.property_type_label,
              id: element.property_type_id,
              status: element.status,
            };
            for (let key in element.property_type_label) {
              //getting language detail by id
              language = this.fnc.getArrayValue(
                "language_id",
                key,
                this.api.language_list
              );
              find = true;
              let lang_value = {
                label:
                  "<span class='menu-icon'>" +
                  language.name +
                  " - " +
                  element.property_type_label[key] +
                  "</span>",
              };
              val.right_menu.push(lang_value);
            }
            ctr++;
            this.dataSource.push(val);
          });
          this.total_property_records = this.dataSource.length;
        }
      },
      (err) => {
        this.loader = false;
      }
    );
  }

  getAllowResource() {
    this.properties_access = this.fnc.checkResourceAccess("propertytypes");
  }

  openDialog(type: string, info: any = {}): void {
    let dialogview;
    info.pObj = this;
    // if (type == "createattribute") {
      dialogview = CreatePropertyTypeDialogComponent;
    // }
    if (dialogview) {
      let dialogRef = this.dialog.open(dialogview, {
        width: "450px",
        panelClass: "create-master-panel",
        data: info,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.filterStatus(this.status_filter);
        }
      });
    }
  }

  swipeleftactions(s, k) {
    let el = $(this._el.nativeElement);
    el.find("#les" + k)
      .find(".swipeactions")
      .show();
    el.find(".les")
      .not("#les" + k)
      .css("margin-left", "0");
    el.find("#les" + k)
      .show()
      .css("margin-left", "-80px");
  }

  showMobileSearchBar() {
    this.showmobilesearchbar = !this.showmobilesearchbar;
  }

  swiperightactions(s, k) {
    let el = $(this._el.nativeElement);
    el.find(".swipeactions").hide();
    el.find("#les" + k).css("margin-left", "0");
  }

  showSearch() {
    this.showsearchbar = !this.showsearchbar;
  }

  ngOnDestroy() {
    this._crumbs.clear();
  }

  filterStatus(value) {
    if (value == "all") {
      this.getPropertyType("property_type_name", 10000, 0, "0,1");
    } else if (value == "active") {
      this.getPropertyType("property_type_name", 10000, 0, "1");
    } else if (value == "inactive") {
      this.getPropertyType("property_type_name", 10000, 0, "0");
    }
  }

  /**
   *  Change Property type status toggle
   */

  changeStatus(id, event, status) {
    if (!this.properties_access.PATCH) {
      this.notify.notify("You are not authorized to changed status", "warn");
      event.source.checked = status == 1 ? true : false;
      return;
    }
    let payload = {
      property_type_id: id,
      updated_by: this.api.user_id,
      status: status,
    };
    if (status) {
      payload.status = 0;
    } else {
      payload.status = 1;
    }

    this.api.patchEmsData("propertytypes/" + id, payload).subscribe({
      next: (data: any) => {
        console.log(data, status, " val.status");
        this.notify.notify(
          "Property type status changed successfully",
          "success"
        );
        this.filterStatus(this.status_filter);
      },
      error: (err) => {
        // this.notify.notify(err.error.message, "warn");
      },
    });
  }
}
