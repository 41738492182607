<div class="bordered-box" fxLayout="row" fxLayoutAlign="center center">
    <div matTooltip="Unit map">
        <button mat-icon-button *ngIf="entity_type == 'property_unit'" class="btn-gr-multi_parcel"
            [ngClass]="{'active': selected == 'unit_map'}" (click)="changeStatus('unit_map')">
            <mat-icon class="fix">map</mat-icon>
        </button>
    </div>
    <div *ngIf="entity_type == 'property'"  matTooltip="Review Later">
            <button mat-icon-button class="btn-gr-multi_parcel"
                [disabled]="review_later == 0" [ngClass]="{'active': selected == 'review_later'}"
                (click)="changeStatus('review_later')">
                <mat-icon class="fix">assignment_return</mat-icon>
                <span class="btn-count">{{review_count}}</span>
            </button>
        </div>
    <div *ngIf="entity_type == 'property'" matTooltip="Multi parcels">
        <button mat-icon-button class="btn-gr-multi_parcel"
            [disabled]="multi_parcel == 0" [ngClass]="{'active': selected == 'multi_parcel' && multi_parcel != 0}"
            (click)="changeStatus('multi_parcel')">
            <mat-icon class="fix">kitchen</mat-icon>
            <span class="btn-count">{{multi_parcel}}</span>
        </button>
    </div>

    <div matTooltip="Deleted">
        <button mat-icon-button class="btn-gr-delete" [disabled]="deleted_count == 0"
            [ngClass]="{'active': selected == 'deleted' && deleted_count != 0}" (click)="changeStatus('deleted')">
            <mat-icon>delete</mat-icon>
            <span class="btn-count">{{deleted_count}}</span>
        </button>
    </div>
    <div matTooltip="Demolished">
        <button mat-icon-button class="btn-gr-demolished" [disabled]="demolished_count == 0"
            [ngClass]="{'active': selected == 'demolished' && demolished_count != 0}" (click)="changeStatus('demolished')">
            <mat-icon class="fix" svgIcon="demolished"></mat-icon>
            <span class="btn-count">{{demolished_count}}</span>
        </button>
    </div>
</div>