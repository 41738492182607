import { Component, Input } from "@angular/core";
import { CommonfunctionService } from "../../../../services/commonfunction.service";

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_reportGroup_post</li>
 * <li>umsapi_resource_get</li>
 * <li>umsapi_resource_post</li>
 * </ol>
 *
 */

@Component({
  selector: "group-contextmenu",
  templateUrl: "../../subelements/master-menu.component.html",
  styleUrls: ["../../admin-configuration.component.css"],
})
export class GroupContextMenu {
  @Input("item") item;
  @Input("openDialog") openDialog;
  @Input("mastergroups_access") mastergroups;
  @Input("masters_access") masters;

  report_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };

  group_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };

  constructor(private fnc: CommonfunctionService) {
    this.group_access = this.fnc.checkResourceAccess("report_groups", false);
    this.report_access = this.fnc.checkResourceAccess("reports", false);
    // console.log(this.group_access);
  }

  update(action, info: {}) {}

  ngOnInit() {
    // if (this.mastergroups_access && this.masters) {
    //   this.mastergroups_access = this.mastergroups;
    //   this.masters_access = this.masters;
    // }
    //console.log(this.masters_access)
  }
}
