import { Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '../../services/notification.service';
import { ApiserviceService } from '../../../apiservice.service';

@Component({
  selector: 'app-archive-assignment',
  templateUrl: './archive-assignment.component.html',
  styleUrls: ['./archive-assignment.component.scss']
})
export class ArchiveAssignmentComponent {
  archive_comment: string='';
  selectedAssignment;
  archive_data: any=[];
  ignostatus=[5,1];
  propcount=false;
  constructor(public dialogRef: MatDialogRef<ArchiveAssignmentComponent>,
    @Inject(MAT_DIALOG_DATA) public pdata: any,
    private notify: NotificationService,
    private api: ApiserviceService){
      this.selectedAssignment = pdata.selectedAssignment;
      if(pdata.info.data){
        this.archive_data = pdata.info.data;
        let total=0;
        this.archive_data.map((e:any)=>{
          if(this.ignostatus.indexOf(e.status) == -1){
            total = total + Number(e.count);
            if(total > 0){
              this.propcount = true;
            }
          }
        })

      }
  }

  closePopup(){
    this.dialogRef.close(false);
  }
  saveArchiveData(){
    if(this.archive_comment == ''){
      this.notify.notify("Please add comment","warn");
      return;
    }
    let body= {
        "assignment_id" : this.selectedAssignment.id,
        "comment": this.archive_comment,
        "force": true,
        "updated_by": this.api.user_id
      }

    this.api.patchEmsData(`archive-assignment`, body).subscribe({
      next: (res:any)=>{
        this.archive_comment == '';
        this.notify.notify(res.message, "success");
        this.dialogRef.close(true);
      },
      error:(err)=>{
        this.archive_comment == '';
        // this.notify.notify(err.error.message, "error");
        this.dialogRef.close();
      }
    })

  }

}
