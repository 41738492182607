import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApiserviceService } from '../../../apiservice.service';
import { NotificationService } from '../../services/notification.service';
import { ShowPropertyMapComponent } from '../show-property-map/show-property-map.component';
import { CommonfunctionService } from '../../../services/commonfunction.service';

@Component({
  selector: 'app-new-csv-conflict-data',
  templateUrl: './new-csv-conflict-data.component.html',
  styleUrls: ['./new-csv-conflict-data.component.scss']
})
export class NewCsvConflictDataComponent {
  upload_id: number;
  conflict_list=[];
  existing_list:any=[];
  loader:boolean=false;
  select_property_uid: any;
  loader2: boolean;
  constructor(
    public dialogRef: MatDialogRef<NewCsvConflictDataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dailog: MatDialog,
    private notify: NotificationService,
    private fnc: CommonfunctionService,
    private api : ApiserviceService){

      this.upload_id = data.id
      this.getConflictData();
  }

  close(){
    this.dialogRef.close();
  }
  getConflictData(){
    this.loader=true;
    let url :string= `csv-conflict?upload_id=${this.upload_id}`;
    this.api.getCsvData(url)
    .subscribe({
      next: (res:any) =>{
        this.loader=false;
        if(res.data){
          this.conflict_list = res.data;
        }
      },
      error:(err)=>{
        this.loader=false;
        this.notify.notify(err.error.message || "Invalid user token, please try again", "error");
      }
    })
  }

  getExistingList(conflict_data){
    return new Promise(async (resolve, reject) => {
      this.loader2=true;
      let url :string= `csv-conflict?building_gis_uid=${conflict_data.toString()}`;
      this.api.getCsvData(url)
      .subscribe({
        next: (res:any) =>{
          this.loader2=false;
          if(res.data){
            resolve(res.data);
          }else{
            resolve([]);
          }
        },
        error:(err)=>{
          this.loader2=false;
          this.notify.notify(err.error.message || "Invalid user token, please try again", "error");
          reject([]);
        }
      })
    })
  }


 async selectNewtProp(property, map:boolean=false){
    this.select_property_uid = property.legacy_property_uid;
    if(property.conflict_data && property.conflict_data.length>0){
      this.existing_list = await this.getExistingList(property.conflict_data.toString());
      if(map) this.openMap(property);
    }else{
      this.notify.notify('Conflict data not found', 'success');
      this.existing_list =[];
    }
  }

  // 1=create new,
  // 3 = ignore legacy property
  createNewProperty(property, action, event){
    event.stopPropagation();
  let body = {
    "upload_id": this.upload_id,
    "updated_by": this.api.user_id,
    "legacy_property_uid": property.legacy_property_uid,
    "action":action
  }
  this.loader=true;
  this.api.patchCsvData(`csv-conflict-resolution`, body).subscribe({
    next: (res:any)=>{
      this.loader=false;
      if(res.status==201){
        this.select_property_uid=null;
        this.existing_list =[];
        this.conflict_list=[];
        this.getConflictData();
        this.notify.notify(res.message, 'success');
      }else{
        this.notify.notify(res.message, 'warn');
      }

    },
    error:(err)=>{
      this.loader=false;
      this.notify.notify(err.error.message || "Invalid user token, please try again", "error");
    }
  })
  }


  // 2= merge legacy property with existing one,
  mergeProperty(property_data, action){
    let body = {
      "upload_id": this.upload_id,
      "updated_by": this.api.user_id,
      "legacy_property_uid": this.select_property_uid,
      "property_uid": property_data.property_uid,
      "action": action,
    }
      this.api.patchCsvData(`csv-conflict-resolution`, body).subscribe({
        next: (res:any)=>{
          this.loader=false;
          if(res.status==201){
            this.select_property_uid=null;
            this.existing_list =[];
            this.conflict_list=[];
            this.getConflictData();
            this.notify.notify(res.message, 'success');
          }else{
            this.notify.notify(res.message, 'warn');
          }
        },
        error:(err)=>{
          this.notify.notify(err.error.message || "Invalid user token, please try again", "error");
        }
      })
  }

  async openMap(prop){
    if(this.existing_list.length){
      let markers=[];
        this.existing_list.map(p=>{
          markers.push(
            {
              lat: p.lat,
              lon: p.lon,
              marker: 'assets/icon/Pin1.svg',
              uid: p.property_uid,
              w:45,
              h:45
            }
          )
        })
        markers.push(
          {
            lat: Number(prop.lat),
            lon: Number(prop.lon),
            marker: 'assets/icon/Pin.svg',
            uid: prop.legacy_property_uid,
            w:30,
            h:30
          }
        )
        this.dailog.open(ShowPropertyMapComponent,{
          width: '60vw',
          height: '500px',
          data: {property: prop, markers: markers}
        })
      }
    }

    formatData(val){
      if(val){
        return this.fnc.modifyInt({value: val});
      }
      return 0;
    }
  }
