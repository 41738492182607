<div *ngIf="attributegroups_access.PATCH || attributegroups_access.DELETE">
    <div *ngIf="attributegroups_access.PATCH" class="mastergroup_menuactions" (click)="editme(item)">
        Edit
    </div>
    <div class="divider"></div>
    <div *ngIf="attributegroups_access.DELETE" class="mastergroup_menuactions"  (click)="delete(item)">
       Delete
    </div>
    <!-- <div class="arrows"><img src="/assets/images/arr.png" /></div> -->
</div>
