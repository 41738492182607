import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-attribute-deletion-search-value',
  templateUrl: './admin-attribute-deletion-data-table.html',
  styleUrls: ['./admin-attribute-deletion-data-table.scss']
})
export class AdminAttributeDeletionSearchValueComponent {
constructor(){

}
ngOnInit(): void {

}
}
