import { Component, Inject, OnInit, Output, Input, EventEmitter, ViewChild, NgZone } from "@angular/core";
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-dialogbox',
    templateUrl: 'confirm-dialogbox.html',
    styleUrls: ['confirm-dialogbox.css']
  })
  export class ConfirmDialogBox {

    message:string = '';
    data:any;

    constructor(
      public dialogRef: MatDialogRef<ConfirmDialogBox>,
      @Inject(MAT_DIALOG_DATA) public info: any) {
        this.data = this.info.parent_data;
        this.message = this.info.message;
      }
  
      confirmDialogBox(check){
        this.data?.confirmDialogYes(check);
        this.dialogRef.close(check);
      }
  
  }