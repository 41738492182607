import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiserviceService } from "../../../../apiservice.service";
import { CommonfunctionService } from "../../../../services/commonfunction.service";


/**
*
* <strong>List of API using</strong>
* <ol>
* <li>estapi_entity_masters_copy_post</li>
* <li>unlink-master-values</li>
* </ol>
*
*/



@Component({
  selector: 'master-value-dialog',
  templateUrl: 'master-value-dialog.html',
  styleUrls: ['master-value-dialog.scss']
})

export class MasterValueDialog {
  selected_master_val_list: any;
  selected_entity_type: any;
  selected_property_types: any = [];
  is_property_dependent: boolean;
  constructor(public dialogRef: MatDialogRef<MasterValueDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, public api: ApiserviceService, private fnc: CommonfunctionService) {
    this.selected_master_val_list = this.data.parent.selected_master_valueList;
  }

  ngOninit() { }

  getEntity(e) {
    if (e.value == 'property_unit') {
      this.is_property_dependent = true;
    } else {
      this.is_property_dependent = false;
    }
  }
  pasteAttributes() {
    let data = {
      "added_by": this.api.user_id,
      "master_value_ids": this.selected_master_val_list,
    }
    if (this.selected_property_types.length == 0) {
      data["property_type_ids"] = null;
    } else {
      data["property_type_ids"] = this.selected_property_types;
    }
    this.api.postEmsData(this.selected_entity_type + "/masters/copy", data).subscribe(
      {next:(res) => {
      if (res) {
        this.data.parent.notify.notify('Master Values copied successfully!', 'success');
      } else {
        this.data.parent.notify.notify('Master Values not copied', 'warn');
      }
      this.close();
    }, error:(err) => {
      this.data.parent.notify.notify(this.fnc.getErrorMessage(err), 'error');
    }}
    );
  }
  close() {
    this.data.parent.selected_master_valueList = [];
    this.selected_master_val_list = [];
    this.data.parent.showCopy = false;
    this.dialogRef.close();
  }
}
