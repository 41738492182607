import { Component, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatErrorStateMatcher } from "../../../admin-error-state-macher";
import { NotificationService } from "../../../services/notification.service";
import { ApiserviceService } from "../../../../apiservice.service";
import { CommonfunctionService } from "../../../../services/commonfunction.service";

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_module_type_entity_attributegroups_group_id_patch</li>
 * <li>estapi_module_type_entity_attributegroups_post</li>
 * </ol>
 *
 */
@Component({
  selector: "createfields",
  templateUrl: "admin-createfields.component.html",
  styleUrls: ["./create-update-field.component.scss"],
})
export class CreateFieldDialog {
  matcher = new MatErrorStateMatcher();
  groupform: FormGroup;
  languages: Array<any>;
  property_list: Array<{}>;
  language_valueset: Array<{}> = [];
  description_language: Array<{}> = [];
  group_list = [];
  property_type_init = [];
  loader: boolean;
  duplicateLang: any = [];
  descriptionDupLang: any;
  get groupName() {
    return this.groupform.get("group_name");
  }
  constructor(
    public dialogRef: MatDialogRef<CreateFieldDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder,
    private api: ApiserviceService,
    private notify: NotificationService,
    private fnc: CommonfunctionService
  ) {
    this.languages = this.api.language_list;
    this.property_list = this.api.property_type_list;
    this.group_list = this.data.parent.group_custom_list;
    this.property_type_init.push(this.data.parent.selected_property_type);
    /* #Form - Intial Form Definition */
    this.groupform = this._fb.group({
      group_name: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9_]*$"),
        ]),
      ],
      language: this._fb.array([]),
      description: this._fb.array([]),
      //property_type : [null,Validators.compose([this.conditionalValidation('property')])],
      property_type: [this.property_type_init],
      status: [true],
      parent_group: [""],
    });
    // console.log(this.language_valueset);
    /* End #Form - Intial Form Definition */
    if (this.data.val) {
      this.group_list = fnc.getDeletedArray(
        "id",
        this.data.val.id,
        this.group_list
      );
      let labels: any = [];
      let description: any = [];
      let index = 0;
      for (let prop in this.data.val.labels) {
        labels[index] = { language: +prop, value: this.data.val.labels[prop] }; // +prop to convert into number
        this.addRow();
        index++;
      }
      index = 0;
      if (this.data.val.description) {
        for (let prop in this.data.val.description) {
          description[index] = {
            language: +prop,
            value: this.data.val.description[prop],
          }; // +prop to convert into number
          this.addDescriptionRow();
          index++;
        }
      } else {
        this.addDescriptionRow();
      }

      this.groupform.patchValue({
        language: labels,
        description: description,
        group_name: this.data.val.name,
        parent_group: this.data.val.parent_id,
        status: this.data.val.status,
      });

      this.groupform.get("group_name").disable();
    } else {
      this.addRow();
      this.addDescriptionRow();
      this.groupform.patchValue({
        language: [{ language: this.languages[0].language_id, value: "" }],
      });
      this.groupform.patchValue({
        description: [{ language: this.languages[0].language_id, value: "" }],
      });
    }
  }

  conditionalValidation(field) {
    if (this.data.parent.is_property_dependent) {
      return Validators.required;
    } else {
      return Validators.nullValidator;
    }
  }
  // selected duplicate language
  selectedLang(languageList, desLangList) {
    languageList.forEach((lang) => {
      this.duplicateLang = languageList.filter(
        (val) => val.language === lang.language
      ); // filtering duplicate languages list
    });
    desLangList.forEach((resultLang) => {
      this.descriptionDupLang = desLangList.filter(
        (val) => val.language === resultLang.language
      ); // filtering duplicate languages list for description language
    });
  }
  /* #Form - Language multiple field init */

  saveform(groupform) {
    console.log(this.descriptionDupLang, "this.descriptionDupLang");

    let langList = this.groupform.value.language; // selected language list
    let desLangList = this.groupform.value.description; // selected language list
    this.selectedLang(langList, desLangList);
    let isEnglishSelected = langList.find((ele) => ele.language == 1); // finding english language selected or not
    if (this.duplicateLang.length > 1 || this.descriptionDupLang.length > 1) {
      this.notify.notify(
        "You have selected same language multiple time",
        "warn"
      ); // if selected same language multiple time
      return;
    }
    if (!isEnglishSelected) {
      this.notify.notify("English language is required", "warn"); // if english language not selected
      return;
    }
    let value = groupform.value;
    let parent_id = null;
    let property_type = null;
    let status = 0;

    if (value.property_type) property_type = value.property_type;
    if (value.parent_group) parent_id = value.parent_group;
    if (value.status) status = 1;

    let add_group = {
      added_by: this.api.user_id,
      updated_by: this.api.user_id,
      description: {},
      group_label: {},
      group_name: value.group_name,
      parent_group_id: parent_id,
      property_type_ids: property_type,
      sort_order: 0,
      status: status,
      assignment_id: this.data.parent.selected_assignment,
    };
    value.language.forEach((v, k) => {
      add_group.group_label[v.language] = v.value;
    });
    value.description.forEach((v, k) => {
      add_group.description[v.language] = v.value;
    });
    if (this.data.val) {
      add_group.sort_order = null;
      let url =
        this.data.parent.selected_module +
        "/" +
        this.data.parent.selected_entity_type +
        "/attributegroups/" +
        this.data.val.id;
      this.loader = true;
      this.api.patchEmsData(url, add_group).subscribe({
        next: (data: any) => {
          this.loader = false;
          this.notify.notify("Group Updated", "success");
          this.data.parent.getGroups("sort_order", 1000, 0);
        },
        error: (err) => {
          this.loader = false;
          this.notify.notify(this.fnc.getErrorMessage(err), "error");
        },
        complete: () => this.closeDialog(),
      });
    } else {
      //this.data.parent.sortGroup(); // sort before adding new value
      let url =
        this.data.parent.selected_module +
        "/" +
        this.data.parent.selected_entity_type +
        "/attributegroups/";
      this.loader = true;
      this.api.postEmsData(url, add_group).subscribe({
        next: (data: any) => {
          this.loader = false;
          this.notify.notify("Group Created", "success");
          this.data.parent.getGroups("sort_order", 1000, 0);
        },
        error: (err) => {
          this.loader = false;
          this.notify.notify(this.fnc.getErrorMessage(err), "error");
        },
        complete: () => this.closeDialog(),
      });
    }
    // this.closeDialog();
  }

  initLanguageFields() {
    return this._fb.group({
      language: [this.languages[0].language_id, Validators.required],
      value: ["", Validators.required],
    });
  }

  initDescriptionLanguageFields() {
    return this._fb.group({
      language: [this.languages[0].language_id, Validators.required],
      value: [],
    });
  }
  /* End #Form - Language multiple field init */

  closeDialog(): void {
    this.dialogRef.close();
  }

  addRow() {
    if (this.language_valueset?.length == this.languages?.length) {
      this.notify.notify("No more Languages Available to Add", "warn", 4000);
      return;
    }
    this.language_valueset.push({ language: "", value: "" });
    /* #Form */
    let dd = <FormArray>this.groupform.controls.language;
    dd.push(this.initLanguageFields());
    /* End - #Form */
  }

  addDescriptionRow() {
    if (this.description_language?.length == this.languages?.length) {
      this.notify.notify("No more Languages Available to Add", "warn", 4000);
      return;
    }
    this.description_language.push({ language: "", value: "" });
    /* #Form */
    let dd = <FormArray>this.groupform.controls.description;
    dd.push(this.initDescriptionLanguageFields());
    /* End - #Form */
  }

  removeRow(key) {
    if (this.language_valueset.length > 1) {
      this.language_valueset.splice(key, 1);
      let dd = <FormArray>this.groupform.controls.language;
      dd.removeAt(key);
    }
  }

  removeDescriptionRow(key) {
    if (this.description_language.length > 1) {
      this.description_language.splice(key, 1);
      let dd = <FormArray>this.groupform.controls.description;
      dd.removeAt(key);
    }
  }
}
