import { Component, AfterViewInit, ViewChild, Inject } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiserviceService } from "../../../../apiservice.service";
import { CommonfunctionService } from "../../../../services/commonfunction.service";
import { MatErrorStateMatcher } from "../../../admin-error-state-macher";
import { NotificationService } from "../../../services/notification.service";


/**
*
* <strong>List of API using</strong>
* <ol>
* <li>estapi_mastergroups_post</li>
* <li>estapi_mastergroups_master_group_id_patch</li>
* </ol>
*
*/

@Component({
  selector: 'master-group-dialog',
  templateUrl: 'admin-creategroup.component.html',
  styleUrls: ['admin-create-group.scss']
})
export class CreateMasterGroupDialog implements AfterViewInit {
  @ViewChild('groupForm') groupForm: NgForm;
  @ViewChild('groupFormEdit') groupFormEdit: NgForm;
  matcher = new MatErrorStateMatcher();
  public group: any;
  public group_update: any;
  public edit: boolean;
  public id: number;
  constructor(
    public dialogRef: MatDialogRef<CreateMasterGroupDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, private api: ApiserviceService, private notify: NotificationService, private fnc: CommonfunctionService) {
    this.edit = data.edit;
    this.group = {
      added_by: this.api.user_id,
      master_group_label: { 1: "" }, // 1 is for english
      master_group_name: "",
      parent_master_group_id: null,
      sort_order: 1,
      status: 1
    };
    this.group_update = {
      updated_by: this.api.user_id,
      master_group_label: { 1: "" },
      master_group_name: "",
      parent_master_group_id: null,
      sort_order: 1,
      status: 1
    };

    if (this.edit) {
      this.id = data.group.id;
      this.group_update.master_group_label[this.api.language] = data.group.title;
    }

  }

  ngAfterViewInit(): void {
  }

  closeDialog(e): void {
    e.preventDefault();
    this.dialogRef.close();
  }

  createMasterGroup(e) {
    let re = /\ /gi;
    this.group.master_group_name = this.group.master_group_label[this.api.language].replace(re, "_"); // replace all space of lable with _ for name
    this.api.postEmsData('mastergroups', this.group)
      .subscribe(
       {next : (data:any) => {
          this.notify.notify('Group Created', 'success');
          this.data.pObj.getMaster('master_name', 1000, 0);
        },
        error :(err) => this.notify.notify(this.fnc.getErrorMessage(err), 'error'),
        complete : () => this.closeDialog(e)
      }
      );
  }
  updateMasterGroup(e) {
    let re = /\ /gi;
    this.group_update.master_group_name = this.group_update.master_group_label[this.api.language].replace(re, "_"); // replace all space of lable with _ for name
    this.api.patchEmsData('mastergroups/' + this.id, this.group_update)
      .subscribe(
       {next: (data:any) => {
          this.notify.notify('Group Updated', 'success');
          this.data.pObj.getMaster('master_name', 1000, 0);
        },
       error : (err) => this.notify.notify(this.fnc.getErrorMessage(err), 'error'),
       complete : () => this.closeDialog(e)
      }
      );
  }
}
