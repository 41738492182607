import { Component, OnInit } from "@angular/core"
import { ApiserviceService } from '../../apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { NotificationService } from '../services/notification.service';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';

@Component({
  selector: 'app-sync-property',
  templateUrl: './sync-property.component.html',
  styleUrls: ['./sync-property.component.css']
})
export class SyncPropertyComponent implements OnInit {

  cardsList: Array<{}> = [];
  load: boolean = false;
  selectedCardId: any = null;
  sync_property_access = { GET: false, PATCH: false, POST: false, DELETE: false, };
  constructor(private api: ApiserviceService, private menu: AdminMenusService,
    private fnc: CommonfunctionService,
    private notify: NotificationService) {
    this.menu.setActive('sync-property');
    this.getAllowResourcesApi();
  }
  getAllowResourcesApi() {
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body)
      .subscribe((res: any) => {
        this.api.allowResources = res.data;
        this.sync_property_access = this.fnc.checkResourceAccess('sync_property', false);
        this.getSnapshotCards();

      }, err => {
      })
  }
  refreshData(data) {
    if (!this.sync_property_access.POST) {
      this.notify.notify(`You don't have permission to execute this process`);
      return;
    }
    this.selectedCardId = data.index;
    this.load = true;
    let body = {
      "action": data['action'],
      "type": data['type']
    };
    this.api.postEpsData('snapshot-refresh', body).subscribe((res: any) => {
      this.load = false;
      this.notify.notify('Refresh complete', 'success');
      this.getSnapshotCards();
    }, err => {
      this.load = false;
      this.notify.notify('Could not refresh snapshot, please try again in a while', 'error');
    })
  }

  getSnapshotCards() {
    if (!this.sync_property_access.GET) {
      this.notify.notify(`You don't have permission to view this page!`, `warn`);
      return;
    }
    this.api.getEpsData('snapshot-refresh?action=get').subscribe((res: any) => {
      if (!res) return;
      let arr = [];
      res.map((item: any, index) => {
        arr.push({
          title: item['title'],
          id: item['id'],
          index: index + 1,
          description: item['description'],
          action: item['action'],
          type: item['type'],
          last_sync: this.getDate(item['last_sync']),
          time_spent: item['time_spent']//this.getDate(item['time_spent'])
        })
      });
      this.cardsList = arr;
    }, err => {

    })
  }

  roundTimeQuarterHour(time) {
    var timeToReturn = new Date(time);

    timeToReturn.setMilliseconds(Math.round(timeToReturn.getMilliseconds() / 1000) * 1000);
    timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
    timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 15) * 15);
    return timeToReturn;
  }

  getIndex(index) {
    return ((index % 10) == 0) ? (index % 10) + 1 : index % 10;
  }
  getDate(dt) {
    if(dt){
      let d = new Date(dt);
      return this.fnc.formatDateUTC(d, true);
    }else{
      return '';
    }
  }

  ngOnInit() {
    // console.log(this.cardsList)

  }
}
