<mat-toolbar class="matpopup-header">
  <div>Add Report</div>
</mat-toolbar>
<!--<p>Validation status: {{ reportform.status }}</p>-->
<form [formGroup]="reportform" (ngSubmit)="saveReport(reportform)">
  <mat-dialog-content>
    <div class="form-container">
      <div class="form-input-container" fxLayout="row" fxLayoutAlign="space-between center">
        <label fxFlex="30%">Report Name</label>
        <mat-form-field floatLabel="never" fxFlex="70%">
          <input type="text" formControlName="report_name" name="report_name" class="form-control" />
          <mat-error *ngIf="!reportform.controls.report_name.valid">
            Enter valid <strong>report name</strong>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-input-container" fxLayout="row" fxLayoutAlign="space-between start">
        <label fxFlex="30%" style="padding-top: 2%">Label</label>
        <div fxFlex="70%" fxLayout="column" formArrayName="language">
          <div *ngFor="let languagevalues of language_valueset; let kk = index">
            <div [formGroupName]="kk" fxLayout="row" fxLayoutAlign="space-between center">
              <mat-form-field floatLabel="never" fxFlex="30%">
                <mat-select formControlName="language" name="language">
                  <mat-option *ngFor="let language of languages" [value]="language.language_id">
                    {{ language.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field floatLabel="never" fxFlex="45%">
                <input type="text" matInput formControlName="value" name="value" class="form-control" />
                <mat-error *ngIf="
                    !reportform.controls.language.controls[kk].controls.value
                      .valid
                  ">
                  This field is <strong>required</strong>
                </mat-error>
              </mat-form-field>
              <div class="addbtn">
                <div (click)="removeRow(kk)" class="border iconTick-1">
                  <mat-icon>remove</mat-icon>
                </div>
                <div (click)="addRow()" class="border iconTick-1">
                  <mat-icon>add</mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-input-container" fxLayout="row" fxLayoutAlign="space-between start">
        <label style="padding-top: 3%" fxFlex="30%">Description</label>
        <div class="w-100" fxFlex="70%" formArrayName="description">
          <div *ngFor="let languagevalues of description_language; let kk = index">
            <div [formGroupName]="kk" fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field floatLabel="never" fxFlex="30%">
                <mat-select formControlName="language" name="language">
                  <mat-option *ngFor="let language of languages" [value]="language.language_id">
                    {{ language.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field floatLabel="never" fxFlex="45%">
                <textarea matInput formControlName="value" name="value"></textarea>
                <!-- <mat-error *ngIf="!reportform.controls.description.controls[kk].controls.value.valid">
                                  This field is <strong>required</strong>
                              </mat-error> -->
              </mat-form-field>
              <div class="addbtn">
                <div (click)="removeDescriptionRow(kk)" class="border iconTick-1">
                  <mat-icon>remove</mat-icon>
                </div>
                <div (click)="addDescriptionRow()" class="border iconTick-1">
                  <mat-icon>add</mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-input-container" fxLayout="row" fxLayoutAlign="space-between center">
        <label fxFlex="30%">Report URL</label>
        <mat-form-field floatLabel="never" fxFlex="70%">
          <input type="text" formControlName="url" name="url" matInput />
          <mat-error *ngIf="!reportform.controls.url.valid">
            Enter valid <strong>report url</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="dialog-actions">
    <button [disabled]="reportform.invalid" type="submit" mat-button class="blue-button">
      Submit
    </button>
    <button type="button" mat-raised-button (click)="closeDialog()">
      Cancel
    </button>
  </mat-dialog-actions>
</form>
