<div class="dialog-container">
    <mat-toolbar class="customize">
        <span *ngIf="!update">Create Assignment</span>
        <span *ngIf="update">Update Assignment</span>
    </mat-toolbar>
    <div class="p-3">
        <form [formGroup]="assignmentForm" (ngSubmit)="addAssignmentVal(assignmentForm,$event)">
            <div *ngIf="update" class="section">
                <strong class="size-M">Assignment Id</strong> - {{assignment_id}}
            </div>
            <div class="section">
                <div class="form-group">
                    <strong class="size-M">Assignment Name <span class="text-danger">*</span></strong>
                </div>
                <div class="form-group">
                    <input class="form-control" placeholder="Assignment Name" formControlName="assignment_name" #assignment_Name  maxlength="64">
                    <mat-error *ngIf="assignmentName.hasError('required') && assignmentName.dirty">Assignment Name is required!</mat-error>
                    <mat-error *ngIf="assignmentName.hasError('pattern')">Special Characters not Allowed!</mat-error>
                    <mat-error *ngIf="assignmentName.hasError('maxlength')"> Maximum 64 Characters Allowed!</mat-error>
                    <mat-error *ngIf="assignmentName.hasError('minlength')"> Minimum 3 Characters Allowed!</mat-error>
                    <mat-hint class="d-flex justify-content-end"> {{assignment_Name.value.length}}/64 </mat-hint>
                </div>

            </div>
            <div class="section d-flex justify-content-between align-items-center">
                <div class="w-50">
                    <div class="form-group">
                        <strong class="size-M">Assignment Type <span class="text-danger">*</span></strong>
                    </div>
                    <div class="form-group">
                        <mat-select class="form-control" placeholder="Select Assignment Type" formControlName="assignment_type">
                            <mat-option *ngFor="let assignment of assignment_type" [value]="assignment.name">
                                {{assignment.label}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="assignmentType.hasError('required') && assignmentType.dirty">Assignment Type is required!</mat-error>
                    </div>
                </div>
                <div class="w-50">
                    <div class="form-group">
                        <strong class="size-M">Year <span class="text-danger">*</span></strong>
                    </div>
                    <div class="form-group">
                        <mat-select class="form-control" placeholder="Select year" formControlName="year" (openedChange)="closeMatSelectEvent($event)" >
                            <div class="search_bar"><input [(ngModel)]="search_year" [ngModelOptions]="{standalone: true}"
                                placeholder="Search..." (keydown)="$event.stopPropagation()" /></div>
                            <mat-option *ngFor="let year of yearList | searchFilter :'val':search_year:yearList" [value]="year.val">
                                {{year.val}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="Year.hasError('required') && Year.dirty">Year is required!</mat-error>
                    </div>
                </div>
            </div>
            <div class="section" formArrayName="language">
                <div class="form-group">
                    <strong class="size-M">Language <span class="text-danger">*</span></strong>
                    <strong class="size-M" style="margin-left: 91px;">Assignment Label <span class="text-danger">*</span></strong>
                </div>

                <div class="form-group" *ngFor="let languagevalues of language_valueset;let kk = index">
                    <ng-container [formGroupName]="kk">
                        <div class="rowgrid">
                            <div class="form-container">
                                <div class="rowgrid">
                                    <mat-select class="form-control form-select-len" formControlName="language">
                                        <mat-option *ngFor="let language of languages" value="{{language.id}}"> {{language.name}}</mat-option>
                                    </mat-select>
                                    <span>
                                        <input class="form-control form-select-len" formControlName="assignment_label" #assignment_label maxlength="64">
                                        <mat-error *ngIf="assignmentForm.controls.language.controls[kk].controls['assignment_label'].hasError('required') && assignmentForm.controls.language.controls[kk].controls['assignment_label'].dirty">Label is required</mat-error>
                                        <mat-error *ngIf="assignmentForm.controls.language.controls[kk].controls['assignment_label'].hasError('maxlength')">Label can not more than 45 Characters </mat-error>
                                        <mat-hint class="d-flex justify-content-end mr-1"> {{assignment_label.value.length}}/64 </mat-hint>
                                      </span>
                                </div>
                            </div>
                            <div class="addbtn">
                                <div (click)="removeRow(kk)" class="border iconTick-1">
                                    <mat-icon>remove</mat-icon>
                                </div>
                                <div (click)="addRow()" class="border iconTick-1">
                                    <mat-icon>add</mat-icon>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="section grid-2" fxLayout="row" fxLayoutAlign="center center">
                <button mat-raised-button (click)="saveDialog()" [disabled]="assignmentForm.invalid" class="blue-button">
                    <span *ngIf="!update">Create</span>
                    <span *ngIf="update">Update</span>
                </button>
                <button (click)="closeDialog()" mat-raised-button type="button">Cancel</button>
            </div>
        </form>
    </div>
</div>
