<!-- #4 Sortable Master Groups set -->
<div>
  <div class="grouplist">
    <div class="cst rowse" (click)="quickAction()">
      <div title="Quick Actions" class="group-b dragx" dndDropzone (dndDrop)="onDrop($event)"
        [ngClass]="{active: isActive(null)}">
        <span class="w-100 title">Quick Actions</span>
        <span style="padding-right: 5px;">({{attrCount}})</span>
      </div>
    </div>
  </div>
  <div class="grouplist" cdkDropList (cdkDropListDropped)="groupoptions($event)">

    <div class="cst rowse" *ngFor="let item of grouplist;let k = index;" cdkDrag>
      <div class="data-placeholder" *cdkDragPlaceholder></div>
      <div (click)="item.status == 0 ? $event.stopPropagation() : listAttributes(item);" class="group-b dragx"
        [ngClass]="{active: isActive(item.id), disabled: !item.status}" (mouseover)="showsmallpop($event, item.id)">
        <!-- <mat-icon class="draghandle" matTooltip="Drag me" svgIcon="move-icon"></mat-icon> -->
        <img width="18" matTooltip="Drag me" style="cursor: move; margin: 0 10px 0 0;" src="assets/icon/drag.svg"
          alt="">
        <span class="w-100 title">{{item.group_name}}</span>
        <span style="padding-right: 5px;"
          (click)="select(item.id); $event.stopPropagation()">({{item.sub_group_count}}/{{item.attributes_count}})</span>
        <mat-icon class="add-b" [ngClass]="{open : isActive(item.id)}"
          (click)="select(item.id);  $event.stopPropagation()" *ngIf="item.childrens && item.childrens.length"
          svgIcon="down-arrow-icon"></mat-icon>
        <field-master-contextmenu [attributegroups_access]="attributegroups_access" [item]="item" *ngIf="!data"
          (editclick)="editgroup($event)" class="tooltip-nearmaster"
          [ngClass]="{'showtool' : popwindow===item.id && dragDisabled, 'hidetool': popwindow != item.id}"></field-master-contextmenu>

      </div>


      <!-- #5 Submenu Master group -->
      <div class="cs" fxLayout="row" fxLayoutAlign="start start">
        <mat-nav-list class="masterslist w-100" [sortablejs]="item.childrens" [sortablejsOptions]="subgroupoptions"
          [ngClass]="{active: isActive(item.id)}" *ngIf="item.childrens && item.childrens.length">
          <ng-container *ngFor="let childitem of item.childrens;let ks = index">
            <div (click)="listAttributes(childitem)" class="item subdraghandle"
              [ngClass]="{disabled: !childitem.status, active: isActiveSub(childitem.id)}"
              (mouseover)="showsmallsubpop($event, 'tooltip-subnearmaster',ks,false)"
              (mouseout)="showsmallsubpop($event,  'tooltip-subnearmaster', null, false)">
              <!-- <mat-icon class="draghandle" matTooltip="Drag me" svgIcon="move-icon"></mat-icon> -->
              <img width="18" matTooltip="Drag me" style="cursor: move; margin: 0 10px 0 0;"
                src="assets/icon/drag-w.svg" alt="">
              <div class="txt">{{childitem.group_name}}</div>
              <span style="padding-left: 5px;">({{childitem.attributes_count}})</span>
              <field-submaster-contextmenu [attributegroups_access]="attributegroups_access" [item]="childitem"
                *ngIf="!data" (shuffle)="editgroup($event)" (edit)="editgroup($event)" class="tooltip-subnearmaster"
                [ngClass]="{'showsubtool' : popsubwindow===ks, 'hidesubtool': popsubwindow != ks}"></field-submaster-contextmenu>
            </div>
          </ng-container>
        </mat-nav-list>
      </div>
      <!-- #5 End of submaster group -->
    </div>
  </div>
</div>
<!-- End of #4 -->

<!--#1 For Mobile -->
<!-- <div fxShow="true" fxShow.gt-sm="false" fxLayout="row" fxLayoutAlign="space-between left">
    <div class="m-grouplist">
        <mat-nav-list class=" mobilemastergroups" [sortablejs]="grouplist" [sortablejsOptions]="mobilegroupoptions">
            <ng-container *ngFor="let item of grouplist;let k = index;">
                <mat-list-item class="mobilerowslist item">
                    <svg matTooltip="Drag me" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 490.677 490.677" style="enable-background:new 0 0 490.677 490.677;" xml:space="preserve" width="18px" height="18px">
                        <g><g><path fill="#424c73" d="M487.557,237.789l-64-64c-3.051-3.051-7.659-3.947-11.627-2.304c-3.989,1.643-6.592,5.547-6.592,9.856v32h-128v-128h32    c4.309,0,8.213-2.603,9.856-6.592c1.643-3.989,0.725-8.576-2.304-11.627l-64-64c-4.16-4.16-10.923-4.16-15.083,0l-64,64    c-3.051,3.072-3.968,7.637-2.325,11.627c1.643,3.989,5.547,6.592,9.856,6.592h32v128h-128v-32c0-4.309-2.603-8.213-6.592-9.856    c-3.925-1.664-8.555-0.747-11.627,2.304l-64,64c-4.16,4.16-4.16,10.923,0,15.083l64,64c3.072,3.072,7.68,4.011,11.627,2.304    c3.989-1.621,6.592-5.525,6.592-9.835v-32h128v128h-32c-4.309,0-8.213,2.603-9.856,6.592c-1.643,3.989-0.725,8.576,2.304,11.627    l64,64c2.091,2.069,4.821,3.115,7.552,3.115s5.461-1.045,7.552-3.115l64-64c3.051-3.051,3.968-7.637,2.304-11.627    c-1.664-3.989-5.547-6.592-9.856-6.592h-32v-128h128v32c0,4.309,2.603,8.213,6.592,9.856c3.947,1.685,8.576,0.747,11.627-2.304    l64-64C491.717,248.712,491.717,241.971,487.557,237.789z" />
                        </g></g>
                    </svg>
                    <div (click)="loadGroup()" fxFlex="80%" class="txt">{{item.group_name}} </div>
                    <mat-icon fxFlex="10%" (click)="showsmallpopmobile($event,'m-tooltip-nearmaster', k,item)">more_vert</mat-icon>
                </mat-list-item>
            </ng-container>
        </mat-nav-list>

    </div>
    <field-master-contextmenu [item]="currentitem" class="m-tooltip-nearmaster" [ngClass]="{'showtool' : popwindow >= 0}"
        (editclick)="editgroup($event)"></field-master-contextmenu>
    <div class="m-subgrouplist" (swipeleft)="swiped()">
        <mat-nav-list class="mobilesubmastergroups">
            <ng-container *ngFor="let item of submasterlist;let k = index;">
                <mat-list-item class="item">
                    <svg matTooltip="Drag me" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 490.677 490.677" style="enable-background:new 0 0 490.677 490.677;" xml:space="preserve" width="18px" height="18px">
                        <g><g><path fill="#424c73" d="M487.557,237.789l-64-64c-3.051-3.051-7.659-3.947-11.627-2.304c-3.989,1.643-6.592,5.547-6.592,9.856v32h-128v-128h32    c4.309,0,8.213-2.603,9.856-6.592c1.643-3.989,0.725-8.576-2.304-11.627l-64-64c-4.16-4.16-10.923-4.16-15.083,0l-64,64    c-3.051,3.072-3.968,7.637-2.325,11.627c1.643,3.989,5.547,6.592,9.856,6.592h32v128h-128v-32c0-4.309-2.603-8.213-6.592-9.856    c-3.925-1.664-8.555-0.747-11.627,2.304l-64,64c-4.16,4.16-4.16,10.923,0,15.083l64,64c3.072,3.072,7.68,4.011,11.627,2.304    c3.989-1.621,6.592-5.525,6.592-9.835v-32h128v128h-32c-4.309,0-8.213,2.603-9.856,6.592c-1.643,3.989-0.725,8.576,2.304,11.627    l64,64c2.091,2.069,4.821,3.115,7.552,3.115s5.461-1.045,7.552-3.115l64-64c3.051-3.051,3.968-7.637,2.304-11.627    c-1.664-3.989-5.547-6.592-9.856-6.592h-32v-128h128v32c0,4.309,2.603,8.213,6.592,9.856c3.947,1.685,8.576,0.747,11.627-2.304    l64-64C491.717,248.712,491.717,241.971,487.557,237.789z" />
                        </g></g>
                    </svg>
                    <div fxFlex="76%" (click)="loadGroup()" class="txt">{{item.group_name}}</div>
                    <mat-icon fxFlex="10%" (click)="showsmallsubpop($event,'m-tooltip-subnearmaster',k,true)">more_vert</mat-icon>
                </mat-list-item>
            </ng-container>
        </mat-nav-list>
    </div>
    <field-submaster-contextmenu  class="m-tooltip-subnearmaster" [ngClass]="{'showsubtool' : popsubwindow>=0}"></field-submaster-contextmenu>
</div> -->
<!--#1 End For Mobile -->
