import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "search-bar",
  templateUrl: "./admin-search.component.html",
  styleUrls: ["../../admin-breadcrumb.component.css"],
})
export class AdminSearchbarComponent implements OnInit {
  showsearchbar: boolean = false;
  @Input("search") search;
  constructor() {}

  ngOnInit() {}

  showSearch() {
    this.showsearchbar = true;
  }
}
