import { Component, HostBinding, HostListener  } from '@angular/core';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromRoot from './state/reducers';
import * as ApplicationActions from './state/application/actions';
import { ApiserviceService } from './apiservice.service';
import { ShareService } from './admin/services/share.service';
import { NotificationService } from './admin/services/notification.service';
import { EncryptDecryptService } from './encryptdecrypt.service';
import { CommonfunctionService } from './services/commonfunction.service';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  // @HostListener('window:message', ['$event'])
  // onMessage(e:any){
  //   if(e.origin  == environment.adminHost){
  //     let record = e.data;
  //     this.setUserDataSSO(record);
  //   }
  // }


  $isLoggedIn: Observable<boolean>;
  @HostBinding('class.application') class = 'application';
  constructor(private store: Store<fromRoot.State>, private api: ApiserviceService, private share: ShareService,
    private EncryptDecrypt: EncryptDecryptService, private fnc: CommonfunctionService,
    private notify: NotificationService, private router: Router) {
    this.share.appComp = this;
    if(environment.isLocalServe || this.api.isMobile){
        // if (localStorage.getItem('user_id') && localStorage.getItem('city_id')) {
        //     this.router.navigateByUrl('/admin');
        // }
        // if (localStorage.getItem('user_id') && !localStorage.getItem('city_id')) {
        //     this.router.navigateByUrl('/admin/country');
        // }
    }else{
        if (!localStorage.getItem('user_id') && !localStorage.getItem('user_token')) {
            let newwindow = window.open(`${environment.adminHost}/login?continue=${environment.baseURL}`, '_self');
            if (window.focus) {newwindow.focus()}
        }
        // else  if (localStorage.getItem('user_id') && localStorage.getItem('city_id')) {
        //     this.router.navigateByUrl('/admin');
        // }
        // if (localStorage.getItem('user_id') && !localStorage.getItem('city_id')) {
        //     this.router.navigateByUrl('/admin/country');
        // }
    }
  }

  /**
   * This function used for set sso data
   * @param record
   */
  // setUserDataSSO(record:any){
  //     if(record && record?.token){
  //     }else{
  //       return;
  //     }
  //     let name='';
  //     this.share.appComp.logIn();
  //     localStorage.setItem('user_token', record.token);
  //     localStorage.setItem('user_id', record.user_id);
  //     localStorage.setItem('session_id', record.session_id);
  //     localStorage.setItem('profile_image', record.profile_image);
  //     this.share.profileImage.next(record.profile_image)
  //     if (this.share.adminComp) this.share.adminComp.profile_image = record.profile_image;
  //     if (record.user_details[1].first_name) {
  //         name = record.user_details[1].first_name;
  //     }
  //     if (record.user_details[1].last_name) {
  //         name += " " + record.user_details[1].last_name;
  //     }
  //     localStorage.setItem('user_name', name);
  //     let user_info = {
  //         "fname": record.user_details[1].first_name,
  //         "lname": record.user_details[1].last_name,
  //         "contact": record.phone_number,
  //         "country": record.nationality,
  //         "email": record.primary_email_id,
  //         "language": 'en',
  //         "imageURL": record.profile_image,
  //     }
  //     localStorage.setItem('user_info', JSON.stringify(user_info))
  //     this.api.user_id = record.user_id;
  //     this.api.user_name.next(name);
  //     this.api.session_id = record.session_id;
  //     this.api.user_token = record.token;
  // }


  ngOnInit(){
    //this.getGoogleMapKey();
    // this.$isLoggedIn = this.store.select(fromRoot.selectIsLoggedIn);
    // this.$isLoggedIn.subscribe((state:any)=>{
    //   if(!state){
    //     this.api.logout(false);
    //   }
    // })
    // document.body.addEventListener('click', () => this.extendLogout());
  }
  logIn(){
    this.store.dispatch(new ApplicationActions.LogIn());
  }
  // extendLogout(){
  //   this.store.dispatch(new ApplicationActions.ExtendLogoutTimer());
  // }
  public getGoogleMapKey(){
    this.api.getUmsData("setting").subscribe({next: (res:any) => {
      if (res.data) {

        let goole_key = res.data.filter(obj => obj.key == 'google_map_key');
        goole_key = goole_key[0].value;
        // this.EncryptDecrypt.EncryptDecrypt('HqfDb@2389xDYaM');
        // goole_key = this.EncryptDecrypt.decryptKey(goole_key);
        const url = 'https://maps.googleapis.com/maps/api/js?key=' + goole_key + '&libraries=drawing';
        const script = document.createElement('script');
        script.setAttribute('src', url);
        script.setAttribute('async', '');
        script.setAttribute('defer', 'defer');
        document.head.appendChild(script);
      }
    }, error: (err)=>{
      // this.notify.notify(err.error.message, 'error')
    }})
  }
}
