<div class="container">
  <mat-toolbar class="toolb">
    Notifications
  </mat-toolbar>
  <div class="inner">
    <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
    <div class="cent" *ngIf="notify_list.length==0 && !loader">
      <span class="f3"> No notifications yet...</span>
    </div>
    <ng-container *ngFor="let item of notify_list">
      <div class="n-body" [ngClass]="{'active': item.email_status == 'sent'}" (click)="markAsRead(item)">
        <div class="header" fxLayout="row" fxLayoutAlign="space-between end">
          <div class="l-header cp">
            <p class="cl">
              {{item.from_user_name}}
            </p>
          </div>
          <div class="r-header cp" [ngClass]="{'sent': item.email_status == 'sent'}">
            {{item.added_date | date:'medium'}}
          </div>
        </div>
        <div class="subtitle">
          <p class="cl1" *ngIf="item.from_user_id" [ngClass]="{'sent': item.email_status == 'sent'}">
            ({{item.from_user_id}})
          </p>
        </div>
        <div class="content">
          <p class="clamp2 message">
            {{item.message}}
          </p>
        </div>
      </div>
    </ng-container>
  </div>
  <mat-divider *ngIf="notify_list.length!=0"></mat-divider>
  <div class="cent" *ngIf="notify_list.length!=0">
    <button mat-raised-button class="bluebutton" routerLink="notifications" (click)="overlay_off()">View
      all</button>
  </div>
</div>