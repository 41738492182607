import { Component, Inject, OnInit, Output, Input, EventEmitter, ViewChild, NgZone } from "@angular/core";
import { ShareService } from '../../../services/share.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { CommonfunctionService } from '../../../../services/commonfunction.service';


@Component({
    selector: 'toolbox-map',
    templateUrl: './toolbox.component.html',
    styleUrls: ['./toolbox.component.css']
  })
  export class ToolboxComponent {
    mobile: boolean = false;
    floors: any = [];
    parent: any;
    selected_floor: any;
    current_floor_info: any;
    addUnitAction: boolean = false;
    setActive: string = '';
    toggle: boolean = false;
    copied: any = '';
    flagVal: Number = 0;
    constructor(public share: ShareService, private fnc: CommonfunctionService, public dialog: MatDialog,
      private route: Router) {
      
      // this.share.toolbox_obj = this;
      // this.floors = this.share.floor_obj.floorData;
      // this.selected_floor = this.share.floor_obj.selected_floor;
      // this.current_floor_info = this.share.floor_obj.selected_floor;
      // this.parent = this.share.floor_map_obj;
      // this.current_floor_info = this.fnc.getArrayValue('name', this.selected_floor.name, this.floors);
    }
    setUnitId() {
      //this.copied = this.share.floor_map_obj.copied.uid;
    }
  
    isActive(mode) {
      return (mode == this.setActive);
    }
  
    currentFloor(e) {
      this.parent.copied = null;
      this.parent.unitGisUids = [];
      this.parent.unitGisUidsForPaste = [];
      this.current_floor_info = {};
      let name = e.source.value;
      this.current_floor_info = this.fnc.getArrayValue('name', name, this.floors);
      // this.share.floor_map_obj.selected_floor = this.current_floor_info;
      // this.share.floor_obj.selected_floor_id = this.current_floor_info.id;
      // this.share.unit_obj.estaterUnitCount = this.share.floor_map_obj.selected_floor.units;
      this.parent.getUnitData();
  
      if (!this.mobile) {
        this.unitList(true);
      }
      //this.share.floor_map_obj.clearDrawing();
      this.parent.map.switchFloor(this.current_floor_info.gid);
    }
    unitList(flag = false) {
      this.flagVal = 1;
      // this.share.floor_obj.unit_list = true;
      // this.share.floor_obj.selected_floor = this.current_floor_info;
      this.parent.copied = null;
      if (this.mobile) {
        this.route.navigateByUrl('/unit');
      } else {
        if (!flag) {
          this.dialog.closeAll();
        }
        // this.share.unit_obj.unitData = [];
        // this.share.unit_obj.unitDataGis = [];
        this.recallUnitApi();
        // if (this.share.floor_map_obj.unitGisUids.length == 1) {
        //   this.share.dynamic_obj.onOptionsRequested('unitGroup');
        // } else {
        //   this.share.dynamic_obj.onOptionsRequested('unit');
        // }
      }
    }
    recallUnitApi() {
      setTimeout(() => {
        // this.share.unit_obj.getUnitData();
        // this.share.unit_obj.getGisUnitData();
        // this.share.unit_obj.getNoOfUnits();
      }, 200);
    }
    clear() {
      this.parent.unitGisUidsForPaste = [];
      this.parent.unitGisUids = [];
      this.parent.copied = null;
    //  this.share.floor_map_obj.clearDrawing();
      this.parent.map.switchFloor(this.current_floor_info.gid);
      this.parent.copied = null;
      // setTimeout(() => {
      //   this.share.floor_map_obj.setColorunitLayout();
      // }, 2000);
    }
    copyUnit() {
      this.parent.copy();
    }
  
    drawPolygon(mode) {
      // this.toggle = !this.toggle;
      if(!this.setActive){
        this.toggle = true;
      }else{
        if(this.setActive == mode){
          this.toggle = false;
        }else{
          this.toggle = true;
        }
      }
  
      // if (!this.share.unit_obj.unit_access.POST) {
      //   this.share.floor_map_obj.notify('You are not authorized to create unit', 'Dismiss');
      //   return;
      // }
      // if (this.toggle) {
      //   if (mode == 'circle') {
      //     this.share.floor_map_obj.enableDrawing = true;
      //     this.parent.drawUnitPolygon(mode);
      //   } else if (mode == 'polygon') {
      //     this.addUnitAction = true;
      //     this.parent.drawUnitPolygon(mode);
      //   }
      // } else {
      //   this.setActive = '';
      //   if (mode == 'circle') {
      //     this.share.floor_map_obj.enableDrawing = false;
      //     this.parent.drawUnitPolygon();
      //   }
      //   if (mode == 'polygon') {
      //     this.addUnitAction = false;
      //     this.share.floor_map_obj.enableDrawing = false;
      //     this.parent.drawingManager.setDrawingMode(null);
      //     this.share.floor_map_obj.clearDrawing();
      //   }
      // }
    }
  
    openGroupDialog() {
      if (this.parent.unitGisUidsForPaste.length) {
        this.parent.openDialog();
      }
    }
  }