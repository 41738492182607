import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiserviceService } from "../../../apiservice.service";
import { CommonfunctionService } from "../../../services/commonfunction.service";
import { NotificationService } from "../../services/notification.service";
import { ShareService } from "../../services/share.service";

@Component({
    selector: "report-dialog",
    templateUrl: "./report-dialog.component.html",
    styleUrls: ["./report-dialog.component.css"],
  })
  export class ReportsDialog {
    selectedRow: any;
    selectedUploadId: any;
    legacyData: any = [];
    loader: boolean = false;
    //table start==================================================
    displayedColumns = ['upload_count','id','upload_id','remark','status','validate_time','accept_time','property_count','floor_count','ground_floor_count','unit_count','action'];
    dataSource = null;
    //table start==================================================
    newConflict = false;
    conflictdata: any;
    uploadStatus: any = {
      "1": "Property added",
      "11": "Property validated",
      "111": "Property created",
      "2": "Floor added",
      "22": "Floor validated",
      "222": "Floor created",
      "3": "Unit added",
      "33": "Unit validated",
      "333": "Unit created",
      "-1": "Invalid in property added",
      "-11": "Invalid in property validated",
      "-111": "Invalid in property created",
      "-2": "Invalid in floor added",
      "-22": "Invalid in floor validated",
      "-222": "Invalid in floor created",
      "-3": "Invalid in Unit added",
      "-33": "Invalid in Unit validated",
      "-333": "Invalid in unit created",
    };

    checkResol = ["-1",
                  "-11",
                  "-111",
                  "-2",
                  "-22",
                  "-222",
                  "-3",
                  "-33",
                  "-333"
                ];

    newStatus = {
      "3": "Accepting",
      "4": "Accepted",
      "-2": "Accept failed",
      "0": "Idle",
      "1": "Processing",
      "-1": "Failed",
    };
    colorStatus = {
      "3": "accepting",
      "4": "accepted",
      "-2": "accept-failed",
      "0": "idle",
      "1": "processing",
      "-1": "failed",
    };
    constructor(
      public dialogRef: MatDialogRef<ReportsDialog>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private api: ApiserviceService,
      private fnc: CommonfunctionService,
      private share: ShareService,
      private notify: NotificationService
    ) {
      this.selectedRow = this.data.selectedRow;
      this.getLegacyData(this.selectedRow["upload_id"]);
    }


    isDisabled(){
        if([-2,-1,2].includes(this.selectedRow.status)){
            return true;
        }
        return false;
    }

    resolveNewConfilct(element = null) {
      if (element) {
        this.conflictdata = element;
      } else {
        this.legacyData = [];
        this.getLegacyData(this.selectedRow["upload_id"]);
      }
      this.newConflict = !this.newConflict;
      if (this.selectedRow["upload_id"]) {
        this.data.parent.conflict_id = this.selectedRow["upload_id"];
      }
      this.loader = false;
    }
    acceptButton(val, conflict) {
      if (conflict == 1) {
        return true;
      }
      if (val != 33) {
        return true;
      }
      return false;
    }
    resolveButton(val) {

      if (val.has_conflict == true && !this.checkResol.includes(val.status.toString())) {
        return false;
      } else {
        return true;
      }
    }
    refreshList() {
      this.getLegacyData(this.selectedRow["upload_id"]);
    }
    closepopup(){
      this.dialogRef.close();
    }

    /**
     * This is a function to manual chunk  wise validate
     * @returns void
     */
    manualValidate() {
      this.loader = true;
      this.api
        .getCsvData(
          `chunk-wise-validate?user_id=${this.api.user_id}&upload_id=${this.selectedRow["upload_id"]}`
        )
        .subscribe({
          next: (res: any) => {
            this.loader = false;
            if (res.status == 200) {
              this.notify.notify(res.message, "success");
              this.getLegacyData(this.selectedRow["upload_id"]);
            }
          },
          error: () => {
            this.loader = false;
          },
        });
    }
    getLegacyData(id) {
      this.loader = true;
      let url = "get-upload-legacy?upload_id=" + id;
      this.api.getNodeData(url).subscribe({
        next: (res: any) => {
          this.loader = false;
          this.legacyData = [];
          if (res && res.length) {
            this.legacyData = res;

            // res.map((item, i) => {
            //     let newObj = {};
            //     newObj["S.N."] = i + 1;
            //     delete item["conflict_data"];
            //     newObj = { ...newObj, ...item };
            //     newObj["action"] = "";
            //     this.legacyData.push(newObj);
            // });
            //let keysArr = Object.keys(this.legacyData[0]);
            // this.displayedColumns=[];
            // keysArr.map((key) => {
            //     if (key != "conflict_status" && key != "process_status" && key != 'added_date') {
            //     this.displayedColumns.push(key);
            //     }
            // });
            this.dataSource = this.legacyData;
          }
        },
        error: () => {
          this.loader = false;
        },
      });
    }
    getuploadstatus(status) {
      return this.uploadStatus[status];
    }
    getStatusLabel(el) {
      if (this.newStatus.hasOwnProperty(el)) {
        return this.newStatus[el];
      } else {
        return "Accept";
      }
    }

    getBgColor(el){
      if (this.colorStatus.hasOwnProperty(el)) {
        return this.colorStatus[el];
      } else {
        return "accept";
      }
    }
    getDate(dt) {
      let d = new Date(dt);
      return this.fnc.formatDateUTC(d, true);
    }
    getLogFile(event, element: any) {
      this.loader = true;
      this.selectedUploadId = element.id;
      let id = element.upload_id,
        count = element.upload_count;
      // console.log(id);

      let url = `uploaded-chunk-details?upload_id=${id}&upload_count=${count}`;
      this.api.getNodeData(url).subscribe({
        next: (res: any) => {
          this.loader = false;
          window.location.href = `${this.api.nodeUrl}legacy-report?file_url=${res.url}`;
        },
        error: () => {
          this.loader = false;
        },
      });
    }
    downloadConflict() {
      this.loader = true;
      let upload_id = this.conflictdata.upload_id;
      let upload_count = this.conflictdata.upload_count;
      let status = this.conflictdata.status;
      let url = `download-conflict-data?upload_id=${upload_id}&upload_count=${upload_count}&status=${status}`;
      this.api.getNodeData(url).subscribe({
        next: (res: any) => {
          if (res.status == 200) {
            window.open(`https://${res.url}`, "_blank");
          } else {
            this.notify.notify(res.massage, "warn");
          }
          this.loader = false;
        },
        error: (err) => {
          this.loader = false;
        },
      });
    }
    accept(event, element: any) {
      this.loader = true;
      let url = `accept?upload_id=${element.upload_id}&upload_type=${this.data.parent.selected_File_Type}&user_id=${this.api.user_id}&upload_count=${element.upload_count}`;
      this.api.postNodeData(url, null).subscribe(
        {next: (res: any) => {
          event.target.disabled = true;
          this.loader = false;
        },
        error: () => {
          this.loader = false;
        }
      }
      );
    }
    getLabel(key) {
      return key.replace(/_/g, " ");
    }
    ngOninit() {}
  }
