<div>
    <div fxLayout="row" class="w-100 crumb-c px-3" fxLayoutAlign="space-between center">
        <admin-breadcrumb></admin-breadcrumb>
        <div fxLayout="row" class="" fxLayoutAlign="space-between center">
            <span>Search : <input [formControl]="searchField" class="form-input-l search-inputbox mx-2" placeholder="Process name/parameter..." type="text"></span>
            Status : <mat-select (selectionChange)="selectionChange($event)" class="form-select-l mx-2" placeholder="Select status">
                <mat-option *ngFor="let list of status_list;" [value]="list.id">{{list.label}}
                </mat-option>
            </mat-select>
            <button mat-icon-button  matTooltip="Refresh list" class="refresh" (click)="getProgressData()">
              <mat-icon>refresh</mat-icon>
          </button>
        </div>
    </div>
    <div class="act-container" fxLayout="column">

        <div class="table-estater table table-view" style="position: relative">
            <table class="attr-table">
                <thead>
                    <tr>
                        <th *ngFor="let val of displayedColumns" class="text-capitalize">
                            <span *ngIf="val =='sno'">
                                S.No.
                            </span>
                        <span *ngIf="val !='sno'">
                            {{getName(val)}}
                        </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let element of dataSource | paginate: { id: 'inpro', itemsPerPage: limit, currentPage: pageIndex, totalItems: totalRecord}, let i = index; ">
                        <td *ngFor="let val of displayedColumns">
                            <ng-container *ngIf="val =='sno'"> {{ i + (offset +1) }} </ng-container>
                            <ng-container *ngIf="val =='id'"> {{ element[val] }} </ng-container>
                            <ng-container *ngIf="val =='module'"> <span class="text-uppercase">{{ element[val] }}</span></ng-container>
                            <ng-container *ngIf="val =='process_name'"> <span class="text-capitalize">{{ getName(element[val]) }}</span></ng-container>
                            <ng-container *ngIf="val =='user_id'"> {{ element[val] }} </ng-container>

                            <ng-container *ngIf="val =='parameter'"> <p title="{{element[val]}}">{{element[val]}} </p></ng-container>
                            <ng-container *ngIf="val =='added_date'"> {{ getDateFormat(element[val]) }} </ng-container>
                            <ng-container *ngIf="val =='updated_date'"> {{ getDateFormat(element[val]) }} </ng-container>
                            <ng-container *ngIf="val =='time_taken'"> {{ element[val] }} </ng-container>
                            <ng-container *ngIf="val =='status'">
                                <span [class.skyblue]="element.status == '1'"
                                [class.green]="element.status == '2'"
                                [class.red]="element.status == '-1'"> <span>{{ element?.data?.cities?.length ? "--" : statusList[element[val]] }}</span> </span></ng-container>
                            <ng-container *ngIf="val =='actions'">
                               <div class="d-flex justify-content-center">
                                <button class="action-icon" (click)="stop(element)" matTooltip="Edit" [disabled]="element.status != '1' || (loader && selectedrow_id == element.id)">
                                    Stop process
                                 </button>
                                 <button class="download-file" matTooltip="Download File" (click)="downloadFile(element)">
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="19" height="19" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><g id="Solid"><path d="m239.029 384.97a24 24 0 0 0 33.942 0l90.509-90.509a24 24 0 0 0 0-33.941 24 24 0 0 0 -33.941 0l-49.539 49.539v-262.059a24 24 0 0 0 -48 0v262.059l-49.539-49.539a24 24 0 0 0 -33.941 0 24 24 0 0 0 0 33.941z" fill="#3fc93c" data-original="#000000" class=""></path><path d="m464 232a24 24 0 0 0 -24 24v184h-368v-184a24 24 0 0 0 -48 0v192a40 40 0 0 0 40 40h384a40 40 0 0 0 40-40v-192a24 24 0 0 0 -24-24z" fill="#3fc93c" data-original="#000000" class=""></path></g></g></svg>
                                 </button>
                               </div>
                            </ng-container>
                       <ng-container *ngIf="val =='attributes'">
                        <button type="button" class="show-data-btn" (click)="open(element)" [disabled]="!element?.data?.cities?.length">Show Data</button>
                       </ng-container>


                        </td>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="!loader && dataSource.length == 0" class="text-center">
                    <h5 class="text-danger mt-5">Process not found</h5>
            </div>

            <!-- <mat-table #table [dataSource]="dataSource | paginate: { id: 'inpro', itemsPerPage: limit, currentPage: pageIndex, totalItems: totalRecord}">

                <ng-container matColumnDef="sno">
                    <mat-header-cell *matHeaderCellDef fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="center center"> S.N. </mat-header-cell>
                    <mat-cell *matCellDef="let element; let i = index;" fxLayout="row" fxLayoutAlign="center center"> {{i + (offset +1)}} </mat-cell>
                </ng-container>


                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="center center"> Id </mat-header-cell>
                    <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center"> {{element.id}} </mat-cell>
                </ng-container>


                <ng-container matColumnDef="module">
                    <mat-header-cell *matHeaderCellDef fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="center center"> Module </mat-header-cell>
                    <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center" class="text-uppercase"> {{element.module}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="process_name">
                    <mat-header-cell *matHeaderCellDef fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="center center"> Process Name </mat-header-cell>
                    <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center" class="text-capitalize"> {{getName(element.process_name)}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="user_id">
                    <mat-header-cell *matHeaderCellDef fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="center center"> User Id </mat-header-cell>
                    <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center"> {{element.user_id}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="center center"> Status </mat-header-cell>
                    <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center"
                    [class.skyblue]="element.status == '1'"
                    [class.green]="element.status == '2'"
                    [class.red]="element.status == '-1'"
                    > {{statusList[element.status]}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="added_date">
                    <mat-header-cell *matHeaderCellDef fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="center center"> Start Date </mat-header-cell>
                    <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center"> {{getDateFormat(element.added_date)}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="updated_date">
                    <mat-header-cell *matHeaderCellDef fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="center center"> End Date </mat-header-cell>
                    <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center"> {{getDateFormat(element.updated_date)}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="time_taken">
                    <mat-header-cell *matHeaderCellDef fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="center center"> Time Taken </mat-header-cell>
                    <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center"> {{element.time_taken}} </mat-cell>
                </ng-container>


                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef> Actions </mat-header-cell>
                    <mat-cell *matCellDef="let element">

                    </mat-cell>
                </ng-container>

                <mat-header-row class="skipdrag" *matHeaderRowDef="displayedColumns"></mat-header-row>
                <div class="rowss">
                    <mat-row class="rowset draggableset" *matRowDef="let row; columns: displayedColumns;"></mat-row>
                </div>
            </mat-table> -->

        </div>
        <div  fxLayout="row" class="w-100 px-3" fxLayoutAlign="end center">
            <pagination-controls (pageChange)="pageAction($event)" id="inpro">
            </pagination-controls>
        </div>
        <!-- loader start -->
        <div *ngIf="loader" class='loader-div'>
            <ngx-loading [show]="loader"></ngx-loading>
        </div>
        <!-- loader end -->

    </div>
</div>
