import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { NotificationService } from './services/notification.service';
import { environment } from '../../environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
@Injectable()
export class InterceptorService {
  isMobile: boolean;

  constructor(private route: Router,  private notify: NotificationService,
    private deviceInfo: DeviceDetectorService
  ) {
    this.isMobile = this.deviceInfo.isMobile();
   }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                if ([403].includes(event?.body?.status) && localStorage.getItem('user_token')) {
                    localStorage.clear();
                    if(!this.isMobile){
                        let newwindow = window.open(`${environment.adminHost}/logout?continue=${environment.baseURL}&all_device=false`, '_self');
                        if (window.focus) {newwindow.focus()}
                    }else{
                        this.route.navigateByUrl('/admin/login');
                    }

                }
            }
            return event;
        }),
        catchError((error: HttpErrorResponse) => {
            if([403].includes(error.status) && localStorage.getItem('user_token')){
               localStorage.clear();
               if(!this.isMobile){
                let newwindow = window.open(`${environment.adminHost}/logout?continue=${environment.baseURL}&all_device=false`, '_self');
                    if (window.focus) {newwindow.focus()}
                }else{
                    this.route.navigateByUrl('/admin/login');
                }
            }else{
              this.notify.notify(error.error.message, "error");
            }
            return throwError(() => error)
        })
    );
}
}
