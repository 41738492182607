import { Component } from '@angular/core';



export class Style {
    fillColor: string;
    fillOpacity: number;
    outlineColor: string;
    outlineWidth: number;
    outlineOpacity: number;
    iconUrl: string;
    iconHeight: number;
    iconWidth: number;
    baseHeight: number;
    topHeight: number;
    closeTop: boolean;
    closeBottom: boolean;
    zIndex: number;
    clickable : boolean;

    constructor(options) {
    	this.fillColor = options.fillColor || 'gray';
        this.fillOpacity = options.fillOpacity || 1;
    	this.outlineColor = options.outlineColor || 'black';
    	this.outlineWidth = options.outlineWidth || 1;
        this.outlineOpacity = options.outlineOpacity || 1;
    	this.iconUrl = options.iconUrl;
    	this.iconHeight = options.iconHeight;
    	this.iconWidth = options.iconWidth;
        this.baseHeight = options.baseHeight || 0;
        this.topHeight = options.topHeight || 0;
        this.closeTop = options.closeTop || true;
        this.closeBottom = options.closeBottom || true;
        this.zIndex = options.zIndex || 1;
        this.clickable = options.clickable || false;
     }
    
}