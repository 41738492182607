import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ShareService {
  heightService: number;
  notify_obj: any;
  adminComp: any;
  fieldObj: any;
  gpObj: any;
  csv_obj: any;
  fieldShuffleDialog:any;
  qaqc_obj:any;
  unit_map_obj:any;
  appComp:any;

  progress = new BehaviorSubject<any>(null);
  quick_edit_count = new BehaviorSubject<any>(0);
  constructor() { }

}
