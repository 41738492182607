<mat-card appearance="outlined" class="br min-pad-card w-100 mt-10 mb-1 clr1-hv" (click)="select(item)"
[ngClass]="{'active-green': active && item.entity_type == 'property', 'active-grey': active && (item.entity_type == 'property_existing' || item.entity_type == 'property_floor' || item.entity_type == 'property_unit' || item.entity_type == 'property_floor_existing' || item.entity_type == 'property_unit_existing')}">
    <mat-card-header class="mr-1 mt-1">
        <div class="w-100" fxLayout="row" fxLayoutAlign="space-between">
            <div fxLayout="row" fxLayoutAlign="start">
                <h6 *ngIf="(item.entity_type != 'property_unit_existing' || item.entity_type != 'property_floor_existing') && item.legacy_property_uid && item.entity_type != 'property_existing' && item.entity_type != 'property_floor' && item.entity_type != 'property_existing' && item.entity_type != 'property_unit'" class="mat-h6">
                    {{k}}. {{item.legacy_property_uid}}
                </h6>
                <h6 *ngIf="item.entity_type == 'property_existing'" class="mat-h6">
                    {{k}}. {{item.property_uid}}
                </h6>
                <h6 *ngIf="item.entity_type == 'property_unit_existing'" class="mat-h6">
                    {{k}}. {{item.unit_uid}} - [{{item.unit_number}}]
                </h6>
                <h6 *ngIf="item.entity_type == 'property_floor_existing'" class="mat-h6">
                    {{k}}. {{item.floor_uid}}
                </h6>
                <h6 *ngIf="item.entity_type == 'property_floor'" class="mat-h6">
                    {{k}}. Floor number - {{item.floor_number}}
                </h6>
                <h6 *ngIf="item.entity_type == 'property_unit'" class="mat-h6">
                    {{k}}. Unit number - {{item.unit_number}}
                </h6>
            </div>
            <div fxLayout="row" fxLayoutAlign="end" *ngIf="item.entity_type == 'property' || item.entity_type == 'property_floor' || item.entity_type == 'property_unit' || item.entity_type == 'property_floor_existing' || item.entity_type == 'property_unit_existing'">
                <h6 *ngIf="item.property_name" class="mat-h6">
                    {{item.property_name}}
                </h6>
                <h6 *ngIf="item.floor_type" class="mat-h6">
                    {{item.floor_type}}
                </h6>
                <h6 *ngIf="item.unit_type" class="mat-h6">
                    {{item.unit_type}}
                </h6>
            </div>
        </div>
    </mat-card-header>
    <mat-card-content class="p2-t0 content" [ngClass]="{'active-green': active && item.entity_type == 'property', 'active-grey': active && (item.entity_type == 'property_existing' || item.entity_type == 'property_floor' || item.entity_type == 'property_unit' || item.entity_type == 'property_floor_existing' || item.entity_type == 'property_unit_existing')}">
        <div class="w-100" fxLayout="row" fxLayoutAlign="space-between">
            <div fxLayout="row" fxLayoutAlign="start center">
                <h6 *ngIf="item.no_of_floor" class="mat-h6 hvr-link" (click)="$event.stopPropagation();getFloors(item)">
                    Floors: {{item.no_of_floor}}
                </h6>
                <h6 *ngIf="item.no_of_unit >= 0" class="mat-h6 ml-1" [ngClass]="{'hvr-link': item.entity_type == 'property_floor' || item.entity_type == 'property_floor_existing'}" (click)="$event.stopPropagation(); getUnits(item)">
                    Units: {{item.no_of_unit}}
                </h6>
                <h6 *ngIf="item.unit_property_type" class="mat-h6 ml-1">
                    {{item.unit_property_type}}
                    <ng-container *ngIf="item.unit_area">
                        - [{{item.unit_area}}]
                    </ng-container>
                </h6>
            </div>
            <div fxLayout="row" fxLayoutAlign="end">
                <button *ngIf="item.entity_type == 'property'" mat-raised-button class="primary mr-1" (click)="$event.stopPropagation(); confirm('create',item)">Create
                    New</button>
                <button *ngIf="item.entity_type == 'property'" mat-raised-button class="danger" (click)="$event.stopPropagation(); confirm('remove',item)">Remove</button>
                <button *ngIf="item.entity_type == 'property_existing'" mat-raised-button class="blue" (click)="$event.stopPropagation(); confirm('merge',item)">Merge</button>
            </div>
        </div>
    </mat-card-content>
</mat-card>
