import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {

  @Input() min:number=0;
  @Input() max:number=0;
  @Input() percent:any=0;

  constructor(){

  }

}
