<div fxLayout="row" class="w-100 crumb-c" fxLayoutAlign="space-between center">
    <div fxLayout="row" class="w-100 bleft" fxLayoutAlign="space-between center">
        <admin-breadcrumb></admin-breadcrumb>
    </div>
</div>
<div fxLayout="row" [ngStyle]="{'height':height+'px'}">
    <div fxLayout="row" fxFlex="60%" class="br-r p-2 rel">
        <div class="w-100 abs">
            <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.5)'}"></ngx-loading>
        </div>
        <div class="scr" fxLayout="row" fxFlex="100%" [ngStyle]="{'max-height':height2+'px'}" detect-scroll (scroll)="onScroll($event)">
            <mat-list class="w-100">
                <mat-list-item *ngFor="let item of notification_list" class="li mb-1 bg-w c3" [ngClass]="{'active': item.email_status == 'sent', 'selected': isSelected(item)}"
                    (click)="markAsRead(item)">
                    <div fxLayout="row" fxFlex="5%">
                        <mat-icon mat-list-icon [ngClass]="{'sent-icon': item.email_status == 'sent'}">notifications</mat-icon>
                    </div>
                    <div fxLayout="column" fxFlex="90%">
                        <div fxLayout="row" fxFlex="100%" fxLayoutAlign="space-between center">
                            <div fxLayout="row" fxLayoutAlign="start center">
                                <h5 mat-line class="cp"> {{item.from_user_name}}</h5>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="end center">
                                <p mat-line class="f3"> {{item.added_date | date:'medium'}}</p>
                            </div>
                        </div>
                        <div fxLayout="row" fxFlex="100%">
                            <span class="f4">{{item.message}}</span>
                        </div>
                    </div>

                    <!-- <mat-icon mat-list-icon>notifications</mat-icon>
                    <h4 mat-line> {{item.from_user_name}}</h4>
                    <p mat-line> {{item.added_date | date}} </p> -->
                </mat-list-item>
            </mat-list>
        </div>
    </div>
    <div fxLayout="row" fxFlex="40%">
        <div fxLayout="row" class="w-100 p-2" fxLayoutAlign="start start">
            <h4 mat-line>Notification Details</h4>
        </div>
    </div>
</div>