import { Component, OnInit, Output, Input, EventEmitter, ViewChild, NgZone } from "@angular/core";
import { ApiserviceService } from '../../../apiservice.service';
import { NotificationService } from '../../services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { CommonfunctionService } from '../../../services/commonfunction.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LatLon } from '../../map_api/latlon';
import { HttpClient } from '@angular/common/http';
import { Style } from '../../map_api/style';
import { IndoorMap } from '../../map_api/indoormap';
import { ShareService } from '../../services/share.service';

export class State {
  constructor(public name: string) { }
}

export interface unit_list {
  uid: string,
  id: number,
  show: boolean,
  unit_id: number,
  data: number,
  attribute_info: any,
  property_type_name: string,
  property_type_id: number,
  unit_type_label: string,
  unit_type_name: string,
  status: boolean,
  assigned_to_emailid: string,
  assigned_to_name: string,
  assigned_to: number,
  updated_date: any,
  submitted_date: any,
  profile_image: string,
  unit_gis_uid: string
}


/**
* <strong>List of API using</strong>
* <ol>
* <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_units_get</li>
* </ol>
*
*/

@Component({
  selector: 'app-unit-map',
  templateUrl: './unit-map.component.html',
  styleUrls: ['./unit-map.component.css']
})
export class UnitMapComponent implements OnInit {

  google: any;
  map: any;
  marker: any;
  enableEdit: boolean = false;
  loader: boolean = false;
  loader_map: boolean = false;
  property_status: any = '';
  location: any = {
    lat: '',
    lon: ''
  };
  building_name: any;
  available_address_list: any = [];
  @ViewChild('map') mapElement: any;

  assignmentName: any;
  @Output() sidesidesideUnitMapToggle = new EventEmitter();
  @Input() parent: any;
  @Input() selectedAssignment: any = null;

  //for polygon drawing
  drawingManager: any;
  poly: any = null;
  circle: any = null;
  polygon: any = [];
  polygons: any = [];
  current_polygon: any = null;
  mode: string = null;
  _map: any;
  toggleFilters: boolean = true;
  geoData: any = [];

  confirm: boolean = false;

  assignment_detail: any;
  selected_property: any;
  selected_floor: any;
  selected_unit: any;
  polygonCords: any;
  geomNew: any;
  unitdetail: any;
  unit_list: any = [];
  constructor(private api: ApiserviceService, private share: ShareService,
    private notify: NotificationService, private http: HttpClient,
    public dialog: MatDialog,
    private fnc: CommonfunctionService,
    iconRegistry: MatIconRegistry, sanitizer: DomSanitizer
  ) {

  }

  ngOnInit() {
  }
  ngAfterViewInit() {
    this.setInitialValues();
  }

  ngOnChanges() {
    //this.setInitialValues()

    //this.setColorunitLayout();
  }
  setInitialValues() {
    this.assignment_detail = this.selectedAssignment;
    this.selected_property = this.parent.selected_property;
    this.selected_floor = this.parent.selected_floor;
    this.assignmentName = this.parent.selected_assignment.name;
    this.unit_list = this.parent.unit_list;
    this.selected_unit = this.parent.selected_unit;
    setTimeout(() => {
      this.renderMap();
    }, 200);

    this.share.unit_map_obj = this;
  }
  renderMap() {
    var options = {
      center: (this.selected_property.lat) ? new LatLon(this.selected_property.lat, this.selected_property.lon) : new LatLon(29.374732, 47.974151, 0),
      zoom: 18,
      showZoomControl: false,
      showScaleControl: false,
      showBaseLayer: false,
      showBaseMapTypeControl: false,
      showFullScreenControl: true,
      api: 'googlemap',
      element: this.mapElement.nativeElement,
      source: {
        buildingId: this.selected_property.building_gis_uid,
        floorId: this.selected_floor.floor_gis_uid,
        apiKey: 'test',
        http: this.http,
        cityCode: this.api.city_code,
        assignment_name: this.assignmentName ,
        property_id: this.selected_property.id,
        floor_id: this.selected_floor.id,
        get_latest: this.share.qaqc_obj?.all_year ? 'Y': 'N'
      },
      styleMap: {

        'floors': new Style({
          'fillColor': 'transparent',
          'outlineColor': 'transparent',
          'baseHeight': 0,
          'topHeight': 0.75,
          'closeTop': true,
          'clickable': false,
          'zIndex': 10
        }),
        'units': new Style({
          'fillColor': '#fbe0bb',
          'outlineColor': '#64706c',
          'outlineWidth': 1.0,
          'fillOpacity': 1.0,
          'baseHeight': 0.80,
          'topHeight': 2.5,
          'closeTop': true,
          'iconWidth': 10.0,
          'iconHeight': 10.0,
          'clickable': true,
          'zIndex': 11
        })
      }
    };


    this.map = new IndoorMap(options, this.api);
    let unit_uid;

    this.map.addEventListener('click', (e, feature) => {
      unit_uid = feature.attributes.unit_uid;
      this.getUnitDetail(unit_uid);
    });
  }

  setColorunitLayout() {

    if (!this.selected_unit) return;
    let color = "#fbe0bb";
    let getUnitLayerData = this.map.getUnitLayer();
    let laydata = getUnitLayerData.dataForStyle;
    laydata = laydata.features;
    laydata.forEach(element => {
      color = "#fbe0bb";
      if (element.properties.unit_uid == this.selected_unit.uid) {
        color = "red";
      }
      this.map.getUnitLayer().setStyle({ "id": element.properties.unit_id }, new Style({
        'fillColor': color,
        'outlineColor': '#64706c',
        'outlineWidth': 1.0,
        'fillOpacity': 1.0,
        'baseHeight': 0.80,
        'topHeight': 2.5,
        'closeTop': true,
        'iconWidth': 10.0,
        'iconHeight': 10.0
      }));

    });
  }


  toggle() {
    this.sidesidesideUnitMapToggle.emit();
  }


  getUnitDetail(uid) {
    this.loader_map = true;
    let url = `deadmin/${this.share.qaqc_obj.selected_assignment.name}/properties/${this.share.qaqc_obj.selected_property.id}/floors/${this.share.qaqc_obj.selected_floor.id}/units?unit_uid=${uid}`;
    this.api.getEpsData(url)
      .subscribe({next:(data: any) => {
        if (data.length) {
          let v = data[0];
          let rec = {
            uid: v.unit_uid,
            id: v.unit_id,
            show: false,
            unit_id: v.unit_uid,
            data: v.data,
            attribute_info: v.attribute_info,
            property_type_name: v.property_type_name,
            property_type_id: v.property_type_id,
            unit_type_label: v.unit_type_label,
            unit_type_name: v.unit_type_name,
            status: v.status,
            assigned_to_emailid: v.assigned_to_emailid,
            assigned_to_name: v.assigned_to_name,
            assigned_to: v.assigned_to,
            updated_date: v.updated_date,
            submitted_date: v.submitted_date,
            profile_image: v.profile_image,
            unit_gis_uid: v.unit_gis_uid
          };
          this.selected_unit = rec;
          //this.share.qaqc_obj.property_type_id = this.selected_unit;
          let share_select_pty = this.share.qaqc_obj.property_type_id;
          let unit_pty = this.selected_unit.property_type_id;
          this.share.qaqc_obj.property_type_id = this.selected_unit.property_type_id;
          this.share.qaqc_obj.search_input = this.selected_unit.uid;
          this.share.qaqc_obj.getUnits();
          if (share_select_pty !== unit_pty) {
            this.share.qaqc_obj.getDataForPagin();
            this.share.qaqc_obj.getAttributes();
          }
          this.setColorunitLayout();
        }
        this.loader_map = false;
      },
        error: (err) => {
          this.loader_map = false;
        }}
      );
  }

  public resetFilter() {
    this.selected_unit = null;
    this.share.qaqc_obj.search_input = null;
    this.share.qaqc_obj.getUnits();
    this.share.qaqc_obj.getDataForPagin();
    this.share.qaqc_obj.getAttributes();
    setTimeout(() => {
      this.renderMap();
    }, 400);
  }

}
