<mat-toolbar class="matpopup-header">
  <div>Select group for copy selected report</div>
</mat-toolbar>
<mat-dialog-content>
  <div fxLayout="row">
    <!--<group-list [list]="groups" [data]="data" class="popupgroup" (eventtrigger)="loadlistevent($event)"></group-list>-->
    <!-- #4 Sortable Master Groups set -->
    <div fxLayoutAlign="left center" fxLayout="column">
      <div class="cst rowse" *ngFor="let item of group_list; let k = index" fxLayout="row" fxLayoutAlign="start center">
        <mat-checkbox (change)="changeGroupSelectedIds($event, item)"></mat-checkbox>
        <button class="group-b ml-1" mat-button [ngClass]="{ active: isActive(k) }">
          <span class="w-100 title">{{ showLabel(item) }}</span>
        </button>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="dialog-actions">
  <button *ngIf="!data.val" [disabled]="!selected_ids.length" type="button" (click)="copyReport()" mat-button
    class="blue-button">
    Copy
  </button>
  <button type="button" mat-raised-button (click)="close()">Cancel</button>
</mat-dialog-actions>
