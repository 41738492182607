import { Component, OnInit, Renderer2, HostListener, ViewChild , ElementRef} from '@angular/core';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { ApiserviceService } from '../../apiservice.service';
import { SocketService } from '../socket.service';
import { Subscription } from 'rxjs'
@Component({
  selector: 'app-in-progress-tooltip',
  templateUrl: './in-progress-tooltip.component.html',
  styleUrls: ['./in-progress-tooltip.component.scss']
})
export class InProgressTooltipComponent implements OnInit {

  progress=[];
  showTooltip: boolean =false;
  slideConfig = {
    "dots": true,
    "infinite": false,
    "speed": 500,
    "slidesToShow": 2,
    "slidesToScroll": 2,
    "autoplay": false,
    "rtl": false,
    "fade": false,
    responsive: [{

      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        dots: true
      }

    },
     {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true
      }
    }, {

      breakpoint: 300,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true
      }

    }]
  };
  socketObj: Subscription;
  in_progress_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };
  constructor(
    private api:ApiserviceService,
    private socketService: SocketService,
    private fnc: CommonfunctionService
  ) {
    this.getProgressData();

  }

  getProgressData(){

    this.api.getCsvData(`center-process-status?status=1&order_by=added_date&sort_asc=false`).subscribe(
      {
        next: (res:any)=>{
          if(res && res.data && res.data.length){
            this.socketService.message$.next(res);
          }else{
            this.socketService.message$.next(null);
            this.showTooltip=false;
          }
        },
        error:()=>{

        }
      }
      )
  }
 // getting allow resources
 getAllowResourcesApi() {
  let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`;
  this.api.getUmsData(body).subscribe(
   { next: (res: any) => {
      this.api.allowResources = res.data;
      this.in_progress_access = this.fnc.checkResourceAccess("in_progress");
    },
    error: (err) => {
    }}
  );
}
  ngOnInit(): void {
    this.getAllowResourcesApi();

   this.socketObj =  this.socketService.message$.subscribe((res:any)=>{
      if(res){
        let temp = [];
        if(this.in_progress_access.POST == true){
          this.progress = res.data && res.data.filter((e:any) => e.status == 1);
        }else{
          res.data.map(item=>{
            if(item.user_id == this.api.user_id && item.status == 1){
              temp.push(item);
            }
          })
          this.progress = temp;
        }
        this.slideConfig['slidesToShow'] = (this.progress.length > 1) ? 2 : 1;
      }else{
        this.progress=[];
      }
    })

  }
  showProgress(){
    this.showTooltip = !this.showTooltip;
  }

  getName(name:string){
    if(name){
      return name.replace(/_/g, ' ')
    }
    return '';
  }

  getDateFormat(date){
    if(date){
      return this.fnc.formatDateUTC(date, true)
    }
    return '';
  }

  ngAfterViewInit() {

  }

  ngOnDestroy(): void {
    if(this.socketObj){
      this.socketObj.unsubscribe();
    }
  }
}
