import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NotificationService } from "../../../../admin/services/notification.service";
import { ApiserviceService } from "../../../../apiservice.service";
import { CommonfunctionService } from "../../../../services/commonfunction.service";
/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_delete_report_id_delete</li>
 * </ol>
 *
 */

@Component({
  selector: "app-delete-report",
  templateUrl: "deleteReport.component.html",
  styleUrls: ["../../admin-configuration.component.css"],
})
export class DeleteReportComponent {
  public report_id: any;
  public report_name: any;
  selected_group: any;
  selected_report: any;
  progress: number = 0;
  message: string = "";
  constructor(
    public dialogRef: MatDialogRef<DeleteReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fnc: CommonfunctionService,
    private api: ApiserviceService,
    private notify: NotificationService
  ) {
    this.selected_group = this.data.parent.selected_group;
    this.selected_report = this.data.parent.selected_report;
    if (this.selected_group) {
      this.report_id = this.data.parent.selected_report.id;
      this.report_name = this.data.parent.selected_report.name;
    }
  }

  public deleteReport(id) {
    this.progress = 10;
    this.message = "Deleting group...";
    if (id) {
      if (id) {
        this.api
          .deleteData("delete/" + id + "?updated_by=" + this.api.user_id)
          .subscribe(
            (data) => {
              this.notify.notify(
                "Report " + this.report_name + " is deleted",
                "success"
              );
              this.data.parent.getReportList(this.data.parent.selected_group);
              this.close();
            },
            (err) => {
              this.notify.notify(this.fnc.getErrorMessage(err), "error");
            }
          );
      }
    }
  }
  public close() {
    this.progress = 0;
    this.dialogRef.close();
  }

  ngOnInit() {}
}
