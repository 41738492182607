
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, NgZone } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import {  map, startWith } from 'rxjs/operators';
import { LatLon } from '../../../map_api/latlon';
import { DomSanitizer } from '@angular/platform-browser';
import { State } from '../parcel-map/parcel-map.component';
import { ApiserviceService } from '../../../../apiservice.service';
import { NotificationService } from '../../../../admin/services/notification.service';
import { CommonfunctionService } from '../../../../services/commonfunction.service';
import { environment } from '../../../../../environments/environment';
import { Map } from '../../../map_api/map';
import { AddressListDialog } from '../Address-List-Dialog/Address-List-Dialog.component';
import { Address } from '../../models/address';

/**
*
* <strong>List of API using</strong>
* <ol>
* <li>estapi_module_type_assignment_name_properties_property_id_patch</li>
* <li>gisapi_address_json_get</li>
* <li>gisapi_place_location_post</li>
* <li>gisapi_streets_json_get</li>
* </ol>
*
*/

@Component({
  selector: 'app-review-map',
  templateUrl: 'review-map.component.html',
  styleUrls: ['../../qa-qc.component.scss']
})
export class ReviewMapComponent implements OnInit {
  google: any;
  // map: Map;
  map: Map;
  marker: any;
  enableEdit: boolean = false;
  loader: boolean = false;
  loader_map: boolean = false;
  property_status: any = '';
  location: any = {
    lat: '',
    lon: ''
  };
  building_name: any;
  available_address_list: any = [];
  @ViewChild('gmap', { static: true }) gmapElement: any;
  addressForm: FormGroup;
  assignmentName: any;
  selected_addr: any = null;
  blockInfo: any;
  streetObj: any = [];
  laneObj: any = [];
  streetList: any = [];
  laneList: any = [];
  streetdata: Observable<any[]>;
  lanedata: Observable<any[]>;
  lanes: State[] = [];
  streets: State[] = [];
  formReady: boolean = false;
  alert: boolean = false;
  displayAddress: Address;
  selected_property_id: any;
  data: any = {
    details: null
  }
  @Output() sideNavToggle = new EventEmitter();
  @Input() parent: any;
  @Input() rowData: any;
  @Input() selectedAssignment: any = null;
  selectionMode: boolean = false;
  selected_parcel: any;


  //for polygon drawing
  drawingManager: any;

  selectedParcelPolygon: any;
  poly: any = null;
  circle: any = null;
  polygon: any = [];
  polygons: any = [];
  current_polygon: any = null;
  mode: string = null;
  _map: any;
  toggleFilters: boolean = true;
  isOutSideMoved: boolean = false;

  constructor(private fb: FormBuilder, private api: ApiserviceService,
    private notify: NotificationService,
    private _zone: NgZone, public dialog: MatDialog,
    private fnc: CommonfunctionService,
    iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon('circle', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/dots-circle.svg'))
      .addSvgIcon('polygon', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/line-path.svg'));
    const city = JSON.parse(localStorage.getItem('city_detail'));
  }

  ngAfterViewInit() {
    this.setInitialValues();
  }

  ngOnChanges() {
    this.enableEdit = false;
    this.property_status = null;
    if (this.selected_property_id != this.rowData.id && this.selected_property_id) {
      this.selectionMode = false;
      this.setInitialValues(true);
    } else {
      this.setInitialValues();
    }
    if (!this.selectedAssignment) {
      this.selectedAssignment = this.parent.selected_assignment;
    } else {
      this.parent.selectedPolygons = [];
    }
  }

  drawCircle() {
    if (this.mode == null) {
      this.drawingManager.setMap(this.map.map);
      this.selectionMode = false;
      this.mode = 'Circle';
      this.map.removeAllMarkers();
      this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE);
    } else {
      // this.mode = null;
      this.panToLocation();
      this.clearDrawing(true);
    }
  }

  drawPolygon() {
    if (this.mode == null) {
      this.drawingManager.setMap(this.map.map);
      this.selectionMode = false;
      this.mode = 'Polygon';
      this.map.removeAllMarkers();
      this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    } else {
      //this.mode = null;
      this.panToLocation();
      this.clearDrawing(true);
    }
  }

  clearDrawing(clear_all = false) {
    if (!this.circle && !this.poly) {
      this.drawingManager.setMap(this.map.map);
    }
    if (clear_all) {
      this.mode = null;
      if (this.selectedParcelPolygon) {
        this.selectedParcelPolygon = null;
        this.parent.selectedPolygons = [];
        this.parent.getProperties();
      }
      this.current_polygon = null;
      this.selectionMode = false;
      this.renderMap();
      if (this.circle) {
        this.circle.setMap(null);
      }
      if (this.poly) {
        this.poly.setMap(null);
      }

    }
  }

  toggleSelection() {
    this.selectionMode = !this.selectionMode;
    if (this.selectionMode) {
      //remove all markers
      this.map.removeAllMarkers();
      this.map.addEventListener('click', (e) => {
        this.getParcelInfo(e.latlon);
      });
    } else {
      this.panToLocation();
      if (this.parent.selectedPolygons.length) {
        this.parent.selectedPolygons = [];
        this.parent.getProperties();
      }
    }
  }

  getParcelInfo(location) {
    this.parent.selectedPolygons = [];
    if (this.selectionMode) {
      this.loader_map = true;
      let url = "address/json?categories=parcel&lat=" + location.lat + "&lon=" + location.lon + "&return_geometry=true";
      this.api.getGesData(url)
        .subscribe({next:(data: any) => {
          this.loader_map = false;
          if (data.length) {
            this.selected_parcel = data[0];
            this.selectedParcelPolygon = this.selected_parcel.geom;
            this.parent.selectedPolygons.push(this.selectedParcelPolygon);
            this.parent.getProperties();
            this.parent.getDataForPagin()
          }
        }, error:(err) => {
          this.loader_map = false;
          this.notify.notify(`GIS Error - ${err.error.message}`, 'error', 10000)
        }})
    }
  }

  setInitialValues(locationChange = false) {
    this.selectionMode = false;
    this.property_status = this.parent.property_status;
    this.assignmentName = this.parent.selected_assignment.name;
    this.building_name = this.rowData.own_address.building_name;
    this.displayAddress = {
      parcel_no: this.rowData.own_address.parcel_no,
      house_no: this.rowData.own_address.house_no,
      building_name: this.rowData.own_address.building_name,
      lane_name: this.rowData.own_address.lane_name,
      street_name: this.rowData.own_address.street_name,
      block_name: this.rowData.own_address.block_name,
      neigh_name: this.rowData.own_address.neigh_name
    }
    this.addressForm = this.fb.group({
      'house_no': [this.rowData.own_address.house_no],
      'parcel_no': [this.rowData.own_address.parcel_no],
      'building_name': [this.building_name],
      'lane_name': [this.rowData.own_address.lane_name, null],
      'street_name': [this.rowData.own_address.street_name, null],
      // 'city_name': [{ value: this.rowData.own_address.city_name, disabled: true }],
      'block_name': [{ value: this.rowData.own_address.block_name, disabled: true }, Validators.required],
      'neigh_name': [{ value: this.rowData.own_address.neigh_name, disabled: true }, Validators.required]
    });
    this.location.lat = this.rowData.lat;
    this.location.lon = this.rowData.lon;
    this.selected_property_id = this.rowData.id;
    if (locationChange) {
      this.panToLocation();
    } else {
      setTimeout(() => {
        this.renderMap();
      }, 500);
    }
  }


  panToLocation() {
    let location_obj: any = new LatLon(this.location.lat, this.location.lon, 0);
    //remove all markers
    this.map.removeAllMarkers();
    this.map.panTo(location_obj);
    this.map.addMarker(new LatLon(this.location.lat, this.location.lon, 0),
      {
        'iconUrl': '../assets/icon/Pin.svg',
        'iconWidth': 50,
        'iconHeight': 50,
        'dragend': (e) => {
          this._zone.run(() => {
            if (this.enableEdit) {
              this.location.lat = e.latlon.getLat();
              this.location.lon = e.latlon.getLon();
              this.checkParcel(this.location);
              //this.getLocationInfo(this.location);
            }
          });
        }
      }, this.enableEdit);
  }

  renderMap() {

    let location_obj: any = new LatLon(this.location.lat, this.location.lon, 0);
    let _map: any = null;
    let mapProp = {
      center: new LatLon(this.location.lat, this.location.lon, 0),
      zoom: 19,
      zoomControl: false,
      scaleControl: false,
      streetViewControl: false,
      mapTypeControl: true,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT
      },
      mapTypeId: google.maps.MapTypeId.HYBRID,
      styles: [
        {
          featureType: "road",
          stylers: [
            { visibility: "off" }
          ]
        }
      ],
      labels: true,
      element: this.gmapElement.nativeElement
    };
    this.map = new Map(mapProp);
    _map = this.map.map; //map object to be used for Drawing polygons and circles
    this._map = _map;

    //remove all markers
    this.map.removeAllMarkers();
    this.map.panTo(location_obj);

    this.map.addMarker(new LatLon(this.location.lat, this.location.lon, 0),
      {
        'iconUrl': '../assets/icon/Pin.svg',
        'iconWidth': 50,
        'iconHeight': 50,
        'dragend': (e) => {
          this._zone.run(() => {
            if (this.enableEdit) {
              this.location.lat = e.latlon.getLat();
              this.location.lon = e.latlon.getLon();
              this.checkParcel(this.location);
              //this.getLocationInfo(this.location);
            }
          });
        }
      }, this.enableEdit);

    // adding parcel layer
    // this.map.addLayer({
    //   'format': 'wms',
    //   'layerName': 'parcels',
    //   'name': 'parcels',
    //   'url': this.api.wms_url,
    //   'assignment': this.parent.selected_assignment.id
    // });

    // //adding properties layer
    // this.map.addLayer({
    //   'format': 'wms',
    //   'layerName': 'properties',
    //   'name': 'properties',
    //   'url': this.api.wms_url,
    //   'assignment': this.parent.selected_assignment.id
    // });

    // Geoserver
    this.map.addGeoLayer({
      'format': 'wms',
      'layerName': 'parcels',
      'name': 'parcels',
      'url': environment.geoestater + 'wms-layer',
      'assignment': this.parent.selected_assignment.id,
      'city_code': this.api.city_code,
      'city_id': this.api.city_id,
      'user_token': environment.user_token
    });
    this.map.addGeoLayer({
      'format': 'wms',
      'layerName': 'properties',
      'name': 'properties',
      'url': environment.geoestater + 'wms-layer',
      'assignment': this.parent.selected_assignment.id,
      'city_code': this.api.city_code,
      'city_id': this.api.city_id,
      'user_token': environment.user_token
    });

    if (this.current_polygon) {
      let poly = new google.maps.Polygon({
        paths: this.getgeom(this.current_polygon),
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 1,
        fillColor: '#FF0000',
        fillOpacity: 0.35
      });
      poly.setMap(this.map.map);
      this.getBound(poly);
      google.maps.event.addListener(poly, 'click', (e) => {
        this.parent.selectedPolygons = [];
        this.selectedParcelPolygon = this.current_polygon;
        this.parent.selectedPolygons.push(this.selectedParcelPolygon);
        this.parent.getProperties();
      });

    }

    //map drawing functions and events===================================================================================
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.MARKER,
      drawingControl: false,
      circleOptions: {
        strokeColor: '#F1AE65',
        strokeOpacity: 0.8,
        strokeWeight: 1,
        fillColor: '#F1AE65',
        fillOpacity: 0.35,
        editable: false,
        draggable: false
      },
      polygonOptions: {
        editable: false,
        draggable: false
      }
    });

    google.maps.event.addListener(this.drawingManager, 'circlecomplete', (circle) => {
      let radius = circle.getRadius();
      let clat = circle.getCenter().lat();
      let clng = circle.getCenter().lng();
      let position = new google.maps.LatLng(clat, clng);
      _map.setCenter(position);
      _map.fitBounds(circle.getBounds());
      this.circle = circle;
      const numberOfEdges = 32;

      let polygonc = this.generateGeoJSONCircle(position, radius, numberOfEdges);
      // this.current_polygon = polygonc.coordinates;
      this.current_polygon = 'POLYGON((' + polygonc.coordinates.toString() + '))';
      this.renderMap();
    });

    google.maps.event.addListener(this.drawingManager, 'polygoncomplete', (polygon) => {
      let polygonBounds = polygon.getPath();
      let pBounds = polygon.getPaths();
      this.poly = polygon;
      let coordinates = [];
      for (let i = 0; i < polygonBounds.length; i++) {
        coordinates.push([polygonBounds.getAt(i).lng() + ' ' + polygonBounds.getAt(i).lat()]);
      }
      coordinates.push(coordinates[0]);
      // this.current_polygon = [coordinates];
      this.current_polygon = 'POLYGON((' + [coordinates].toString() + '))';
      this.renderMap();
    });

    google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event) => {
      this.drawingManager.setDrawingMode(null);
      if (event.type == 'circle') {
        let radius = event.overlay.radius;
        let center = { lat: event.overlay.center.lat() };
      }
    });

    //map drawing functions and events===================================================================================
  }

  getBound(polygon) {
    var bounds = new google.maps.LatLngBounds();
    var paths = polygon.getPaths();
    var path;
    for (var i = 0; i < paths.getLength(); i++) {
      path = paths.getAt(i);
      for (var ii = 0; ii < path.getLength(); ii++) {
        bounds.extend(path.getAt(ii));
      }
    }
    this.map.map.fitBounds(bounds);
  }

  getgeom(data) {
    if (data != null) {
      let str = data;
      let result = str.match(/\(\((.*)\)\)/);
      let str2 = result[1];
      let latlon = str2.split(',');

      let temp = [];
      latlon.forEach(el => {
        let s = el.split(' ')
        temp.push({ "lat": +s[1], "lng": +s[0] });
      });
      return temp;
    }
  }
  //convert circle to polygon function
  generateGeoJSONCircle(center: any, radius: any, numSides: any) {
    var points = [],
      degreeStep = 360 / numSides;
    for (var i = 0; i < numSides; i++) {
      var gpos = google.maps.geometry.spherical.computeOffset(center, radius, degreeStep * i);
      points.push([gpos.lng() + ' ' + gpos.lat()]);
    };

    // Duplicate the last point to close the geojson ring
    points.push(points[0]);
    return {
      type: 'Polygon',
      coordinates: [points]
    };
  }

  checkParcel(loc) {
    this.loader_map = true;
    let url = 'place/location', body = {
      location1: {
        lat: this.rowData.lat,
        lon: this.rowData.lon
      },
      location2: {
        lat: this.location.lat,
        lon: this.location.lon
      },
      scope: 'parcel'
    };
    this.api.postGmsData(url, body).subscribe({next:(res: any) => {
      if (res.match == true) {
        this.getLocationInfo(loc);
        this.isOutSideMoved = false;
      } else {
        this.loader_map = false;
        this.notify.notify("You can't move property outside of its parcel", 'warn');
        this.isOutSideMoved = true;
      }
    }, error:(err) => {
      this.loader_map = false;
      this.notify.notify('Unable to check parcel', 'error');
      this.isOutSideMoved = false;
    }});
  }


  getLocationInfo(loc) {
    this.getStreets(loc);
    this.getLanes(loc);
    let url = "address/json?categories=parcel&lat=" + loc.lat + "&lon=" + loc.lon + "&return_geometry=true";
    this.api.getGesData(url)
      .subscribe({next:(data: any) => {
        if (data.length) {
          this.alert = false;
          this.loader_map = false;
          if (data.length == 1) {
            let val = data[0];
            this.selected_addr = val;
            this.addressForm.patchValue({
              'house_no': val.house_no,
              'parcel_no': val.parcel_no,
              'building_name': this.building_name,
              'lane_name': val.lane_name,
              'street_name': val.street_name,
              // 'city_name': val.city_name,
              'block_name': val.block_name,
              'neigh_name': val.neigh_name
            })
          } else {
            this.available_address_list = data;
            this.loader_map = false;
            this.openDialog();
          }
        } else {
          this.getNeighInfo(loc);
        }
      }, error:(err) => {
        this.notify.notify(`GIS Error - ${err.error.message}`, 'error', 10000)
        this.getNeighInfo(loc);
      }})
  }

  getStreets(loc) {
    // for street
    let filtered = "streets/json?categories=street,major road,highway,roundabout&radius=200&lat=" + loc.lat + "&lon=" + loc.lon + "&return_geometry=false";
    this.api.getGpsData(filtered)
      .subscribe({next:(data: any) => {
        data.forEach((v) => {
          this.streetObj.push(v);
          if (v.street_name) {
            if (this.streetList.indexOf(v.street_name) == -1) {
              this.streetList.push(v.street_name);
            }
          }
        });
        this.setStreetSuggestion();
      },error:(err)=>{
        this.notify.notify(`GIS Error - ${err.error.message}`, 'error', 10000)
      }});
  }
  getLanes(loc) {
    // // for lane suggestion
    let filtered = "streets/json?categories=lane&radius=200&lat=" + loc.lat + "&lon=" + loc.lon + "&return_geometry=false";
    this.api.getGpsData(filtered)
      .subscribe({next:(data: any) => {
        data.forEach((v) => {
          this.laneObj.push(v);
          if (v.street_name) {
            if (this.laneList.indexOf(v.street_name) == -1) {
              this.laneList.push(v.street_name);
            }
          }
        })
        this.setLaneSuggestion();
      },error:(err)=>{
        this.notify.notify(`GIS Error - ${err.error.message}`, 'error', 10000)
      }});
  }
  getNeighInfo(loc) {
    let filtered = "address/json?categories=block&lat=" + loc.lat + "&lon=" + loc.lon + "&return_geometry=true";
    this.api.getGesData(filtered)
      .subscribe({next:(data: any) => {
        if (data) {
          this.alert = false;
          this.blockInfo = data[0];
          this.addressForm.patchValue({
            'house_no': '',
            'parcel_no': '',
            'building_name': this.building_name,
            'lane_name': '',
            'street_name': '',
            // 'city_name': '',
            'block_name': this.blockInfo.block_name,
            'neigh_name': this.blockInfo.neigh_name
          })
          this.selected_addr = this.blockInfo;
        }
        this.loader_map = false;
      }, error:(err) => {
        this.notify.notify(`GIS Error - ${err.error.message}`, 'error', 10000)
        this.alert = true;
        this.openDialog();
        this.addressForm.patchValue({
          'house_no': '',
          'parcel_no': '',
          'building_name': this.building_name,
          'lane_name': '',
          'street_name': '',
          // 'city_name': '',
          'block_name': '',
          'neigh_name': ''
        })
        this.selected_addr = null;
        this.loader_map = false;

      }})
  }

  edit(opt?) {
    this.enableEdit = !this.enableEdit;
    if (this.enableEdit) {
      this.getStreets(this.location);
      this.getLanes(this.location);
      this.setStreetSuggestion();
      this.setLaneSuggestion();
      this.toggleFilters = false;
    } else {
      this.toggleFilters = true;
    }
    this.panToLocation();
    // this.renderMap();
    if (opt) {
      this.loader_map = false;
    }
  }
  setLaneSuggestion() {
    this.lanes = [];
    this.laneList.forEach((v) => {
      this.lanes.push({
        'name': v
      });
    });
    if (this.addressForm) {
      this.lanedata = this.addressForm.get('lane_name').valueChanges
        .pipe(
          startWith(''),
          map(state => state ? this.currentLane(state) : this.lanes.slice())
        );
    }
  }
  setStreetSuggestion() {
    this.streets = [];
    this.streetList.forEach((v) => {
      this.streets.push({
        'name': v
      });
    });
    if (this.addressForm) {
      this.streetdata = this.addressForm.get('street_name').valueChanges
        .pipe(
          startWith(''),
          map(state => state ? this.currentStreet(state) : this.streets.slice())
        );
    }
  }
  currentLane(name: string) {
    return this.lanes.filter(state =>
      state.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }
  currentStreet(name: string) {
    return this.streets.filter(state =>
      state.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }
  save(form) {
    this.loader = true;
    let data = form.value, _building_name, _lane_name, _street_name, _neigh_name, _body, _block, street_uid = '-1', lane_uid = '-1';

    if (data.lane_name) { //Get lane UID
      let lane_obj = this.fnc.getArrayValue('street_name', data.lane_name, this.laneObj);
      if (this.fnc.jsonLength(lane_obj) > 0 && data.lane_name) {
        lane_uid = lane_obj.street_uid;
      }
    }
    if (data.street_name) { //Get Street UID
      let street_obj = this.fnc.getArrayValue('street_name', data.street_name, this.streetObj);
      if (this.fnc.jsonLength(street_obj) > 0 && data.street_name) {
        street_uid = street_obj.street_uid;
      }
    }

    _building_name = { [this.api.language.toString()]: data.building_name };
    _lane_name = { [this.api.language.toString()]: data.lane_name };
    _street_name = { [this.api.language.toString()]: data.street_name };

    if (this.selected_addr) {
      _neigh_name = { [this.api.language.toString()]: this.selected_addr.neigh_name };
      _block = { [this.api.language.toString()]: this.selected_addr.block_name };
    }

    _body = {
      "updated_by": this.api.user_id,
      "data": {
        [this.api.language]: {
          [this.parent.property_name_id]: data.building_name
        },
        "0": {}
      },
      "own_address": {
        "building_name": _building_name,
        "house_no": data.house_no,
        "parcel_no": data.parcel_no,
        "lane_name": _lane_name,
        "street_name": _street_name,
        "lane_uid": lane_uid,
        "street_uid": street_uid
      }
    }
    this.displayAddress = {
      parcel_no: data.parcel_no,
      house_no: data.house_no,
      building_name: data.building_name,
      lane_name: data.lane_name,
      street_name: data.street_name,
      block_name: this.rowData.own_address.block_name,
      neigh_name: this.rowData.own_address.neigh_name
    }
    if (this.selected_addr) {
      _body.own_address['block_name'] = _block;
      _body.own_address['neigh_name'] = _neigh_name;
      _body.own_address['block_uid'] = this.selected_addr.block_uid;
      _body.own_address['neigh_uid'] = this.selected_addr.neigh_uid;
      // _body.own_address['street_uid'] = street_uid;
      // _body.own_address['lane_uid'] = lane_uid;
      if (this.selected_addr.parcel_uid) _body.own_address['parcel_uid'] = this.selected_addr.parcel_uid;
      if (this.selected_addr.building_uid) _body.own_address['building_uid'] = this.selected_addr.building_uid;
    }
    if (this.location && this.location.lat) {
      _body['lat'] = this.location.lat;
      _body['lon'] = this.location.lon;
    }

    if (this.alert) {
      this.loader = false;
      this.alert = true;
      this.openDialog();
      return;
    }

    this.api.patchEpsData('deadmin/' + this.assignmentName + '/properties/' + this.rowData.property_id, _body)
    .subscribe({next:(data: any) => {
      this.loader = false;
      this.parent.getProperties();
      this.parent.notify.notify('Address successfully updated', 'Dismiss');
      this.enableEdit = false;
    },
      error:(err) => {
        if (err['status'] == 401) {
          this.notify.notify('You are not authorized to modify property', 'error');
        }
        this.loader = false;
      }});
  }

  openDialog() {
    let dialogRef = this.dialog.open(AddressListDialog, {
      width: '400px',
      panelClass: 'create-master-panel',
      data: { parent: this }//optional
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }
  ngOnInit() {
  }

  toggle() {
    // if (this.parent.selectedPolygons.length) {
    //   this.parent.selectedPolygons = [];
    //   this.parent.getProperties();
    // }
    this.sideNavToggle.emit();
  }
}
