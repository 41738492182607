<div fxFill>
  <div class="crumb-c" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" class="w-100 bleft" fxLayoutAlign="space-between center">
      <admin-breadcrumb></admin-breadcrumb>
      <div fxLayout="row" fxLayoutAlign="end center">
        <label>Report Type: </label>
        <mat-select (selectionChange)="getGroups($event.value)" [(ngModel)]="selectedTypeId" placeholder="Report type"
          class="form-select-l ovrd100 ml-1">
          <mat-option *ngFor="let data of reportType" [value]="data.id">{{showLabel(data)}}</mat-option>
        </mat-select>
        <button *ngIf="group_access.POST == true" mat-button class="orange-button ml-1"
          (click)="openDialog('ADD_GROUP')">Add Group</button>
      </div>
    </div>
  </div>
  <div class="w-100" fxLayout="row">
    <div class="l-cont">
      <div class="w-100 mb-2 rel">
        <h4 class="l-mg">Report Groups</h4>
        <mat-progress-bar *ngIf="loader" class="w-100 abs" mode="indeterminate"></mat-progress-bar>
      </div>
      <!-- <div class="newscroll" [sortablejs]="group_custom_list" [sortablejsOptions]="groupoptions"> -->
      <ngx-sortable [items]="group_custom_list" (listSorted)="groupoptions($event)" listStyle='{
          width:100%, //width of the list defaults to 300,
          height: 100%, //height of the list defaults to 250
          }' showHeader={{showListHeader}}>
        <div class="cst rowse" *ngFor="let item of group_custom_list;let kk = index;" fxLayout="column"
          (mouseleave)="mouseLeave(item.id)" fxLayoutAlign="start left">
          <button class="group-b rel" (click)="getReportList(item, item.id)" mat-button dndDropzone
            (dndDrop)="onDrop($event,item.id)"
            [ngClass]="{active: isActive(item.id), disabled: !item.status, 'dis': item.status == 0}"
            (mouseenter)="showsmallpop(item.id, false, $event)">
            <!-- <mat-icon class="draghandle" matTooltip="Drag me" svgIcon="move-icon">drag_handle</mat-icon> -->
            <svg matTooltip="Drag me" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 490.677 490.677"
              style="enable-background:new 0 0 490.677 490.677;" xml:space="preserve" width="18px" height="18px">
              <g>
                <g>
                  <path fill="#424c73"
                    d="M487.557,237.789l-64-64c-3.051-3.051-7.659-3.947-11.627-2.304c-3.989,1.643-6.592,5.547-6.592,9.856v32h-128v-128h32    c4.309,0,8.213-2.603,9.856-6.592c1.643-3.989,0.725-8.576-2.304-11.627l-64-64c-4.16-4.16-10.923-4.16-15.083,0l-64,64    c-3.051,3.072-3.968,7.637-2.325,11.627c1.643,3.989,5.547,6.592,9.856,6.592h32v128h-128v-32c0-4.309-2.603-8.213-6.592-9.856    c-3.925-1.664-8.555-0.747-11.627,2.304l-64,64c-4.16,4.16-4.16,10.923,0,15.083l64,64c3.072,3.072,7.68,4.011,11.627,2.304    c3.989-1.621,6.592-5.525,6.592-9.835v-32h128v128h-32c-4.309,0-8.213,2.603-9.856,6.592c-1.643,3.989-0.725,8.576,2.304,11.627    l64,64c2.091,2.069,4.821,3.115,7.552,3.115s5.461-1.045,7.552-3.115l64-64c3.051-3.051,3.968-7.637,2.304-11.627    c-1.664-3.989-5.547-6.592-9.856-6.592h-32v-128h128v32c0,4.309,2.603,8.213,6.592,9.856c3.947,1.685,8.576,0.747,11.627-2.304    l64-64C491.717,248.712,491.717,241.971,487.557,237.789z" />
                </g>
              </g>
            </svg>
            <span class="w-100 title">{{showLabel(item)}}</span>
            <mat-icon class="add-b" svgIcon="down-arrow-icon"></mat-icon>
          </button>
          <group-contextmenu [item]="item" [openDialog]="dialogFn" class="tooltip-nearmaster"
            [ngClass]="{'showtool' : popwindow===item.id && !submasterpop}"></group-contextmenu>

          <!-- #5 Submenu Master group -->
          <div class="cs" fxLayout="row" fxLayoutAlign="start start">
            <mat-nav-list class="masterslist w-100" [ngClass]="{active: isActive(item.id)}"
              *ngIf="item.childrens && item.childrens.length">
              <ng-container *ngFor="let childitem of item.childrens;let ks = index" (mouseleave)="mouseLeave(ks)">
                <mat-list-item class="item subdraghandle"
                  (mouseenter)="showsmallsubpop($event, 'tooltip-subnearmaster',ks,false)">
                  <!-- <mat-icon class="draghandle" matTooltip="Drag me" svgIcon="move-icon"></mat-icon> -->
                  <div class="txt cp">{{showLabel(childitem)}}</div>
                  <subgroup-contextmenu [item]="childitem" [openDialog]="dialogFn" class="tooltip-subnearmaster"
                    [ngClass]="{'showsubtool' : popsubwindow===ks}">
                  </subgroup-contextmenu>
                </mat-list-item>
              </ng-container>
            </mat-nav-list>
          </div>
          <!-- #5 End of submaster group -->
        </div>
      </ngx-sortable>
      <!-- </div> -->
    </div>
    <div class="col3">
      <div class="p-1 rel h50p" fxLayout="row" fxLayoutAlign="space-between center">
        <span>Group Name : <b class="cp">{{showLabel(selected_group)}}</b></span>
        <!-- <button *ngIf="selected_ids.length" title="copy Group" [disabled]="!selected_ids.length" mat-button
          class="shuffleiconbutton2" (click)="openDialog('GROUP_LiST')">
          <mat-icon svgIcon="copy"></mat-icon>
        </button> -->
      </div>

      <div class="table-container mat-elevation-z8">
        <mat-table [sortablejs]="dataSource" [sortablejsOptions]="reportoptions" [dataSource]="dataSource"
          class="w-100">
          <mat-progress-bar class="w-100 abs" mode="indeterminate"></mat-progress-bar>
          <!-- select Column -->
          <ng-container matColumnDef="drag">
            <mat-header-cell *matHeaderCellDef width="10%"> </mat-header-cell>
            <mat-cell *matCellDef="let element;">
              <div fxLayout="row" fxLayoutAlign="space-between center" class="draghandle1">
                <mat-icon class="handle" svgIcon="dragx"></mat-icon>
              </div>
            </mat-cell>
          </ng-container>
          <!-- select Column -->
          <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef> </mat-header-cell>
            <mat-cell *matCellDef="let element;" [dndDraggable]="{data:element}"
              [dndEffectAllowed]="draggable.effectAllowed" (dndStart)="dragStart($event, element)"
              (dndCanceled)="dragCanceled($event, element)">
              <div fxLayout="row" fxLayoutAlign="space-between center">
                <mat-icon class="dragico" svgIcon="move-icon"></mat-icon>
                <!-- <mat-checkbox class="ml-1" [checked]="isChecked(element.id)" (change)="changeSelectedIds($event, element)"></mat-checkbox> -->
              </div>
            </mat-cell>
          </ng-container>
          <!-- Id Column -->
          <ng-container matColumnDef="sn">
            <mat-header-cell *matHeaderCellDef> SN </mat-header-cell>
            <mat-cell *matCellDef="let element; let i = index;">
              <span>{{ i + 1 }}</span>
            </mat-cell>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <mat-header-cell width="10%" *matHeaderCellDef> Name </mat-header-cell>
            <mat-cell *matCellDef="let element"><span class="inline-fix-lab">{{element.name}}</span></mat-cell>
          </ng-container>

          <ng-container matColumnDef="label">
            <mat-header-cell width="10%" mat-header-cell *matHeaderCellDef> Label </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <span *ngIf="selectedCellId != element.id" class="inline-fix-lab">{{getLabel(element)}}</span>
              <input class="editable" *ngIf="selectedCellId == element.id" matInput type="text"
                placeholder="Enter new value" [(ngModel)]="tempCellData.label">
            </mat-cell>
          </ng-container>

          <!-- Url Column -->
          <ng-container matColumnDef="url">
            <mat-header-cell width="30%" *matHeaderCellDef> URL </mat-header-cell>
            <mat-cell *matCellDef="let element" title="{{element.url}}">
              <span *ngIf="selectedCellId != element.id" class="inline-fix">{{element.url}}</span>
              <input class="editable" *ngIf="selectedCellId == element.id" matInput type="text"
                placeholder="Enter new value" [(ngModel)]="tempCellData.url">
            </mat-cell>
          </ng-container>

          <!-- Description Column -->
          <ng-container matColumnDef="description">
            <mat-header-cell width="20%" *matHeaderCellDef> Description </mat-header-cell>
            <mat-cell *matCellDef="let element" title="{{getDiscript(element)}}">
              <span *ngIf="selectedCellId != element.id" class="inline-fix-desc">{{getDiscript(element)}}</span>
              <input class="editable" *ngIf="selectedCellId == element.id" matInput type="text"
                placeholder="Enter new value" [(ngModel)]="tempCellData.description">
            </mat-cell>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <mat-header-cell mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <!-- <mat-slide-toggle [checked]="element.status">
                Slide me!
              </mat-slide-toggle> -->
              <mat-slide-toggle [checked]="element.status == '1'" (change)="disableReport($event, element)">
              </mat-slide-toggle>
              <!-- <span>{{element.status}}</span>  -->
            </mat-cell>
          </ng-container>



          <!-- Action Column -->
          <ng-container matColumnDef="action">
            <mat-header-cell class="fxwidth" mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-button-toggle-group class="toggle-btn-group" #group="matButtonToggleGroup">
                <mat-button-toggle *ngIf="selectedCellId != element.id" value="left" (click)="edit(element)"
                  title="Edit">
                  <mat-icon class="size-sm default">edit</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle *ngIf="selectedCellId == element.id" value="left"
                  (click)="updateReport(tempCellData)" title="Save">
                  <mat-icon *ngIf="!updateLoader" class="size-sm accent">save</mat-icon>
                  <mat-spinner *ngIf="updateLoader"></mat-spinner>
                </mat-button-toggle>
                <mat-button-toggle [disabled]="selectedCellId != element.id" value="center"
                  (click)="cancelEdit(element.id)" title="Cancel">
                  <mat-icon class="size-sm"
                    [ngClass]="{'default': selectedCellId == element.id, 'inactive': selectedCellId != element.id}">
                    close</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="right" title="Disable">
                  <mat-icon class="size-sm warn" (click)="deteleReport(element)">delete</mat-icon>
                </mat-button-toggle>
              </mat-button-toggle-group>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" class="dragx1"></mat-row>
        </mat-table>
      </div>
    </div>
  </div>
</div>
