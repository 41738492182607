import { Component, Inject } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import {
  MatDialogRef,
  MatDialog,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { NotificationService } from "../../../services/notification.service";
import { ShareService } from "../../../services/share.service";
import { ApiserviceService } from "../../../../apiservice.service";
import { CommonfunctionService } from "../../../../services/commonfunction.service";
import { ShuffleConfirmationDialog } from "../Shuffle-Confirmation-Dialog/shuffle-confirmation-dialog.component";
import { Options } from "sortablejs";

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_module_type_entity_attributegroups_group_id_patch</li>
 * <li>estapi_module_type_entity_attributes_get</li>
 * <li>estapi_module_type_entity_attributes_patch</li>
 * </ol>
 *
 */

@Component({
  selector: "field-shuffle-dialog",
  templateUrl: "../../subelements/admin-shuffle.component.html",
  styleUrls: ["./field-shuffle-dialog.component.scss"],
})
export class FieldShuffleDialog {
  groups: any;
  dependent_list: string;
  selected: any;
  selected_sub: any;
  popwindow: number = -1;
  popsubwindow: number = -1;
  currentitem: any;
  selected_group_id: number;
  dependent_attribute_ids: any = [];
  dependent_attributes: Array<any>;
  field_list: Array<any>;
  confirm: boolean = false;
  groupoptions: Options = {
    draggable: ".rowse",
    handle: ".draghandle",
  };
  mobilemasterpopup = false;
  mobilemasterpopupslideout = false;
  field_group: any = [];
  group_list: any;
  is_undefined: boolean;

  submasterlist: Array<{}> = [];
  mobilegroupoptions: Options = {
    draggable: ".mobilerowslist",
    handle: ".draghandle",
  };
  confirmation: boolean;
  selected_attr_ids: any = [];
  loader: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<FieldShuffleDialog>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder,
    private api: ApiserviceService,
    private fnc: CommonfunctionService,
    private share: ShareService,
    private notify: NotificationService
  ) {
    this.dialogRef.disableClose = true;
    this.selected_attr_ids = this.data.parent.selected_attributes_id;
    this.group_list = JSON.parse(
      JSON.stringify(this.data.parent.group_custom_list)
    ); // to get value only
    if (data.val) {
      this.groups = this.fnc.getDeletedArray(
        "id",
        data.val.parent_id,
        this.group_list
      );
    } else {
      if (this.data.parent.selected_group) {
        let index = null;
        if (this.data.parent.selected_group.parent_id) {
          let p_detail = this.fnc.getArrayValue(
            "id",
            this.data.parent.selected_group.parent_id,
            this.group_list
          ); // incomplete section
          let deleted_children = fnc.getDeletedArray(
            "id",
            this.data.parent.selected_group.id,
            p_detail.childrens
          );
          this.group_list.forEach((obj, k) => {
            if (obj.id == p_detail.id) {
              index = k;
            }
          });

          this.group_list[index].childrens = deleted_children;
          this.groups = this.group_list;
        } else {
          this.groups = this.fnc.getDeletedArray(
            "id",
            this.data.parent.selected_group.id,
            this.group_list
          );
        }
        this.is_undefined = false;
      } else {
        this.is_undefined = true;
        this.groups = this.group_list;
      }
    }
  }

  ngOnInit() {
    if (this.data && !this.data.class) {
      this.data.class = "";
    }
    this.getAttributes();
  }

  openDialog() {
    let confimDialog = this.dialog.open(ShuffleConfirmationDialog, {
      width: "300px",
      panelClass: "create-master-panel",
      data: { parent: this },
    });
    confimDialog.afterClosed().subscribe((result) => {});
  }

  shuffleGroups() {
    let field_group = [];
    let val = {};
    if (
      this.data.val &&
      this.selected_group_id &&
      this.selected_group_id != -1
    ) {
      let shuffle_group = {
        updated_by: this.api.user_id,
        parent_group_id: this.selected_group_id,
        assignment_id: this.data.parent.selected_assignment,
      };
      //let assignment = '';
      //if(this.data.parent.is_assignment_dependent && this.data.parent.selected_assignment) assignment = "/"+this.data.parent.selected_assignment;
      let url =
        this.data.parent.selected_module +
        "/" +
        this.data.parent.selected_entity_type +
        "/attributegroups/" +
        this.data.val.id;
      this.api.patchEmsData(url, shuffle_group).subscribe({
        next: (data: any) => {
          this.notify.notify("Group shuffle successfully", "success");
          this.data.parent.getGroups("sort_order", 1000, 0);
        },
        error: (err) =>
          this.notify.notify(this.fnc.getErrorMessage(err), "error"),
        complete: () => this.closeDialog(),
      });
    }
  }

  getAttributes() {
    let url =
      this.data.parent.selected_module +
      "/" +
      this.data.parent.selected_entity_type +
      "/attributes?sort_by=sort_order&limit=10&offset=0&assignment_id=" +
      this.data.parent.selected_assignment;
    this.api.getEmsData(url).subscribe((data: any) => (this.field_list = data));
  }

  shuffleFields() {
    let val = {};
    let field_details: any;
    let parent_details: any;
    let child_detail: any;
    if (this.selected_attr_ids.length > 20) {
      this.notify.notify(
        `You cannot assign more than 20 items at a time, you have selected (${this.selected_attr_ids.length}) items.`,
        "warn"
      );
      return;
    }

    //console.log(this.selected_attr_ids);
    else {
      if (this.selected_attr_ids.length && this.selected_group_id) {
        this.field_group = [];
        this.loader = true;
        this.selected_attr_ids.forEach((element) => {
          val = {
            updated_by: this.api.user_id,
            attribute_id: element,
            group_id: this.selected_group_id,
            assignment_id: this.data.parent.selected_assignment,
            move_linked_attributes: this.confirm,
          };
          this.field_group.push(val);
        });
        let url =
          this.data.parent.selected_module +
          "/" +
          this.data.parent.selected_entity_type +
          "/attributes";
        this.loader = true;
        this.api.patchEmsData(url, this.field_group).subscribe({
          next: (data: any) => {
            this.loader = false;
            this.data.parent.selected_attributes_id = [];
            this.notify.notify("Attributes assigned successfully", "success");
            this.data.parent.getGroups("sort_order", 1000, 0);
          },
          error: (err) => {
            this.loader = false;
            this.data.parent.selected_attributes_id = [];
            //this.notify.notify(this.fnc.getErrorMessage(err), 'error');
            if (err.status == 500 && !this.confirm) {
              let end,
                dependent_string = "";
              let msg = this.fnc.getErrorMessage(err);
              this.notify.notify(msg, 'warn')
              this.loader = false;
              let list = msg.split("{");
              list.forEach((element, k) => {
                if (k != 0) {
                  end = element.indexOf("}");
                  if (end != -1) {
                    dependent_string += element.substring(0, end) + "|";
                  }
                }
              });
              this.dependent_list = dependent_string;
              this.openDialog();
              this.confirm = false;
              this.loader = false;
            }
            if (err.status == 400 && !this.confirm) {
              this.notify.notify(this.fnc.getErrorMessage(err), 'warn')
            }
          },
          complete: () => {
            this.closeDialog();
            this.confirm = false;
            this.loader = false;
          },
        });
      }
    }
  }

  isActive(item) {
    return this.selected === item;
  }

  select(item, id: number) {
    // this.selected = (this.selected === item ? null : item);
    this.selected = item;
    this.selected_group_id = id;
  }

  isActiveSub(item) {
    return this.selected_sub === item;
  }

  selectSub(item, id: number) {
    this.selected_sub = this.selected_sub === item ? null : item;
    this.selected_group_id = id;
  }

  closeDialog(): void {
    this.dialogRef.close();
    this.selected_attr_ids = [];
  }
}
