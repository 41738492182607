import { Component, Inject } from '@angular/core';
// import { debounceTime } from 'rxjs/operators';
// import 'rxjs/add/operator/map';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiserviceService } from '../../apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { NotificationService } from '../services/notification.service';


/**
*
* <strong>List of API using</strong>
* <ol>
* <li>umsapi_language_get</li>
* <li>estapi_assignments_assignment_id_patch</li>
* <li>estapi_assignments_post</li>
* </ol>
*
*/

@Component({
    selector: 'createAssignment',
    templateUrl: './create-assignment.component.html',
    styleUrls: ['./master-assignment.component.css']
  })
  export class CreateAssignmentComponent {
    mobile: boolean = false;
    update: boolean = false;
    assignment_id;
    assignmentForm: any;
    languages: any = [];
    language_valueset: Array<{}> = [];
    assignment_type = [
    {name:'general',label:'General'},
    {name:'self_assign',label:'Self Assign'},
    {name:'dc',label:'Data Collection'},
    {name:'em',label:'Estater Meter'},
    {name:'parcel_survey',label:'Parcel Survey'}];
  duplicateLang: any;
    get assignmentType() {
      return this.assignmentForm.get('assignment_type');
    }
    get assignmentName() {
      return this.assignmentForm.get('assignment_name');
    }
    get Year() {
      return this.assignmentForm.get('year');
    }
    get AssignmentLabel() {
      return this.assignmentForm.get('assignment_label');
    }

    yearList=[];
    search_year:string;
    constructor(public dialogRef: MatDialogRef<CreateAssignmentComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
      private fb: FormBuilder, public api: ApiserviceService, private fucn: CommonfunctionService, private notify: NotificationService) {
      this.getLanguages();

      this.update = data.update;
      this.assignment_id = data?.stats?.assignment_id;

      this.assignmentForm = this.fb.group({
        year:['', Validators.required],
        assignment_name: [{ value: '', disabled: data.update }, [Validators.required, Validators.maxLength(64), Validators.minLength(3), Validators.pattern('^[a-zA-Z0-9_]*$')]],
        assignment_type: [{ value: this.assignment_type[0].name, disabled: data.update },Validators.required],
        language: this.fb.array([])
      });
      if (this.update == true) {
        this.assignmentForm = null;
        this.assignmentForm = this.fb.group({
          year:[{ value: '', disabled: data.update }, Validators.required],
          assignment_name: [{ value: '', disabled: data.update }, Validators.required],
          assignment_type: [{ value: this.assignment_type[0].name , disabled: data.stats.type ? true :false}, Validators.required],
          language: this.fb.array([])
        })
        let labels: any = [];
        let index = 0;
        this.assignmentForm.patchValue({
          assignment_name: data.stats.name,
          assignment_type: data.stats.type,
          year: this.data.stats.year?this.data.stats.year.toString():''
        });
        for (let prop in data.stats.lang) {
          labels[index] = { language: prop, assignment_label: data.stats.lang[prop] };
          this.addRow();
          index++
        }
        setTimeout(() => {
          this.assignmentForm.patchValue({ language: labels });
        }, 100);
      } else {
        this.addRow();
      }
    }

    closeMatSelectEvent(event){
      if(!event){
        this.search_year = '';
      }
    }

      //create year dropdown
      getYears() {
        const currentYear = new Date().getFullYear()
        const startYear  = 2009;
        for (var i = currentYear; i >= startYear; i--) {
          this.yearList.push({ val: (i).toString() });
        }
      }


    modifyString(string) {
      return string.replace(/ /g, "_");
    }
    ngOnInit() {
      this.getYears();
    }
    getLanguages() {
      if (!this.api.language_list) {
        this.api.getUmsData('language')
          .subscribe(
            (res: any) => {
              if (res.data.length) {
                res.data.forEach((v) => {
                  let lang = {
                    id: v.language_id,
                    name: v.name
                  }
                  this.languages.push(lang);
                });
              }
            }
          );
      } else {
        this.api.language_list.forEach((v) => {
          let lang = {
            id: v.language_id,
            name: v.name
          }
          this.languages.push(lang);
        });
      }

    }
    initLanguageFields() {
      return this.fb.group({
        language: [String(this.languages[0].language_id), Validators.required],
        assignment_label: ['', Validators.compose([Validators.required, Validators.maxLength(64)])]
      })
    }
    addRow() {
      if (this.language_valueset?.length == this.languages?.length) {
        this.notify.notify("No more Languages Available to Add", 'warn', 4000);
        return;
      }
      this.language_valueset.push({ language: '', value: '' });
      let dd = <FormArray>this.assignmentForm.controls.language;
      dd.push(this.initLanguageFields());
    }
    removeRow(key) {
      if (this.language_valueset.length > 1) {
        this.language_valueset.splice(key, 1);
        let dd = <FormArray>this.assignmentForm.controls.language;
        dd.removeAt(key);
      }
    }
    closeDialog(): void {
      this.dialogRef.close();
    }
      // selected duplicate language
  selectedLang(languageList){
    languageList.forEach(lang => {
      this.duplicateLang = languageList.filter((val) => val.language === lang.language); // filtering duplicate languages list
    });
}
    addAssignmentVal(val, e) {
      let langList = this.assignmentForm.value.language; // selected language list
      this.selectedLang(langList)
      let isEnglishSelected = langList.find(ele => ele.language == 1); // finding english language selected or not
      if(this.duplicateLang.length > 1){
        this.notify.notify('You have selected same language multiple time', 'warn'); // if selected same language multiple time
        return
       }
      if(!isEnglishSelected){
        this.notify.notify('English language is required', 'warn'); // if english language not selected
        return
      }
      let url, body, labels = {}, data = val.value;

      if (this.update == true) {
        this.data.pObj.loader1 = true;
        let url = "assignments/" + this.data.stats.id;
        data.language.forEach((v) => {
          labels[v.language] = v.assignment_label;
        })
        body = {
          "assignment_id": this.data.stats.id,
          "assignment_label": labels,
          "updated_by": +this.api.user_id,
          "type": data.assignment_type ? data.assignment_type : this.data.stats.type
        }
        this.api.patchEmsData(url, body)
          .subscribe({next: (data) => {
            this.data.pObj.masterList = [];
            this.data.pObj.getAssignment(false);
            this.data.pObj.notify.notify('Assignment successfully updated', 'success');
          }, error: (err) => {
            this.data.pObj.loader1 = false;
          }})
        this.dialogRef.close();
      } else {
        let valid = false;
        var format = /[!@#$%^&*()~+\-=\[\]{};':"\\|,.<>\/?]/;
        if (format.test(data.assignment_name)) {
          this.data.pObj.notify.notify('Special characters not allowed', 'warn');
          return;
        } else {
          valid = true;
        }
        this.data.pObj.loader1 = true;
        data.language.forEach((v) => {
          labels[v.language] = v.assignment_label;
        })
        url = "assignments";


        if (valid) {
          let assignmentName = data.assignment_name;
          body = {
            "added_by": this.api.user_id,
            "assigned_to": null,
            "assignment_label": labels,
            "assignment_name": assignmentName,
            "type": data.assignment_type,
            "sort_order": 0,
            "status": 1,
            "year": data.year
          }

          this.api.postEmsData(url, body)
            .subscribe({next: (data) => {
              this.data.pObj.masterList = [];
              this.data.pObj.getAssignment(false);
              this.data.pObj.notify.notify('New assignment created', 'success');
              this.data.pObj.loader1 = false;
              this.dialogRef.close();
            }, error : (err) => {
              this.data.pObj.loader1 = false;
              this.data.pObj.notify.notify(this.fucn.getErrorMessage(err), 'error');
            }})
        }
      }
    }
    saveDialog(): void {
    }
  }

