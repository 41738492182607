import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiserviceService } from '../../../apiservice.service';

@Component({
  selector: 'app-usermenu',
  templateUrl: './usermenu.component.html',
  styleUrls: ['./usermenu.component.css']
})
export class UsermenuComponent implements OnInit {

  constructor(private router: Router, private api:ApiserviceService, public dialog: MatDialog) { 
  }

  ngOnInit() {
  }
  logout(val){
    this.api.logout(val);
  }
  openProfile(){
    let dialogRef = this.dialog.open(ProfileDialog, {
      width: '450px',
      panelClass: 'create-master-panel',
      data: { parent: this }//optional
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./usermenu.component.css']
})

export class ProfileDialog{

  constructor(public dialogRef: MatDialogRef<ProfileDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any) {}
  
  ngOninit(){}
  close(){
    this.dialogRef.close();
  }
}