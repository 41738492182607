<div class="parent-container">
    <mat-card appearance="outlined" class="example-card">
        <div [class.highlighsection]="useNewApi == true">
            <div class="grid-ap header-wrapper">
                <div class="mat-h2 title">CSV Upload<span><small *ngIf="selected_File_Type" class="cp">&nbsp;- {{selected_File_Type | removeUnderscore}}</small></span>
                </div>
                <div class="cent">
                    <button mat-button matTooltip="Info" (click)="openDialog()" class="refresh1">
                        <mat-icon>info</mat-icon>
                    </button>
                    <small class="margl5">
                        <button [matMenuTriggerFor]="menu" mat-raised-button color="primary"> {{download}} <mat-icon>
                            keyboard_arrow_down
                            </mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="downloadSample(0)">Empty template</button>
                            <button *ngIf="selected_File_Type == 'properties'" mat-menu-item (click)="downloadSample(1)">Selected
                            unit type with data</button>
                            <button *ngIf="selected_File_Type == 'properties'" mat-menu-item (click)="downloadSample(2)">All
                            property with data</button>
                            <button *ngIf="selected_File_Type == 'properties'" mat-menu-item (click)="downloadSample(2,'2,7')">All
                            property with data(Review)</button>
                        </mat-menu>
                    </small>
                </div>
            </div>
        </div>
        <mat-card-content>

            <div class="gis_data_form">
                <div>
                    <div class="panel_div">
                        <div class="ml-10  d-flex align-items-center">
                            <label for="">Upload Type <span class="mandatory">*</span> : </label>
                            <mat-select placeholder="Select File Type" [(ngModel)]="selected_File_Type" (selectionChange)="onChangeEntity('masters', $event)" class="form-select-l">
                                <mat-option class="cp" *ngFor="let data of upload_type_list;" [value]="data.master_value">
                                    {{data.master_label}}</mat-option>
                            </mat-select>
                        </div>

                        <div class="ml-10  d-flex align-items-center" *ngIf="selected_File_Type == 'properties' || selected_File_Type == 'attributes'">
                            <label for="">Module Type <span class="mandatory">*</span> : </label>
                            <mat-select placeholder="Select Module Type" [(ngModel)]="selected_module_type"
                             (selectionChange)="onChangeEntity('module', $event)"
                             class="form-select-l">
                                <mat-option class="cp" *ngFor="let data of moduleTypeList" [value]="data.module_type_name">
                                    {{data.module_type_name}}</mat-option>
                            </mat-select>
                        </div>

                        <div class="ml-10  d-flex align-items-center" *ngIf="selected_File_Type == 'properties' || selected_File_Type == 'attributes'">
                            <label for="">Assignment <span class="mandatory">*</span> : </label>
                            <mat-select placeholder="Select Assignment" [(ngModel)]="selected_assignment" (openedChange)="searchAssignment=''" class="form-select-l">
                                <div class="search_bar"><input [(ngModel)]="searchAssignment" placeholder="Type here to search..." (keydown)="$event.stopPropagation()" /></div>
                                <mat-option class="cp" *ngFor="let data of assignmentList | searchfilter:'name':searchAssignment:assignmentList" [value]="data.assignment_id">
                                    {{getName(data.assignment_label) == undefined ? data.assignment_name : getName(data.assignment_label)}}_{{data?.year}}
                                </mat-option>
                            </mat-select>
                        </div>

                        <div class="ml-10  d-flex align-items-center" *ngIf="show_entity">
                            <label for="">Entity Type <span class="mandatory">*</span> : </label>
                            <mat-select placeholder="Select Entity Type"  [(ngModel)]="selected_entity_type" (selectionChange)="onChangeEntity('entity', $event)" class="form-select-l">
                                <mat-option class="cp" *ngFor="let ent of api.entity_type_list" [value]="ent.entity_name">
                                    {{ent.entity_name}}</mat-option>
                            </mat-select>
                        </div>
                        <!-- feat: conditionally limit Unit Type selection based on Upload Type "unlink_master_value" EST-I609   -->
                        <div *ngIf="is_property_dependent" class="ml-10  d-flex align-items-center">
                            <label for="">Unit Type <span class="mandatory">*</span> : </label>
                            <mat-select [multiple]="selected_File_Type !== 'unlink_master_value'"  placeholder="Select Property Type" (openedChange)="searchType=''" [(ngModel)]="selected_property_type" class="form-select-l" (selectionChange)="onChangeEntity('type',$event)">
                                <div class="search_bar"><input [(ngModel)]="searchType" placeholder="Type here to search name..." (keydown)="$event.stopPropagation()" /></div>
                                <mat-option class="cp" *ngFor="let prp of api.property_type_list | searchfilter:'property_type_name':searchType" [value]="prp.property_type_id">
                                  <span [title]="prp.property_type_label[api.language]"> {{prp.property_type_label[api.language].length > 64 ? (prp.property_type_label[api.language] | slice:0:64)+'...' : prp.property_type_label[api.language]}}</span>
                                  <span *ngIf="prp.property_type_label[api.language] == undefined " [title]="prp.property_type_name">{{prp.property_type_name}}</span>
                                </mat-option>
                            </mat-select>
                        </div>

                        <div *ngIf="is_master_dependent" class="ml-10  d-flex align-items-center">
                            <label for="">Master Group <span class="mandatory">*</span> : </label>
                            <mat-select placeholder="Select Master Group" [(ngModel)]="selected_master_group" (selectionChange)="onChangeEntity('group',$event)" class="form-select-l">
                                <mat-option class="cp" *ngFor="let prp of masterGroupList" [value]="prp.master_group_id">
                                    {{prp.master_group_label[api.language] == undefined ? prp.master_group_name : prp.master_group_label[api.language]}}
                                </mat-option>
                            </mat-select>
                        </div>

                        <div class="ml-10 d-flex align-items-center" *ngIf="selected_File_Type">
                            <label for="">Language <span class="mandatory">*</span> : </label>
                            <mat-select placeholder="Select Language" [(ngModel)]="selected_lang" class="form-select-l">
                                <mat-option class="cp" *ngFor="let data of language_list" [value]="data.language_id">{{data.name}}
                                </mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>

                <div class="gridV">
                    <div>
                        <!-- <span class="fntcustom" *ngIf="fileName">{{fileName}}</span> -->
                        <mat-chip-option class="vspace" *ngIf="fileName" [selectable]="selectable" [removable]="removable" (removed)="reset()">
                            {{fileName}}
                            <mat-icon matChipRemove *ngIf="removable" class="lnk Rejected">cancel</mat-icon>
                        </mat-chip-option>

                    </div>
                    <div class="btnGrid">
                        <div class="upload-btn-wrapper">
                            <div class="wrapper">
                                <button class="sel">Select file</button>
                                <p>Or Drag & drop file here</p>
                            </div>
                            <input *ngIf="refresh" type="file" #myFileInput name="myfile" (change)="handleFileInput($event)" accept=".zip" />
                        </div>
                        <button class="submit" type="submit" [disabled]="loading" mat-raised-button color="primary" (click)="uploadFile()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                                <path d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path>
                            </svg>
                            <span *ngIf="!loading">Upload</span>
                            <span *ngIf="loading">Uploading...</span>
                        </button>
                    </div>
                </div>

            </div>
        </mat-card-content>
        <div class="bbar w-100" *ngIf="loading">
            <mat-progress-bar mode="indeterminate" class="btm"></mat-progress-bar>
        </div>

    </mat-card>
    <mat-card appearance="outlined" class="example-card" *ngIf="!showConflict">
        <div [class.highlighsection]="useNewApi == true">
            <div class="grid-ap">
                <div class="mat-h2 title">
                    Upload Status<span><small *ngIf="selected_File_Type" class="cp">&nbsp;- {{selected_File_Type | removeUnderscore}}</small></span>
                </div>
                <div class="text-right mr-1">
                    <button mat-icon-button  matTooltip="Refresh list" class="refresh" (click)="getUploadStatus()">
                        <mat-icon>refresh</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <mat-card-content class="tabs">
            <div class="table-estater table" style="position: relative">
                <ng-container *ngIf="useNewApi">
                    <table class="attr-table">
                        <thead>
                            <tr>
                                <th> S.No.</th>
                                <th> Added Date</th>
                                <!-- <th> Row Count</th> -->
                                <ng-container *ngIf="selected_File_Type == 'properties'">
                                    <th> Property Count</th>
                                    <th> Floor Count</th>
                                    <th> Unit Count</th>
                                    <th> Chunk Process
                                        <span (click)="showChunk = !showChunk"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="15" height="15" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g>
                                            <g xmlns="http://www.w3.org/2000/svg">
                                                <g>
                                                    <g>
                                                        <path d="M290.211,360.727c-5.234,0.488-10.489-0.693-15.011-3.375c-3.304-3.392-4.888-8.101-4.305-12.8     c0.122-3.912,0.589-7.806,1.396-11.636c0.781-4.394,1.79-8.744,3.025-13.033l13.731-47.244c1.416-4.663,2.352-9.459,2.793-14.313     c0-5.236,0.698-8.844,0.698-10.938c0.292-9.333-3.693-18.289-10.822-24.32c-8.769-6.732-19.689-10.041-30.72-9.309     c-7.905,0.119-15.749,1.413-23.273,3.84c-8.223,2.56-16.873,5.624-25.949,9.193l-3.956,15.36     c2.676-0.931,5.935-1.978,9.658-3.142c3.552-1.052,7.234-1.601,10.938-1.629c5.196-0.563,10.426,0.713,14.778,3.607     c2.956,3.527,4.343,8.109,3.84,12.684c-0.013,3.913-0.442,7.814-1.28,11.636c-0.815,4.073-1.862,8.378-3.142,12.916     l-13.847,47.476c-1.116,4.413-2.009,8.879-2.676,13.382c-0.544,3.855-0.816,7.743-0.815,11.636     c-0.057,9.397,4.24,18.291,11.636,24.087c8.904,6.837,19.98,10.226,31.185,9.542c7.89,0.162,15.753-0.978,23.273-3.375     c6.594-2.25,15.399-5.469,26.415-9.658l3.724-14.662c-2.984,1.238-6.057,2.249-9.193,3.025     C298.346,360.583,294.274,360.935,290.211,360.727z" fill="#ffffff" data-original="#000000" class=""></path>
                                                        <path d="M304.756,136.727c-6.333-5.816-14.677-8.945-23.273-8.727c-8.591-0.194-16.927,2.932-23.273,8.727     c-11.632,10.03-12.931,27.591-2.9,39.224c0.894,1.037,1.863,2.006,2.9,2.9c13.252,11.853,33.294,11.853,46.545,0     c11.632-10.129,12.851-27.769,2.722-39.401C306.635,138.481,305.725,137.571,304.756,136.727z" fill="#ffffff" data-original="#000000" class=""></path>
                                                        <path d="M256,0C114.615,0,0,114.615,0,256s114.615,256,256,256s256-114.615,256-256S397.385,0,256,0z M256,488.727     C127.468,488.727,23.273,384.532,23.273,256S127.468,23.273,256,23.273S488.727,127.468,488.727,256S384.532,488.727,256,488.727     z" fill="#ffffff" data-original="#000000" class=""></path>
                                                    </g>
                                                </g>
                                            </g>
                                            </g>
                                        </svg></span>
                                        <div class="chunkTool" *ngIf="showChunk">
                                            <!-- <span (click)="showChunk = false"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 16 16.004"><defs><style>.a{fill:#777;}</style></defs><path class="a" d="M.227,1.335a.8.8,0,0,1,0-1.11.8.8,0,0,1,1.11,0L8,6.9,14.67.225a.781.781,0,0,1,1.1,1.11L9.108,8l6.661,6.672a.781.781,0,0,1-1.1,1.11L8,9.107,1.337,15.779a.8.8,0,0,1-1.11,0,.8.8,0,0,1,0-1.11L6.888,8Z" transform="translate(-0.002 0)"/></svg></span> -->
                                            <p>Total chunk / Validated chunk / Accepted chunk</p>
                                    </div>
                                    </th>
                                </ng-container>
                                <th> Upload Id</th>
                                <th> Name</th>
                                <th *ngIf="selected_File_Type == 'properties' || selected_File_Type == 'attributes'"> Assignment</th>
                                <th> Status</th>
                                <th> View Log</th>
                                <th> Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of upload_report; let k = index">
                                <td width="5%">{{offset+k+1}}</td>
                                <td>{{getDate(data.added_date)}}</td>
                                <!-- <td width="5%"> {{data.row_count}}</td> -->
                                <ng-container *ngIf="selected_File_Type == 'properties'">
                                    <td> {{data.property_count}}</td>
                                    <td> {{data.floor_count}}</td>
                                    <td> {{data.unit_count}}</td>
                                    <td> {{data.total_chunk}}/{{data.validated_chunk}}/{{data.accepted_chunk}}</td>
                                </ng-container>
                                <td width="5%"> {{data.upload_id}}</td>
                                <td width="30%"> {{data.name}} </td>
                                <td wisth="20%" style="text-transform: capitalize;" *ngIf="selected_File_Type == 'properties' || selected_File_Type == 'attributes'">{{data?.assignment_name}}_{{data?.assignment_year}}</td>
                                <td [ngClass]="getFileStatus(data)">{{getFileStatus(data)}}</td>
                                <td class="text-center">
                                    <ng-container *ngIf="selected_File_Type == 'masters' || selected_File_Type == 'attributes' || selected_File_Type == 'unlink_master_value'">
                                        <a  href="https://{{data.errfile_link}}" target="_blank" class="dl-btn bordered download mr-2">Validate Log</a>
                                        <button class="dl-btn bordered accept-log  download mr-2" [disabled]="!(data.status == -3) && !(data.status == 2)" (click)="logDownload(data.accept_link)">Accept Log</button>
                                    </ng-container>
                                    <button class="dl-btn download" *ngIf="selected_File_Type == 'properties'" (click)="openReportsDialog(data)">View / Accept</button>
                                </td>
                                <td>

                                        <button *ngIf="useNewApi && (selected_File_Type == 'masters' || selected_File_Type == 'attributes' || selected_File_Type == 'unlink_master_value')" class="dl-btn accept mr-1" [disabled]="hideAccept(data.status)" (click)="acceptUpload(data.upload_id)">Accept</button>
                                        <button class="dl-btn reject mr-1" color="warn" (click)="rejectUpload(data.upload_id)" [disabled]="hideReject(data.status)">Reject</button>
                                        <button class="download-file" matTooltip="Download File" (click)="downloadFile(data)" *ngIf="selected_File_Type == 'properties'">
                                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="19" height="19" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><g id="Solid"><path d="m239.029 384.97a24 24 0 0 0 33.942 0l90.509-90.509a24 24 0 0 0 0-33.941 24 24 0 0 0 -33.941 0l-49.539 49.539v-262.059a24 24 0 0 0 -48 0v262.059l-49.539-49.539a24 24 0 0 0 -33.941 0 24 24 0 0 0 0 33.941z" fill="#3fc93c" data-original="#000000" class=""></path><path d="m464 232a24 24 0 0 0 -24 24v184h-368v-184a24 24 0 0 0 -48 0v192a40 40 0 0 0 40 40h384a40 40 0 0 0 40-40v-192a24 24 0 0 0 -24-24z" fill="#3fc93c" data-original="#000000" class=""></path></g></g></svg>
                                         </button>
                                </td>
                            </tr>
                        </tbody>
                        <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
                    </table>
                </ng-container>
            </div>
            <mat-paginator *ngIf="selected_File_Type" [length]="total" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageAction($event)">
            </mat-paginator>
        </mat-card-content>
    </mat-card>
    <app-legacy-component *ngIf="showConflict"></app-legacy-component>
</div>
