import { Component, OnInit, Output, Input, EventEmitter, ViewChild, NgZone } from "@angular/core";
import { Map } from '../../../map_api/map';
import { map, startWith } from 'rxjs/operators';
import { ApiserviceService } from '../../../../apiservice.service';
import { NotificationService } from '../../../services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { CommonfunctionService } from '../../../../services/commonfunction.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { LatLon } from '../../../map_api/latlon';
import { Observable } from 'rxjs';
import { ConfirmDialogBox } from '../../../confirm-dialog/confirm-dialogbox';
import {environment} from '../../../../../environments/environment'
import { Address } from "../../models/address";
export class State {
  constructor(public name: string) { }
}

@Component({
  selector: 'app-parcel-map',
  templateUrl: './parcel-map.component.html',
  styleUrls: ['./parcel-map.component.css']
})
export class ParcelMapComponent implements OnInit {
  google: any;
  // map: Map;
  map: Map;
  marker: any;
  enableEdit: boolean = false;
  loader: boolean = false;
  loader_map: boolean = false;
  property_status: any = '';
  location: any = {
    lat: '',
    lon: ''
  };
  building_name: any;
  available_address_list: any = [];
  @ViewChild('gmap') gmapElement: any;

  assignmentName: any;
  selected_addr: any = null;
  blockInfo: any;
  streetObj: any = [];
  laneObj: any = [];
  streetList: any = [];
  laneList: any = [];
  streetdata: Observable<any[]>;
  lanedata: Observable<any[]>;
  lanes: State[] = [];
  streets: State[] = [];
  formReady: boolean = false;
  alert: boolean = false;
  displayAddress: Address;
  selected_property_id: any;
  data: any = {
    details: null
  }
  @Output() sidePNavToggle = new EventEmitter();
  @Input() parent: any;
  @Input() rowData: any;
  @Input() selectedAssignment: any = null;
  selectionMode: boolean = false;
  selected_parcel: any;
  parcel_uids_list: any = [];
  selected_building_uid: any;
  //for polygon drawing
  drawingManager: any;

  selectedParcelPolygon: any;
  poly: any = null;
  circle: any = null;
  polygon: any = [];
  polygons: any = [];
  current_polygon: any = null;
  mode: boolean = false;
  _map: any;
  toggleFilters: boolean = true;
  public geoData: any = [];
  parcelLoader: boolean;

  constructor(private fb: FormBuilder, private api: ApiserviceService,
    private notify: NotificationService,
    private _zone: NgZone, public dialog: MatDialog,
    private fnc: CommonfunctionService,
    iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon('circle', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/dots-circle.svg'))
      .addSvgIcon('polygon', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/line-path.svg'));
    const city = JSON.parse(localStorage.getItem('city_detail'));


  }

  ngAfterViewInit() {
    // this.setInitialValues();
  }

  ngOnChanges() {
    this.setInitialValues()
    // this.enableEdit = false;
    // this.property_status = null;
    // if (this.selected_property_id != this.rowData.id && this.selected_property_id) {
    //   this.selectionMode = false;
    //   this.setInitialValues(true);
    // } else {
    //   this.setInitialValues();
    // }
    // if (!this.selectedAssignment) {
    //   this.selectedAssignment = this.parent.selected_assignment;
    // } else {
    //   this.parent.selectedPolygons = [];
    // }
  }

  // drawCircle() {
  //   if (this.mode == null) {
  //     this.drawingManager.setMap(this.map.map);
  //     this.selectionMode = false;
  //     this.mode = 'Circle';
  //     this.map.removeAllMarkers();
  //     this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE);
  //   } else {
  //     this.mode = null;
  //     this.panToLocation();
  //     this.clearDrawing(true);
  //   }
  // }

  // drawPolygon() {
  //   if (this.mode == null) {
  //     this.drawingManager.setMap(this.map.map);
  //     this.selectionMode = false;
  //     this.mode = 'Polygon';
  //     this.map.removeAllMarkers();
  //     this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
  //   } else {
  //     this.mode = null;
  //     this.panToLocation();
  //     this.clearDrawing(true);
  //   }
  // }

  // clearDrawing(clear_all = false) {
  //   if (!this.circle && !this.poly) {
  //     this.drawingManager.setMap(this.map.map);
  //   }
  //   if (clear_all) {
  //     if (this.selectedParcelPolygon) {
  //       this.selectedParcelPolygon = null;
  //     }
  //     this.current_polygon = null;
  //     this.selectionMode = false;
  //     this.renderMap();
  //     if (this.circle) {
  //       this.circle.setMap(null);
  //     }
  //     if (this.poly) {
  //       this.poly.setMap(null);
  //     }

  //   }
  // }

  // toggleSelection() {
  //   this.selectionMode = !this.selectionMode;
  //   if (this.selectionMode) {
  //     //remove all markers
  //     this.map.removeAllMarkers();
  //     this.map.addEventListener('click', (e) => {
  //       this.getParcelInfo(e.latlon);
  //     });
  //   } else {
  //     this.panToLocation();
  //     if (this.parent.selectedPolygons.length) {
  //       this.parent.selectedPolygons = [];
  //       this.parent.getProperties();
  //     }
  //   }
  // }

  // getParcelInfo(location) {
  //   this.parent.selectedPolygons = [];
  //   if (this.selectionMode) {
  //     this.loader_map = true;
  //     let url = "address/json?categories=parcel&lat=" + location.lat + "&lon=" + location.lon + "&return_geometry=true";
  //     this.api.getGisData(url)
  //       .subscribe((data: any) => {
  //         this.loader_map = false;
  //         if (data.length) {
  //           this.selected_parcel = data[0];
  //           this.selectedParcelPolygon = this.selected_parcel.geom;
  //           this.parent.selectedPolygons.push(this.selectedParcelPolygon);
  //           this.parent.getProperties();
  //           this.parent.getDataForPagin()
  //         }
  //       }, err => {
  //         this.loader_map = false;
  //       })
  //   }
  // }

  getparcels() {
    let url = this.rowData.building_gis_uid + "/parcels";
    this.parcelLoader=true;
    this.api.getGpsData(url)
      .subscribe({next: (res: any) => {
        this.parcelLoader=false;
        if (res && res.length) {
          this.geoData = res;
          this.geoData = this.fnc.uniqueObject('parcel_uid',this.geoData);
          this.geoData.forEach((elm) => {
            let geomdata = this.convertToArray(elm.geom);
            this.drawPolygon(geomdata, elm);
          })
        }
      }, error: (err) => {
        this.parcelLoader=false;
        this.notify.notify(`GIS Error - ${err.error.message}`, 'error', 10000)
      }})
  }
  setInitialValues(locationChange = false) {
    this.selectionMode = false;
    this.property_status = this.parent.property_status;
    this.assignmentName = this.parent.selected_assignment.name;
    this.building_name = this.rowData.own_address.building_name
    this.location.lat = this.rowData.lat;
    this.location.lon = this.rowData.lon;
    this.selected_property_id = this.rowData.id;
    this.selected_building_uid = null;
    this.parcel_uids_list = [];
    this.geoData = [];
    this.getparcels();
    if (locationChange) {
      this.panToLocation();
    } else {
      setTimeout(() => {
        this.renderMap();
      }, 300);
    }

  }


  panToLocation() {
    let location_obj: any = new LatLon(this.location.lat, this.location.lon, 0);
    //remove all markers
    this.map.removeAllMarkers();
    this.map.panTo(location_obj);
    this.map.addMarker(new LatLon(this.location.lat, this.location.lon, 0),
      {
        'iconUrl': '../assets/icon/Pin.svg',
        'iconWidth': 50,
        'iconHeight': 50,
        'dragend': (e) => {
          this._zone.run(() => {
            if (this.enableEdit) {
              this.location.lat = e.latlon.getLat();
              this.location.lon = e.latlon.getLon();
              this.checkParcel(this.location);
              //this.getLocationInfo(this.location);
            }
          });
        }
      }, this.enableEdit);
  }

  renderMap() {
    let location_obj: any = new LatLon(this.location.lat, this.location.lon, 0);
    let _map: any = null;
    let mapProp = {
      center: new LatLon(this.location.lat, this.location.lon, 0),
      zoom: 19,
      zoomControl: false,
      scaleControl: false,
      streetViewControl: false,
      mapTypeControl: true,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT
      },
      mapTypeId: google.maps.MapTypeId.HYBRID,
      styles: [
        {
          featureType: "road",
          stylers: [
            { visibility: "off" }
          ]
        }
      ],
      labels: true,
      element: this.gmapElement.nativeElement
    };
    this.map = new Map(mapProp);
    _map = this.map.map; //map object to be used for Drawing polygons and circles
    this._map = _map;

    //remove all markers
    // this.map.removeAllMarkers();
    // this.map.panTo(location_obj);

    this.map.addMarker(new LatLon(this.location.lat, this.location.lon, 0),
      {
        'iconUrl': '../assets/icon/Pin.svg',
        'iconWidth': 50,
        'iconHeight': 50,
        'dragend': (e) => {
          this._zone.run(() => {
            if (this.enableEdit) {
              this.location.lat = e.latlon.getLat();
              this.location.lon = e.latlon.getLon();
              this.checkParcel(this.location);
              //this.getLocationInfo(this.location);
            }
          });
        }
      }, this.enableEdit);

    // adding parcel layer
    // this.map.addLayer({
    //   'format': 'wms',
    //   'layerName': 'parcels',
    //   'name': 'parcels',
    //   'url': this.api.wms_url,
    //   'assignment': this.parent.selected_assignment.id
    // });

    // geoserver

    this.map.addGeoLayer({
      'format': 'wms',
      'layerName': 'parcels',
      'name': 'parcels',
      'url': environment.geoestater+'wms-layer',
      'assignment': this.parent.selected_assignment.id,
      'city_code': this.api.city_code,
      'city_id': this.api.city_id,
      'user_token': environment.user_token
    });


    // //adding properties layer
    // this.map.addLayer({
    //   'format': 'wms',
    //   'layerName': 'properties',
    //   'name': 'properties',
    //   'url': this.api.wms_url,
    //   'assignment': this.parent.selected_assignment.id
    // });

    // if (this.current_polygon) {
    //   let poly = new google.maps.Polygon({
    //     paths: this.getgeom(this.current_polygon),
    //     strokeColor: '#FF0000',
    //     strokeOpacity: 0.8,
    //     strokeWeight: 1,
    //     fillColor: '#FF0000',
    //     fillOpacity: 0.35
    //   });
    //   poly.setMap(this.map.map);
    //   this.getBound(poly);
    //   google.maps.event.addListener(poly, 'click', (e) => {
    //     this.parent.selectedPolygons = [];
    //     this.selectedParcelPolygon = this.current_polygon;
    //     this.parent.selectedPolygons.push(this.selectedParcelPolygon);
    //     this.parent.getProperties();
    //   });

    // }

    // //map drawing functions and events===================================================================================
    // this.drawingManager = new google.maps.drawing.DrawingManager({
    //   drawingMode: google.maps.drawing.OverlayType.MARKER,
    //   drawingControl: false,
    //   circleOptions: {
    //     strokeColor: '#F1AE65',
    //     strokeOpacity: 0.8,
    //     strokeWeight: 1,
    //     fillColor: '#F1AE65',
    //     fillOpacity: 0.35,
    //     editable: false,
    //     draggable: false
    //   },
    //   polygonOptions: {
    //     editable: false,
    //     draggable: false
    //   }
    // });

    // google.maps.event.addListener(this.drawingManager, 'circlecomplete', (circle) => {
    //   let radius = circle.getRadius();
    //   let clat = circle.getCenter().lat();
    //   let clng = circle.getCenter().lng();
    //   let position = new google.maps.LatLng(clat, clng);
    //   _map.setCenter(position);
    //   _map.fitBounds(circle.getBounds());
    //   this.circle = circle;
    //   const numberOfEdges = 32;

    //   let polygonc = this.generateGeoJSONCircle(position, radius, numberOfEdges);
    //   // this.current_polygon = polygonc.coordinates;
    //   this.current_polygon = 'POLYGON((' + polygonc.coordinates.toString() + '))';
    //   this.renderMap();
    // });

    // google.maps.event.addListener(this.drawingManager, 'polygoncomplete', (polygon) => {
    //   let polygonBounds = polygon.getPath();
    //   let pBounds = polygon.getPaths();
    //   this.poly = polygon;
    //   let coordinates = [];
    //   for (let i = 0; i < polygonBounds.length; i++) {
    //     coordinates.push([polygonBounds.getAt(i).lng() + ' ' + polygonBounds.getAt(i).lat()]);
    //   }
    //   coordinates.push(coordinates[0]);
    //   // this.current_polygon = [coordinates];
    //   this.current_polygon = 'POLYGON((' + [coordinates].toString() + '))';
    //   this.renderMap();
    // });

    // google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event) => {
    //   this.drawingManager.setDrawingMode(null);
    //   if (event.type == 'circle') {
    //     let radius = event.overlay.radius;
    //     let center = { lat: event.overlay.center.lat() };
    //   }
    // });

    //map drawing functions and events===================================================================================
  }

  public drawPolygon(geom, data) {
   
    let _this = this;

    let options = {
      paths: geom,
      strokeColor: '#6d6a6a',
      strokeOpacity: 1,
      strokeWeight: 1,
      fillColor: "transparent",
      _data: data
    };

    let poly = new google.maps.Polygon(options);
    this.poly = poly;
    this.poly.setMap(this._map);

    // google.maps.event.addListener(poly, "mouseover", function () {
    //   this.setOptions({ fillColor: "#00FF00" });
    // });
    // google.maps.event.addListener(poly, "mouseout", function () {
    //   this.setOptions({ fillColor: "transparent" });
    // });
    google.maps.event.addListener(poly, "click", function () {
      _this.selected_building_uid = _this.rowData.building_gis_uid;
      if (_this.parcel_uids_list.indexOf(poly["_data"].parcel_uid) == -1) {
        this.setOptions({ fillColor: "#00FF00" });
        _this.parcel_uids_list.push(poly["_data"].parcel_uid);
      } else {
        this.setOptions({ fillColor: "transparent" });
        _this.parcel_uids_list.splice(_this.parcel_uids_list.indexOf(poly["_data"].parcel_uid), 1)
      }
      //_this.getBound(poly);

    });
  }

  getBound(polygon) {
    var bounds = new google.maps.LatLngBounds();
    var paths = polygon.getPaths();
    var path;
    for (var i = 0; i < paths.getLength(); i++) {
      path = paths.getAt(i);
      for (var ii = 0; ii < path.getLength(); ii++) {
        bounds.extend(path.getAt(ii));
      }
    }
    this._map.fitBounds(bounds);
  }

  public convertToArray(geom) {
    let geomArr = [];
    let temp = [];
    let newArr = [];
    geom = geom.replace('MULTIPOLYGON', '');
    geom = geom.replace('(((', '');
    geom = geom.replace(')))', '');
    geom = geom.replace('(', '');
    geom = geom.replace(')', '');
    geom = geom.replace('(', '');
    geom = geom.replace(')', '');
    geomArr = geom.split(',');
    geomArr.forEach(latlng => {
      newArr.push([latlng]);
    });

    newArr.forEach(el => {
      let s = el[0].split(' ');
      temp.push({ "lat": +s[1], "lng": +s[0] });
    });
    return temp;
  }

  getgeom(data) {
    if (data != null) {
      let str = data;
      let result = str.match(/\(\((.*)\)\)/);
      let str2 = result[1];
      let latlon = str2.split(',');

      let temp = [];
      latlon.forEach(el => {
        let s = el.split(' ')
        temp.push({ "lat": +s[1], "lng": +s[0] });
      });
      return temp;
    }
  }


  openConfirmation(mode) {
    this.mode = mode;
    if (mode) {
      if (this.parcel_uids_list.length == 0) {
        this.notify.notify("Please select parcel", "warn");
        return;
      } else if (this.parcel_uids_list.length == 1) {
        this.notify.notify("Please select more than one parcel", "warn");
        return;
      } else if (this.selected_building_uid == null) {
        this.notify.notify("Building Uid can not be null", "warn");
        return;
      }
    } else {
      if (this.parcel_uids_list.length == 0) {
        this.notify.notify("Please select a building parcel to de-merge?", "warn");
        return;
      }
    }
    let _data = {
      parent_data: this,
      message: (mode === true) ? 'Do you wish to merge parcels?' : 'Do you wish to de-merge parcels?',
      merge: mode
    }
    const dialogRef = this.dialog.open(ConfirmDialogBox, {
      width: '350px',
      data: _data
    });
    dialogRef.afterClosed().subscribe(result => {
      
    });
  }

  public confirmDialogYes(check) {
    if (check == 'YES') {
      let selected_data = {
        "building_uid": this.selected_building_uid,
        "parcel_uids": this.parcel_uids_list.toString(),
        "action_by": this.api.user_id
      }
      if (this.mode == false) {
        selected_data['status'] = -1;
      }
      this.api.postGpsData('parcels/association', selected_data).subscribe({next: (res) => {
        if (res) {
          if (this.mode) {
            this.notify.notify("Parcel merged successfully", "success");
          } else {
            this.notify.notify("Parcel de-merged successfully", "success");
          }
        } else {
          if (this.mode) {
            this.notify.notify("Parcel merge unsuccessful", "warn");
          } else {
            this.notify.notify("Parcel de-merge unsuccessful", "warn");
          }

        }
      }, error: (err) => {
        this.notify.notify(this.fnc.getErrorMessage(err), "error");
      }})
    }
  }
  //convert circle to polygon function
  // generateGeoJSONCircle(center: any, radius: any, numSides: any) {
  //   var points = [],
  //     degreeStep = 360 / numSides;
  //   for (var i = 0; i < numSides; i++) {
  //     var gpos = google.maps.geometry.spherical.computeOffset(center, radius, degreeStep * i);
  //     points.push([gpos.lng() + ' ' + gpos.lat()]);
  //   };

  //   // Duplicate the last point to close the geojson ring
  //   points.push(points[0]);
  //   return {
  //     type: 'Polygon',
  //     coordinates: [points]
  //   };
  // }

  checkParcel(loc) {
    this.loader_map = true;
    let url = 'place/location', body = {
      location1: {
        lat: this.rowData.lat,
        lon: this.rowData.lon
      },
      location2: {
        lat: this.location.lat,
        lon: this.location.lon
      },
      scope: 'parcel'
    };
    this.api.postGmsData(url, body).subscribe({next: (res: any) => {
      if (res.match == true) {
        this.getLocationInfo(loc);
      } else {
        this.loader_map = false;
        this.notify.notify("You can't move property outside of its parcel", 'warn');
      }
    }, error: (err) => {
      this.loader_map = false;
      this.notify.notify('Unable to check parcel', 'warn');
    }});
  }


  getLocationInfo(loc) {
    this.getStreets(loc);
    this.getLanes(loc);
    let url = "address/json?categories=parcel&lat=" + loc.lat + "&lon=" + loc.lon + "&return_geometry=true";
    this.api.getGesData(url)
      .subscribe({next: (data: any) => {
        if (data.length) {
          this.alert = false;
          this.loader_map = false;
          if (data.length == 1) {
            let val = data[0];
            this.selected_addr = val;
          } else {
            this.available_address_list = data;
            this.loader_map = false;
            //this.openDialog();
          }
        } else {
          this.getNeighInfo(loc);
        }
      }, error:(err) => {
        this.notify.notify(`GIS Error - ${err.error.message}`, 'error', 10000)
        this.getNeighInfo(loc);
      }})
  }

  getStreets(loc) {
    // for street
    let filtered = "streets/json?categories=street,major road,highway,roundabout&radius=200&lat=" + loc.lat + "&lon=" + loc.lon + "&return_geometry=false";
    this.api.getGpsData(filtered)
      .subscribe({next:(data: any) => {
        data.forEach((v) => {
          this.streetObj.push(v);
          if (v.street_name) {
            if (this.streetList.indexOf(v.street_name) == -1) {
              this.streetList.push(v.street_name);
            }
          }
        });
        this.setStreetSuggestion();
      },error : (err)=>{
        this.notify.notify(`GIS Error - ${err.error.message}`, 'error', 10000)
      }});
  }
  getLanes(loc) {
    // // for lane suggestion
    let filtered = "streets/json?categories=lane&radius=200&lat=" + loc.lat + "&lon=" + loc.lon + "&return_geometry=false";
    this.api.getGpsData(filtered)
      .subscribe({next: (data: any) => {
        data.forEach((v) => {
          this.laneObj.push(v);
          if (v.street_name) {
            if (this.laneList.indexOf(v.street_name) == -1) {
              this.laneList.push(v.street_name);
            }
          }
        })
        this.setLaneSuggestion();
      },error: (err)=>{
        this.notify.notify(`GIS Error - ${err.error.message}`, 'error', 10000)
      }});
  }
  getNeighInfo(loc) {
    let filtered = "address/json?categories=block&lat=" + loc.lat + "&lon=" + loc.lon + "&return_geometry=true";
    this.api.getGesData(filtered)
      .subscribe({next: (data: any) => {
        if (data) {
          this.alert = false;
          this.blockInfo = data[0];
          this.selected_addr = this.blockInfo;
        }
        this.loader_map = false;
      }, error: (err) => {
        this.notify.notify(`GIS Error - ${err.error.message}`, 'error', 10000)
        this.alert = true;
        //this.openDialog();
        this.selected_addr = null;
        this.loader_map = false;
      }})
  }

  edit(opt?) {
    this.enableEdit = !this.enableEdit;
    if (this.enableEdit) {
      this.getStreets(this.location);
      this.getLanes(this.location);
      this.setStreetSuggestion();
      this.setLaneSuggestion();
      this.toggleFilters = false;
    } else {
      this.toggleFilters = true;
    }
    this.panToLocation();
    // this.renderMap();
    if (opt) {
      this.loader_map = false;
    }
  }
  setLaneSuggestion() {
    this.lanes = [];
    this.laneList.forEach((v) => {
      this.lanes.push({
        'name': v
      });
    });
  }
  setStreetSuggestion() {
    this.streets = [];
    this.streetList.forEach((v) => {
      this.streets.push({
        'name': v
      });
    });
  }
  currentLane(name: string) {
    return this.lanes.filter(state =>
      state.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }
  currentStreet(name: string) {
    return this.streets.filter(state =>
      state.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  ngOnInit() {
  }

  toggle() {
    if (this.parent.selectedPolygons.length) {
      this.parent.selectedPolygons = [];
      this.parent.getProperties();
    }
    this.sidePNavToggle.emit();
  }
}
