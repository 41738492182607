import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ApiserviceService } from '../../../../apiservice.service';
import { CommonfunctionService } from '../../../../services/commonfunction.service';
import { ImageEditorComponent } from "../../../admin-profile/image-editor/image-editor.component";
import { NotificationService } from '../../../services/notification.service';

declare var $: any;

export interface IImage {
  url: string | null;
  href?: string;
  clickAction?: Function;
  caption?: string;
  title?: string;
  backgroundSize?: string;
  backgroundPosition?: string;
  backgroundRepeat?: string;
}


/**
*
* <strong>List of API using</strong>
* <ol>
* <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_units_unit_id_patch</li>
* <li>estapi_module_type_assignment_name_properties_property_id_floors_floor_id_patch</li>
* <li>estapi_module_type_assignment_name_properties_property_id_patch</li>
* <li>estapi_module_type_images_post</li>
* <li>estapi_module_type_videos_post</li>
* </ol>
*
*/

@Component({
  selector: 'gallery-dialog',
  templateUrl: './gallery-dialog.component.html',
  styleUrls: ['./gallery-dialog.component.scss']
})

export class GalleryDialog {
  cellData: any;
  mode: any;
  imageGallery: IImage[] = [];
  videoGallery: any = [];

  selectedSource: string = '';
  currentImageUrl: string = '';

  count: number = 0;
  loader: boolean = false;
  showConfirm: boolean = false;
  addImageDiv: Boolean = false;
  addVideoDiv: Boolean = false;
  imageForm: FormGroup;
  photoList: any = [];
  videoList: any = [];
  uploading: boolean = false;
  image_access: any = {
    'GET': true,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  video_access: any = {
    'GET': true,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  @ViewChild('imageInput') imageInput;

  @ViewChild('video', { static: true }) videoElem: any;
  @ViewChild('slideshow', { static: true }) slideshow: any;
  adddescrip: boolean;
  description:string='Estater Image';
  result: any;
  unique_id: any;
  openDescriptionBox: boolean;
  current_slide=''
  constructor(public dialogRef: MatDialogRef<GalleryDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private fb: FormBuilder, private api: ApiserviceService,
    private fnc: CommonfunctionService,
    private elementRef: ElementRef,
    private notify: NotificationService,
    private route: Router) {
    this.getAllowResourcesApi();
    this.photoList = [];
    this.videoList = [];
    this.cellData = this.data.cellData;
    this.mode = this.data.mode;

    if (this.cellData.data && this.mode == 'images') {
      this.cellData.data.forEach(img => {
        this.imageGallery.push({ url: img[0], caption: img[1] })
      });
    }
    if (this.cellData.data && this.mode == 'videos') {
      this.cellData.data.forEach(vid => {
        this.videoGallery.push({ url: vid[0] })
      });
    }
    if (this.videoGallery.length) {
      let obj = this.videoGallery[0];
      if (obj) this.selectedSource = obj.url;
    }
    if (this.imageGallery.length) {
      let obj = this.imageGallery[0]
      this.currentImageUrl = obj.url;
    }

    this.imageForm = this.fb.group({
      'image': [],
      'video': [],
      'description': [],
      'isDefault': []
    });

  }



  ngAfterViewInit() {
    /**
     * slider initlization jQuery with customizetion
     */
    if(this.videoGallery?.length || this.imageGallery?.length){
      $(".slide").carousel({
        interval: false,
        wrap: false
      });
      document.getElementById("scroll_active").addEventListener("click", function () {
        scroll();
      });
      $('.carousel').bind('slide.bs.carousel', function (e) {
        scroll();
      });

      function scroll() {
        setTimeout(() => {
          var listarry = "";
          var listItem = $('.carousel-indicators li').length;
          for(let i =0; i <= listItem; i++){
            listarry = $('.carousel-indicators li')[i];
            if($(listarry).hasClass('active')){
              if($(listarry).data('slide-to') == i){
                $('.carousel-indicators').animate({
                  scrollLeft: 75 * i
                }, 500);
                if(i == 0){
                  $('.carousel-control-prev').addClass('hide');
                  $('.carousel-control-next').removeClass('hide');
                }
                else if(listItem - 1 == i){
                  $('.carousel-control-prev').removeClass('hide');
                  $('.carousel-control-next').addClass('hide');
                }
                else{
                  $('.carousel-control-prev').removeClass('hide');
                  $('.carousel-control-next').removeClass('hide');
                }
              }
            }
          }
        }, 500);
      }

    }

  }

  close(){
    this.dialogRef.close();
  }

  getAllowResourcesApi() {
    // console.log("imageapi")
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body)
      .subscribe({next: (res: any) => {
        this.api.allowResources = res.data;
        this.getAllowResource();
      }, error : (err) => {
      }})
  }
  getAllowResource() {
    // console.log("image")
    this.image_access = this.fnc.checkResourceAccess('images', false);
    this.video_access = this.fnc.checkResourceAccess('videos', false);
  }
  playSelected(url) {
    this.selectedSource = url;
    this.videoElem.nativeElement.load();

  }

  /**
   * add Description
   * @param slide
   */
  add_description(slide){
    if(this.cellData.row.status == '5'){
      return;
    }
    if (!this.image_access.PATCH) {
      this.notify.notify("User Not Authorized to update Image", 'warn', 5000);
      return;
    }
    this.current_slide = slide.url;
    this.description = slide.caption;
    this.openDescriptionBox=true;
  }

  uploadDescription(flag){
    if(flag){
      let newData=[];
      this.imageGallery.map(ele=>{
        if(ele.url == this.current_slide){
          newData.push({'0': ele.url, '1': this.description ,'2': false})
        }else{
          newData.push({'0': ele.url, '1': ele.caption ,'2': false})
        }
      })

      let data = {}, body = {}, info;

      if (this.data.parent.dataSource) {
        info = this.fnc.getArrayValue('uid', this.data.cellData.row.uid, this.data.parent.dataSource)
      }
      let attribute_info=this.data.cellData.row.attribute_info? this.data.cellData.row.attribute_info:{};
      attribute_info[this.data.cellData.id] = {
        status: 2,
        comment: ''
      }
      body["attribute_info"] = attribute_info;

      data["0"] = { [this.data.cellData.id]: newData };
      body["data"] = data;
      body["updated_by"] = this.api.user_id;
      body["assignment_id"] = this.data.parent.selected_assignment.assignment_id;

      let url = "";
      if (this.data.parent.entity_type == 'property') {
        url = "deadmin/" + this.data.parent.selected_assignment.name + "/properties/" + info.property_id;
      }
      if (this.data.parent.entity_type == 'property_floor') {
        url = "deadmin/" + this.data.parent.selected_assignment.name + "/properties/" + this.data.parent.selected_property.id + "/floors/" + info.id;
      }
      if (this.data.parent.entity_type == 'property_unit') {
        url = "deadmin/" + this.data.parent.selected_assignment.name + "/properties/" + this.data.parent.selected_property.id + "/floors/" + this.data.parent.selected_floor.id + "/units/" + info.id;
      }
      this.loader = true;
      this.api.patchEpsData(url, body)
        .subscribe({next: (data: any) => {
          this.openDescriptionBox=false;
          this.loader = false;
          this.description = 'Estater Image';
          this.current_slide=''
          this.data.parent.notify.notify("Image description updated successfully", 'success');
          if (this.data.parent.entity_type == 'property') {
            this.data.parent.getProperties();
          }
          if (this.data.parent.entity_type == 'property_floor') {
            this.data.parent.getFloors();
          }
          if (this.data.parent.entity_type == 'property_unit') {
            this.data.parent.getUnits();
          }
          if (this.fnc.jsonLength(newData)) {
            let arr = [];
            newData.forEach(e => {
              arr.push({ url: e[0], caption: e[1] })
            });
            this.imageGallery = arr;
            this.data.cellData.data = newData
            //this.currentImageUrl = arr[this.imageGallery.length - 1].url;
          } else {
            this.imageGallery = [];
          }

          this.showConfirm = false;
          // this.dialogRef.close();
        },
          error: (err) => {
            this.loader = false;
            this.data.parent.notify.notify(this.fnc.getErrorMessage(err), 'error');
          }});

    }else{
      this.description = 'Estater Image';
      this.openDescriptionBox=false;
    }
  }

  //delete image
  deleteImage() {
    if (!this.image_access.DELETE) {
      this.notify.notify("User Not Authorized to DELETE Image", 'warn', 5000);
      return;
    }
    this.loader = true;
    let data = {}, body = {}, info, newData = this.fnc.getDeletedArray('0', this.currentImageUrl, this.data.cellData.data);
    if (this.data.parent.dataSource) {
      info = this.fnc.getArrayValue('uid', this.data.cellData.row.uid, this.data.parent.dataSource)
    }
    let attribute_info=this.data.cellData.row.attribute_info?this.data.cellData.row.attribute_info:{};
      attribute_info[this.data.cellData.id] = {
        status: 2,
        comment: ''
      }
      body["attribute_info"] = attribute_info;

    data["0"] = { [this.data.cellData.id]: newData };
    body["data"] = data;
    body["updated_by"] = this.api.user_id;
    body["assignment_id"] = this.data.parent.selected_assignment.assignment_id;
    let url = "";
    if (this.data.parent.entity_type == 'property') {
      url = "deadmin/" + this.data.parent.selected_assignment.name + "/properties/" + info.property_id;
    }
    if (this.data.parent.entity_type == 'property_floor') {
      url = "deadmin/" + this.data.parent.selected_assignment.name + "/properties/" + this.data.parent.selected_property.id + "/floors/" + info.id;
    }
    if (this.data.parent.entity_type == 'property_unit') {
      url = "deadmin/" + this.data.parent.selected_assignment.name + "/properties/" + this.data.parent.selected_property.id + "/floors/" + this.data.parent.selected_floor.id + "/units/" + info.id;
    }

    this.api.patchEpsData(url, body)
      .subscribe({next: (data: any) => {
        this.data.parent.notify.notify("Image has been deleted", 'success');
        if (this.data.parent.entity_type == 'property') {
          this.data.parent.getProperties();
        }
        if (this.data.parent.entity_type == 'property_floor') {
          this.data.parent.getFloors();
        }
        if (this.data.parent.entity_type == 'property_unit') {
          this.data.parent.getUnits();
        }
        if (this.fnc.jsonLength(newData)) {
          let arr = [];
          newData.forEach(e => {
            arr.push({ url: e[0], caption: e[1] })
          });
          this.imageGallery = arr;
          this.data.cellData.data = newData
          this.currentImageUrl = arr[this.imageGallery.length - 1].url;
        } else {
          this.imageGallery = [];
        }
        this.loader = false;
        this.showConfirm = false;
        // this.dialogRef.close();
      },
        error: (err) => {
          this.loader = false;
          this.data.parent.notify.notify(this.fnc.getErrorMessage(err), 'error');
        }});
  }

  //delete video
  deleteVideo() {
    if (!this.video_access.DELETE) {
      this.notify.notify("User Not Authorized to DELETE Videos", 'warn', 5000);
      return;
    }
    this.loader = true;
    let data = {}, body = {}, info, newData = this.fnc.getDeletedArray('0', this.selectedSource, this.data.cellData.data);
    if (this.data.parent.dataSource) {
      info = this.fnc.getArrayValue('uid', this.data.cellData.row.uid, this.data.parent.dataSource)
    }
    let attribute_info=this.data.cellData.row.attribute_info?this.data.cellData.row.attribute_info:{};
      attribute_info[this.data.cellData.id] = {
        status: 2,
        comment: ''
      }
      body["attribute_info"] = attribute_info;

    data["0"] = { [this.data.cellData.id]: newData };
    body["data"] = data;
    body["updated_by"] = this.api.user_id;
    body["assignment_id"] = this.data.parent.selected_assignment.assignment_id;
    let url = "";
    if (this.data.parent.entity_type == 'property') {
      url = "deadmin/" + this.data.parent.selected_assignment.name + "/properties/" + info.property_id;
    }
    if (this.data.parent.entity_type == 'property_floor') {
      url = "deadmin/" + this.data.parent.selected_assignment.name + "/properties/" + this.data.parent.selected_property.id + "/floors/" + info.id;
    }
    if (this.data.parent.entity_type == 'property_unit') {
      url = "deadmin/" + this.data.parent.selected_assignment.name + "/properties/" + this.data.parent.selected_property.id + "/floors/" + this.data.parent.selected_floor.id + "/units/" + info.id;
    }

    this.api.patchEpsData(url, body)
      .subscribe({next: (data: any) => {
        this.data.parent.notify.notify("Video has been deleted", 'success');
        if (this.data.parent.entity_type == 'property') {
          this.data.parent.getProperties();
        }
        if (this.data.parent.entity_type == 'property_floor') {
          this.data.parent.getFloors();
        }
        if (this.data.parent.entity_type == 'property_unit') {
          this.data.parent.getUnits();
        }
        if (this.fnc.jsonLength(newData)) {
          let arr = [];
          newData.forEach(e => {
            arr.push({ url: e[0] })
          });
          this.videoGallery = arr;
          this.data.cellData.data = newData
        } else {
          this.videoGallery = [];
        }
        this.loader = false;
        this.showConfirm = false;
        // this.dialogRef.close();
      },
        error: (err) => {
          this.loader = false;
          this.data.parent.notify.notify(this.fnc.getErrorMessage(err), 'error');
        }});
  }

  // get current url from image array on right and left swipe or click
  slideChange(direction) {
    let obj;
    if (direction == 'left' && this.count == 0) {
      this.count = this.imageGallery.length - 1;
      obj = this.imageGallery[this.count];
      this.currentImageUrl = obj.url;
    } else if (direction == 'left' && this.count <= this.imageGallery.length - 1) {
      this.count--;
      obj = this.imageGallery[this.count];
      this.currentImageUrl = obj.url;
    }

    if (direction == 'right' && this.count < this.imageGallery.length - 1) {
      this.count++;
      obj = this.imageGallery[this.count];
      this.currentImageUrl = obj.url;
    } else if (direction == 'right' && this.count == this.imageGallery.length - 1) {
      this.count = 0;
      obj = this.imageGallery[this.count];
      this.currentImageUrl = obj.url;
    }
  }

  showModal(action, url) {
    if(this.cellData.row.status == '5'){
      return;
    }
    if (action === 'delele') {
      if(this.mode == 'images'){

        this.currentImageUrl = url;
      }else if(this.mode == 'videos'){

        this.selectedSource = url;
      }
      this.showConfirm = true;
    } else if (action === 'addImage') {
      if (this.imageGallery.length >= 10) {
        this.notify.notify('Image cannot be uploaded more than 10', 'warn');
        return;
      }
      this.addImageDiv = true;
    } else if (action === 'addVideo') {
      if (this.videoGallery.length >= 5) {
        this.notify.notify('Video cannot be uploaded more than 5', 'warn');
        return;
      }
      this.addVideoDiv = true;
    }
  }

  // Image update
  openExplorer(type) {
    if(this.cellData.row.status == '5'){
      return;
    }

    if (type == 'image') {
      if (this.imageGallery.length >= 10) {
        this.notify.notify('Image can not upload more than 10', 'warn');
        return;
      }
      if (!this.image_access.POST) {
        this.notify.notify("User Not Authorized to ADD Images", 'warn');
        return;
      }
      let e = $(this.elementRef.nativeElement);
      e.find("#image_input").click();
    }
    if (type == 'video') {
      if (this.videoGallery.length >= 5) {
        this.notify.notify('Video cannot be uploaded more than 5', 'warn');
        return;
      }
      if (!this.video_access.POST) {
        this.notify.notify("User Not Authorized to ADD Videos", 'warn', 5000);
        return;
      }
      let e = $(this.elementRef.nativeElement);
      e.find("#video_input").click();
    }
  }

/**
   * This is a funtion that use to attach a file and open crop option to edit image

   * @param event event is parameter
   * @returns return void
   */

 uploadExplorImage(event){
  if(this.cellData.row.status == '5'){
    return;
  }
  if (this.imageGallery.length >= 10) {
    this.notify.notify('Image cannot be uploaded more than 10', 'warn');
    return;
  }
  const dialogRef = this.dialog.open( ImageEditorComponent, {
    width:'800px',
    panelClass:'image-croper-dialog',
    data: {info: event, ratio: 4 / 3, size: 1100,  type: 'property'},
    disableClose:true
  })
  dialogRef.afterClosed().subscribe((result: FormData)=>{
    if(result){
      this.result = result;
     this.unique_id =  Math.floor((Math.random() * 10000) + 100);

     let img = {
      imageName: 'a',
      imageURL: 'assets/images/no-img.png',
      id: this.unique_id,
      loader: true,
      description: '',
      isDefault: false
    };

    if (this.photoList.length > 0) {
      this.photoList.splice(0, 0, img);
    } else {
      this.photoList.push(img);
    }
      //this.uploadImages(result)
      this.adddescrip=true;
    }
    this.imageInput.nativeElement.value = '';
  })
}

uploadImagewithdescription(flag){
  if(flag){
    this.adddescrip=false;
    this.uploadImages(this.result)
  } else{
    this.adddescrip=false;
    this.description = "Estater Image";
    this.uploadImages(this.result)
  }
}
  uploadImages(formData) {
    //let fileList: FileList = event.target.files;
    // let formData: FormData = new FormData();
    if (formData) {
      // let file: File = fileList[0];
      // let pattern = /image-*/;
      // if (!file.type.match(pattern)) {
      //   return;
      // }
      // adding image from list
      // let img = {
      //   imageName: 'a',
      //   imageURL: 'assets/images/no-img.png',
      //   id: this.unique_id,
      //   loader: true,
      //   description: '',
      //   isDefault: false
      // };
      // let reader = new FileReader();
      // reader.onload = (event: any) => {
      //   img.imageURL = event.target.result;
      // }
      // reader.readAsDataURL(file);
      // if (this.photoList.length > 0) {
      //   this.photoList.splice(0, 0, img);
      // } else {
      //   this.photoList.push(img);
      // }

      // formData.append('file', file, file.name.replace(/["'\(\)]/g, ""));
      this.uploading = true;
      this.api.postFileUms('uploads/images?folder_name=property_image&city-code=' + this.api.city_id, formData, 'application/json')
        .subscribe({next: (data: any) => {
          this.result=null;
          if (data && typeof data == 'string') {
            data = JSON.parse(data);
            let index = this.fnc.getArrayindex('id', this.unique_id, this.photoList);
            if (index !== null) {
              this.photoList[index].imageURL = data.data;
              this.photoList[index].description = this.description
              setTimeout(() => {
                this.updateImage();
                this.photoList[index].loader = false;
                this.uploading = false;
              }, 150);
            }
          }

        },
          error: (err) => {
            let index = this.fnc.getArrayindex('id', this.unique_id, this.photoList);
            this.photoList[index].loader = false;
            this.uploading = false;
          }}
        );
    }
  }

  updateImage() {
    let body = {}, record = {}, url;
    let img_data = {};

    if (this.cellData.data == null) {
      this.cellData.data = [];
    }
    if (this.photoList[0] && this.photoList[0].imageURL.length < 200) {
      this.cellData.data.push({ '0': this.photoList[0].imageURL, '1': this.photoList[0].description, '2': this.photoList[0].isDefault });
    }


    img_data[this.data.cellData.id] = this.cellData.data;
    record["0"] = img_data;
    record[this.api.language] = {};
    body["data"] = record;
    body["updated_by"] = this.api.user_id;

    let attribute_info=this.data.cellData.row.attribute_info ? this.data.cellData.row.attribute_info : {};
      attribute_info[this.data.cellData.id] = {
        status: 2,
        comment: ''
      }
      body["attribute_info"] = attribute_info;

    if (this.data.parent.entity_type == 'property') {
      url = "deadmin/" + this.data.parent.selected_assignment.name + "/properties/" + this.data.cellData.row.id;
    }
    if (this.data.parent.entity_type == 'property_floor') {
      url = "deadmin/" + this.data.parent.selected_assignment.name + "/properties/" + this.data.parent.selected_property.property_id + "/floors/" + this.data.cellData.row.id;
    }
    if (this.data.parent.entity_type == 'property_unit') {
      url = "deadmin/" + this.data.parent.selected_assignment.name + "/properties/" + this.data.parent.selected_property.property_id + "/floors/" + this.data.parent.selected_floor.id + "/units/" + this.data.cellData.row.id;
    }
    this.api.patchEpsData(url, body)
      .subscribe({next: (data: any) => {
        this.description = "Estater Image";
        this.imageGallery.push({ url: this.photoList[0].imageURL, caption: this.photoList[0].description });
        if (this.data.parent.entity_type == 'property') {
          this.data.parent.getProperties();
        }
        if (this.data.parent.entity_type == 'property_floor') {
          this.data.parent.getFloors();
        }
        if (this.data.parent.entity_type == 'property_unit') {
          this.data.parent.getUnits();
        }
        this.notify.notify('Image Information Updated', 'success');
      },error:()=>{}
    });
  }

  //upload Video
  uploadVideos(event) {
    if(this.cellData.row.status == '5'){
      return;
    }
    if(this.videoGallery.length >= 5){
      this.notify.notify('Video cannot be uploaded more than 5', 'warn');
      return;
    }

    let body = {}, record = {};
    let fileList: FileList = event.target.files;
    let formData: FormData = new FormData();
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let pattern = /video-*/;
      if (!file.type.match(pattern)) {
        ('Not Video');
        return;
      }
      // adding image from list
      let vid = {
        videoName: file.name,
        videoThumbURL: '../../../assets/images/vid.png',
        videoURL: '../../../assets/images/vid.png',
        id: Math.floor((Math.random() * 10000) + 100),
        loader: true,
        description: '',
        isDefault: false
      }
      if (this.videoList.length > 0) {
        this.videoList.splice(0, 0, vid);
      } else {
        this.videoList.push(vid);
      }
      formData.append('file', file, file.name.replace(/["'\(\)]/g, ""));
      this.uploading = true;
      this.api.postFileUms('uploads/videos?folder_name=property_video&city-code=' + this.api.city_id,  formData, 'application/json')
        .subscribe({next: (data: any) => {
          if (data && typeof data == 'string') {
            data = JSON.parse(data);
            let index = this.fnc.getArrayindex('id', vid.id, this.videoList);
            if (index !== null) {
              this.videoList[index].videoURL = data.data;
              setTimeout(() => {
                this.updateVideoList();
                this.videoList[index].loader = false;
                this.uploading = false;
              }, 200);
            }
          }

        },
         error: (err) => {
            this.uploading = false;
          }}
        );
    }
  }

  uploadVideoByUrl() {
    if(this.cellData.row.status == '5'){
      return;
    }
    if(this.videoGallery.length >= 5){
      this.notify.notify('Video cannot be uploaded more than 5', 'warn');
      return;
    }
    let e = $(this.elementRef.nativeElement);
    let vid_url = e.find("#video_url").val();
    vid_url = vid_url;
    if (vid_url) {
      let vid = {
        videoName: 'estVideo',
        videoThumbURL: '../../../assets/images/vid.png',
        videoURL: vid_url,
        id: Math.floor((Math.random() * 10000) + 100),
        loader: false,
        description: '',
        isDefault: false
      }

      if (this.videoList.length > 0) {
        this.videoList.splice(0, 0, vid);
      } else {
        this.videoList.push(vid);
      }
      e.find("#video_url").val('');
      this.updateVideoList();
    }
  }

  updateVideoList() {

    let body = {}, record = {}, url;
    let vid_data = {};

    if (this.data.cellData.data == null) {
      this.data.cellData.data = [];
    }
    this.data.cellData.data.push({ '0': this.videoList[0].videoURL, '1': this.videoList[0].description, '2': this.videoList[0].isDefault });
    vid_data[this.data.cellData.id] = this.data.cellData.data;
    record["0"] = vid_data;
    record[this.api.language] = {};
    body["data"] = record;
    body["updated_by"] = this.api.user_id;
    let attribute_info=this.data.cellData.row.attribute_info ? this.data.cellData.row.attribute_info : {};
      attribute_info[this.data.cellData.id] = {
        status: 2,
        comment: ''
      }
      body["attribute_info"] = attribute_info;

    if (this.data.parent.entity_type == 'property') {
      url = "deadmin/" + this.data.parent.selected_assignment.name + "/properties/" + this.data.cellData.row.id;
    }
    if (this.data.parent.entity_type == 'property_floor') {
      url = "deadmin/" + this.data.parent.selected_assignment.name + "/properties/" + this.data.parent.selected_property.property_id + "/floors/" + this.data.cellData.row.id;
    }
    if (this.data.parent.entity_type == 'property_unit') {
      url = "deadmin/" + this.data.parent.selected_assignment.name + "/properties/" + this.data.parent.selected_property.property_id + "/floors/" + this.data.parent.selected_floor.id + "/units/" + this.data.cellData.row.id;
    }
    this.api.patchEpsData(url, body)
      .subscribe({next: (d: any) => {
        this.notify.notify('Video Information Updated', 'success');
        this.videoGallery.push({ url: this.videoList[0].videoURL });
        if (this.data.parent.entity_type == 'property') {
          this.data.parent.getProperties();
        }
        if (this.data.parent.entity_type == 'property_floor') {
          this.data.parent.getFloors();
        }
        if (this.data.parent.entity_type == 'property_unit') {
          this.data.parent.getUnits();
        }
      },
       error: (err) => {

        }});
  }

  confirm(delele) {
    if (delele == 'image') {
      this.showConfirm = false;
      if (!this.currentImageUrl) { this.data.parent.notify.notify("no image selected", 'warn'); return }
      this.deleteImage();
    } else if (delele == 'video') {
      this.showConfirm = false;
      if (!this.selectedSource) { this.data.parent.notify.notify("no video selected", 'warn'); return }
      this.deleteVideo()
    }
  }
  cancel() {
    this.showConfirm = false;
    this.addImageDiv = false;
    this.addVideoDiv = false;
    this.photoList = [];
    this.videoList = [];
    this.currentImageUrl = ''
    this.selectedSource = ''
  }

  ngOninit() {
  }
}
