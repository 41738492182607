<div class="wide-1">
    <mat-toolbar class="h45p w-100 rel">
        <button mat-icon-button (click)="toggle()">
            <mat-icon>arrow_forward</mat-icon>
        </button>
        <h1 class="mat-h1">Unit Layout </h1>
        <label *ngIf="selected_unit" class="unit-info"> {{selected_unit.uid}}</label>
        <button [disabled]="!selected_unit" class="clear-btn" mat-stroked-button color="primary" (click)="resetFilter()">Reset filter</button>
    </mat-toolbar>

    <div class="w-100 map-form-grid">
        <div class="rel">
            <!-- <toolbox-map class="toolbox-web"></toolbox-map> -->
            <ngx-loading [show]="loader_map" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.8)'}"></ngx-loading>
            <div #map class="map"></div>
        </div>
    </div>
</div>

