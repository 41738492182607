import { Component, ElementRef, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DndDropEvent } from 'ngx-drag-drop';
import { debounceTime } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApiserviceService } from '../../apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { AdminBreadcrumbService } from '../admin-breadcrumb/services/admin-breadcrumb.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { ConfirmDialogBox } from '../confirm-dialog/confirm-dialogbox';
import { Map } from '../map_api/map';
import { NotificationService } from '../services/notification.service';
import { ShareService } from '../services/share.service';
import { CopyAssignmentDialogComponent } from './copy-assignment-dialog/copy-assignment-dialog.component';
import { CreateAssignmentComponent } from './create-assignment.component';
import { PropertyOtherAssignmentComponent } from './property-other-assignment/property-other-assignment.component';
import { ArchiveAssignmentComponent } from './archive-assignment/archive-assignment.component';
interface userlist {
  id: number,
  imageURL: string,
  username: string,
  mail: string,
  contact: string,
  status: number
}
interface masterlist {
  id: number,
  name: string,
  PropertyId: string,
  AssignTo: string,
  PropertyNo: number,
  Users: number,
  lang: any,
  status: number,
  type:string
}
interface propList {
  id: number,
  uid: number,
  property_name: string,
  userId: number,
  imageURL: string,
  username: string,
  buildYear: number,
  category: string,
  address: string
}
interface AssignmentDetails {
  name: string,
  assignment_id: any
}

/**
*
* <strong>List of API using</strong>
* <ol>
* <li>gisapi_neighbourhoods_json_get</li>
* <li>umsapi_user_resource_post</li>
* <li>estapi_count_post</li>
* <li>estapi_assignments_patch</li>
* <li>estapi_module_type_assignment_name_properties_get</li>
* <li>estapi_module_type_entity_attributes_get</li>
* <li>estapi_module_type_entity_attributes_get</li>
* <li>estapi_assignments_get</li>
* <li>umsapi_user_list_post</li>
* <li>estapi_assignments_assignment_id_patch</li>
* <li>estapi_module_type_assignments_name_properties_patch</li>
* <li>estapi_assignments_assignments_deallocate_Post</li>
* </ol>
*
*/

@Component({
  selector: 'master-assignment',
  templateUrl: './master-assignment.component.html',
  styleUrls: ['./master-assignment.component.css']
})

export class MasterAssignmentComponent implements OnInit {
  google: any;
  // map: google.maps.Map;
  dHeight: number;
  createForm: boolean = false;
  createMap: boolean = false;
  index = 0;
  loader1: boolean = false;
  loader2: boolean = false;
  loader3: boolean = false;
  loader5: boolean = false;
  results: boolean = false;
  unassignProploader: boolean = false;
  eventReceived: boolean = false;
  selectedAssignment: any;
  selectedUser: any;
  selected_properties: any = [];
  selected_unassProperties: any = [];
  masterList: Array<masterlist> = [];
  allocatedUserlist: any = [];
  userlist: Array<userlist> = [];
  filteredUserList: Array<userlist> = [];
  searchText = '';
  searchAssignType:string = '';
  selected_year:string = '';
  selectedAssignType:string='';
  resultsList: Array<userlist> = [];
  propertyList: Array<propList> = [];
  property_name_id: number;
  property_image_id: number;
  build_year_id: number;
  category_id: number;
  properties_per_user: number;
  propertyCount: number = 0;
  searchField: FormControl;
  searchAssignment: FormControl;
  searchproperty: FormControl;
  search_input: string = null;
  search_assign_input: string = '';
  search_property_input: string = '';
  searchVal: string;
  selectedUserId: any = [];
  polygons: any = [];
  property_drop = false;
  user_drop = false;
  searchData: any = {};
  unAssigned: boolean = false;
  count_loader: boolean = false;
  assignPropertyToUser: boolean = false;
  checked_status = '1';
  status_list=[{label:'Active', value: '1'},{label:'Inactive', value: '0'},{label:'Archive', value: '-3'}];
  assignment_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  property_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  propertyBlock4: boolean;
  searchUnAssignProperty: FormControl;
  search_unassign_property_input: string;
  filteredPropListPolymain: Array<propList> = [];
  filteredPropListPoly: Array<propList> = [];

  selectAll: boolean = false;
  //counters
  getUnallocatedCount: any;

  public assignment_val_offset = 0;
  public assignment_val_limit = 10;

  public user_id: any;
  public property_val_offset = 0;
  public property_val_limit = 10;
  public loader4 = false;

  public attributeValue = '';
  public propertyUid = '';

  public selectedLimit: number = 20;
  public limtOption: number[] = [10, 20, 100, 500, 1000]

  public neighbourhoods: any = [];
  public selectedNeighUid: any = "";

  yearList=[];
  search_year:string;
  assignment_type = [
    {name:"",label:'All'},
    {name:'general',label:'General'},
    {name:'self_assign',label:'Self Assign'},
    {name:'dc',label:'Data Collection'},
    {name:'em',label:'Estater Meter'},
    {name:'parcel_survey',label:'Parcel Survey'}];

  public statusList: any = [
    { status: '1' },
    { status: '2' },
    { status: '7' },
    { status: '3,4,6' },
    { status: '5' }
  ];
  public statusfilter: string = '1,2,3,4,5,6,7';
  public propertyLimit: number = 10;
  get_assignmt_info_loader: boolean = true;
  qaqc_access:any= {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
   };
  tooltopMessage: string = 'Show disabled';
  alreadyExisting: any;

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, public api: ApiserviceService, private route: Router, private menus: AdminMenusService,
    public dialog: MatDialog, private elementRef: ElementRef, public share: ShareService, private _crumbs: AdminBreadcrumbService,
    private fnc: CommonfunctionService, private notify: NotificationService) {
    this.getYears();
    this.api.getUserRole()
    this.menus.setActive('assignments');
    this._crumbs.clear();
    this._crumbs.addcrumb = { title: 'Assignments' };
    this._crumbs.mobiletitle = 'Assignments';
    this._crumbs.count = 1;
    iconRegistry.addSvgIcon('edit', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/Edit.svg'))
      .addSvgIcon('tick', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/Check.svg'))
      .addSvgIcon('delete', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/delete.svg'))
    this.getNeighbourhoods();
    this.searchAssignment = new FormControl();
    this.searchField = new FormControl();
    this.searchproperty = new FormControl();
    this.searchUnAssignProperty = new FormControl();
    this.searchField.valueChanges
      .pipe(debounceTime(600))
      .subscribe(term => {
        this.search_input = term;
        this.getSearchResults();
      });
    this.searchAssignment.valueChanges
      .pipe(debounceTime(600))
      .subscribe(tag => {
        this.search_assign_input = tag;
        this.getAssignment(false);
      })

    this.searchproperty.valueChanges
    .pipe(debounceTime(600))
      .subscribe(tag => {
        this.search_property_input = tag;
        this.getPropertyByAssignment(this.user_id, false);
      })

    this.searchUnAssignProperty.valueChanges
    .pipe(debounceTime(800))
      .subscribe(tag => {
        this.search_unassign_property_input = tag;
        this.getSearchResultsUnAssProperty();
      });
    this.api.setApiUrl();// reseting url to populate city code
  }
  getNeighbourhoods() {
    this.api.getGpsData('neighbourhoods/json?return_geometry=true')
      .subscribe({next: (data: any) => {
        if (data.length) {
          this.neighbourhoods.push({ 'neigh_uid': '', 'neigh_name': 'All' })
          data.forEach(e => {
            this.neighbourhoods.push(e);
          });
        }
      }, error : (err) => {
        this.notify.notify(`GIS Error - ${err.error.message}`, 'error', 10000)
      }});
  }

  selectNeighbourhoodParent(neigh) {
    this.selectedNeighUid = neigh
    this.getPropertyByAssignment(this.user_id)

  }
  // change limit
  public selectLimit(num) {
    this.searchData.data.unassignproLimit = num;
    this.searchData.data.unassignproOffset = 0;
    this.searchData.data.getProperty(false, true);
  }

  /**
   * Download Property is active in another assignment of selected area
   */
  downloadProperty(){
    let body = this.searchData.body;
    if(body){
      body['get_duplicate_assignment'] = true;
      this.loader2=true;
      this.api.postEpsData('properties-copy', body).subscribe({next: (res:any)=>{
        this.loader2=false;
       if(res && res.length > 0){
        let obj = res[0];
        delete obj['own_address'];
        delete obj['data'];
        let header =  Object.keys(obj);
        let csvJson=[];
        csvJson.push(header);
        let items = [];
        res.map(ele=>{
          delete ele['own_address'];
          delete ele['data'];
          items.push(Object.values(ele))
        })
        csvJson = [...csvJson , ...items];

        let csvContent = '';
        csvJson.forEach(function (rowArray) {
          let row = rowArray.join(',');
          csvContent += row + '\r\n';
        });
        this.fnc.downloadFile('property', csvContent);
       }else{
        this.notify.notify('Property not found', 'warn');
       }
      },error :(err)=>{
        this.loader2=false;
      }})
    }

  }
  onscroll(e) {
    let scroll = document.getElementById('mapProList');
    //console.log(e);
    let isReachingBottom = e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight;
    let isReachingBottom_1 = scroll.scrollTop + scroll.offsetHeight + 20 >= scroll.scrollHeight;

    if ((isReachingBottom || isReachingBottom_1 )&& !this.unassignProploader && this.filteredPropListPoly.length > 3) {
      this.searchData.data.unassignproOffset += this.searchData.data.unassignproLimit;
      this.searchData.data.getProperty(true, false);
    }
  }
  // getting allow resources
  getAllowResourcesApi() {
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
    this.api.getUmsData(body)
      .subscribe({next: (res: any) => {

        this.api.allowResources = res.data;
        this.getAllowResource();
      },error: (err) => {
      }})
  }
  // getAllowResourcesApi() {
  //   let body = "place_id=" + this.api.city_id;
  //   this.api.postUmsData('user/resource', body)
  //     .subscribe(
  //       (data: any) => {
  //         this.api.allowResources = data;
  //         this.getAllowResource();
  //       },
  //       err => {
  //       }
  //     )
  // }
  getAllowResource() {
    this.assignment_access = this.fnc.checkResourceAccess('assignments', false);
    this.property_access = this.fnc.checkResourceAccess('properties', false);
    this.qaqc_access = this.fnc.checkResourceAccess('qa_qc', false);
    if (!this.assignment_access.GET) {
      this.route.navigateByUrl('');
      return;
    }
    this.getAssignment(false);
    //this.getUsers();
  }
  selectionChange(){
    this.selectedAssignment=null;
    this.get_assignmt_info_loader =true;
    // this.userlist=[];
    // this.propertyList=[];
    // this.resultsList =[];
    // this.filteredUserList =[];
    this.getAssignment(false);

  }

   //create year dropdown
   getYears() {
    const currentYear = new Date().getFullYear()
    const startYear  = 2009;
    this.yearList.push({ val: '', label: 'All' });
    // this.yearList.push({ val: year.toString(), label: year.toString() });
    for (var i = currentYear; i >= startYear; i--) {
      this.yearList.push({ val: (i).toString(),label: i.toString()});
    }
  }

  searchFunc(term: string, arr: any, key: string = '') {
    let result = [];
    let name;
    let email;
    arr.forEach((v) => {
      name = v.username.toLowerCase();
      email = v.mail.toLowerCase();
      if (email.search(term) != -1 || name.search(term) != -1) {
        result.push(v);
      }
    });
    return result;
  }
  searchFuncProperty(term: string, arr: any, key: string = '') {
    let result = [];
    let name;
    let proName;
    arr.forEach((v) => {
      name = v.uid.toString();
      proName = v.property_name.toUpperCase();
      if (proName.search(term) != -1 || name.search(term) != -1) {
        result.push(v);
      }
    });
    return result;
  }
  getCount(action) {
    this.getUnallocatedCount = null;
    this.count_loader = true;
    if (this.searchData.body && (this.searchData.body.polygon || this.searchData.body.neighUid)) {
      let filter = {}, params = '';
      if (this.searchData.body.polygon && this.searchData.body.polygon.length) filter['polygon'] = this.searchData.body.polygon;
      if (this.searchData.body.neighUid) filter['neigh_uid'] = this.searchData.body.neighUid;
      if (this.selectedAssignment && this.selectedAssignment.id) filter['assignment_id'] = this.selectedAssignment.id;
      let url = 'count?action=' + action;
      this.api.postEpsData(url, filter).subscribe(
        {next:(data: any) => {
        this.count_loader = false;
        this.getUnallocatedCount = data;
      }, error: (err) => {
        this.count_loader = false;
        this.getUnallocatedCount = null;
      }})
    }
  }

  //block 2 search
  getSearchResultsUnAssProperty() {
    if (!this.searchData.data) return;
    if (this.search_unassign_property_input && !this.unassignProploader) {
      this.searchData.data.unassignproOffset = 0;
      this.attributeValue = this.search_unassign_property_input;
      this.propertyUid = this.search_unassign_property_input;
      this.searchData.data.getProperty(false, true);
    } else {
      this.attributeValue = '';
      this.propertyUid = '';
      this.searchData.data.unassignproOffset = 0;
      this.searchData.data.getProperty(false, true);
    }
  }


  /**
   * This is a archive assignment function
   */
  archiveAssignment(){
    let body= {
      "assignment_id" : this.selectedAssignment.id,
      "comment": null,
      "force": false,
      "updated_by": this.api.user_id
    }

  this.api.patchEmsData(`archive-assignment`, body).subscribe({
    next: (res:any)=>{
      if(res.status == 200 && res.data){
        this.dialog.open(ArchiveAssignmentComponent, {
          width: '550px',
          data: {info: res.data, selectedAssignment : this.selectedAssignment}
        }).afterClosed().subscribe((res)=>{
          if(res){
            this.getAssignment(false);
          }
        })
      }else{
        this.notify.notify(res.message, "warn")
      }
    },
    error:(err)=>{
      this.notify.notify(err.error.message, "error")
    }
  })
  }

  //block 3 search
  getSearchResults() {
    if(this.masterList && this.selectedAssignment?.id){
      if(this.selectedAssignment?.status == -3){
        this.notify.notify('Assignment is archived', 'warn');
        return;
      }
    }else{
      // if(this.masterList.length) this.notify.notify('Please select assignment', 'warn', 3000)
      return;
    }

    this.resultsList = [];
    let img, userdetail: any;
    if (!this.search_input) this.search_input = '';
    this.search_input = this.search_input.toLowerCase();
    if (this.search_input.trim()) {
      let checkexist;
      this.loader3 = true;
      this.api.getUmsData('users?user_email=' + this.search_input +"&status=1").subscribe({next: (data: any) => {
        this.loader3 = false;
        if (data.data) {
          data.data.forEach(e => {
            if (e.profile_image) {
              img = e.profile_image;
            } else {
              img = '../../../assets/images/user.png';
            }

            let fullname = (e.user_details && (Object.keys(e.user_details)).length) ? (e.user_details['1'].first_name) ? e.user_details['1'].first_name : '' + ' ' + (e.user_details['1'].last_name) ? e.user_details['1'].last_name : '' : ''
            userdetail = {
              id: e.user_id,
              imageURL: img,
              username: fullname,
              mail: e.primary_email_id,
              contact: e.phone_number,
              status: e.status
            }
            checkexist = this.searchFunc(userdetail.mail, this.userlist);
            if (checkexist.length > 0) {
              this.resultsList.push(userdetail);
              this.alreadyExisting = checkexist[0].id;
            } else {
              this.resultsList.push(userdetail);
            }
          });
        }
      }, error: (err) => {
        this.loader3 = false;
      }})
    }
  }
  //update status
  updateStatus(data) {
    this.eventReceived = true;
    if (!this.assignment_access.PATCH) {
      this.notify.notify('You are not authorized to update assignment', 'warn');
      return;
    }
    let status: number = null, body = {}, url = "assignments/" + data.id;
    if (data.status == 1) {
      status = 0;
    } else {
      status = 1;
    }
    body = {
      "assignment_id": data.id,
      "status": status,
      "updated_by": +this.api.user_id
    }
    this.api.patchEmsData(url, body)
      .subscribe((v) => {
        if (status == 0) {
          this.notify.notify('Assignment disabled', 'success');
          this.masterList = [];
          this.getAssignment(false);
        } else {
          this.notify.notify('Assignment enabled', 'warn');
          this.masterList = [];
          this.getAssignment(false);
        }
      })
  }
  isActive(v) {
    if (this.selectedAssignment) {
      if (v == this.selectedAssignment.id) return true;
    }
    return false;
  }
  isActiveUser(v) {
    if (this.selectedUser) {
      if (v == this.selectedUser) return true;
    }
    return false;
  }
  countProperty(userId) {
    let data = this.selectedAssignment;
    let no_property: any = 0;
    if (data && data.AssignTo) {
      for (let x in data.AssignTo) {
        if (x == userId) {
          no_property = 0;
          for (let y in data.AssignTo[x]) {
            if (y !== 'qaqc') {
              no_property += data.AssignTo[x][y];
            }

          }
        }
      }
    }

    return no_property;
  }
  /**
   * This is function that use to ckeck user have qaqc access
   * @param id id is a param
   * @returns return boolean value
   */
  is_qaqc_access(id) {
    let data = this.selectedAssignment;
    let qaqc: boolean = false;
    if (data) {
      for (let x in data?.AssignTo) {
        if (x == id) {
          qaqc = data?.AssignTo[x]['qaqc'];
        }
      }
    }
    return qaqc;
  }

  /**
   * Update QAQC access
   * @param event
   * @param user_id
   */
  updateQaqcAccess(event, user_id) {
    event.stopPropagation();
    if(this.selectedAssignment?.status == -3){
      this.notify.notify('Assignment is archived', 'warn');
      return;
    }
    if (this.qaqc_access.POST == true) {
      let body = {
        "assignment_id": this.selectedAssignment.id,
        "user_id": user_id,
        "updated_by": this.api.user_id
      }
      this.loader3 = true;
      this.api.patchEmsData('assignment-qaqc', body).subscribe({next: (res: any) => {
        // this.loader3=false;
        this.getAllocatedUsers(this.selectedAssignment, 'user');
      }, error :(err) => {
        this.loader3 = false;
      }})
    } else {
      this.notify.notify('You are not authorized to update QA/QC access', 'warn');
    }

  }

  // getRemainingUsers() {
  //   let index;
  //   this.NewUserList = [];
  //   this.userlist.forEach((v) => {
  //     index = this.fnc.getArrayindex('id', v.id, this.filteredUserList);
  //     if (index == null) {
  //       this.NewUserList.push(v);
  //     }
  //   });
  // }

  getAllocatedUsers(data, option: string) {
    this.filteredPropListPoly = [];
    this.filteredPropListPolymain = [];
    this.getUnallocatedCount=null;
    this.polygons = [];
    this.propertyBlock4 = false;

    if (option == 'user') {
      this.searchField.setValue('');
      this.selectedUserId = [];
      this.selectedAssignment = data;
      this.getAssignmentById(this.selectedAssignment.id);
      // this.getUsers();
    }
    if (option == 'dialog') {
      this.openDialog('createAssignment', { update: true, stats: data });
    }
    if (option == 'switch') {
      this.updateStatus(data);
    }
  }
  getAssignmentById(id: number) {
    this.get_assignmt_info_loader=true;
    this.api.getEmsData('/assignments/' + id).subscribe(
     {
        next: (res: any) => {
          this.get_assignmt_info_loader =false;
          if (res.geom) this.polygons = JSON.parse(res.geom);
          if (this.selectedAssignment.assigned_to == null && res.assigned_to) {
            let totalUsers = 0;
            let no_property = 0;
            for (let x in res.assigned_to) {
              if (x != '0') totalUsers++;
              for (let y in res.assigned_to[x]) {
                if (y !== 'qaqc') {
                  no_property += res.assigned_to[x][y];
                }
              }
            }
            this.selectedAssignment = {
              ...this.selectedAssignment, AssignTo: res.assigned_to,
              PropertyNo: no_property,
              Users: totalUsers
            }
            this.masterList[this.fnc.getArrayindex('id', this.selectedAssignment.id, this.masterList)] = this.selectedAssignment;
          }
          this.getUsers();
        },
        error:()=>{
          this.get_assignmt_info_loader =true;
        }
      }
    )
  }

  // use to get user detail of assignment , get call on hover of assignment list
  //   getAssignmentByInfo(data){
  //     if(data.AssignTo != null) return;
  //     this.api.getEmsData('/assignments/'+data.id).subscribe((res:any) =>{
  //       if(res.assigned_to){
  //         let totalUsers = 0;
  //         let no_property = 0;
  //         for (let x in res.assigned_to) {
  //           if(x != '0') totalUsers++;
  //           for (let y in res.assigned_to[x]) {
  //             no_property += res.assigned_to[x][y];
  //           }
  //       }
  //         data = {...data, AssignTo: res.assigned_to, PropertyNo: no_property, Users: totalUsers};
  //         this.masterList[this.fnc.getArrayindex('id',data.id,this.masterList)] = data;
  //       }
  //     })
  // }


  onscrollProperty(e) {
    let scroll = document.getElementById('block4');
    //console.log(e);
    let isReachingBottom = e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight;
    let isReachingBottom_1 = scroll.scrollTop + scroll.offsetHeight + 20 >= scroll.scrollHeight;
    if ((isReachingBottom || isReachingBottom_1) && !this.loader4) {
      this.property_val_offset += this.property_val_limit;
      this.getPropertyByAssignment(this.user_id, true);
    }
  }

  getPropertyByAssignment(id, is_scroll: boolean = false) {
    if(this.selectedAssignment?.status == -3){
      this.notify.notify('Assignment is archived', 'warn');
      return;
    }
    this.user_id = id;
    if(this.loader4 == true){
      return;
    }
    if (!is_scroll) {
      this.propertyList = [];
      this.property_val_offset = 0;
    }
    if (!this.property_name_id) {
      this.getAttributes();
    }

    let neigh_uid = "", search_param = "";
    if (this.selectedNeighUid != '') {
      neigh_uid = '&neigh_uid=' + this.selectedNeighUid;
    }

    if (this.search_property_input != '') search_param += "&property_uid=" + this.search_property_input + "&attribute_value=" + this.search_property_input;
    let address = '', property_name, build_year, category, images: any, img_url = "../assets/images/building_icon.png", image, img_list;
    this.loader4 = true;
    let url = "deadmin/" + this.selectedAssignment.name + "/properties?sort_by=added_date&sort_asc=false&assigned_to=" + id + '&offset=' + this.property_val_offset + '&get_latest_property=Y' +'&limit=' + this.property_val_limit + search_param + '&status=' + this.statusfilter + neigh_uid;
    this.api.getEpsData(url)
      .subscribe({next: (data: any) => {
        this.loader4 = false;
        this.propertyBlock4 = true;
        if(data && data.length>0){
          data.forEach(e => {
            address = '';
            images = [];
            if (e.own_address) {
              address = this.fnc.getAddressText(e.own_address);
            }
            if (e.data) {
              property_name = e.data.property_name != null ? e.data.property_name : 'Property Name';
              build_year = e.data.build_year != null ? e.data.build_year : 'UC';
              if (e.data.property_category) category = e.data.property_category;
              if (e.data.images) {
                img_list = e.data.images;
                if (img_list.length > 0) {
                  img_url = img_list[0][0];
                  image = this.fnc.getArrayValue('2', true, img_list); //get default property image
                  if (image) {
                    img_url = image[0];
                  }
                }
              }
              if (!img_url) img_url = "../assets/images/building_icon.png";
            }
            let propData: any = {
              id: e.property_id,
              uid: e.property_uid,
              property_name: property_name,
              userId: e.assigned_to,
              imageURL: img_url,
              username: 'string',
              buildYear: build_year,
              category: category,
              address: address,
              status: e.status
            }
            this.propertyList.push(propData);
          });
        }

      }, error: (err) => {
        this.loader4 = false;
      }})
  }
  getAttributes() {
    let url = "deadmin/property/attributes?sort_by=sort_order&limit=10000&offset=0&assignment_id=" + this.selectedAssignment.id;
    this.api.getEmsData(url)
      .subscribe((data: any) => {
        data.forEach((e) => {
          if (e.attribute_name == 'property_name') {
            this.property_name_id = e.attribute_id;
          }
          if (e.attribute_name == 'images') {
            this.property_image_id = e.attribute_id;
          }
          if (e.attribute_name == 'build_year') {
            this.build_year_id = e.attribute_id;
          }
          if (e.attribute_name == 'property_category') {
            this.category_id = e.attribute_id;
          }
        });
      })
  }

  onscrollAssign(e) {
    let scroll = document.getElementById('block1');
    //console.log(e);
    let isReachingBottom = e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight;
    let isReachingBottom_1 = scroll.scrollTop + scroll.offsetHeight + 20 >= scroll.scrollHeight;
    if ((isReachingBottom || isReachingBottom_1) && !this.loader1) {
      this.assignment_val_offset += this.assignment_val_limit;
      this.getAssignment(true);
    }
  }
  getAssignment(is_scroll: boolean = false) {
    this.selectedNeighUid = '';
    if (!is_scroll) {
      this.assignment_val_offset = 0;
    }
    let tempArr=[]
    let status = '&status='+this.checked_status;
    let namesearch = '&assignment_name=' + this.search_assign_input;
    let url = 'assignments?sort_by=assignment_name&limit=' + this.assignment_val_limit + '&offset=' + this.assignment_val_offset + namesearch + status + '&user_count=true&property_count=true';
    if(this.selectedAssignType) url += '&type=' + this.selectedAssignType;
    if(this.selected_year) url += `&year=${this.selected_year}`;
    this.loader1 = true;
    this.api.getEmsData(url)
      .subscribe(
        {next : (data: any) => {
          this.loader1 = false;
          //change sort order
          // data = this.fnc.assignmentSortOrder(data);
          if (!is_scroll) {
            this.masterList = [];
          }
          let label = "", assignData: any;
          data.forEach(e => {
            //   totalUsers = 0;
            //   no_property = 0;
            //   for (let x in e.assigned_to) {
            //     if(x != '0') totalUsers++;
            //     for (let y in e.assigned_to[x]) {
            //       no_property += e.assigned_to[x][y];
            //     }
            // }
            if (e.assignment_label) {
              label = e.assignment_label[this.api.language] == undefined ? e.assignment_name : e.assignment_label[this.api.language];
            }
            assignData = {
              id: e.assignment_id,
              name: e.assignment_name,
              assignment_id: e.assignment_id,
              AssignTo: null,
              PropertyNo: e.property_count,
              Users: e.user_count,
              label: label,
              lang: e.assignment_label,
              status: e.status,
              geom: null,
              type: e.type ? e.type : '',
              year:e.year
            }
            // if(this.selectedAssignment){
            //   if(this.selectedAssignment.id == assignData.id){
            //     this.selectedAssignment = assignData;
            //   }
            // }
            tempArr.push(assignData);
          });

          // this.masterList = this.masterList.concat(this.getUnique(tempArr,'id'));
          this.masterList = this.masterList.concat(tempArr);
          this.masterList = this.getUnique(this.masterList,'id')
        }, error: (err) => {
          this.loader1 = false;
          if (!is_scroll) {
            this.masterList = [];
          }
        }}
      );
  }


  getUnique(arr, comp) {
    // store the comparison  values in array
    const unique = arr.map(e => e[comp])
      // store the indexes of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)
      // eliminate the false indexes & return unique objects
      .filter((e) => arr[e]).map(e => arr[e]);
    return unique;
  }

  // hideDisabledAssignments(e) {
  //   this.checked_status = e
  //   this.getAssignment(false);
  // }

  getUsers() {

    let img, userdetail: any, user = null;
    if (this.selectedAssignment && this.selectedAssignment.AssignTo) {
      user = Object.keys(this.selectedAssignment.AssignTo);
    } else {
      return;
    }

    this.loader3 = true
    this.api.getUmsData(`users?place_id=${this.api.city_id}&user_ids=${user}`)
      .subscribe(
        {next: (data: any) => {
          this.loader3 = false;
          let users: any = data?.data;
          if (users) {
            let userlist = [];
            users.forEach(e => {
              // if (e.status == 1) {
              if (e.profile_image) {
                img = e.profile_image;
              } else {
                img = '../../../assets/images/user.png';
              }
              let fullname = (e.user_details && (Object.keys(e.user_details)).length) ? (e.user_details['1'].first_name) ? e.user_details['1'].first_name : '' + ' ' + (e.user_details['1'].last_name) ? e.user_details['1'].last_name : '' : ''; //(e.user_details) ? (e.user_details['1'].first_name) ? e.user_details['1'].first_name : '' + ' ' + (e.user_details['1'].last_name) ? e.user_details['1'].last_name : '' : ''
              userdetail = {
                id: e.user_id,
                imageURL: img,
                username: fullname,
                mail: e.primary_email_id,
                contact: e.phone_number,
                status: e.status
              }
              userlist.push(userdetail);
              // }
            });
            this.userlist = userlist
            this.filteredUserList = userlist;
          } else {
            this.userlist = [];
            this.filteredUserList = [];
          }
          //this.NewUserList = this.userlist;
        }, error: (err) => {
          this.loader3 = false;
        }}
      );
  }

  // color title
  public getTitle(val) {
    if (val == 1) {
      return 'Created'
    } else if (val == 2) {
      return 'Submited for QA/QC'
    } else if (val == 3) {
      return 'Rejected'
    } else if (val == 4) {
      return 'Rejected & assigned'
    } else if (val == 5) {
      return 'Approved'
    } else if (val == 6) {
      return 'Start editing in Deapp'
    } else if (val == 7) {
      return 'Re-submit'
    }
  }

  // filter by color
  public getTitlefilter(val) {
    if (val == '1') {
      return 'Created'
    } else if (val == '2') {
      return 'Submited for QA/QC'
    } else if (val == '7') {
      return 'Re-submit'
    } else if (val == '3,4,6') {
      return 'Rejected / Rejected & assigned / Start editing'
    } else if (val == '5') {
      return 'Approved'
    }
  }

  // filter with status
  public filterProperty(val) {
    this.statusfilter = val;
    this.getPropertyByAssignment(this.user_id, false);

  }
  //select limit for get property list
  public selectPropertyLimit(num) {
    this.property_val_limit = num;
    this.property_val_offset = 0;
    this.getPropertyByAssignment(this.user_id, false);
  }
  // drag test start
  draggable = {
    data: "myDragData",
    effectAllowed: "all",
    disable: false,
    handle: false
  };
  onDragStart(event: DragEvent, data = null, id) {
    this.unAssigned = false;
    if (data == 'property') {
      this.property_drop = true;
      this.user_drop = true;
      if (!this.selected_properties.length) {
        this.selected_properties.push(id);
      }
    }
    if (data == 'user') {
      this.user_drop = true;
    }
  }

  moveUnassigned(event: DragEvent, id) {
    this.property_drop = true;
    this.unAssigned = true;
    this.user_drop = false;
    if (!this.selected_unassProperties.length) {
      this.selected_unassProperties.push(id);
    }
  }

  onDragCanceled(event: DragEvent, data = null) {
    this.selected_properties = [];
    this.selected_unassProperties = [];
    this.property_drop = false;
    this.unAssigned = false;
    this.user_drop = false;
    if (data == 'user') {
      this.user_drop = false;
    }
  }
  assignUser() {
    if (!this.assignment_access.PATCH) {
      this.notify.notify('You are not authorized to update to assignment', 'warn');
      return;
    }
    this.search_input = null;
    let url = "/assignments/" + this.selectedAssignment.id;
    let assignUser = [];
    if (this.selectedAssignment.AssignTo == null) {
      assignUser = this.selectedUserId;
    } else {
      assignUser = Object.keys(this.selectedAssignment.AssignTo).concat(this.selectedUserId);
    }
    let incomingData = {
      "assigned_to": assignUser,
      "updated_by": this.api.user_id
    }
    this.api.patchEmsData(url, incomingData)
      .subscribe({next : (data:any) => {
        this.notify.notify(this.selectedUserId.length + ' users were successfully assigned to assignment: ' + this.selectedAssignment.label, 'success');
        this.selectedUserId = [];
        this.getAssignmentById(this.selectedAssignment.id);
        // this.getAssignment(false);
        // setTimeout(() => {
        //   this.selectedAssignment = this.fnc.getArrayValue('id', this.selectedAssignment.id, this.masterList); // refresh selected assignment
        //   this.getUsers();
        // }, 1500);
      }, error: (err) => {
        this.notify.notify('Error: ' + err, 'error');
      }
    });
  }
  onCheckStatus(e) {
    if (e.checked) {
      if (this.selectedUserId.indexOf(+e.source.value) == -1) {
        this.selectedUserId.push(+e.source.value);
      }
    } else {
      if (this.selectedUserId.indexOf(+e.source.value) > -1) {
        this.selectedUserId.splice(this.selectedUserId.indexOf(+e.source.value), 1);
      }
    }
  }

  ischecked(id) {
    if (this.selectedUserId.indexOf(id) > -1) {
      return true;
    }
    return false;
  }
  //not in use now
  onAssignmentDrop(event: DndDropEvent, received_data: any) {
    if (this.user_drop && !this.property_drop) {//User drop on assignment
      if (!this.assignment_access.PATCH) {
        this.notify.notify('You are not authorized to update Assignment', 'warn');
        return;
      }
      let url = "/assignments/" + received_data.id;
      let userId = event.data.id;
      let name = event.data.username;
      let assignUser: any = [];
      if (received_data.AssignTo == '{}') {
        assignUser.push(userId);
      } else {
        let udata = Object.keys(received_data.AssignTo);
        udata.push(userId);
        assignUser = udata;
      }
      let incomingData = {
        "assigned_to": assignUser,
        "updated_by": this.api.user_id
      }
      this.api.patchEmsData(url, incomingData)
        .subscribe({next: (data) => {
          this.notify.notify(name + ' was successfully assigned to assignment: ' + received_data.label, 'success');
          this.user_drop = false;
          this.property_drop = false;
          this.getAssignment(false);
        }, error: (err) => {
          this.notify.notify('Error: ' + err, 'erro');
          this.user_drop = false;
          this.property_drop = false;
          this.selected_unassProperties = [];
        }});
    }

    if (this.user_drop && this.property_drop) {//property drop on assignment
      if (!this.assignment_access.PATCH) {
        this.notify.notify('You are not authorized to update Assignment/Property', 'warn');
        return;
      }
      if (!this.unAssigned) {
        this.assignProperties(received_data, this.selected_properties);
      } else {
        this.assignProperties(received_data, this.selected_unassProperties);
      }
    } else {
      this.user_drop = false;
      this.property_drop = false;
    }
  }

  assignProperties(assignment_details: AssignmentDetails, prop_arr: any, assigned_to = 0) {

    let user = this.fnc.getArrayValue('id', assigned_to, this.userlist)
    if (user && user.status == 0) {
      this.notify.notify(`This user is not active`, `warn`);
      this.loader3 = false;
      this.user_drop = false;
      this.property_drop = false;
      return;
    }
    this.loader1 = true;
    let property_data = {};
    let body = [];
    let url = "deadmin/" + assignment_details.name + "/copy-properties";
    prop_arr.forEach((v) => {
      property_data = {
        "assigned_to": assigned_to,
        "assignment_id": parseInt(assignment_details.assignment_id),
        "updated_by": +this.api.user_id,
        "property_id": parseInt(v)
      }
      body.push(property_data);
    })
    // console.log(body);
    this.api.patchEpsData(url, body).subscribe({next: (data:any) => {
      this.loader3 = false;
      this.loader1 = false;
      this.user_drop = false;
      this.property_drop = false;
      if(data.length > 0){
        this.notify.notify('Some properties already active in another assignment', 'warn');
        return;
      }
      this.notify.notify('Property successfully assigned', 'success');
      // this.masterList = [];

      this.propertyList = [];
      this.filteredUserList = [];
      //  this.filteredPropListF = [];
      this.selected_properties = [];
      this.selected_unassProperties = [];
      this.assignPropertyToUser = true;
      this.filteredPropListPoly = this.fnc.getDeletedArray('id', this.selected_unassProperties, this.filteredPropListPoly);

      //this.getCount('property_inassignment');
      // this.getAssignment(false);
      // this.getUsers();
      this.getAssignmentById(assignment_details.assignment_id);
      this.searchData.data.getProperty();
    }, error : (err) => {
      //this.getCount('property_inassignment');
      this.loader1 = false;
      this.user_drop = false;
      this.property_drop = false;
    }})
  }

  checked_property(e, v) {
    if (e.checked) {
      this.selected_properties.push(v.id);
    } else {
      this.selected_properties.splice(this.selected_properties.indexOf(v.id), 1);
    }
  }
  select_all(e) {
    if (e.checked) {
      this.propertyList.forEach((property) => {
        this.selected_properties.push(property.id);
      })
    } else {
      this.selected_properties = [];
    }
  }
  ifChecked(data) {
    let flag = false;
    if (this.selected_properties) {
      if (this.selected_properties.includes(data)) {
        flag = true;
      }
    }
    return flag;
  }
  //unAssignProperties all select
  checked_unAssignProperty(e, v) {
    if (e.checked) {
      this.selected_unassProperties.push(v.id);
    } else {
      this.selected_unassProperties.splice(this.selected_unassProperties.indexOf(v.id), 1);
    }
  }
  select_all_unallocated(e) {
    if (e.checked) {
      if(this.filteredPropListPoly.length>0){
        this.filteredPropListPoly.forEach((property) => {
          this.selected_unassProperties.push(property.id);
        })
      }
    } else {
      this.selected_unassProperties = [];
    }
  }
  ifUnChecked(data) {
    let flag = false;
    if (this.selected_unassProperties) {
      if (this.selected_unassProperties.includes(data)) {
        flag = true;
      }
    }
    return flag;
  }

  onDrop(event: DndDropEvent, data = null) {
    if(this.selectedAssignment?.status == -3){
      this.notify.notify('Assignment is archived', 'warn');
      return;
    }
    if (!this.property_access.PATCH) {
      this.notify.notify('You are not authorized to update property', 'Dismiss');
      return;
    }
    let flag = false;

    //assigning unassigned property to unallocated
    if (data == 'Unallocated' && event.data.type && event.data.type != 'droppedonuser' && event.data.type != 'prop') {
      let arr = this.selected_unassProperties;
      this.assignProperties(this.selectedAssignment, arr);
      return;
    }
    //assigning user assigned property to unallocated
    if (data == 'Unallocated' && event.data.type && event.data.type == 'prop') {
      let arr = this.selected_properties;
      this.assignProperties(this.selectedAssignment, arr);
      return;
    }
    //assigning unassigned property to user
    if (data.type && event.data.type == 'Unallocated' && data.type && data.type == 'droppedonuser') {
      let arr = this.selected_unassProperties;
      this.assignProperties(this.selectedAssignment, arr, data.data.id);
      return;
    }
    if (this.property_drop == true && data.type && data.type == 'droppedonuser' && event.data.type && event.data.type == 'prop') {
      let user = 'Unallocated', property_data = {};
      let body = [];
      let prop_name = event.data.data.property_name;
      let url = "deadmin/" + this.selectedAssignment.name + "/properties";
      if (!data.data) {
        this.selected_properties.forEach((v) => {
          property_data = {
            "assigned_to": 0,
            "assignment_id": this.selectedAssignment.id,
            "updated_by": +this.api.user_id,
            "property_id": v
          }
          body.push(property_data);
        });
      }
      if (data) {
        if (data && data.data.status == 0) {
          this.notify.notify(`This user is not active`, `warn`);
          this.loader3 = false;
          this.user_drop = false;
          this.property_drop = false;
          return;
        }
        user = data.data.username;
        let property_detail: any;
        this.selected_properties.forEach((v) => {
          property_detail = this.fnc.getArrayValue('id', v, this.propertyList);
          if (data.data.id == property_detail.userId) {
            this.notify.notify("This property already assigned to " + user, "warn");
            this.property_drop = false;
            flag = true;
            return;
          }
          property_data = {
            "assigned_to": data.data.id,
            "assignment_id": this.selectedAssignment.id,
            "updated_by": +this.api.user_id,
            "property_id": v
          }
          body.push(property_data);
        })
      }
      if (flag) {
        return;
      }
      this.loader3 = true;
      this.api.patchEpsData(url, body)
        .subscribe({next: (data) => {
          this.filteredUserList = [];
          this.propertyList = [];
          this.getAssignmentById(this.selectedAssignment.id);
          this.getAssignment(false);
          this.notify.notify('Properties successfully assigned to: ' + user, 'success');
          this.selected_properties = [];
          this.loader3 = false;
          this.user_drop = false;
          this.property_drop = false;
        },error: (err) => {
          this.notify.notify('Error: ' + err, 'error');
          this.selected_properties = [];
          this.loader3 = false;
          this.user_drop = false;
          this.property_drop = false;
        }});
    } else {
      this.notify.notify('Invalid action', 'warn');
      this.selected_properties = [];
      this.user_drop = false;
      this.property_drop = false;
    }
  }

  openDialog(type: string, info: any = {}): void {
    let dialogview;
    info.pObj = this;
    if (type == 'createAssignment') {
      this.eventReceived = true;
      dialogview = CreateAssignmentComponent
    }
    else if (type == 'createMap') {
      dialogview = CreateMapComponent
    }
    else if (type == 'existingProp') {
      dialogview = ExistingPropComponent
    }
    if (dialogview && type == 'createAssignment') {
      let dialogRef = this.dialog.open(dialogview, {
        width: '450px',
        panelClass: 'create-master-panel',
        data: info
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
    else if (dialogview && type == 'createMap') {
      let dialogRef = this.dialog.open(dialogview, {
        width: '79vw',
        panelClass: 'create-master-panel',
        data: info
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
    else if (dialogview && type == 'existingProp') {
      if(this.selectedAssignment?.status == -3){
        this.notify.notify('Assignment is archived', 'warn');
        return;
      }
      let dialogRef = this.dialog.open(dialogview, {
        width: '350px',
        panelClass: 'create-master-panel',
        data: info
      });
      dialogRef.afterClosed().subscribe(result => {

      });
    }
  }

  codyAssignment() {

    if (this.selectedAssignment?.id) {
      let dialogRef = this.dialog.open(CopyAssignmentDialogComponent, {
        width: '450px',
        panelClass: 'create-master-panel',
        data: { selectAssign: this.selectedAssignment },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result == true) {
          this.getAssignment(false);
        }
      });
    } else {
      this.notify.notify("Please select Assignment", 'warn', 5000)
    }

  }
  ngOnInit() {
    this.getAllowResourcesApi();
    // setTimeout(() => {
      // this.dHeight = this.share.heightService;
      // let e = $(this.elementRef.nativeElement);
      // e.find('#block1').css('height', (this.dHeight - 199));
      // e.find('#block2').css('height', (this.dHeight - 59));
      // e.find('#block3').css('height', (this.dHeight - 303));
      // e.find('#block4').css('height', (this.dHeight - 197));
      // e.find('#mapProList').css('height', (this.dHeight - 400));
      // e.find('#fixed').css('height', (this.dHeight));
    // }, 10);
  }

  openConfirmation(all: boolean = false) {

    if (all) {
      this.selectAll = true;
    } else {
      if (this.selected_properties == null || this.selected_properties.length == 0) {
        this.notify.notify('No any property selected', 'warn');
        return;
      }
      this.selectAll = false;
    }

    let _data = {
      parent_data: this,
      message: 'Do you want to Deallocate property from this assignment'
    }
    const dialogRef = this.dialog.open(ConfirmDialogBox, {
      width: '350px',
      data: _data
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  confirmDialogYes(confirm) {
    //console.log(confirm);
    if (confirm == 'YES') {
      let body = {
        "all_properties": this.selectAll,
        "assignment_id": this.selectedAssignment.id,
      }
      if (this.selectAll) {
        body["property_ids"] = null
      } else {
        body["property_ids"] = this.selected_properties;
      }

      this.api.postEmsData('assignments/deallocate', body).subscribe({next: (res: any) => {
        if (res) {
          this.getAssignment();
          this.getAllocatedUsers(this.selectedAssignment, 'user');
          this.getPropertyByAssignment(this.user_id, false);
          if (res.data == true) {
            this.selected_properties = [];
            this.notify.notify('property deallocate successful', 'success');
          } else {
            this.notify.notify('Edited or rework property can not be deallocated', 'warn');
          }
        }
      }, error: (err) => {
        this.notify.notify(this.fnc.getErrorMessage(err), 'error');
      }})

    }
  }
  openCopyProperty(data) {
    let dialogRef = this.dialog.open(PropertyOtherAssignmentComponent, {
      width: '800px',
      panelClass: 'create-master-panel',
      data: { info : data }, //optional
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }
}

/**
*
* <strong>List of API using</strong>
* <ol>
* <li>estapi_propertytypes_get</li>
* <li>estapi_assignments_assignment_id_patch</li>
* <li>estapi_getProperties_get</li>
* </ol>
*
*/
@Component({
  selector: 'createMap',
  templateUrl: './create-map.component.html',
  styleUrls: ['./master-assignment.component.css']
})
export class CreateMapComponent {
  //for mat chips
  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = true;
  //for mat chips
  MapObj: Map;
  map_overlay: any;
  mobile: boolean = false;
  map: any;
  drawingManager: any;
  circle: any;
  poly: any;
  polygon: any = [];
  selectedpolygon: any;
  selectedNeightbour: any;
  mode: string = '';
  polygons: any = [];
  tempPolygons: any = [];
  selectedType: any;
  saveLoader: boolean = false;
  getPropertyLoader: boolean = false;
  unassignproLimit = 20;
  unassignproOffset = 0;
  property_uids: any = null;
  assignment_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  }
  neighbourhoods: any = [];
  assignment_name: any;
  property_types: any = [];
  propdata: Array<propList> = [];


  @ViewChild('gmap') gmapElement: any;
  @ViewChild('drawer') sidenav: MatSidenav;

  showFiller=false;

  constructor(public dialogRef: MatDialogRef<CreateMapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
    private api: ApiserviceService,
    private notify: NotificationService,
    private dialog: MatDialog,
    private fnc: CommonfunctionService) {
    iconRegistry.addSvgIcon('circle', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/dots-circle.svg'))
      .addSvgIcon('polygon', sanitizer.bypassSecurityTrustResourceUrl('assets/icon/line-path.svg'));
    this.polygons = this.data.pObj.polygons;
    this.assignment_access = this.data.pObj.assignment_access;
    this.assignment_name = this.data.pObj.selectedAssignment.name;
    this.data.pObj.selected_unassProperties = [];
    this.neighbourhoods = this.data.pObj.neighbourhoods;
    this.getPropertyTypes();
  }
  getPropertyTypes() {
    this.api.getEmsData('propertytypes?sort_by=property_type_name').subscribe((data: any) => {
      data.forEach(e => {
        let obj = {
          added_date: e.added_date,
          "property_type_id": e.property_type_id,
          "property_type_label": e.property_type_label[this.api.language],
          "property_type_name": e.property_type_name,
          "status": e.status,
          "updated_by": e.updated_by,
          "updated_date": e.updated_date
        }
        this.property_types.push(obj);
      });
    })
  }
  filterByType(e) {
    let val = e.value;
    if (val) {
      this.selectedType = val;
    }
  }
  openMenu() {
    this.sidenav.open();
  }
  sidenavClose() {
    this.sidenav.close();
  }
  getLabel(data) {
    if (data) {
      return data.label[this.api.language];
    }
  }
  selectNeighbourhood(neigh) {
    let neighbourhood = this.fnc.getArrayValue('neigh_uid', neigh, this.neighbourhoods);
    let geomArr = [], newArr = [], geom = neighbourhood.geom, flag = false;
    geom = geom.replace('MULTIPOLYGON', '');
    geom = geom.replace('(((', '');
    geom = geom.replace(')))', '');
    geomArr = geom.split(',');
    geomArr.forEach(latlng => {
      newArr.push([latlng]);
    });
    this.polygon = newArr;
    //checking if polygon exists
    // if (this.polygons) {
    //   this.polygons.forEach(element => {
    //     if (element.geom == 'POLYGON((' + this.polygon.toString() + '))') {
    //       flag = true;
    //     }
    //   });
    // }
    if (!flag) {
      this.drawingManager.setDrawingMode(null);
      this.addLabel({ poly: { label: { [this.api.language]: neighbourhood.neigh_name }, geom: 'POLYGON((' + this.polygon.toString() + '))' } });
    } else {
      this.notify.notify('Area already exist', 'warn')
    }
  }
  currentArea(polygon) {
    let poly = new google.maps.Polygon({
      paths: this.getgeom(polygon.geom),
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: '#FF0000',
      fillOpacity: 0.35
    });
    this.getBound(poly);
  }
  remove(poly) {
    let index = this.polygons.indexOf(poly);
    if (index >= 0) {
      this.polygons.splice(index, 1);
    }
    this.sidenavClose();
    this.saveDialog(true);
  }

  confirm(poly) {
    let dialogRef = this.dialog.open(ConfirmDialogBox, {
      width: '400px',
      panelClass: 'create-master-panel',
      data: {
        parent_data: this,
        message: `Are you sure you wish to delete Polygon - ${poly.label['1']}`
      }//optional
    });
    dialogRef.afterClosed().subscribe(result => {
      if(result == 'YES'){
        this.remove(poly);
      }else{
        this.sidenavClose();
      }
    });
  }
  confirmDialogYes(val){

  }
  add(event: MatChipInputEvent): void {
    let input = event.input;
    let value = event.value;

    // Add poly
    if ((value || '').trim()) {
      this.polygons.push({ label: 'extra' });
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  addLabel(neigh_obj = null) {

    let dialogRef = this.dialog.open(LabelDialog, {
      width: '450px',
      panelClass: 'create-master-panel',
      data: { parent: this, neigh_obj: neigh_obj }//optional
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }


  getgeom(data) {
    if (data != null) {
      let str = data;
      let result = str.match(/\(\((.*)\)\)/);
      let str2 = result[1];
      let latlon = str2.split(',');

      let temp = [];
      latlon.forEach(el => {
        let s = el.split(' ')
        temp.push({ "lat": +s[1], "lng": +s[0] });
      });
      return temp;
    }
  }
  getBound(polygon) {
    var bounds = new google.maps.LatLngBounds();
    var paths = polygon.getPaths();
    var path;
    for (var i = 0; i < paths.getLength(); i++) {
      path = paths.getAt(i);
      for (var ii = 0; ii < path.getLength(); ii++) {
        bounds.extend(path.getAt(ii));
      }
    }
    this.map.fitBounds(bounds);
  }
  initMap() {
    let lt = 29.370656;
    let ln = 47.978168;
    if (localStorage.getItem('city_detail')) {
      let city_detail = JSON.parse(localStorage.getItem('city_detail'));
      if (city_detail.lat) {
        lt = city_detail.lat;
        ln = city_detail.lon;
      }
    }
    let map = new google.maps.Map(this.gmapElement.nativeElement, {
      center: { lat: lt, lng: ln },
      zoom: 12,
      mapTypeId: google.maps.MapTypeId.HYBRID
    });
    this.map_overlay = map.overlayMapTypes;
    this.map = map;

    //drowpolygons
    this.polygonDrow(map);

    // let circle;
    this.drawingManager = new google.maps.drawing.DrawingManager({

      drawingMode: google.maps.drawing.OverlayType.MARKER,
      drawingControl: false,
      circleOptions: {
        strokeColor: '#F1AE65',
        strokeOpacity: 0.8,
        strokeWeight: 1,
        fillColor: '#F1AE65',
        fillOpacity: 0.35,
        editable: false,
        draggable: false
      },
      polygonOptions: {
        editable: false,
        draggable: false
      }
    });

    //this.drawingManager.setMap(map);
    google.maps.event.addListener(this.drawingManager, 'circlecomplete', (circle) => {
      let radius = circle.getRadius();
      let clat = circle.getCenter().lat();
      let clng = circle.getCenter().lng();
      let position = new google.maps.LatLng(clat, clng);
      map.setCenter(position);
      map.fitBounds(circle.getBounds());
      this.circle = circle;
      const numberOfEdges = 32;

      let polygonc = this.generateGeoJSONCircle(position, radius, numberOfEdges);
      this.polygon = polygonc.coordinates;
      this.addLabel();
    });


    google.maps.event.addListener(this.drawingManager, 'polygoncomplete', (polygon) => {
      let polygonBounds = polygon.getPath();
      let pBounds = polygon.getPaths();
      this.poly = polygon;
      let coordinates = [];
      for (let i = 0; i < polygonBounds.length; i++) {
        coordinates.push([polygonBounds.getAt(i).lng() + ' ' + polygonBounds.getAt(i).lat()]);
      }
      coordinates.push(coordinates[0]);
      this.polygon = [coordinates];
      this.addLabel();
    });

    google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event) => {
      this.drawingManager.setDrawingMode(null);
      if (event.type == 'circle') {
        let radius = event.overlay.radius;
        let center = { lat: event.overlay.center.lat() };
      }
    });

    // WMS layer
    // this.addLayer({
    //   'format': 'wms',
    //   'layerName': 'parcels',
    //   'name': 'parcels',
    //   'url': this.api.wms_url,
    //   'assignment': this.data.pObj.selectedAssignment.id
    // });
    // this.addLayer({
    //   'format': 'wms',
    //   'layerName': 'properties',
    //   'name': 'properties',
    //   'url': this.api.wms_url,
    //   'assignment': this.data.pObj.selectedAssignment.id
    // });

    // Geoserver
    this.addGeoLayer({
      'format': 'wms',
      'layerName': 'parcels',
      'name': 'parcels',
      'url': environment.geoestater + 'wms-layer',
      'assignment': this.data.pObj.selectedAssignment.id,
      'city_code': this.api.city_code,
      'city_id': this.api.city_id,
      'user_token': environment.user_token
    });
    this.addGeoLayer({
      'format': 'wms',
      'layerName': 'properties',
      'name': 'properties',
      'url': environment.geoestater + 'wms-layer',
      'assignment': this.data.pObj.selectedAssignment.id,
      'city_code': this.api.city_code,
      'city_id': this.api.city_id,
      'user_token': environment.user_token
    });
  }

  /// drow polyygons /////////////
  polygonDrow(map){

    if (this.polygons) {
      this.polygons.forEach(geomdata => {
        if (geomdata.geom) {
          let polyBroundory = new google.maps.Polygon({
            paths: this.getgeom(geomdata.geom),
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 1,
            fillColor: '#FF0000',
            fillOpacity: 0.35
          });
          polyBroundory.setMap(map);
          this.getBound(polyBroundory);
          let info = new google.maps.InfoWindow();
          google.maps.event.addListener(polyBroundory, 'click', (e) => {
            this.selectedpolygon = geomdata;
            info.setContent(geomdata.label[this.api.language]);
            let latLng = e.latLng;
            info.setPosition(latLng);
            info.open(map);
          });
        }
      });
    }
  }

  // addLayer(options) {
  //   if (!options.url) return;
  //   if (options.format == 'wms') {
  //     var layer =
  //       new google.maps.ImageMapType(
  //         {
  //           getTileUrl: (coord: google.maps.Point, zoom: number) => {
  //             // Compose URL for overlay tile
  //             var s = Math.pow(2, zoom);
  //             var twidth = 256;
  //             var theight = 256;

  //             //latlng bounds of the 4 corners of the google tile
  //             //Note the coord passed in represents the top left hand (NW) corner of the tile.
  //             var gBl = this.map.getProjection().fromPointToLatLng(new google.maps.Point(coord.x * twidth / s, (coord.y + 1) * theight / s)); // bottom left / SW
  //             var gTr = this.map.getProjection().fromPointToLatLng(new google.maps.Point((coord.x + 1) * twidth / s, coord.y * theight / s)); // top right / NE

  //             // Bounding box coords for tile in WMS pre-1.3 format (x,y)

  //             var bbox = parseFloat(gBl.lng()) + "," + parseFloat(gBl.lat()) + "," + parseFloat(gTr.lng()) + "," + parseFloat(gTr.lat());

  //             //base WMS URL
  //             var url = options.url;

  //             url += "&service=WMS";           //WMS service
  //             url += "&version=1.1.0";         //WMS version
  //             url += "&request=GetMap";        //WMS operation
  //             url += "&layers=gisdb:" + options.layerName; //WMS layers to draw
  //             url += "&styles=";               //use default style
  //             url += "&format=image/png";      //image format
  //             url += "&TRANSPARENT=TRUE";      //only draw areas where we have data
  //             url += "&srs=EPSG:4326";         //projection WGS84
  //             url += "&bbox=" + bbox;          //set bounding box for tile
  //             url += "&width=256";             //tile size used by google
  //             url += "&height=256";
  //             url += "&tiled=true";
  //             if (options.assignment) url += "&viewparams=assignment_no:" + options.assignment;
  //             //url = "http://51.15.221.10:8080/geoserver/gisdb/wms?service=WMS&version=1.1.0&request=GetMap&layers=gisdb:properties&styles=&
  //             //bbox=47.9728552938352,29.33170430618,48.0253671652929,29.379311241798&width=768&height=696&srs=EPSG:4326&format=image/png";
  //             return url;                 //return WMS URL for the tile
  //           },
  //           tileSize: new google.maps.Size(256, 256),
  //           opacity: 0.85,
  //           name: options.name
  //         });

  //     // add WMS layer to map
  //     this.map.overlayMapTypes.push(layer);

  //   }
  // }


  // geoserver
  addGeoLayer(options) {

    var layer =
      new google.maps.ImageMapType(
        {
          getTileUrl: (coord: google.maps.Point, zoom: number) => {
            // Compose URL for overlay tile
            var s = Math.pow(2, zoom);
            var twidth = 256;
            var theight = 256;

            //latlng bounds of the 4 corners of the google tile
            //Note the coord passed in represents the top left hand (NW) corner of the tile.
            var gBl = this.map.getProjection().fromPointToLatLng(new google.maps.Point(coord.x * twidth / s, (coord.y + 1) * theight / s)); // bottom left / SW
            var gTr = this.map.getProjection().fromPointToLatLng(new google.maps.Point((coord.x + 1) * twidth / s, coord.y * theight / s)); // top right / NE

            // Bounding box coords for tile in WMS pre-1.3 format (x,y)

            var bbox = parseFloat(gBl.lng()) + "," + parseFloat(gBl.lat()) + "," + parseFloat(gTr.lng()) + "," + parseFloat(gTr.lat());

            //base WMS URL=
            var url = `${options.url}?place_id=${options.city_id}&token=${options.user_token}&store=${options.city_code.toUpperCase()}_gisdb`;


            url += "&layers=gisdb:" + options.layerName; //WMS layers to draw
            // url += "&format=image/png";      //image format
            // url += "&transparent=TRUE";      //only draw areas where we have data
            // url += "&version=1.1.0";         //WMS version
            url += "&request=GetMap";        //WMS operation
            // url += "&service=WMS";           //WMS service
            url += "&styles=";               //use default style
            url += "&srs=EPSG:4326";         //projection WGS84
            url += "&bbox=" + bbox;          //set bounding box for tile
            url += "&width=256";             //tile size used by google
            url += "&height=256";
            // url += "&tiled=true";
            if (options.assignment) url += "&viewparams=assignment_no:" + options.assignment;
            //url = "http://51.15.221.10:8080/geoserver/gisdb/wms?service=WMS&version=1.1.0&request=GetMap&layers=gisdb:properties&styles=&
            //bbox=47.9728552938352,29.33170430618,48.0253671652929,29.379311241798&width=768&height=696&srs=EPSG:4326&format=image/png";
            return url;                 //return WMS URL for the tile
          },
          tileSize: new google.maps.Size(256, 256),
          opacity: 0.85,
          name: options.name
        });

    // add WMS layer to map
    this.map.overlayMapTypes.push(layer);
  }



  refresh() {
    //this will change the zoom of the map
    this.map.setZoom(this.map.getZoom() + .000000000000001);
    //this will change the zoom again and load fresh tiles
    this.map.setZoom(Math.round(this.map.getZoom()));
  }

  //convert circle to polygon function
  generateGeoJSONCircle(center: any, radius: any, numSides: any) {

    var points = [],
      degreeStep = 360 / numSides;

    for (var i = 0; i < numSides; i++) {
      var gpos = google.maps.geometry.spherical.computeOffset(center, radius, degreeStep * i);
      points.push([gpos.lng() + ' ' + gpos.lat()]);
    };

    // Duplicate the last point to close the geojson ring
    points.push(points[0]);
    return {
      type: 'Polygon',
      coordinates: [points]
    };
  }

  /* custom functions for map & search */
  getMapBounds() {
    let bounds = this.map.getBounds();
    let bbox = '';
    if (bounds) {
      var isFull = 0;
      let min_lat = isFull ? -90 : bounds.getSouthWest().lat()
      let max_lat = isFull ? 90 : bounds.getNorthEast().lat()
      let min_lng = isFull ? 180 : bounds.getSouthWest().lng()
      let max_lng = isFull ? -180 : bounds.getNorthEast().lng()
      bbox = min_lng + ',' + min_lat + ',' + max_lng + ',' + max_lat;
    }
    return bbox;
  }
  drawCircle() {
    this.clearDrawing();
    this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE);
    this.mode = 'Circle';
  }

  drawPolygon() {
    this.clearDrawing();
    this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    this.mode = 'Polygon';
  }

  reset() {
    this.drawingManager.setDrawingMode(null);
    this.mode = '';
  }

  clearDrawing(clear_all = false) {
    if (!this.circle && !this.poly) {
      this.drawingManager.setMap(this.map);
    }
    if (clear_all) {
      if (this.poly) {
        this.poly.setMap(null);
      }
      if (this.circle) {
        this.circle.setMap(null);
      }
    }
  }
  /**
   * map initalization
   */
  ngAfterViewInit() {
    this.initMap();
    this.drawingManager.setDrawingMode(null);
  }

  ngOnInit() {
    // this.renderMap();
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
  saveDialog(renew = false): void {
    if (!this.assignment_access.PATCH) {
      this.notify.notify('You are not authorized to update assignment', 'warn');
      return;
    }
    this.saveLoader = true;
    let body = {}, url = "assignments/" + this.data.pObj.selectedAssignment.assignment_id;

    body = {
      "assignment_id": this.data.assignment_id,
      "updated_by": +this.api.user_id,
      "geom": this.polygons
    }

    this.api.patchEmsData(url, body).subscribe({
      next: (v) => {
        if (renew) {
          this.reset();
          this.initMap();
          // this.polygonDrow(this.map)

          this.drawingManager.setDrawingMode(null);
          this.drawingManager.setMap(this.map);
        }
        this.notify.notify("Area has been updated successfuly for this assignment", "success");
        this.saveLoader = false;
        // this.data.pObj.getAssignment(true);
        // setTimeout(() => {
        //   let assignment = this.data.pObj.fnc.getArrayValue('id', this.data.pObj.selectedAssignment.id, this.data.pObj.masterList);
        //   if (assignment) {
        //     this.data.pObj.selectedAssignment = assignment;
        //   }
        // }, 1200);
      },
      error: () => {
        this.saveLoader = false;
        this.data.pObj.getAssignment(false);
      },
    });

    // this.dialogRef.close();
  }

  getProperty(is_scroll = false, isLimit = false) {
  if(this.getPropertyLoader){
    return;
  }
    this.propdata = [];
    if (!is_scroll) {
      this.data.pObj.filteredPropListPolymain = [];
    }
    let polys: any = [], neigh: any = null;
    let propertyUids: any = null;
    let url = 'properties-copy';

    let address = '', property_name, build_year, category, images: any, img_url = "../assets/images/building_icon.png", image, img_list;
    if (this.selectedpolygon) {
      polys.push(this.selectedpolygon.geom)
    } else {
      polys = null;
    }
    if (this.property_uids) {
      propertyUids = this.property_uids;
    }
    if (this.selectedNeightbour) {
      neigh = this.selectedNeightbour.toString();
      polys = null;
    }
    if (neigh == null && polys == null && propertyUids == null) {
      this.notify.notify('Please select Polygons or Neighbourhood Or enter propertyUids', 'warn');
      return;
    }

    this.data.pObj.unassignProploader = true;

    let body = {
      "assignmentId": this.data.pObj.selectedAssignment.id,
      "returnAttributeIds": false,
      "returnAttributeLabels": false,
      "returnDefaultValueForNulls": false,
      "returnGisProperties": false,
      "returnMasterValueIds": false,
      "returnMeasureUnits": false,
      "returnUserDetails": false,
      "showSeparateColumnForTableValues": false,
      "showTotalOfTableValues": false,
      "polygon": polys,
      "sortAsc": true,
      "sortBy": 'property_uid',
      "get_duplicate_assignment":false
    }

    if (neigh) body["neighUid"] = neigh;
    if (propertyUids != null) {
      body["propertyUid"] = propertyUids;
      body["attributeValue"] = propertyUids;
    }

    if (this.data.pObj.attributeValue != '') body["attributeValue"] = this.data.pObj.attributeValue;
    if (this.data.pObj.propertyUid != '') body["propertyUid"] = this.data.pObj.propertyUid;

    body["limit"] = (this.data.pObj.searchData.data == undefined || this.data.pObj.searchData.data == null) ? this.unassignproLimit : this.data.pObj.searchData.data.unassignproLimit;
    body["offset"] = this.unassignproOffset;
    body["status"] = '-100';

    if (this.selectedType && this.selectedType != 'All') body['propertyTypeIds'] = this.selectedType;
    this.getPropertyLoader = true;
    this.api.postEpsData(url, body)
      .subscribe({next: (data: any) => {
        this.getPropertyLoader = false;
        this.data.pObj.searchData = {
          body: body,
          url: url,
          data: this
        }
        this.data.pObj.filteredPropListPoly = [];
        this.data.pObj.unassignProploader = false;
        if (data?.length) {
          data.forEach(e => {
            address = '';
            images = [];
            if (e.own_address) {
              address = this.fnc.getAddressText(e.own_address);
            }
            if (e.data) {
              property_name = e.data.property_name != null ? e.data.property_name : 'Property Name';
              build_year = e.data.build_year != null ? e.data.build_year : 'UC';
              if (e.data.property_category) category = e.data.property_category;
              if (e.data.images) {
                img_list = e.data.images;
                if (img_list.length > 0) {
                  img_url = img_list[0][0];
                  image = this.fnc.getArrayValue('2', true, img_list); //get default property image
                  if (image) {
                    img_url = image[0];
                  }
                }
              }
              if (!img_url) img_url = "../assets/images/building_icon.png";
            }

            this.data.pObj.filteredPropListPolymain.push({
              id: e.property_id,
              uid: e.property_uid,
              property_name: property_name,
              userId: e.assigned_to,
              imageURL: img_url,
              username: 'string',
              buildYear: build_year,
              category: category,
              address: address
            });
          });

        }
        this.data.pObj.filteredPropListPoly = this.data.pObj.filteredPropListPolymain
        if (!is_scroll && !isLimit) {
          this.data.pObj.getCount('property_notinassignment');
        }
        this.closeDialog();
      }, error: (err) => {
        this.getPropertyLoader = false;
        if (!is_scroll && !isLimit) {
          this.data.pObj.getCount('property_notinassignment');
        }
        this.notify.notify(this.fnc.getErrorMessage(err), "error");
        this.data.pObj.unassignProploader = false;

        if (!is_scroll) {
          this.data.pObj.filteredPropListPoly = [];
        }
      }});
  }
}

/**
*
* <strong>List of API using</strong>
* <ol>
* <li>estapi_assignments_assignment_id_patch</li>
* </ol>
*
*/

@Component({
  selector: 'existingProp',
  templateUrl: './existing-prop.component.html',
  styleUrls: ['./master-assignment.component.css']
})
export class ExistingPropComponent {
  parent: any;
  user: any;
  assignment: any;
  no_of_property: number;
  constructor(public dialogRef: MatDialogRef<ExistingPropComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.parent = this.data.pObj;
    this.user = this.data.user;
    this.no_of_property = this.parent.countProperty(this.user.id);
    this.assignment = this.parent.selectedAssignment;
  }
  ngOnInit() {
  }
  closeDialog(): void {
    this.dialogRef.close();
  }
  remove(): void {
    this.no_of_property = this.parent.countProperty(this.user.id);
    if (this.no_of_property > 0) return;
    let udata = Object.keys(this.assignment.AssignTo);
    udata.splice(udata.indexOf('' + this.user.id + ''), 1);
    let body = {
      "assigned_to": udata,
      "updated_by": this.parent.api.user_id
    }
    this.parent.api.patchEmsData('assignments/' + this.assignment.id, body)
      .subscribe(data => {
        let assignment = this.parent.fnc.getArrayValue('id', this.parent.selectedAssignment.id, this.parent.masterList);
        if (assignment) {
          this.parent.selectedAssignment = assignment;
          this.parent.getAssignmentById(assignment.id);
        }
        this.parent.notify.notify('User removed from assignment successfully', 'success');
      },
        err => this.parent.notify.notify('Something went wrong', 'error')
      );
    this.closeDialog();
  }
}

@Component({
  selector: 'label-dialog',
  templateUrl: './label-dialog.html',
  styleUrls: ['./master-assignment.component.css']
})

export class LabelDialog {
  language_valueset: Array<{}> = [];
  languages: Array<any>;
  labelValueForm: any;
  parent: any;
  constructor(public dialogRef: MatDialogRef<LabelDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, private api: ApiserviceService,
    private _fb: FormBuilder, private notify: NotificationService) {
    this.parent = this.data.parent;
    this.languages = this.api.language_list;
    this.labelValueForm = this._fb.group({
      language: this._fb.array([])
    })
    this.addRow();
    if (this.data.neigh_obj) {
      this.labelValueForm.patchValue({ 'language': [{ language: this.languages[0].language_id, value: this.data.neigh_obj.poly.label[this.api.language] }] });
    } else {
      this.labelValueForm.patchValue({ 'language': [{ language: this.languages[0].language_id, value: '' }] });
    }
  }
  addLabel(data, e) {
    let body = { label: {}, geom: null };
    let val = data.value
    val.language.forEach((v) => {
      body.label[v.language] = v.value;
    });
    let enlang = val.language.find(a=> a.language == 1);
    if(enlang){
    }else{
      this.notify.notify('English Label is required','warn');
      return;
    }
    body.geom = 'POLYGON((' + this.parent.polygon + '))';


    if (!this.data.neigh_obj) {
      // let matched = this.parent.polygons.findIndex(e=> e.label[1].toLowerCase() == body.label[1].toLowerCase());
      let label = body.label[1].toLowerCase();
      let matched = (this.parent.polygons.find((e)=> e.label[1].toLowerCase() == label));
      if(matched){
        this.notify.notify('Geometry already exist with same name!','warn');
        return;
      }else{
        this.parent.polygons.push(body);
      }
    } else {
      let label = body.label[1].toLowerCase();
      let matched = (this.parent.polygons.find((e)=> e.label[1].toLowerCase() == label));
      if(matched){
      this.notify.notify('Geometry already exist with same name!', 'warn')
        return;
      }else{
        let ndata = this.data.neigh_obj.poly;
        ndata['label']=body.label;
        this.parent.polygons.push(ndata);
      }
    }
    this.parent.saveDialog(true);
    this.dialogRef.close();
  }
  initLanguageFields() {
    return this._fb.group({
      language: ['', Validators.required],
      value: ['', Validators.required]
    });
  }
  addRow() {
    if (this.language_valueset?.length == this.languages?.length) {
      this.notify.notify("No more Languages Available to Add", 'warn', 4000);
      return;
    }
    this.language_valueset.push({ language: '', value: '' });
    /* #Form */
    let dd = <FormArray>this.labelValueForm.controls.language;
    dd.push(this.initLanguageFields());
    /* End - #Form */
  }

  removeRow(key) {
    if (this.language_valueset.length > 1) {
      this.language_valueset.splice(key, 1);
      let dd = <FormArray>this.labelValueForm.controls.language;
      dd.removeAt(key);
    }
  }
  close() {
    this.parent.reset();
    // this.parent.initMap();
    this.parent.drawingManager.setDrawingMode(null);
    this.parent.drawingManager.setMap(this.parent.map);
    this.dialogRef.close();
  }
  ngOninit() { }
}

