<div class="archive-div">
    <h2>Assignment Name - {{selectedAssignment?.label}}</h2>
    <mat-dialog-content>
        <table class="table" *ngIf="archive_data.length">
            <thead>
                <tr>
                    <th>Status</th>
                    <th>Count</th>
                   </tr>
            </thead>
            <tbody>
                <tr *ngFor="let d of archive_data">
                    <td>{{d.message}}</td>
                    <td>{{d.count}}</td>
                </tr>
            </tbody>
        </table>
        <p *ngIf="propcount"> <strong>NOTE:</strong> Please clear the properties in QA/QC first.</p>
        <div *ngIf="!propcount">
            <label class="font-weight-bold">Comment <span class="text-danger">*</span></label>
            <textarea  placeholder="Comment here" [(ngModel)]="archive_comment"></textarea>
        </div>
        <p *ngIf="!propcount">
            All properties will be deleted from selected assignment after archive, do you still want to proceed?
        </p>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button class="cancel" (click)="closePopup()">Close</button>
        <button *ngIf="!propcount" class="submit" (click)="saveArchiveData()" [disabled]="archive_comment==''">Submit</button>
    </mat-dialog-actions>
</div>
