<mat-toolbar *ngIf="!data.val" class="matpopup-header"><div>Create Group</div></mat-toolbar>
<!--<p>Validation status: {{ groupform.status }}</p>-->
<form [formGroup]="groupform" (ngSubmit)="saveform(groupform)">
<mat-dialog-content>
        <div fxLayout="column" class="w-100">
                <mat-progress-bar class="w-100" *ngIf="progress != 0" mode="determinate" value="{{progress}}"></mat-progress-bar>
                <small *ngIf="progress != 0">{{message}}</small>
        </div>

    <div class="form-container">
        <div class="form-input-container" fxLayout="row" fxLayoutAlign="space-between center">
            <label fxFlex="30%">Group Name</label>
            <mat-form-field floatLabel="never" fxFlex="70%">
                <input type="text" formControlName="group_name" name="group_name" matInput />
                <mat-error *ngIf="!groupform.controls.group_name.valid">
                    Enter valid <strong>group name</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="form-input-container" fxLayout="row" fxLayoutAlign="space-between start">
            <label fxFlex="30%" style="padding-top: 2%;">Label</label>
            <div fxFlex="70%" fxLayout="column" formArrayName="language">
                <div  *ngFor="let languagevalues of language_valueset;let kk = index">
                    <div [formGroupName]="kk"  fxLayout="row" fxLayoutAlign="space-between center">
                        <mat-form-field floatLabel="never" fxFlex="30%">
                            <mat-select formControlName="language"  name="language">
                                <mat-option *ngFor="let language of languages" [value]="language.language_id">
                                    {{ language.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field floatLabel="never" fxFlex="45%">
                            <input type="text" matInput  formControlName="value" name="value" />
                             <mat-error *ngIf="!groupform.controls.language.controls[kk].controls.value.valid">
                                    This field is <strong>required</strong>
                                </mat-error>
                        </mat-form-field>
                        <div class="addbtn">
                          <div (click)="removeRow(kk)" class="border iconTick-1">
                              <mat-icon>remove</mat-icon>
                          </div>
                          <div (click)="addRow()" class="border iconTick-1">
                              <mat-icon>add</mat-icon>
                          </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="form-input-container" fxLayout="row" fxLayoutAlign="space-between center">
            <label fxFlex="30%">Parent Group</label>
            <mat-form-field floatLabel="never" fxFlex="70%">
                <mat-select formControlName="parent_group">
                    <mat-option *ngFor="let grp of group_list" [value]="grp.id">
                        {{showLabel(grp)}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div> -->
        <mat-slide-toggle class="example-margin" formControlName="status">
          Status
      </mat-slide-toggle>
    </div>
</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="dialog-actions" *ngIf="progress == 0">
    <button [disabled]="groupform.invalid" type="submit" mat-button class="blue-button">Submit</button>
    <button  type="button"  mat-raised-button (click)="closeDialog()">Cancel</button>
</mat-dialog-actions>
</form>
