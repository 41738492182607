import { Component, Input, OnInit } from "@angular/core";
import { NgModel } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { CreateMasterValueDialog } from "../create-update-master-value/admin-createvalue.component";



@Component({
  selector: 'app-select-check-all',
  templateUrl: 'select-check-all.component.html',
  styleUrls: ['select-ceck-all-component.scss']
})
export class SelectCheckAllComponent implements OnInit {
  @Input() model: NgModel;
  @Input() values = [];
  @Input() text = 'Select All';


  constructor(private parent: CreateMasterValueDialog) { }

  ngOnInit() {
  }

  isChecked(): boolean {
    return this.model.value && this.values.length
      && this.model.value.length === this.values.length;
  }

  isIndeterminate(): boolean {
    return this.model.value && this.values.length && this.model.value.length
      && this.model.value.length < this.values.length;
  }

  toggleSelection(change: MatCheckboxChange): void {
    if (change.checked) {
      this.parent.property_types.forEach(v => {
        this.parent.property_types_ids.push(v.property_type_id);
      });
      this.parent.masterValueForm.patchValue({
        property_type: this.parent.property_types_ids
      });
    } else {
      //this.model.update.emit([]);
      this.parent.masterValueForm.patchValue({
        property_type: []
      });
    }
  }
}
