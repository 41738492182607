/**
*
* <strong>List of API using</strong>
* <ol>
* <li>estapi_module_type_assignment_name_properties_property_id_patch</li>
* </ol>
*
*/

import { Component, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable, startWith, map } from "rxjs";
import { ConfirmDialogBox } from "../../../../admin/confirm-dialog/confirm-dialogbox";
import { ApiserviceService } from "../../../../apiservice.service";
import { CommonfunctionService } from "../../../../services/commonfunction.service";
import { Users } from "../../models/constructor-exports";

@Component({
  selector: 'submit-dialog',
  templateUrl: 'submit-dialog.component.html',
  styleUrls: ['../../qa-qc.component.scss']
})

export class SubmitDialog {
  current_group: string = '';
  option_primary: any = [];

  option_secondary: any = [];

  resource_access: any = {
    'GET': true,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };

  user_list: any = [];
  user: Users[] = [];

  user_comment: any;
  user_option: any;
  reject_option: any
  selected_user_id: any;
  selected_rejection_type: any;
  current_attribue: any;


  assignment: FormGroup;
  filteredOptions: Observable<any[]>;
  parent: any;
  selected_row: any;

  constructor(public dialogRef: MatDialogRef<SubmitDialog>, private matDialog:MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder,
    private fnc: CommonfunctionService, private api: ApiserviceService) {
    this.parent = data.parent;
    this.current_group = this.parent.selected_group;
    this.selected_row = this.data.row;
    this.option_primary = [
      { label: 'Reject data for ' + '"' + this.parent.selected_group.group_name + '"', value: '8' },//8 and 9 will be equal to 3;
      { label: 'Reject data for all Groups', value: '9' },
      { label: `Reject data for selected Property ,Floor and Unit`, value: '11' },
      { label: 'Mark for review later', value: '10' }
    ];
    if(this.selected_row.status == 3){
      this.option_secondary.push({ label: 'Submit property for rework', value: '4' })
    }
    this.option_secondary.push({ label: 'Approve property', value: '5' })
    this.user_list = data.parent.user_list;
    this.selected_user_id = this.data.row.assigned_to;
    this.resource_access = this.fnc.checkResourceAccess('qa_qc', false);
    this.assignment = fb.group({
      'user_name': [null, Validators.required],
    })
    this.setUsersList();
  }
  setUsersList() {
    this.user = [];
    this.user_list.forEach(e => {
      if (e.id != 0)
        this.user.push({
          'name': e.username,
          'id': e.id
        });
    });
    if (this.assignment) {
      this.filteredOptions = this.assignment.get('user_name').valueChanges
        .pipe(
          startWith(''),
          map((name, id) => name ? this.currentName(name, id) : this.user.slice())
        );
    }
  }
  currentName(name: string, id: any) {
    return this.user.filter(s =>
      s.name.toLowerCase().indexOf(name.toLowerCase()) === 0);
  }

  reject_type(e) {
    this.selected_rejection_type = e.value;
    if (this.selected_rejection_type == -1) this.user_option = null;
    if (['8','9','10','11'].indexOf(this.selected_rejection_type) > -1 && this.user_option == 5
      ) this.user_option = null;
  }


  /**
   * Open confirmation box before select approve property
   * @returns
   */
   openConfirmation(){
    if(this.user_option == 5){
      let _data = {
        parent_data: null,
        message: 'New data added for past year will be deleted.'
      }
      let dialogRef = this.matDialog.open(ConfirmDialogBox,{
        width: '350px',
        data: _data
      })
      dialogRef.afterClosed().subscribe(result => {
        if(result == 'YES'){
         this.submit();
        }else{

        }
      });
    }else{
      this.submit();
    }

  }


  submit() {
    if(!this.resource_access.PATCH){
      this.parent.notify.notify("You're not authorized to perform update operation in qa/qc property", 'warn');
      return
    }
    let url, body: any = {}, attr = { new_floor_ids: [], new_unit_ids: [] }, attributes = [],
    all_attributes = this.parent.attribute_list;
    let info = this.selected_row;
    if (this.selected_rejection_type == 11) {
      body = {
        assignment_id: this.parent.selected_assignment.assignment_id,
        property_id: this.selected_row.id.toString(),
        updated_by: this.api.user_id
      }
      if (this.user_comment) {
        body['comment'] = this.user_comment;
      }

      url = `property/reject`;
      this.api.patchEpsData(url, body)
        .subscribe({next:(data: any) => {
          this.parent.selectedIds = [];
          this.parent.notify.notify('Properties submited for rework', 'warn');
          this.closeDialog();
          this.parent.getProperties();
        }, error:(err) => {
          this.parent.notify.notify(this.fnc.getErrorMessage(err), 'error');
        }});
      return;
    }
    if (info.attribute_info) {
      attr = info.attribute_info;
      //clear new floor and unit identification
      this.parent.new_floors_list = [];
      this.parent.new_units_list = [];
      if (attr.new_floor_ids) {
        attr.new_floor_ids = [];
      }
      if (attr.new_unit_ids) {
        attr.new_unit_ids = [];
      }
      // reset default value
      if (all_attributes.length && this.user_option == 4) {
        all_attributes.forEach((c) => {
          if (attr[c.id] && attr[c.id].status == 3) {
            attr[c.id] = {
              "comment": '',
              "status": 1
            }
          }
        });
      }

    }
    if (this.selected_rejection_type == 8 && this.parent.attribute_list.length) {
      attributes = this.fnc.getChildArray('group_id', this.parent.selected_group.id, this.parent.attribute_list);
    }
    if (this.selected_rejection_type == 9 && this.parent.attribute_list.length) {
      attributes = this.parent.attribute_list;
    }
    if (attributes.length) {
      attributes.forEach((c) => {
        attr[c.id] = {
          "comment": this.user_comment,
          "status": -1
        }
      });
    }

    body = {
      "attribute_info": attr,
      "data": {},
      "updated_by": this.api.user_id,
    }

    if (this.selected_rejection_type == -1) {
      body['status'] = this.selected_rejection_type;
    }
    if ((this.selected_rejection_type == 8 || this.selected_rejection_type == 9) && attributes.length) {
      body['status'] = 3;
    }
    if (this.user_option) {
      body['status'] = this.user_option;
      if(this.user_option == 5){
        body['review_later'] = null;
      }
    }
    if (this.selected_rejection_type == 10) {
      body['review_later'] = true;
      delete body.status; // remove status from body
      delete body.attribute_info;
      delete body.assigned_to;

    }
    if (this.selected_user_id && body.status == 4) body['assigned_to'] = this.selected_user_id;
    if (this.parent.entity_type == 'property') {
      url = "deadmin/" + this.parent.selected_assignment.name + "/properties/" + info.property_id;
    }
    this.api.patchEpsData(url, body)
      .subscribe({next:(data: any) => {
        if (body.status == 5) {
          this.parent.notify.notify('Property approved', 'success');
        } else if (body.status == -1) {
          this.parent.notify.notify('Property marked for deletion', 'warn');
        } else {
          this.parent.notify.notify('Property submited for rework', 'warn');
        }
        this.closeDialog();
        this.parent.getProperties();
      }, error:(err) => {
        if (err['status'] == 401) {
          this.parent.notify.notify('You are not authorized to update property', 'warn');
        } else {
          this.parent.notify.notify('Something went wrong', 'error');
        }
      }});
  }

  assign_user(e) {
    this.selected_user_id = e.value;
  }
  ngOninit() {

  }
  closeDialog() {
    this.dialogRef.close();
  }

}
