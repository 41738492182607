import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AdminComponent } from '../admin.component';
import { AdminMasterComponent } from '../admin-master/admin-master.component';
import { AdminAttributeComponent } from '../admin-attribute/admin-attribute.component';
import { AdminPropertyComponent } from '../admin-property/admin-property.component';
import { AdminFieldComponent } from '../admin-field/admin-field.component';
import { AuthGuard } from '../services/auth.guard';
import { MasterAssignmentComponent } from '../master-assignment/master-assignment.component';
import { NotifyDetailComponent } from '../notification/notification.component';
import { QaQcComponent } from '../qa-qc/qa-qc.component';
import { CsvUploadComponent } from '../csv-upload/csv-upload.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { AdminProfileComponent } from '../admin-profile/admin-profile.component';
import { AdminConfigurationComponent } from '../admin-configuration/admin-configuration.component';
import { SyncPropertyComponent } from '../sync-property/sync-property.component';
import { SyncPropertyGisComponent } from '../sync-property-gis/sync-property-gis.component';
import { SyncPropertyLogComponent } from "../sync-property-log/sync-property-log.component";
import { InProgressComponent } from '../in-progress/in-progress.component';
import { ADMComponent } from '../adm/adm.component';
import { HistoricalSyncDataComponent } from '../historical-sync-data/historical-sync-data.component';
import { SettingsComponent } from '../settings/settings.component';
import { NewCsvUploadComponent } from '../new-csv-upload/new-csv-upload.component';
import { AdminAttributeDeletionComponent } from '../admin-attribute-deletion/component/admin-attribute-deletion/admin-attribute-deletion.component';
import { AdminObserverAdmUploadComponent } from '../admin-observer-adm-upload/admin-observer-adm-upload.component';
import { AdminVisibilityCalculationComponent } from '../admin-visibility-calculation/admin-visibility-calculation.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'admin', component: AdminComponent, canActivate: [AuthGuard],
        children: [
          { path: '', component: AdminPropertyComponent, canActivate: [AuthGuard] },
          { path: 'masters', component: AdminMasterComponent, canActivate: [AuthGuard] },
          // { path: 'masters/list-view', component: MastersListViewComponent, canActivate: [AuthGuard] },
          { path: 'attribute', component: AdminAttributeComponent, canActivate: [AuthGuard] },

          { path: 'property', component: AdminPropertyComponent, canActivate: [AuthGuard] },
          { path: 'field', component: AdminFieldComponent, canActivate: [AuthGuard] },
          // Feature on hold commented by YM
          // { path: 'attribute-deletion', component: AdminAttributeDeletionComponent, canActivate: [AuthGuard] },
          { path: 'assignment', component: MasterAssignmentComponent, canActivate: [AuthGuard] },
          { path: 'notifications', component: NotifyDetailComponent, canActivate: [AuthGuard] },
          { path: 'quality', component: QaQcComponent, canActivate: [AuthGuard] },
          // { path: 'configuration', component: AdminConfigurationComponent, canActivate: [AuthGuard] },
          { path: 'csv-upload', component: CsvUploadComponent, canActivate: [AuthGuard] },
          { path: 'new-csv-upload', component: NewCsvUploadComponent, canActivate: [AuthGuard] },
          { path: 'sync-property', component: SyncPropertyComponent, canActivate: [AuthGuard] },
          { path: 'sync-property-gis', component: SyncPropertyGisComponent, canActivate: [AuthGuard] },
          // { path: 'sync-property-log', component: SyncPropertyLogComponent, canActivate: [AuthGuard] },
          { path: 'in-progress', component: InProgressComponent, canActivate: [AuthGuard] },
          { path: 'adm-data', component: ADMComponent, canActivate: [AuthGuard] },
          { path: 'visibility-calculation', component: AdminVisibilityCalculationComponent, canActivate: [AuthGuard] },
          { path : 'historical-sync-data', component : HistoricalSyncDataComponent, canActivate:[AuthGuard]},
          { path : 'settings', component : SettingsComponent, canActivate:[AuthGuard]}
        ]
      },
    ])
  ],
  declarations: [],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
