<div class="top-section">
  <div fxLayout="row" class="w-100" fxLayoutAlign="start center">
    <div fxLayout="row" class="w-100" fxLayoutAlign="start center">
      <admin-breadcrumb></admin-breadcrumb>
      <button (click)="changeView()" class="switch-list">
        <span>Switch to Masters</span>
      </button>
      <mat-select placeholder="Select master" [(ngModel)]="selected_master" (selectionChange)="changeMasters()" (openedChange)="search_master=''"
        class="form-select-l">
        <div class="search_bar"><input [(ngModel)]="search_master" placeholder=" Type here to search..."
            (keydown)="$event.stopPropagation();" /></div>
        <mat-option *ngFor="let ent of master_list | searchfilter:'filterParm':search_master:master_list"
          [value]="ent">
          {{ent.master_label['1']}}
        </mat-option>
      </mat-select>

      <mat-select (selectionChange)="getValueData($event.value)" [(ngModel)]="sub_master" class="form-select-l" placeholder="Select sub master">
        <mat-option *ngFor="let ent of sub_master_list" [value]="ent.master_id">{{ent.master_label['1']}}
        </mat-option>
      </mat-select>
    </div>
    <div fxLayout="row" class="w-100" fxLayoutAlign="start center">
      <mat-select [(ngModel)]="selected_entity_type" (selectionChange)="getChangeEntity()" class="form-select-l">
        <mat-option *ngFor="let ent of api.entity_type_list" [value]="ent.entity_name">{{ent.entity_name}}
        </mat-option>
      </mat-select>
      <mat-select *ngIf="selected_entity_type == 'property_unit'" [(ngModel)]="selected_property_type_id"
        placeholder="Select property type" (selectionChange)="getTypeChange()" class="form-select-l">
        <mat-option *ngFor="let ent of property_type_list;"
          [value]="ent.property_type_id">{{ent.property_type_label[1]}}
        </mat-option>
      </mat-select>
      <mat-select [(ngModel)]="status"
        placeholder="Select status" (selectionChange)="getTypeChange()" class="form-select-l">
        <mat-option *ngFor="let ent of status_list;"
          [value]="ent.key">{{ent.val}}
        </mat-option>
      </mat-select>
      <input type="text" placeholder="Search" class="form-control" [formControl]="searchField">
      <div class="download-all-check">
        <mat-checkbox class="" [labelPosition]="'after'" [(ngModel)]="isChecked" [checked]="isChecked">Download All</mat-checkbox>
      </div>
      <button class="download-list" (click)="downloadMasterData()">Download</button>
    </div>
  </div>
</div>

<div>
  <div class="table-estater table-estater-over table-estater-wrapper">
    <mat-table [dataSource]="master_value_list">
      <ng-container matColumnDef="sno">
        <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutAlign="center center"> S.No.
        </mat-header-cell>
        <mat-cell *matCellDef="let i= index" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
          <div>{{i + 1 + offset}}</div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="master_value_name">
        <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutAlign="center center"> Master Value Name
        </mat-header-cell>
        <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
          <div>{{element.master_value_name}}</div>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="master_value_label">
        <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutAlign="center center"> Master Value Label
        </mat-header-cell>
        <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center">
          {{element.master_value_label[1]}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutAlign="center center"> Status </mat-header-cell>
        <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center">
          <span [class.text-success]="element.status == '1'" [class.text-danger]="element.status == '0'">{{getStatus(element.status)}}</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="image_url">
        <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutAlign="center center"> Icon </mat-header-cell>
        <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center">
          <img class="image-url" src="{{element.image_url}}" onerror="this.src='assets/images/banned.png';" alt="">
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutAlign="center center"> Action </mat-header-cell>
        <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center">
          <button mat-icon-button class="action-icon actionbtn justify-content-center"   (click)="editMasterValue(element)" matTooltip="Edit">
            <mat-icon>edit</mat-icon>
            </button>
        </mat-cell>
      </ng-container>
      <mat-header-row class="skipdrag" *matHeaderRowDef="displayedColumns"></mat-header-row>
      <div class="rowss">
        <mat-row class="rowset draggableset"
          *matRowDef="let row; columns: displayedColumns;let element;let k = index;"></mat-row>
      </div>
    </mat-table>

  </div>
  <ng-container *ngIf="master_value_list.length != 0">
    <mat-paginator  [length]="total" [pageSize]="defaultPageSize" showFirstLastButtons [pageSizeOptions]="pageSizeOptions" (page)="pageAction($event)">
    </mat-paginator>
  </ng-container>
  <mat-card class="p-5 text-align-center text-center" *ngIf="master_value_list.length == 0">
    <strong> No Result Found</strong>
  </mat-card>
</div>
