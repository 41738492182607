<!-- /**
 * Template for displaying a table with data and actions.
 *
 * This template uses Angular Material components to render a table with multiple columns and a paginator.
 * The table is populated with data from the `dataSource` and allows for viewing and managing records.
 *
 * - The `table-wrapper` class wraps the entire table and paginator.
 *
 * - The `mat-table` directive is used to create the Angular Material table, with the `dataSource` bound to it.
 *
 * - Each `ng-container` defines a column in the table:
 *   - `s_no` Column: Displays a serial number (`S No`) with an index starting from 1 plus the `offset`.
 *   - `id` Column: Shows the unique identifier (`Id`) of the record.
 *   - `added_by` Column: Displays the user who added the record (`Added by`).
 *   - `updated_by` Column: Shows the user who last updated the record (`Updated by`).
 *   - `submitted_date` Column: Displays the date the record was added (`Submitted date`).
 *   - `comment` Column: Shows comments associated with the record (`Comment`).
 *   - `status` Column: Displays the status of the record with textual representation (`Rejected`, `Pending`, or `Approved`).
 *   - `view` Column: Provides a button to view detailed information of the record. Clicking the button triggers the `viewDeletedData` method.
 *   - `action` Column: Contains buttons for rejecting or approving the record. Uses `.rejectd` and `.approve` classes for styling.
 *
 * - `mat-header-row` and `mat-row` directives are used to define header and data rows in the table.
 *
 * - The `mat-paginator` component provides pagination functionality with options for page size and navigation. It binds to the `total` length and calls `pageAction($event)` on page change.
 *
 * - There is an optional section commented out that would display a message if no data is found.
 */ -->
 <!-- loader start -->
<div  [ngClass]="{'fixed': isLoading}">
  <ngx-loading [show]="isLoading"></ngx-loading>
</div>
<!-- loader end -->
<div class="table-wrapper">
  <table mat-table [dataSource]="dataSource" class="">
    <!-- s_no Column -->
    <ng-container matColumnDef="s_no">
      <th mat-header-cell *matHeaderCellDef> S No</th>
      <td mat-cell *matCellDef="let element; let i = index"> {{i + 1 + offset}} </td>
    </ng-container>

    <!-- id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> Id </th>
      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <!-- added_by Column -->
    <ng-container matColumnDef="added_by">
      <th mat-header-cell *matHeaderCellDef> Added by</th>
      <td mat-cell *matCellDef="let element"> {{element.added_by ? element.added_by:'--'}} </td>
    </ng-container>

    <!-- updated_by Column -->
    <ng-container matColumnDef="updated_by">
      <th mat-header-cell *matHeaderCellDef> Updated by</th>
      <td mat-cell *matCellDef="let element"> {{element.updated_by ? element.updated_by : '--' }} </td>
    </ng-container>

    <!-- submitted_date Column -->
    <ng-container matColumnDef="submitted_date">
      <th mat-header-cell *matHeaderCellDef> Submitted date </th>
      <td mat-cell *matCellDef="let element"> {{element.added_date ? (element.added_date | date: 'dd/MM/yyyy'):'--'}} </td>
    </ng-container>

    <!-- comment Column -->
    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef> Comment </th>
      <td mat-cell *matCellDef="let element" [matTooltip]="element.comment.length > 40? element.comment  : ''"> {{element.comment.length > 40? (element.comment | slice:0:40) + '...' : element.comment }} </td>
    </ng-container>
   <!-- comment Column -->
   <ng-container matColumnDef="admin_comment">
    <th mat-header-cell *matHeaderCellDef>Action Comment </th>
    <td mat-cell *matCellDef="let element">
   <ng-container>
    <span *ngIf="element.admin_comment !== null">
      <span  [matTooltip]="element.admin_comment.length > 40? element.admin_comment  : ''">
        {{element.admin_comment.length > 40? (element.admin_comment | slice:0:40) + '...' : element.admin_comment }}
      </span>

    </span>
    <span *ngIf="element.admin_comment == null">--</span>
   </ng-container>

    </td>
  </ng-container>
    <!-- status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> status </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.status == -1" class="Rejected">Rejected</span>
        <span *ngIf="element.status == 0" class="Pending">Pending</span>
        <span *ngIf="element.status == 1" class="Approved">Approved</span>
      </td>
    </ng-container>

    <!-- view Column -->
    <ng-container matColumnDef="view">
      <th mat-header-cell *matHeaderCellDef> view </th>
      <td mat-cell *matCellDef="let element">
        <button type="button" class="view-btn" (click)="viewDeletedData(element)">
          <img src="../../../../../assets/icon/view-page.svg">
        </button>
      </td>
    </ng-container>

    <!-- action Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef style="min-width: 70px;"> actions </th>
      <td mat-cell *matCellDef="let element">
        <div class="action-btn"  *ngIf="isDeletionAccess">
          <button type="button" [disabled]="element.status == 1 || element.status == -1" class="rejectd" (click)="isApprovedOrReject('reject', element)">Reject</button>
          <button type="button" class="approve" [disabled]="element.status == 1 || element.status == -1"    (click)="isApprovedOrReject('approved', element)"> Approve</button>
        </div>
        <span *ngIf="!isDeletionAccess">--</span>
      </td>
    </ng-container>

    <!-- Table header and rows -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;">
    </tr>
  </table>

  <!-- Optional no data found message (commented out) -->
  <!--
  <div class="not-data-found" *ngIf="!total">
    Choose options and click 'Apply' to load the data. {{dataSource.length}}
  </div>
  -->

  <!-- Paginator for the table -->
  <mat-paginator [length]="total" showFirstLastButtons [pageSizeOptions]="pageSizeOptions" (page)="pageAction($event)">
  </mat-paginator>
</div>
