import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'search'
})
export class SearchPipe implements PipeTransform {
    transform(items: any[], searchText: string): any[] {
        if (!items) return [];
        if (!searchText) return items;

        searchText = searchText.toLowerCase();

        return items.filter(it => {
            // Fallback to another field if searchValue is empty
            const name = it.searchValue ? it.searchValue.toString() : (it.attribute_name ? it.attribute_name.toString() : '');
            const matches = name.toLowerCase().includes(searchText);
            console.log(`Filtering "${name}" with "${searchText}" - Match: ${matches}`);
            return matches;
        });
    }
}
