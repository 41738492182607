import { Component, Inject } from "@angular/core";
import { FormGroup, FormBuilder, FormArray } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NotificationService } from "../../../services/notification.service";
import { ApiserviceService } from "../../../../apiservice.service";
import { CommonfunctionService } from "../../../../services/commonfunction.service";

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_module_type_entity_attributes_attribute_id_patch</li>
 * </ol>
 *
 */

@Component({
  selector: "changelanguage",
  templateUrl: "../../subelements/admin-changelanguage.component.html",
})
export class ChangeLanguageDialog {
  languageform: FormGroup;
  language_valueset: Array<{}> = [];
  languages: Array<any> = [];
  constructor(
    public dialogRef: MatDialogRef<ChangeLanguageDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder,
    private api: ApiserviceService,
    private fnc: CommonfunctionService,
    private notify: NotificationService
  ) {
    this.languages = this.api.language_list;
    /* #Form - Intial Form Definition */
    this.languageform = this._fb.group({
      id: [],
      language: this._fb.array([]),
    });

    if (this.data.val.labels) {
      let labels = [];
      let index = 0;
      for (let prop in this.data.val.labels) {
        labels[index] = { language: +prop, value: this.data.val.labels[prop] }; // +prop to convert into number
        this.addRow();
        index++;
      }
      this.languageform.patchValue({ language: labels });
    } else {
      this.addRow();
      this.languageform.patchValue({
        language: [{ language: this.languages[0].language_id, value: "" }],
      });
    }
  }

  saveLabels(languageform) {
    let value = languageform.value;
    let update_label = {
      attribute_label: {},
      updated_by: this.api.user_id,
      assignment_id: this.data.parent.selected_assignment,
    };
    value.language.forEach((v, k) => {
      update_label.attribute_label[v.language] = v.value;
    });

    let url =
      this.data.parent.selected_module +
      "/" +
      this.data.parent.selected_entity_type +
      "/attributes/" +
      this.data.val.id;
    this.api.patchEmsData(url, update_label).subscribe({
      next: (data: any) => {
        this.notify.notify("Attribute label updated", "success");
        this.data.parent.getFields("sort_order", 10, 0);
      },
      error: (err) => this.notify.notify(err, "error"),
      complete: () => this.closeDialog(),
    });
  }

  initLanguageFields() {
    return this._fb.group({
      language: [],
      value: [""],
    });
  }

  addRow() {
    if (this.language_valueset?.length == this.languages?.length) {
      this.notify.notify("No more Languages Available to Add", "warn", 4000);
      return;
    }
    this.language_valueset.push({ language: "", value: "" });
    /* #Form */
    let dd = <FormArray>this.languageform.controls.language;
    dd.push(this.initLanguageFields());
    /* End - #Form */
  }

  removeRow(key) {
    if (this.language_valueset.length > 1) {
      this.language_valueset.splice(key, 1);
      let dd = <FormArray>this.languageform.controls.language;
      dd.removeAt(key);
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
