import { Component, Inject } from "@angular/core";
import { FormBuilder, Validators, FormArray } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiserviceService } from "../../../../apiservice.service";
import { CommonfunctionService } from "../../../../services/commonfunction.service";
import { MatErrorStateMatcher } from "../../../admin-error-state-macher";
import { NotificationService } from "../../../services/notification.service";


/**
*
* <strong>List of API using</strong>
* <ol>
* <li>estapi_masters_master_id_patch</li>
* <li>estapi_masters_post</li>
* </ol>
*
*/



@Component({
  selector: 'master-dialog',
  templateUrl: 'admin-createmaster.component.html',
  styleUrls: ['admin-create-master.scss']
})
export class CreateMaster {
  masterForm: any;
  public add_master: any;
  public edit_master: any;
  public master_label;
  public parent_master: any;
  public masters: any = [];
  languages: Array<any>;
  language_valueset: Array<{}> = [];
  public group_id: any;
  public master_id: number;
  duplicateLang: any;
  get masterName(){
    return this.masterForm.get('master_name');
  }
  matcher = new MatErrorStateMatcher();
  constructor(
    public dialogRef: MatDialogRef<CreateMaster>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public api: ApiserviceService,
    private fb: FormBuilder,
    private notify: NotificationService,
    private fnc: CommonfunctionService) {
      console.log(this.data);

    this.getLanguages().then((res: any) => {
      this.languages = res;
      // this.initForm();
    }).catch((err) => {
      console.log('error from getLanguages', err);
    });

    this.masterForm = this.fb.group({
      master_name: ['', [Validators.required,Validators.pattern('^[a-zA-Z0-9_]*$')]],
      language: this.fb.array([]),
      parent_master: [''],
    });

    //console.log(this.data.master_val);
    if (this.data.edit) {
      this.masterForm = this.fb.group({
        master_name: [{ value: '', disabled: true }],
        language: this.fb.array([]),
        // value: ['', Validators.required],
        parent_master: [{ value: '', disabled: true }],
      });

      let editData = this.data.master_val, labels: any = [], index: number = 0;
      if (editData.label) {
        for (let prop in editData.label) {
          labels[index] = { language: +prop, value: editData.label[prop] };  // +prop to convert into number
          this.addRow();
          index++
        }
      }else if(this.data.sub_master && editData.master_label){
        for (let prop in editData.master_label) {
          labels[index] = { language: +prop, value: editData.master_label[prop] };  // +prop to convert into number
          this.addRow();
          index++
        }
      }
      setTimeout(() => {
        this.masterForm.patchValue({
          'master_name': this.data.master_val.name,
          'parent_master': this.data.master_val.parent_id,
          'language': labels,
        });
      });





      if (this.data.sub_master) {
        this.group_id = this.data.master_val.master_group_id;
        this.master_id = this.data.master_val.master_id;
      }
      else {
        this.master_id = this.data.master_val.id;
      }
      let var1 = this.fnc.getArrayValue('id', this.group_id, this.data.pObj.master_group_list);
      if (var1.length) this.masters = fnc.getDeletedArray('id', this.data.master_val.id, var1.childrens)
    } else {
      this.addRow();
      this.group_id = this.data.group_val.id;
      let var1 = this.fnc.getArrayValue('id', this.group_id, this.data.pObj.master_group_list);
      this.masters = var1.childrens; // getting list of master
    }
    this.add_master = {
      added_by: this.api.user_id,
      is_property_type_dependent: false,
      master_group_id: this.group_id,
      master_label: { 1: "" },
      master_name: "",
      parent_master_id: null,
      status: 1
    };
    this.edit_master = {
      updated_by: this.api.user_id,
      is_property_type_dependent: false,
      master_group_id: this.group_id,
      master_label: { 1: "" },
      master_name: "",
      parent_master_id: null,
      status: 1
    };
    if (this.data.edit) {
      this.master_label = this.data.master_val.title;
      this.edit_master.master_name = this.data.master_val.name;
      this.parent_master = this.data.master_val.parent_id;
    }
    if (this.data.sub_master) {
      this.master_label = this.data.master_val.master_label[this.api.language];
      this.edit_master.master_name = this.data.master_val.master_name;
      this.parent_master = this.data.master_val.parent_master_id;
    }

  }

  closeDialog(): void {

    this.dialogRef.close();
  }
  // selected duplicate language
  selectedLang(languageList){
    languageList.forEach(lang => {
      this.duplicateLang = languageList.filter((val) => val.language === lang.language); // filtering duplicate languages list
    });
}

  createMaster(e) {
    let langList = this.masterForm.value.language; // selected language list
    this.selectedLang(langList)
    let isEnglishSelected = langList.find(ele => ele.language == 1); // finding english language selected or not
    if(this.duplicateLang.length > 1){
      this.notify.notify('You have selected same language multiple time', 'warn'); // if selected same language multiple time
      return
     }
    if(!isEnglishSelected){
      this.notify.notify('English language is required', 'warn'); // if english language not selected
      return
    }
    let val = e.value;
    let re = /\ /gi;
    if (this.data.edit) {
      let label = {};
      val.language.forEach(element => {
        label[element.language] = element.value;
      });
      this.edit_master.master_label = label;
      this.edit_master.parent_master_id =  val.parent_master;
      this.api.patchEmsData('masters/' + this.master_id, this.edit_master)
        .subscribe(
         {next : (data : any) => {
            this.notify.notify('Master Updated', 'success');
            if (!this.data.sub_master) {
              this.data.pObj.getMaster('master_name', 1000, 0);
            } else {
              // console.log("sub master");
              this.data.pObj.getSingleMaster(this.data.pObj.selected_list_item);
              this.data.master_val.master_label[this.api.language] = this.master_label;
            }
          },
          error : (err) => { this.notify.notify(this.fnc.getErrorMessage(err), 'error') },
          complete:   () => this.closeDialog()
        }
        );
    } else {
      let label = {};
      val.language.forEach(element => {
        label[element.language] = element.value;
      });
      this.add_master.master_label = label;
      this.add_master.master_name = val.master_name.replace(re, '_');
      this.add_master.parent_master_id = val.parent_master;
      this.api.postEmsData('masters', this.add_master)
        .subscribe(
         {next: (data:any) => {
            if(data.master_id){
              this.notify.notify('Master Created', 'success');
              this.data.pObj.getMaster('master_name', 1000, 0);
              this.closeDialog()
            }else{
              this.notify.notify(data.message, 'error', 2000);
            }
          },
           error : (err) => this.notify.notify(this.fnc.getErrorMessage(err), 'error'),}
        );
    }

  }
  getLanguages() {
    return new Promise((resolve, reject) => {
      this.api.getUmsData('language').subscribe(
      { next: (res: any) => {
        let langList = [];
        if (res.data.length) {
          res.data.forEach(element => {
            if (element.status == 1) {
              langList.push(element);
            }
          });
          resolve(langList);
        }
        // if (res.data.length) {
        //   console.log(res.data);
        //   resolve(res.data);
        // }

      }, error : (err) => {
        reject(err);
      }}
      )
    })
  }

  initLanguageFields() {
    return this.fb.group({
      language: ['', Validators.required],
      value: ['', Validators.required]
    });
  }
  addRow() {
    if (this.language_valueset?.length == this.languages?.length) {
      this.notify.notify("No more Languages Available to Add",'warn',4000);
      return;
    }
    this.language_valueset.push({ language: '', value: '' });
    /* #Form */
    let dd = <FormArray>this.masterForm.controls.language;
    dd.push(this.initLanguageFields());
    /* End - #Form */
  }

  removeRow(key) {
    if (this.language_valueset.length > 1) {
      this.language_valueset.splice(key, 1);
      let dd = <FormArray>this.masterForm.controls.language;
      dd.removeAt(key);
    }
  }



}
