<mat-toolbar class="tool">
    {{incoming.label}}
</mat-toolbar>

<div fxLayout="row" fxLayoutAlign="start center" class="p-3 bg-light mb-2" *ngIf="data_type == 'multi-select'">
    <mat-form-field class="w-100">
        <input type="search" [formControl]="searchForm" matInput placeholder="Search">
        <mat-icon *ngIf="searchterm" matSuffix class="lnk" (click)="clear()">close</mat-icon>
        <mat-hint *ngIf="!mt_error && searchterm && filteredList.length == 0 && filteredSelectedValues.length == 0" class="text-danger"
            align="start">Master Value is not available</mat-hint>
        <!-- <mat-hint *ngIf="mt_error" class="err">
            Please clear the field before saving data.
        </mat-hint> -->
    </mat-form-field>
    <!-- <button mat-mini-fab class="fab-blue" *ngIf="showAddButton && editable">
        <mat-icon matSuffix class="lnk" (click)="add()">add</mat-icon>
    </button> -->
</div>

<!-- search for single select -->
<div fxLayout="row" fxLayoutAlign="start center" class="p-3 bg-light mb-2" *ngIf="data_type == 'single-select'">
    <mat-form-field class="w-100">
        <input type="search" [formControl]="searchForm" matInput placeholder="Search">
        <mat-icon *ngIf="searchterm" matSuffix class="lnk" (click)="clear()">close</mat-icon>
        <mat-hint *ngIf="!mt_error && searchterm && filteredSingleList.length == 0" align="start" class="text-danger">
            Master Value is not available
        </mat-hint>
        <!-- <mat-hint *ngIf="mt_error" class="err">
            Please clear the field before saving data.
        </mat-hint> -->
    </mat-form-field>
    <!-- <button mat-mini-fab class="fab-blue" *ngIf="showAddButton && editable">
        <mat-icon matSuffix class="lnk" (click)="add()">add</mat-icon>
    </button> -->
</div>

<mat-dialog-content class="rel">
    <mat-progress-bar *ngIf="mainLoader" color="primary" class="top-abs" mode="buffer"></mat-progress-bar>
    <div fxLayout="row" fxLayoutAlign="start center">
        <div class="grid-2" *ngIf="data_type == 'multi-select'">
            <div>
                <strong>Values</strong>
                <div class="ovr-auto">
                    <div cdkDropList #valuesList="cdkDropList" [cdkDropListData]="filteredList"
                        [cdkDropListConnectedTo]="[selectedValuesList]" class="item-list"
                        (cdkDropListDropped)="drop($event, 'left')">
                        <ng-container *ngFor="let item of filteredList">
                            <div *ngIf="item != null" class="vals" [ngClass]="{'selectionbox': !mobile, 'selectionbox-mobile': mobile}"
                                cdkDrag cdkDragLockAxis="x">
                                <span class="inl"  #tooltip1="matTooltip" matTooltip="{{item.label}}"
                                matTooltipPosition="above" (click)="tooltip1.toggle()">{{item.label}}</span>
                            </div>
                        </ng-container>

                    </div>
                </div>
            </div>
            <div>
                <strong>Selected Values</strong>
                <div class="ovr-auto">
                    <div cdkDropList #selectedValuesList="cdkDropList" [cdkDropListData]="filteredSelectedValues"
                        [cdkDropListConnectedTo]="[valuesList]" class="item-list"
                        (cdkDropListDropped)="drop($event, 'right')">
                        <ng-container *ngFor="let item of filteredSelectedValues">
                            <div *ngIf="item != null" class="vals-selected"
                                [ngClass]="{'selectionbox': !mobile, 'selectionbox-mobile': mobile}" cdkDrag
                                cdkDragLockAxis="x">
                                <span class="inl" #tooltip2="matTooltip" matTooltip="{{item.label}}"
                                matTooltipPosition="above" (click)="tooltip2.toggle()">{{item.label}}</span></div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="grid-1" *ngIf="data_type == 'single-select'">
            <div class="ovr-auto w-100">
                <div class="item-list" *ngIf="singleList.length>0">
                    <ng-container *ngFor="let data of filteredSingleList">
                        <div *ngIf="data != null" [ngClass]="{'vals-selected': selectedItem && selectedItem.id == data.id, 'singleselectionbox-mobile': mobile, 'singleselectionbox': !mobile}"
                            (click)="selectItem(data);">
                            <span class="inl" matTooltip="{{data.label}}" matTooltipPosition="above" (click)="tooltip3.toggle()" #tooltip3="matTooltip">{{data.label}}</span></div>
                    </ng-container>

                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="dialog-actions" *ngIf="data_type == 'multi-select'">
    <button *ngIf="editable" mat-raised-button class="bluebtn" (click)="save()">Save</button>
    <button mat-raised-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="dialog-actions" *ngIf="data_type == 'single-select'">
    <button *ngIf="editable" mat-raised-button class="bluebtn" (click)="saveSingle()">Save</button>
    <button mat-raised-button (click)="cancel()">Cancel</button>
</mat-dialog-actions>
