<div class="wide-1">
    <mat-toolbar class="h45p w-100 rel">
        <button mat-icon-button (click)="toggle()">
            <mat-icon>arrow_forward</mat-icon>
        </button>
        <h1 class="mat-h1">Location & Address</h1>
        <button [disabled]="!selectedParcelPolygon" class="clear-btn" mat-stroked-button color="primary" (click)="clearDrawing(true)">Clear filter</button>
        <div class="map-toolbar" *ngIf="toggleFilters">
            <button [color]="(selectionMode)? 'primary': 'default'" mat-raised-button (click)="toggleSelection()">
                <mat-icon>touch_app</mat-icon>
                Selection
            </button>
            <div class="fixed-width" title="Draw area">
                <button mat-raised-button [color]="(mode == 'Circle')? 'primary': ''"
                    (click)="drawCircle()"><mat-icon class="iconfx" svgIcon="circle"></mat-icon>
                    Circle
                </button>
            </div>
            <div class="fixed-width" title="Draw area">
                <button mat-raised-button [color]="(mode == 'Polygon')? 'primary': ''"
                    (click)="drawPolygon()"><mat-icon class="iconfx" svgIcon="polygon"></mat-icon>
                    Polygon
                </button>
            </div>
        </div>
    </mat-toolbar>

    <div class="w-100 map-form-grid">
        <div class="rel">
            <ngx-loading [show]="loader_map" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.8)'}"></ngx-loading>
            <div #gmap class="map"></div>
        </div>

        <div class="p-4 rel bottom-part">
            <div class="d-flex align-items-center">
              <span *ngIf="!enableEdit">
                <strong class="highlight">Address: &nbsp;&nbsp;</strong>
                <span *ngIf="displayAddress.parcel_no"><strong>Parcel Number:
                    </strong>{{displayAddress.parcel_no}}&nbsp;&nbsp;</span>
                <span *ngIf="displayAddress.house_no"><strong>House Number:
                    </strong>{{displayAddress.house_no}}&nbsp;&nbsp;</span>
                <span *ngIf="displayAddress.building_name"><strong>Building Name:
                    </strong>{{displayAddress.building_name}}&nbsp;&nbsp;</span>
                <span *ngIf="displayAddress.lane_name"><strong>Lane Name:
                    </strong>{{displayAddress.lane_name}}&nbsp;&nbsp;</span>
                <span *ngIf="displayAddress.street_name"><strong>Street Name:
                    </strong>{{displayAddress.street_name}}&nbsp;&nbsp;</span>
                <span *ngIf="displayAddress.city_name"><strong>City:
                    </strong>{{displayAddress.city_name}}&nbsp;&nbsp;</span>
                <span *ngIf="displayAddress.block_name"><strong>Block:
                    </strong>{{displayAddress.block_name}}&nbsp;&nbsp;</span>
                <span *ngIf="displayAddress.neigh_name"><strong>Neighbourhood:
                    </strong>{{displayAddress.neigh_name}}</span>
            </span>
            <button class="editb" matTooltip="Edit"
                *ngIf="(!enableEdit && property_status == '2') || (!enableEdit && property_status == '7') || (!enableEdit && property_status == '2,3,7')" (click)="edit()">
                <mat-icon class="icon-sm">edit</mat-icon>
            </button>
            </div>

            <div class="rel" id="editable" fxLayout="row" fxLayoutAlign="start start" *ngIf="enableEdit">
                <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.7)'}"></ngx-loading>
                <ng-container>
                    <form [formGroup]="addressForm" (ngSubmit)="save(addressForm)" class="w-100">
                        <div class="w-100" fxLayout="row" fxLayoutAlign="start start" style="column-gap: 4rem;">
                            <div>
                                <div class="grid-separate">
                                    <strong>Building Name</strong>
                                    <input formControlName="building_name" type="text" matInput
                                        class="form-input-l" placeholder="Enter building name" />
                                </div>
                                <div class="grid-separate">
                                    <strong>Parcel Number</strong>
                                    <input formControlName="parcel_no" type="text" matInput class="form-input-l"
                                        placeholder="Enter parcel number" />
                                </div>
                                <div class="grid-separate">
                                    <strong>House Number</strong>
                                    <input formControlName="house_no" type="text" matInput class="form-input-l"
                                        placeholder="Enter house number" />
                                </div>
                                <div class="grid-separate">
                                    <strong>Lane Name</strong>
                                    <input formControlName="lane_name" type="text" matInput class="form-input-l"
                                        placeholder="Enter lane name" [matAutocomplete]="lanes" />
                                    <mat-autocomplete #lanes="matAutocomplete">
                                        <mat-option *ngFor="let data of lanedata | async" [value]="data.name">
                                            <div>
                                                <span>{{ data.name }}</span>
                                            </div>
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                            </div>
                            <div>
                                <div class="grid-separate">
                                    <strong>Street Name</strong>
                                    <input formControlName="street_name" type="text" matInput
                                        class="form-input-l" placeholder="Enter street name"
                                        [matAutocomplete]="streets" />
                                    <mat-autocomplete #streets="matAutocomplete">
                                        <mat-option *ngFor="let data of streetdata | async" [value]="data.name">
                                            <div>
                                                <span>{{ data.name }}</span>
                                            </div>
                                        </mat-option>
                                    </mat-autocomplete>
                                </div>
                                <div class="grid-separate">
                                    <strong>Block</strong>
                                    <input formControlName="block_name" type="text" matInput class="form-input-l"
                                        placeholder="Block" />
                                </div>
                                <div class="grid-separate">
                                    <strong>Neighbourhood</strong>
                                    <input formControlName="neigh_name" type="text" matInput class="form-input-l"
                                        placeholder="Neighbourhood" />
                                </div>
                            </div>
                        </div>
                        <!-- <div class="grid-separate">
                            <strong>City</strong>
                            <input formControlName="city_name" type="text" matInput class="form-input-l" />
                        </div> -->
                        <div class="action-btns">
                          <button type="button" (click)="edit(true)" class="back-btn">
                            <mat-icon class="color-2">arrow_back</mat-icon>
                            <span>Cancel</span>
                          </button>
                          <button class="save" type="submit" [disabled]="isOutSideMoved">
                              <mat-icon class="icon-save">save</mat-icon>
                              <span>Save</span>
                          </button>
                        </div>
                    </form>
                </ng-container>
            </div>
        </div>
    </div>
</div>
