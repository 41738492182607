import { Component, Inject } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { ConfirmDialogBox } from "../../../../admin/confirm-dialog/confirm-dialogbox";
import { NotificationService } from "../../../../admin/services/notification.service";
import { ApiserviceService } from "../../../../apiservice.service";
import { CommonfunctionService } from "../../../../services/commonfunction.service";

/**
*
* <strong>List of API using</strong>
* <ol>
* <li>estapi_getProperties_post</li>
* <li>estapi_module_ty_assignment_name_properties_post</li>
* </ol>
*
*/

@Component({
  selector: 'submit-dialog',
  templateUrl: 'submit-bulk.component.html',
  styleUrls: ['../../qa-qc.component.scss']
})

export class BulkApproveProperty {
  option_primary: any = [
    { label: 'Approve selected property', value: 1 }
  ];
  resource_access: any = {
    'GET': true,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  option_secondary: any = [];
  // is_admin: boolean;
  action: number;
  selectedFilter: any;
  user_comment: any;
  parent: any;
  button = 'Submit';
  selectedIds: number[] = [];
  selected_rows: any = [];
  selected_user_id: any;
  user_list: any = [];
  loader: boolean;
  bulk_approve = {
     'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };

  constructor(public dialogRef: MatDialogRef<BulkApproveProperty>, private notify: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder,
    private fnc: CommonfunctionService, private api: ApiserviceService,
    private matDialog: MatDialog) {
    this.parent = data.parent;
    this.selectedFilter = this.data.parent.property_status;
    this.selectedIds = this.parent.selectedIds;
    this.selected_rows = this.parent.selected_rows;
    this.user_list = data.parent.user_list;
    this.bulk_approve = this.fnc.checkResourceAccess('bulk_approve', false);

    if (this.bulk_approve.POST == true) {
      this.option_primary.push({ label: 'Approve first 5,000 property of this assignment', value: 2 });
    }
    this.option_secondary.push({ label: 'Reject data for ' + '"' + this.parent.selected_group.group_name + '"', value: 8 })
    this.option_secondary.push({ label: 'Reject data for all Groups of selected property', value: 3 })
    this.option_secondary.push({ label: `Reject data for selected Properties, its floors and units`, value: 11 })
    this.option_secondary.push({ label: 'Mark property for review later', value: 10 })

    if(this.parent.property_status == 3){
      this.option_secondary.push({ label: 'Submit Selected property for rework', value: 4 })
    }


    this.resource_access = this.fnc.checkResourceAccess('qa_qc', false);
  }

  assign_user(e) {
    this.selected_user_id = e.value;
  }
  disableOption(option) {
    if (((option == 8 || option == 3) && (this.selectedFilter == 3))) {
      return true;
    }
    if (option == 4 && (this.selectedFilter == 7 || this.selectedFilter == 2)) {
      return true;
    }
    return false;
  }

  /**
   * Open confirmation box before approve bulk property
   * @returns
   */
  openConfirmation(){
    if(this.action == 2 || this.action == 1){
      let _data = {
        parent_data: null,
        message: 'New data added for past year will be deleted.'
      }
      let dialogRef = this.matDialog.open(ConfirmDialogBox,{
        width: '330px',
        data: _data
      })
      dialogRef.afterClosed().subscribe(result => {
        if(result == 'YES'){
         this.submit();
        }else{

        }
      });
    }else{
      this.submit();
    }

  }

  submit() {

    if (!this.resource_access.PATCH) {
      this.parent.notify.notify("You're not authorized to perform update operation in qa/qc property", 'warn');
      return;
    }

    if (this.button == 'Submitting..' || this.selectedIds.length < 1) {
      return;
    }

    this.button = "Submitting..";
    if (this.action == 2) {
      //this.selectedIds = this.parent.allPropertiesIds;
      this.getPropertyIds();
    } else if (this.action == 3 || this.action == 8) {
      let url, body: any = {}, attr = { new_floor_ids: [], new_unit_ids: [] }, attributes = [], bodydata = [];
      this.selected_rows.forEach(element => {
        let info = element;
        if (info.attribute_info) {
          attr = info.attribute_info;
          //clear new floor and unit identification
          this.parent.new_floors_list = [];
          this.parent.new_units_list = [];
          if (attr.new_floor_ids) {
            attr.new_floor_ids = [];
          }
          if (attr.new_unit_ids) {
            attr.new_unit_ids = [];
          }
        }
        if (this.action == 8 && this.parent.attribute_list.length) {
          attributes = this.fnc.getChildArray('group_id', this.parent.selected_group.id, this.parent.attribute_list);
        } else {
          if (this.action == 3) { attributes = this.parent.attribute_list }
        }
        if (attributes.length) {
          attributes.forEach((c) => {
            attr[c.id] = {
              "comment": this.user_comment,
              "status": -1
            }
          });
        }
        body = {
          "attribute_info": attr,
          "data": {},
          "updated_by": this.api.user_id,
          "status": 3,
          "property_id": element.id
        }

        if (element.assigned_to) body['assigned_to'] = element.assigned_to
        bodydata.push(body)
      });
      url = "deadmin/" + this.parent.selected_assignment.name + "/properties";
      this.api.patchEpsData(url, bodydata)
        .subscribe({next:(data: any) => {
          this.parent.selectedIds = [];
          this.parent.notify.notify('Property submited for rework', 'warn');
          this.closeDialog();
          this.parent.getProperties();
        }, error:(err) => {
          this.parent.notify.notify(this.fnc.getErrorMessage(err), 'error');
        }});
    }

    else if (this.action == 11) {
      let url, body: any = {};
      body = {
        "assignment_id": this.parent.selected_assignment.assignment_id,
        "property_id": this.selectedIds.toString(),
        "updated_by": this.api.user_id
      }
      if (this.user_comment) {
        body['comment'] = this.user_comment;
      }
      url = `property/reject`;
      this.api.patchEpsData(url, body)
        .subscribe({next:(data: any) => {
          this.parent.selectedIds = [];
          this.parent.notify.notify('Properties submited for rework', 'warn');
          this.closeDialog();
          this.parent.getProperties();
        }, error:(err) => {
          this.parent.notify.notify(this.fnc.getErrorMessage(err), 'error');
        }});
    }

    else if (this.action == 4 || this.action == 10) { // for reject and review later
      let url, body: any = {}, bodydata = [], attributes = [], attr = {};
      this.selected_rows.forEach(element => {
        body = {
          "attribute_info": attr,
          "data": {},
          "updated_by": this.api.user_id,
          "status": 4,
          "property_id": element.id,
          "assigned_to": this.selected_user_id
        }
        if (this.action == 10) {
          body['review_later'] = true;
          delete body.status; // remove status from body
          delete body.attribute_info;
          delete body.assigned_to;
        }
        bodydata.push(body)
      });

      url = "deadmin/" + this.parent.selected_assignment.name + "/properties";
      this.api.patchEpsData(url, bodydata)
        .subscribe({next:(data: any) => {
          this.parent.selectedIds = [];
          this.parent.notify.notify('Property Assign for rework', 'warn');
          this.closeDialog();
          this.parent.getProperties();
        }, error:(err) => {
          if (err['status'] == 401) {
            this.parent.notify.notify('You are not authorized to modify property', 'warn');
          } else {
            this.parent.notify.notify(this.fnc.getErrorMessage(err), 'error');
          }
        }});
    } else {
      this.approveProperty(true);
    }
  }

  approveProperty(approve_selected: boolean = false) {
    let url, body: any = [];
    this.selectedIds.forEach((v) => {
      if (approve_selected) {
        let property = this.fnc.getArrayValue('id', v, this.parent.property_list);
        if (property.status === 3 || property.status === undefined) {
        } else {
          body.push(
            {
              "property_id": v,
              "status": 5,
              "data": {},
              "updated_by": this.api.user_id,
            }
          )
        }
      } else {
        body.push(
          {
            "property_id": v,
            "status": 5,
            "data": {},
            "updated_by": this.api.user_id,
          }
        )
      }
    });
    if (this.parent.entity_type == 'property') {
      url = "deadmin/" + this.parent.selected_assignment.name + "/properties";
    }
    if (body.length == 0) { this.parent.notify.notify('no property selected', 'warn'); this.button = 'Submit'; return }
    this.loader = true;
    this.api.patchEpsData(url, body)
      .subscribe({next: (data: any) => {
        setTimeout(() => {
          this.loader = false;
          this.parent.notify.notify(data, 'success');
          this.parent.property_list = [];
          this.parent.dataSource = [];
          this.parent.selectedIds = [];
          this.parent.getProperties();
          this.button = 'Submit';
          this.closeDialog();
        }, 400);

      },
        error:() => {
          this.loader = false;
          this.button = 'Submit';
          this.parent.notify.notify('Something went wrong', 'error');
          this.closeDialog();
        }}
      );
  }

  getPropertyIds() {
    let url, assigned_to = "", neigh_uid = '', assignment_name = this.parent.selected_assignment.name,
      block_uid = "", polys = null;
    if (this.parent.selectedPolygons.length) {
      polys = this.parent.selectedPolygons;
    }
    url = 'getProperties';
    let body = {
      "moduleType": "deadmin",
      "assignmentName": assignment_name,
      "assignmentId": this.parent.selected_assignment.id,
      "polygon": polys,
      "sortAsc": false,
      "sortBy": 'property_uid',
      "limit": 5000,
      "offset": 0,
      "status": this.parent.property_status,
      "get_latest_property":"Y"

    }
    if (this.parent.selected_user_id) {
      body["assignedTo"] = this.parent.selected_user_id;
    }
    if (this.parent.block_uid) {
      body["blockUid"] = this.parent.block_uid;
    };
    if (this.parent.neigh_uid) {
      body["neighUid"] = this.parent.neigh_uid;
    };

    this.api.postEpsData(url, body)
      .subscribe({next:(data: any) => {
        this.selectedIds = [];
        this.selected_rows = [];
        data.forEach((v) => {
          if (v.status != 3 && this.action == 2) {
            this.selectedIds.push(v.property_id);
          }
        });
        this.approveProperty();
      }, error:() => {

      }})
  }


  ngOninit() {

  }
  closeDialog() {
    this.dialogRef.close();
  }

}
