import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NotificationService } from "../../../../admin/services/notification.service";
import { ApiserviceService } from "../../../../apiservice.service";
import { CommonfunctionService } from "../../../../services/commonfunction.service";

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_report/copy_post</li>
 * </ol>
 *
 */

@Component({
  selector: "group-dialog",
  templateUrl: "groupListDialog.component.html",
  styleUrls: ["../../admin-configuration.component.css"],
})
export class GroupListDialog {
  group_list: any = [];
  selected_ids: any = [];
  selected_report_ids: any = [];
  progress: number = 0;
  message: string = "";
  selected: any;
  report_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };

  constructor(
    public dialogRef: MatDialogRef<GroupListDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fnc: CommonfunctionService,
    private api: ApiserviceService,
    private notify: NotificationService
  ) {
    let temp = this.data.parent.group_custom_list;
    this.selected_report_ids = this.data.parent.selected_ids;
    this.group_list = this.fnc.getDeletedArray(
      "id",
      this.data.parent.selected_group.id,
      temp
    );
    //this.group_list = this.grouplist.splice(this.grouplist.indexOf(this.data.parent.selected_group.id), 1);
    this.report_access = this.fnc.checkResourceAccess("reports", false);
  }
  showLabel(item) {
    if (item) {
      let label =
        item.label && item.label[this.api.language]
          ? item.label[this.api.language]
          : item.name;
      return label;
    }
  }
  public changeGroupSelectedIds(e: any, value: any) {
    if (e.checked == true) {
      this.selected_ids.push(value.id);
    } else {
      this.selected_ids.splice(this.selected_ids.indexOf(value.id), 1);
    }
  }
  isActive(item) {
    return this.selected === item;
  }

  public copyReport() {
    if (!this.report_access.PATCH) {
      this.notify.notify("You are not authorized to copy report", "warn");
      return;
    }
    this.progress = 10;
    this.message = "copping...";
    let body = {
      report_ids: this.selected_report_ids,
      status: 1,
      target_group_ids: this.selected_ids,
      updated_by: this.api.user_id,
    };
    this.api.postData("report/copy", body).subscribe(
      (data) => {
        this.notify.notify("report copied in group", "success");
        this.data.parent.getReportList(this.data.parent.selected_group);
        this.dialogRef.close();
      },
      (err) => {
        this.notify.notify(this.fnc.getErrorMessage(err), "error");
        this.dialogRef.close();
      }
    );
  }

  public close() {
    this.progress = 0;
    this.dialogRef.close();
  }

  ngOnInit() {}
}
