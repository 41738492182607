<mat-card appearance="outlined" class="m-2">
    <mat-card-header class="mtc-header position-relative d-flex justify-content-between align-items-center">
        <mat-card-title class="title">Settings</mat-card-title>
        <div class="d-flex justify-content-between align-items-center">
            <div class="show-setting">
              <mat-icon class="position-absolute template-list" [matMenuTriggerFor]="indexList" matTooltip="Show Available Servers" >
                  info_outline</mat-icon>
              <mat-menu #indexList="matMenu">
                <div class="table-estater-wrapper">
                  <table mat-table [dataSource]="tableIndexList">
                      <ng-container matColumnDef="name">
                          <th mat-header-cell *matHeaderCellDef class="headerCellMaterial">Name</th>
                          <td mat-cell class="p-3" *matCellDef="let element" class="cellMaterial"> {{element.name}} </td>
                      </ng-container>
                      <ng-container matColumnDef="options">
                          <th mat-header-cell *matHeaderCellDef class="headerCellMaterial"> Options </th>
                          <td class="p-3" mat-cell *matCellDef="let element" class="cellMaterial"> {{element.options}} </td>
                      </ng-container>
                      <tr mat-header-row *matHeaderRowDef="serverListCols"></tr>
                      <tr mat-row *matRowDef="let row; columns: serverListCols;" class="rowMaterial"></tr>
                  </table>
                </div>
              </mat-menu>
            </div>
            <input class="search-input" [formControl]="searchField" type="text" name="search" placeholder="Search...">
            <div class="show-deleted">
                <!-- Show Deleted-->
                <mat-slide-toggle class="deleted-row" (change)="getDeleted($event)"  [(ngModel)]="deletetoggle">
                    Show Deleted
                </mat-slide-toggle>
            </div>
            <button mat-raised-button (click)="openDailog('add')">
                Add Setting
            </button>
        </div>
    </mat-card-header>
    <mat-progress-bar mode="indeterminate" color="accent" *ngIf="loader"></mat-progress-bar>
    <mat-card-content class="p-0">
        <div class="w-100 calc-h">
            <div class="settings-table mt-4 table-estater">
                <mat-table [dataSource]="dataSource" class="w-100" style="white-space: nowrap;">
                    <!--SN Column -->
                    <ng-container matColumnDef="sn">
                        <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutAlign="center center"> <span class="px-4" >S.No.</span></mat-header-cell>
                        <mat-cell *matCellDef="let element; let i = index;" fxLayout="row" fxLayoutAlign="center center">
                            <span class="px-4">{{ i + 1 }}</span>
                        </mat-cell>
                    </ng-container>

                    <!-- Key Column -->
                    <ng-container matColumnDef="key">
                        <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutAlign="center center"> Key </mat-header-cell>
                        <mat-cell *matCellDef="let element;" fxLayout="row" fxLayoutAlign="center center">
                            <span>{{element.key}}</span>
                        </mat-cell>
                    </ng-container>

                    <!-- Value Column -->
                    <ng-container matColumnDef="value">
                        <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutAlign="center center"> Value </mat-header-cell>
                        <mat-cell *matCellDef="let element;" fxLayout="row" fxLayoutAlign="center center">
                            <span class="_bg view_value text-center" (click)="editValue(element, 'value')"
                                *ngIf="selectedCellId != element.id+'_value'"
                                title="{{element.value}}">{{element.value}}</span>
                            <input matInput type="text" class="input-box" [(ngModel)]="changed_value"
                                *ngIf="selectedCellId == element.id+'_value'">
                            <div class="mlr-2" fxLayout="row" fxLayoutAlign="center center"
                                *ngIf="selectedCellId == element.id+'_value'">
                                <mat-button-toggle class="marg-2" value="left" (click)="edit_setting(element)"
                                    title="Save">
                                    <mat-icon class="size-sm accent">save</mat-icon>
                                </mat-button-toggle>
                                <mat-button-toggle class="marg-2" value="center" (click)="cancelEdit(element.value)"
                                    title="Cancel">
                                    <mat-icon class="size-sm"
                                        [ngClass]="{'default': selectedCellId == element.value, 'inactive': selectedCellId != element.value}">
                                        close</mat-icon>
                                </mat-button-toggle>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <!-- Type Column -->
                    <ng-container matColumnDef="type">
                        <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutAlign="center center"> Type </mat-header-cell>
                        <mat-cell *matCellDef="let element;" fxLayout="row" fxLayoutAlign="center center">
                            <span>{{element.type}}</span>
                        </mat-cell>
                    </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="status">
                        <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutAlign="center center"> Status </mat-header-cell>
                        <mat-cell *matCellDef="let element;" fxLayout="row" fxLayoutAlign="center center">
                            <mat-slide-toggle class="example-margin" [checked]="element.status == 1 ? true: false"
                                (change)="updateStatus(element.id,$event)">
                            </mat-slide-toggle>
                        </mat-cell>
                    </ng-container>

                    <!-- Status Column -->
                    <ng-container matColumnDef="added_by">
                        <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutAlign="center center"> Added By </mat-header-cell>
                        <mat-cell *matCellDef="let element;" fxLayout="row" fxLayoutAlign="center center">
                            <span>{{element.added_by}}</span>
                        </mat-cell>
                    </ng-container>

                    <!-- Added Date Column -->
                    <ng-container matColumnDef="added_date">
                        <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutAlign="center center"> Added Date </mat-header-cell>
                        <mat-cell *matCellDef="let element;" fxLayout="row" fxLayoutAlign="center center">
                            <span>{{element.added_date | date:'dd MMM yyyy'}}</span>
                        </mat-cell>
                    </ng-container>

                    <!-- action Column -->
                    <ng-container matColumnDef="action">
                        <mat-header-cell *matHeaderCellDef fxLayout="row" fxLayoutAlign="center center"> Action </mat-header-cell>
                        <mat-cell *matCellDef="let element;" fxLayout="row" fxLayoutAlign="center center">
                            <button *ngIf="status!=-1" mat-mini-fab color="warn" (click)="openConfirmation(element)"
                                title="delete">
                                <mat-icon class="delete_icon">delete</mat-icon>
                            </button>
                        </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true">
                    </mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;" class="dragx1"></mat-row>
                </mat-table>
            </div>
        </div>

        <div>
          <mat-paginator #paginator [length]="total" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageAction($event)">
          </mat-paginator>
        </div>
    </mat-card-content>
</mat-card>
