import { Component } from '@angular/core';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { ApiserviceService } from '../../../../src/app/apiservice.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../src/environments/environment';
import { NotificationService } from '../services/notification.service';
import { CommonfunctionService } from '../../../../src/app/services/commonfunction.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-visibility-calculation',
  templateUrl: './admin-visibility-calculation.component.html',
  styleUrls: ['./admin-visibility-calculation.component.scss']
})
export class AdminVisibilityCalculationComponent {
  eps_log_path =
  environment.epsUrl + "download-file?file_url=" //+ environment.eps_log_path;
  propertyList: any;
  startYear: number;
  currentYear: number = new Date().getFullYear();;
  visibilityFormGroup:FormGroup;
  loading = false;
  offset: number = 0;
  limit: number = 10;
  totalCount: number;
  visibility_calculation_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };
  statusMap = {
    "-1": { label: "Invalid", color: "#FF4A4A" },
    "1": { label: "Valid", color: "#81A2FF" },
    "2": { label: "Accepted", color: "#0FEB03" },
    "-2": { label: "Accept Invalid", color: "#E3DB05" },
    "0": { label: "Uploaded", color: "#AAAAAA" },
    "-3": { label: "Rejected", color: "#FF4A4A" },
    "4": { label: "Accept Progress", color: "#FF8A3E" },
    "3": { label: "Validating", color: "#ACA710" },
  };
    // Buttons configuration
    buttonConfig = {
      log: [
        {
          label: "Validate Log",
          action: (element) => this.validateLog(element),
          style: {
            hover: {
              "background-color": "#e0e8ff",
            },
            disabled: {
              color: "gray",
              "background-color": "#f0f0f0",
            },
          },
          class: "log-button",
        },
        {
          label: "Accept Log",
          action: (element) => this.acceptLog(element),
          style: {
            hover: {
              "background-color": "#d0ffd0",
            },
            disabled: {
              color: "darkgray",
              "background-color": "#e0e0e0",
            },
          },
          class: "log-button",
        },
        {
          label: "Error Log",
          action: (element) => this.errorLog(element),
          style: {
            hover: {
              "background-color": "#d0ffd0",
            },
            disabled: {
              color: "darkgray",
              "background-color": "#e0e0e0",
            },
          },
          class: "log-button",
        },
        {
          label: "Accept Error",
          action: (element) => this.errorAcceptLog(element),
          style: {
            hover: {
              "background-color": "#d0ffd0",
            },
            disabled: {
              color: "darkgray",
              "background-color": "#e0e0e0",
            },
          },
          class: "log-button",
        },
      ],
      action: [
        {
          label: "Accept",
          action: (element) => this.acceptAndReject(element, 'accept'),
          style: {
            border: "1px solid #008000",
            color: "green",
            padding: "5px 10px",
            width: "100px",
            "background-color": "#eaffea",
            hover: {
              "background-color": "#d9ffd9",
            },
            disabled: {
              color: "#808080",
              background: "red",
            },
          },
          // disabled: (element) => element.status !== '1', // Disable if status is not "Valid"
        },
        {
          label: "Reject",
          action: (element) => this.acceptAndReject(element, 'reject'),
          style: {
            border: "1px solid #ff0000",
            color: "red",
            padding: "5px 10px",
            width: "100px",
            "background-color": "#ffeaea",
            hover: {
              "background-color": "#ffdada",
            },
            disabled: {
              color: "darkred",
              "background-color": "#e0e0e0",
            },
          },
          // disabled: (element) => element.status !== '1', // Disable if status is not "Valid"
        }, {
          label: "Download",
          action: (element) => this.downloadFile(element),
          style: {
            border: "1px solid #008000",
            color: "green",
            padding: "5px 10px",
            width: "100px",
            "background-color": "#eaffea",
            hover: {
              "background-color": "#d9ffd9",
            },
            disabled: {
              color: "#808080",
              background: "red",
            },
          },
          // disabled: (element) => element.status !== '1', // Disable if status is not "Valid"
        },
      ],
    };
    selectedColumns: string[] = ['upload_id', 'file_name','added_by','added_date','accept_time','accept_date','validate_time','updated_date','updated_by','status','log','action'];;
    selectedData: any[] = [];
  constructor(
    private menus: AdminMenusService,
    private api: ApiserviceService,
    private fb:FormBuilder,
    private notify: NotificationService,
    private fnc: CommonfunctionService,
    private route: Router,
  ) {
    this.menus.setActive("visibility-calculation");
  }
  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this. getAllowResourcesApi()
    this.visibilityFormGroup = this.fb.group({
      propertyDetailsControl:['', Validators.required]
    })
  }
  selectionChange(){

  }
    /**
   * Fetches allowed resources from the API and sets access rights.
   */
    getAllowResourcesApi() {
      let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`;
      this.api.getUmsData(body).subscribe({
        next: (res: any) => {
          this.api.allowResources = res.data;
          this.getAllowResource();
        },
        error: (err) => { }
      });
    }

    /**
     * Sets access rights for attribute deletion and navigates if access is denied.
     */
    getAllowResource() {
      this.visibility_calculation_access = this.fnc.checkResourceAccess('visibility_calculation', false);
      if (!this.visibility_calculation_access.GET) {
        this.route.navigateByUrl('');
        return;
      }else{
        this.getPropertyDetails()
        this.getCalcutionData()
      }
    }

  getPropertyDetails(){
    let url = `calculation-properties?layer=unit&limit=1000`;
    this.api.getEpsData(url).subscribe({
      next:(res:any)=>{
        this.propertyList = res.data;
        console.log(this.propertyList);

      },
    error:(err)=>{}})
  }

  calculate(){
    if (!this.visibility_calculation_access.POST) {
      this.notify.notify('Access Denied: You are not authorized for this operation','warn')
      return;
    }
    let url = "calculate-visibility";
    let body = {
      property_id: this.visibilityFormGroup.value.propertyDetailsControl,
      "floor_id": 0,
      "observer_ids": [],
      "radius": 0,
      layer:'observer_visibility',
      user_id:this.api.user_id,
      year:this.currentYear,
      name:'observer_visibility'
    }
    this.loading = true;
    this.api.postEpsData(url, body).subscribe(
      {next:(res:any)=>{
      if(res.status == 200){
        console.log(res);
        this.loading = false;
        this.notify.notify(res.message, 'success')
        this.getCalcutionData();
      }else{
        this.loading = false;
        this.notify.notify(res.message, 'warn')
      }
      },
      error:(err)=>{
        this.loading = false;
      }})
  }
  downloadFile(element = null){
    console.log(element);

    if (!this.visibility_calculation_access.POST) {
      this.notify.notify('Access Denied: You are not authorized for this operation','warn')
      return;
    }
    let url = `calculation-download?&property_id=${this.visibilityFormGroup.value.propertyDetailsControl}&upload_id=${element.upload_id}`;
    this.api.getEpsData(url).subscribe({
      next:(res:any)=>{
        console.log(res);
        let exportFileUrl = `${environment.epsUrl}visibility-download-file?city_code=${this.api.city_code.toLowerCase()}&file_name=${res.data}`
          window.open(exportFileUrl, "_blank")
      },
     error:(err:any)=>{}})
  }
  pageChangeAction(event) {
    console.log(event);
    this.limit = event.limit;
    this.offset = event.offset;
    this.getCalcutionData()
  }
  getCalcutionData(){
    let url = `get-calculation-list?layer=observer_visibility&year=${this.currentYear}&is_count=true&limit=${this.limit}&offset=${this.offset}`;
    this.loading = true;
    this.api.getEpsData(url).subscribe({
      next:(res:any)=>{
        this.loading = false;
        if(res.status == 200){
          this.selectedData = res.data;
          this.notify.notify(res.message, 'success', 5000)
          this.loading = false;
          this.totalCount = res.data[0].count
        }else{
          this.notify.notify(res.message, 'warn')
          this.loading = false;
        }
      },
     error:(err:any)=>{
      this.selectedData = [];
      this.totalCount = 0;
      this.loading = false;
     }})
  }
  acceptAndReject(element, action){
    if (!this.visibility_calculation_access.PATCH) {
      this.notify.notify('Access Denied: You are not authorized for this operation','warn')
      return;
    }
    let url = `calculation-upload-action?user_id=${this.api.user_id}&action=${action}&upload_id=${element.upload_id}`;
    this.loading = true;
    this.api.getEpsData(url).subscribe({
      next:(res:any)=>{
        this.loading = false;
        if(res.status == 200){
          console.log(res);
          this.notify.notify(res.message, 'success')
          this.getCalcutionData();
        }else{
          this.notify.notify(res.message, 'warn')
          this.loading = false;
        }

      },
     error:(err:any)=>{
      this.loading = false;
     }})
  }
  validateLog(element){
    this.downloadLog(`_visibility_validate_${element.upload_id}.log`);
  }

  acceptLog(element) {
    this.downloadLog(`_visibility_accept_${element.upload_id}.log`);
  }
  errorAcceptLog(element) {
    this.downloadLog(`_visibility_accept_${element.upload_id}_err.log`);
  }
  errorLog(element) {
    this.downloadLog(`_visibility_validate_${element.upload_id}_err.log`);
  }

   /**
   * log file is downloading here
   * @param log_url
   */
   downloadLog(type) {
    let log_url = `${
      this.eps_log_path
    }${this.api.city_code.toLocaleLowerCase()}${type}`;
    console.log(log_url, "log_url",  this.eps_log_path);

    window.open(log_url.toString(), "_blank");
  }
}
