<form [formGroup]="visibilityFormGroup">
  <div class="visibility-wrapper">
    <div class="outer-container">
      <div class="header">
        <h5>Visibility Calculation</h5>
      </div>
      <div class="upload-section">
        <!-- <app-admin-file-upload (getAfterUpload)="AfterUploadFile()"></app-admin-file-upload> -->
      </div>
      <!-- <button type="button" (click)="getObserverAdmData()" class="sync-btn"> <img src="../../../assets/icon/sync-icon.svg" alt=""> </button> -->
    </div>
    <div>
    <div class="d-flex align-items-center gap flex-wrap">
      <div class="ml-10 d-flex align-items-center">
        <label for="">Select Property UID <span class="mandatory">*</span> : </label>
        <mat-select
          (selectionChange)="selectionChange()"
          placeholder="Select Property UID"
          class="form-select-l"
          formControlName="propertyDetailsControl"
        >
          <mat-option class="cp"
          *ngFor="let propertyItem of propertyList"  [value]="propertyItem.prop_id"> {{propertyItem.uid}} </mat-option>
        </mat-select>
      </div>
      <button class="btn-submit" type="button" (click)="calculate()" [disabled]="visibilityFormGroup.invalid">Calculate</button>
      <!-- <button class="btn-submit" type="button" (click)="downloadFile()" [disabled]="visibilityFormGroup.invalid">Download</button> -->
      <button mat-icon-button  matTooltip="Refresh list" class="refresh" (click)="getCalcutionData()">
        <mat-icon>refresh</mat-icon>
    </button>
    </div>
    </div>
  </div>
</form>
<div class="bbar w-100" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" class="btm"></mat-progress-bar>
</div>

<app-admin-data-table
[data]="selectedData"
[columns]="selectedColumns"
[buttons]="buttonConfig"
[totalCount]="totalCount"
(pageChangeAction)="pageChangeAction($event)"
[statusMap]="statusMap">
</app-admin-data-table>
