<!-- <div [ngClass]="{'sidebarnormal' : isNormal(), 'sidebarsmall' : isSmall()}">
  <mat-toolbar class="headtoolbar" color="primary">
    <div fxLayout="row" class="w-100 lnk" *ngIf="isSmall()" fxLayoutAlign="center center">
      <img routerLink="/admin" src="assets/images/logo-mini.svg" width="50"  />
    </div>
    <div fxLayout="row" class="w-100 lnk" *ngIf="isNormal()" fxLayoutAlign="center center">
        <img routerLink="/admin" src="assets/images/logovert2.png"  width="100" />
    </div>
  </mat-toolbar>
  <mat-nav-list color="primary">
      <ng-container *ngFor="let menus of adminMenus">
        <mat-list-item *ngIf="menus.show"  class="itemlist" [routerLink]="menus.url" [ngClass]="{'active':getActive(menus)}">
          <mat-icon *ngIf="menus.icon" svgIcon="{{menus.icon}}" title="{{menus.title}}"></mat-icon>
          <div class="menu-text" *ngIf="isNormal()">{{menus.title}}</div>
        </mat-list-item>
        </ng-container>
  </mat-nav-list>
</div>  -->

<div [ngClass]="{'sidebarnormal' : isNormal(), 'sidebarsmall' : isSmall()}">
  <mat-toolbar class="headtoolbar" color="primary">
    <div fxLayout="row" class="w-100 lnk" *ngIf="isSmall()" fxLayoutAlign="center center">
      <img routerLink="/admin" src="assets/images/logo-mini-new.svg" width="32"  />
    </div>
    <div fxLayout="row" class="w-100 lnk" *ngIf="isNormal()" fxLayoutAlign="center center">
        <!-- <img routerLink="/admin" src="assets/images/logovert2.png"  width="100" /> -->
        <img routerLink="/admin" src="assets/images/logo.svg"  width="170" />
    </div>
  </mat-toolbar>
  <mat-nav-list color="primary">
      <ng-container *ngFor="let menus of adminMenus">
        <div *ngIf="menus.show"  class="itemlist" [routerLink]="menus.url" [ngClass]="{'active':getActive(menus)}" title="{{menus.title}}">
          <mat-icon *ngIf="menus.icon" svgIcon="{{menus.icon}}" [ngClass]="{'itemlist-deletion': menus.title == 'Attribute Data Deletion'}"></mat-icon>
          <div class="menu-text" *ngIf="isNormal()">{{menus.title}} </div>
        </div>
        </ng-container>
  </mat-nav-list>
</div>
