import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonfunctionService } from '../../../services/commonfunction.service';

@Component({
  selector: 'app-property-other-assignment',
  templateUrl: './property-other-assignment.component.html',
  styleUrls: ['./property-other-assignment.component.css']
})
export class PropertyOtherAssignmentComponent implements OnInit {
  property_list=[]
  constructor(public dialogRef: MatDialogRef<PropertyOtherAssignmentComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private fnc : CommonfunctionService) { 

    }

  ngOnInit(): void {
  }
  getDate(dt) {
    let d = new Date(dt);
    return this.fnc.formatDateUTC(d, true);
  }
  close(){
    this.dialogRef.close();
  }
}
