<div class="position-relative">
  <div class="min-max"><span>{{min}}</span><span>{{max}}</span></div>
  <div class="w3-round-xlarge w3-light-red" *ngIf="percent >= 0  && percent <= 25">
    <div class="w3-container wprogress-h w3-red w3-round-xlarge" [style.width]="percent+'%'">{{percent}}%</div>
  </div>

  <div class="w3-round-xlarge w3-light-orange" *ngIf="percent > 25 && percent <= 70">
    <div class="w3-container wprogress-h w3-orange w3-round-xlarge" [style.width]="percent+'%'">{{percent}}%</div>
  </div>

  <div class="w3-round-xlarge w3-light-green" *ngIf="percent > 70 && percent <= 100">
    <div class="w3-container wprogress-h w3-green w3-round-xlarge" [style.width]="percent+'%'">{{percent}}%</div>
  </div>
</div>
