import { Component, OnInit } from '@angular/core';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { ApiserviceService } from '../../apiservice.service';
import { NotificationService } from '../services/notification.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';


import { MomentDateAdapter } from '@angular/material-moment-adapter';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
// import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
// import { defaultFormat as _rollupMoment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { SocketService } from '../socket.service';


// const moment = _rollupMoment || _moment;

export const YEAR_MODE_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};



@Component({
  selector: 'app-historical-sync-data',
  templateUrl: './historical-sync-data.component.html',
  styleUrls: ['./historical-sync-data.component.css'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: YEAR_MODE_FORMATS },
  ],
})
export class HistoricalSyncDataComponent implements OnInit {

loader: boolean;

public today = new Date();
public mindate = new Date('01-01-1999');

status_emun={
  '1': 'Progress',
  '2' : 'Completed',
  '-1' : 'Failed',
  '-2': 'Progress stopped'
}
total: number;
defaultPageSize: number = 10;
pageSizeOptions;
sizeOptions: string = "10, 15, 25, 50";
pageEvent: PageEvent;
pageIndex = 0;

DataSource = [];
types = ['property','floor','unit'];
yearList = [];

search_year: string = "";
selected_year = new Date().getFullYear().toString();

selected_type:string;
selected_date:any;

limit: number = 10;
offset: number = 0;
table_loader: boolean;
total_record=[];
  sync_history_access: { GET: boolean; PATCH: boolean; POST: boolean; DELETE: boolean; };
  isRequestSent: boolean = false;
constructor(
  private notify: NotificationService,
  private router: Router,
  private api: ApiserviceService,
  private fnc: CommonfunctionService,
  private menus: AdminMenusService,
  private socket: SocketService
) {
  this.menus.setActive("historical-sync-data");

  this.pageSizeOptions = this.sizeOptions.split(",").map((str) => +str);
}
closeMatSelectEvent(event, type){
  if(!event){
    if(type=='year'){
      this.search_year = "";
    }
  }
}
ngOnInit(): void {
  this.getAllowResourcesApi()
  this.getYears();

///getting data from socket
  this.socket.progress$.subscribe((res:any)=>{
    // console.log(res.data);

    if(res.data && res.data.length>0){
       let socket_list = res.data;
       socket_list.map(el=>{
        let socket_obj = el;
        const objeIndex =  this.total_record.findIndex((obj => obj.id == socket_obj.id))
        if(objeIndex != -1){
          this.total_record[objeIndex].record_processed =  socket_obj.record_processed;
          this.createTable();
        }else{
          if(socket_obj.table_name == this.selected_type+"_log"){
            this.total_record.splice(0,0,socket_obj);
            this.createTable();
          }
        }
       })
    }
  })
}
// getting allow resources
getAllowResourcesApi() {
  let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
  this.api.getUmsData(body)
    .subscribe({next: (res: any) => {
      this.api.allowResources = res.data;
      this.sync_history_access = this.fnc.checkResourceAccess('sync_history');

    },error: (err) => {
    }})
}

//create year dropdown
getYears() {
  const currentYear = new Date().getFullYear()
  const startYear  = 2009;
  for (var i = currentYear; i >= startYear; i--) {
    this.yearList.push({ val: (i).toString() });
  }

}

pageAction($event) {
  this.pageIndex = $event.pageIndex;
  if (this.limit != $event.pageSize) {
    this.limit = $event.pageSize;
  } else {
    this.offset = this.pageIndex * $event.pageSize;
  }
  this.getSynctatus();
}

syncData() {
  if(!this.sync_history_access.POST){
    this.notify.notify('You are not authorize to sync historical data', 'warn');
    return
  }
  if (!this.selected_type) {
    this.notify.notify("Type is required", "warn");
    return;
  } else if (!this.selected_year) {
    this.notify.notify("Year is required", "warn");
    return;
  }
  let url = `sync-history-data?type=${this.selected_type}&year=${this.selected_year}&sync_date=${this.formatDateaApi(this.selected_date)}`
  this.loader = true;
  this.isRequestSent = true;
  this.api
    .getEpsData(url)
    .subscribe(
      {
        next: (res: any) => {
          this.notify.notify(res.message, "success");
          this.getSynctatus();
        },
        error:() => {
        }
      }
    );
    setTimeout(() => {
      this.loader = false;
      this.isRequestSent = false;
      this.notify.notify("Request submitted for sync", "success");
    }, 1000);
}

selectionChange(){
  this.getSynctatus();
}
getSynctatus() {
  if(!this.sync_history_access.GET){
    this.notify.notify('You are not authorize to get historical sync data', 'warn');
    return
  }
  let url = `sync-process-details?limit=${this.limit}&offset=${this.offset}`;
  if(this.selected_year){
    url += `&year=${this.selected_year}`
  }
  if (this.selected_type) {
    url += `&table_name=${this.selected_type}_log`;
  }
  if(this.offset == 0){
    url += `&is_count=true`;
  }
  this.table_loader = true;
  this.api.getEpsData(url).subscribe(
    (res: any) => {
      this.table_loader = false;
      if (res.data && res.data.length > 0) {
        this.total_record = res.data;
        this.createTable();
      }else{
        this.DataSource =[];
        this.total_record =[];
        this.notify.notify(res.message, "warn");
      }
      if(this.offset == 0){
        this.total = res?.totalRecord?res.totalRecord:0;
      }
    },
    (err) => {
      this.table_loader = false;
      this.DataSource =[];
      this.total_record =[]
    }
  );
 }


 // create table structure
 createTable(){
    if(this.total_record.length == 0){
      return;
    }
      let temp =[];
      this.total_record.map(ele=>{
          let sd = {
            id: ele.id,
            table_name: ele.table_name,
            year: ele.year,
            min_time_stamp: this.fnc.formatDateUTC(ele.min_time_stamp, true),
            max_time_stamp: this.fnc.formatDateUTC(ele.max_time_stamp, true),
            execution_start_time: this.fnc.formatDateUTC(ele.execution_start_time, true),
            execution_end_time: this.fnc.formatDateUTC(ele.execution_end_time, true),
            time_difference : this.fnc.getDateDifference(ele.execution_start_time,ele.execution_end_time),
            record_to_be_processed: ele.record_to_be_processed,
            record_processed: ele.record_processed,
            percent :this.fnc.percentageConvertor(ele.record_to_be_processed, ele.record_processed),
            status: this.status_emun[ele.status],
            text_status: ele.status,
            updated_by: ele.updated_by,
            added_by: ele.added_by,
            added_date: this.fnc.formatDateUTC(ele.added_date, true),
            updated_date: this.fnc.formatDateUTC(ele.updated_date, true),
            remarks: ele.remarks
          };
          temp.push(sd);
        })
        this.DataSource= temp;
 }

 // add tooltip
 getTooltip(data){
  return ` Start - ${data?.execution_start_time} \n
           End  - ${data?.execution_end_time}`
 }

 formatDateaApi(value: any) {
  if(value){
    value = new Date(value);
    return (
      value.getFullYear() +
      '-' +
      (value.getMonth() + 1).toString().padStart(2, 0) +
      '-' +
      value.getDate().toString().padStart(2, 0)
    );
  }
  return null;
}
formatDateShow(value: any) {
  if(value){
    value = new Date(value);
    return (
      value.getDate().toString().padStart(2, 0)         +
      '-' +
      (value.getMonth() + 1).toString().padStart(2, 0) +
      '-' +
      value.getFullYear()
    );
  }
  return null;
}

stopProcess(value){
  if(!this.sync_history_access.PATCH){
    this.notify.notify('You are not authorize to stop process', 'warn');
    return
  }
  let url = "stop-sync-process?process_name=" + this.selected_type + "&sync_id=" + value.id + "&uid=" + value.year; //`sync-history-data?type=${this.selected_type}&year=${this.selected_year}&sync_date=${this.formatDateaApi(this.selected_date)}`
  this.loader = true;
  this.api
    .getEpsData(url)
    .subscribe(
      {
        next: (res: any) => {
          console.log(res);
          this.notify.notify(res.message, "success");
          this.loader = false;
          this.getSynctatus()
        },
        error:(err) => {
          // this.notify.notify(err.error.message, "error");
          this.loader = false;
        }
      }
    );
}
}
