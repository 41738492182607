import { Component, Input } from "@angular/core";
import { CommonfunctionService } from "../../../../services/commonfunction.service";

@Component({
  selector: "subgroup-contextmenu",
  templateUrl: "../../subelements/submaster-menu.component.html",
  styleUrls: ["../../admin-configuration.component.css"],
})
export class SubgroupContextMenu {
  @Input("item") item;
  @Input("openDialog") openDialog;
  @Input("master") masterlist;
  @Input("masters_access") masters_a;

  report_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };

  group_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };

  constructor(private fnc: CommonfunctionService) {
    this.group_access = this.fnc.checkResourceAccess("report_groups", false);
    this.report_access = this.fnc.checkResourceAccess("reports", false);
  }
  ngOnInit() {
    if (this.masters_a) {
      //  this.masters_access = this.masters_a;
    }
  }
}
