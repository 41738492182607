import { Injectable } from '@angular/core';
// import 'rxjs/add/operator/map';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
// import { Http, RequestOptions, URLSearchParams, Headers } from '@angular/http';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, Subject,  BehaviorSubject } from 'rxjs';
//import { AutoLogoutService } from './services/auto-logout.service';
import { environment } from '../environments/environment'

@Injectable({ providedIn: 'root' })
export class ApiserviceService {
  public httpOptions;
  public status;
  public device: any;
  public ip: any= '';
  public user_id: any;
  public user_token: string = '';
  public city_id: any = 'null';
  public city_code = '';
  public session_id: string = 'null';
  public language: number = 1;
  public language_list = [];
  public property_type_list = [];
  public entity_type_list = [];
  public allowResources = [];
  public nodeUrl = null;
  public syncUrl = null;
  public umsUrl = null;
  public estUrl = null;
  public epsUrl = null;
  public emsUrl = null;
  public csvUrl = null
  public gisUrl = null;
  public wms_url = null;
  public api_key: string = '';
  isMobile:boolean;
  public user_name =  new BehaviorSubject<any>(localStorage.getItem('user_name'));

  public profile_image = new BehaviorSubject<any>(localStorage.getItem('profile_image'));

  gesUrl: string =null;
  gmsUrl: string =null;
  gpsUrl: string =null;
  gmusUrl: string =null;


  constructor(private http: HttpClient, private route: Router, private deviceInfo: DeviceDetectorService) {

    this.isMobile = this.deviceInfo.isMobile();
    if (localStorage.getItem('language_id')) {
      this.language = +localStorage.getItem('language_id');
    }
    if (localStorage.getItem('language_list')) {
      this.language_list = JSON.parse(localStorage.getItem('language_list'));
    }
    if (localStorage.getItem('city_id')) this.city_id = localStorage.getItem('city_id');
    if (localStorage.getItem('city_code')) this.city_code = localStorage.getItem('city_code');
    this.setApiUrl();

    this.getIpAddress();
    if (localStorage.getItem('device')) {
      this.device = JSON.parse(localStorage.getItem('device'));
    } else {
      this.device = this.getDevice();
      localStorage.setItem('device', JSON.stringify(this.device));
    }
    if (localStorage.getItem("ip")) {
      this.ip = localStorage.getItem("ip");
    } else {
      this.getIpAddress();
    }
    this.user_id = localStorage.getItem('user_id');
    if (localStorage.getItem('user_token')) this.user_token = localStorage.getItem('user_token');
    this.user_name.next(localStorage.getItem('user_name'));

    if (localStorage.getItem('session_id')) {
      this.session_id = localStorage.getItem('session_id');
    }
    this.getLanguageList();
    if (this.user_id && this.city_id && this.city_id != 'null') {
      //this.getAllowResources();
      this.getPropertyType();
      this.getEntityType();
    }
    if(this.user_id && this.city_id){
      //
      this.getGoogleMapKey();
    }


  }

  public getGoogleMapKey(){
    this.getUmsData("setting").subscribe((res:any) => {
      if (res.data) {
        let goole_key = res.data.filter(obj => obj.key == 'google_map_key');
        goole_key = goole_key[0].value;
        // this.EncryptDecrypt.EncryptDecrypt('HqfDb@2389xDYaM');
        // goole_key = this.EncryptDecrypt.decryptKey(goole_key);
        const url = 'https://maps.googleapis.com/maps/api/js?key=' + goole_key + '&libraries=drawing';
        const script = document.createElement('script');
        script.setAttribute('src', url);
        script.setAttribute('async', '');
        script.setAttribute('defer', 'defer');
        document.head.appendChild(script);
      }else{
        console.error('google_map_key not available');
      }
    }, err=>{
    //  console.error(err.message);


    })
  }
  // set url of api
  // set url of api
  setApiUrl() {
    this.estUrl = ''; //environment.estUrl ;
    this.emsUrl = environment.emsUrl ;
    this.epsUrl = environment.epsUrl ;
    this.csvUrl = environment.csvUrl ;
    this.umsUrl = environment.umsUrl;
    // this.gisUrl = environment.gisUrl;


    this.gesUrl = environment.ges;
    this.gmsUrl = environment.gms;
    this.gpsUrl = environment.gps;
    this.gmusUrl = environment.gmus;

    this.wms_url = environment.wms_url + this.city_code.toUpperCase() + "_gisdb/wms?";
    this.syncUrl = environment.syncUrl;
    this.nodeUrl = environment.nodeUrl;
    this.user_token = (this.user_token == '') ? environment.user_token: this.user_token
    this.api_key = environment.api_key;


  }


  // my external ip
  getIpAddress() {
    this.http.get('https://api.ipify.org/?format=json')
      .subscribe((data: any) => {
        let ip_details: any = data;
        this.ip = ip_details.ip;
        localStorage.setItem('ip', this.ip);
      });
  }
  // get device info
  getDevice() {
    let device = this.deviceInfo.getDeviceInfo();
    return device;
  }

  // setting header for UMS
  setUmsHeader(content_type = 'application/json') {
    // let dInfo = this.device.browser + ' ' + this.device.browser_version + ' (' + this.device.os + ')';
    // let headers = new HttpHeaders();
    // headers = headers.append('Content-Type', content_type);
    // headers = headers.append('APPID', '7');
    // headers = headers.append('User-Device', dInfo);
    // headers = headers.append('lang-code', 'en');
    // headers = headers.append('session-id', this.session_id);
    // headers = headers.append('city-id', this.city_id)
    // if (localStorage.getItem('ip')) {
    //   headers = headers.append('User-Ip', this.ip);
    // } else {
    //   headers = headers.append('User-Ip', '::');
    // }
    // headers = headers.append('user-token', this.user_token);
    // this.httpOptions = { headers: headers };
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', content_type);
    headers = headers.append('app-id', '7');
    //headers = headers.append('User-Device', dInfo);
    headers = headers.append('user-lang', 'en');
    headers = headers.append('city-id', this.city_id.toString())
    if (localStorage.getItem('ip')) {
      headers = headers.append('user-ip', this.ip);
    } else {
      headers = headers.append('user-Ip', '::');
    }
    headers = headers.append('user-token', this.user_token);
    // console.log(headers);

    this.httpOptions = { headers: headers };
  }
  // setting header for node upload api
  setNodeUploadHeader() {
    let headers = new HttpHeaders();
    // headers = headers.append('Content-Type', 'multipart/form-data');
    headers = headers.append('USER-CITY', this.city_id.toString());
    headers = headers.append('CITY-CODE', this.city_code);
    headers = headers.append('USER-TOKEN', this.user_token);
    headers = headers.append('USER-LANGUAGE', this.language? this.language.toString() :'1');
    headers = headers.append('USER-LANGUAGE-CODE', 'en');
    headers = headers.append('API-KEY', this.api_key?this.api_key:'');
    this.httpOptions = { headers: headers };

  }
  // setting header for node api
  setNodeHeader() {
    let headers = new HttpHeaders();
    // headers = headers.append('Accept', 'application/json');
    headers = headers.append('USER-CITY', this.city_id.toString());
    headers = headers.append('USER-TOKEN', this.user_token);
    headers = headers.append('USER-LANGUAGE', this.language? this.language.toString() :'1');
    headers = headers.append('API-KEY', this.api_key?this.api_key:'');
    this.httpOptions = { headers: headers };
  }
  // setting header for gis
  setGisHeader() {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('API-KEY', '7kjghhjj');
    headers = headers.append('USER-LANGUAGE', '1');
    headers = headers.append('USER-CITY', String(this.city_id));
    headers = headers.append('user-token', String(this.user_token));
    // headers = headers.append('session-id', this.session_id);
    this.httpOptions = { headers: headers };
  }

  setNewUploadHeader() {
    let headers = new HttpHeaders();
    headers = headers.append('API-KEY', '7kjghhjj');
    headers = headers.append('USER-LANGUAGE', '1');
    headers = headers.append('USER-CITY', String(this.city_id));
    headers = headers.append('user-token', String(this.user_token));
    // headers = headers.append('session-id', this.session_id);
    this.httpOptions = { headers: headers };
  }

  //Setting header for file upload
  setUploadHeader() {
    let headers = new HttpHeaders();
    headers = headers.append('API-KEY', '7kjghhjj');
    headers = headers.append('USER-LANGUAGE', this.language? this.language.toString() :'1');
    headers = headers.append('USER-CITY', this.city_code);
    this.httpOptions = { headers: headers };
  }
  setUploadHeaderEstater(content_type = 'multipart/form-data', response_type) {
    let headers = new HttpHeaders();
    // headers.append('Content-Type', content_type);
    let dInfo = this.device.browser + ' ' + this.device.browser_version + ' (' + this.device.os + ')';
    headers = headers.append('USER-CITY', this.city_id.toString());
    headers = headers.append('USER-TOKEN', this.user_token);
    headers = headers.append('USER-LANGUAGE', '' + this.language + '');
    this.httpOptions = { headers: headers };
    if (response_type) {
      this.httpOptions['responseType'] = response_type;
      //headers = headers.append('responseType', response_type);
    }
  }

  setUploadHeaderUms(content_type = 'multipart/form-data', response_type) {
    let headers = new HttpHeaders();
    // headers.append('Content-Type', content_type);
    // let dInfo = this.device.browser + ' ' + this.device.browser_version + ' (' + this.device.os + ')';
    headers = headers.append('city-id', this.city_id.toString());
    headers = headers.append('USER-CITY', this.city_id.toString());
    headers = headers.append('CITY-CODE', this.city_code ? this.city_code.toString():'');
    headers = headers.append('user-token', this.user_token);
    headers = headers.append('app-id', '7');
    headers = headers.append('user-lang', 'en');
    if (localStorage.getItem('ip')) {
      headers = headers.append('user-ip', this.ip);
    } else {
      headers = headers.append('user-ip', '::');
    }
    this.httpOptions = { headers: headers };
    if (response_type) {
      this.httpOptions['responseType'] = response_type;
      //headers = headers.append('responseType', response_type);
    }
  }
  // setting header for estater
  setHeader(content_type = 'application/json', response_type = null) {

    let dInfo = this.device.browser + ' ' + this.device.browser_version + ' (' + this.device.os + ')';
    let headers = new HttpHeaders();

    if (content_type != 'no-content') {
      headers = headers.append('Content-Type', content_type);
    }
    headers = headers.append('USER-CITY', this.city_id.toString());
    headers = headers.append('USER-TOKEN', this.user_token);
    headers = headers.append('USER-LANGUAGE', '' + this.language + '');
    // console.log(headers);
    headers = headers.append('API-KEY', this.api_key?this.api_key:'');

    this.httpOptions = { headers: headers };
    if (response_type) {
      this.httpOptions['responseType'] = response_type;
      //headers = headers.append('responseType', response_type);
    }
  }
   // setting header for eps upload file
   setUploadEpsHeader() {
    let headers = new HttpHeaders();
    headers = headers.append('USER-CITY', this.city_id.toString());
    headers = headers.append('USER-TOKEN', this.user_token);
    headers = headers.append('USER-LANGUAGE', '' + this.language + '');
    headers = headers.append('API-KEY', this.api_key?this.api_key:'');
    this.httpOptions = { headers: headers };
  }

  setSyncHeader(content_type = 'application/json', response_type = null) {
    let dInfo = this.device.browser + ' ' + this.device.browser_version + ' (' + this.device.os + ')';
    let headers = new HttpHeaders();
    if (content_type != 'no-content') {
      headers = headers.append('Content-Type', content_type);
    }
    //headers = headers.append('USER-CITY', '32');
    headers = headers.append('USER-TOKEN', this.user_token);
    headers = headers.append('USER-CITY', this.city_id.toString());
    headers = headers.append('API-KEY', this.api_key?this.api_key:'');
    this.httpOptions = { headers: headers };
  }
  // get record using this function
  getUmsData(url) {
    this.setUmsHeader();
    return this.http.get(this.umsUrl + url, this.httpOptions)
    // .map(data => data.json());
  }

  // get record using this function
  getData(url) {
    this.setHeader();
    return this.http.get(this.estUrl + url, this.httpOptions)
    // .map(data => data.json());
  }

   // get record using this function
   getEmsData(url) {
    this.setHeader();
    return this.http.get(this.emsUrl + url, this.httpOptions)
    // .map(data => data.json());
  }
   // get record using this function
   getCsvData(url) {
    this.setHeader();
    return this.http.get(this.csvUrl + url, this.httpOptions)
    // .map(data => data.json());
  }
  patchCsvData(url, body, required = true) {
    if ((body === null || body === undefined) && required) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setHeader();
    return this.http.patch(this.csvUrl + url, body, this.httpOptions);
  }
  getSyncData(url) {
    this.setSyncHeader();
    return this.http.get(this.syncUrl + url, this.httpOptions)
    // .map(data => data.json());
  }
  postSyncData(url: any, body: any) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setSyncHeader();
    return this.http.post(this.syncUrl + url, body, this.httpOptions);
  }


  // // get record of gis
  // getGisData(url) {
  //   this.setGisHeader();
  //   return this.http.get(this.gisUrl + url, this.httpOptions)
  //   // .map(data => data.json());
  // }

  // // post record of gis
  // postGisData(url, body) {
  //   if (body === null || body === undefined) {
  //     throw new Error('Required parameter body was null or undefined when calling Api.');
  //   }
  //   this.setGisHeader();
  //   return this.http.post(this.gisUrl + url, body, this.httpOptions);
  // }

  // //Uploaded File action
  // postUserAction(url, body) {
  //   this.setGisHeader();
  //   return this.http.post(this.gisUrl + url, body, this.httpOptions);
  // }

  // postFile(url, body) {
  //   if (body === null || body === undefined) {
  //     throw new Error('Required parameter body was null or undefined when calling Api.');
  //   }
  //   this.setUploadHeader();
  //   return this.http.post(this.gisUrl + url, body, this.httpOptions);
  // }


  // get record of gis
  // getGisData(url) {
  //   this.setGisHeader();
  //   return this.http.get(this.gisUrl + url, this.httpOptions)
  //   // .map(data => data.json());
  // }
  getGmusData(url) {
    this.setGisHeader();
    return this.http.get(this.gmusUrl + url, this.httpOptions)
    // .map(data => data.json());
  }
  getGmsData(url) {
    this.setGisHeader();
    return this.http.get(this.gmsUrl + url, this.httpOptions)
    // .map(data => data.json());
  }
  getGpsData(url) {
    this.setGisHeader();
    return this.http.get(this.gpsUrl + url, this.httpOptions)
    // .map(data => data.json());
  }
  getGesData(url) {
    this.setGisHeader();
    return this.http.get(this.gesUrl + url, this.httpOptions)
    // .map(data => data.json());
  }

  // post record of gis
  // postGisData(url, body) {
  //   if (body === null || body === undefined) {
  //     throw new Error('Required parameter body was null or undefined when calling Api.');
  //   }
  //   this.setGisHeader();
  //   return this.http.post(this.gisUrl + url, body, this.httpOptions);
  // }
  postGmusData(url, body) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setGisHeader();
    return this.http.post(this.gmusUrl + url, body, this.httpOptions);
  }
  postGmsData(url, body) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setGisHeader();
    return this.http.post(this.gmsUrl + url, body, this.httpOptions);
  }
  postGpsData(url, body) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setGisHeader();
    return this.http.post(this.gpsUrl + url, body, this.httpOptions);
  }
  postGesData(url, body) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setGisHeader();
    return this.http.post(this.gesUrl + url, body, this.httpOptions);
  }

  // delete record of gis
  // deleteGisData(url) {
  //   this.setGisHeader();
  //   return this.http.delete(this.gisUrl + url, this.httpOptions);
  // }
  deleteGmusData(url) {
    this.setGisHeader();
    return this.http.delete(this.gmusUrl + url, this.httpOptions);
  }
  deleteGmsData(url) {
    this.setGisHeader();
    return this.http.delete(this.gmsUrl + url, this.httpOptions);
  }
  deleteGpsData(url) {
    this.setGisHeader();
    return this.http.delete(this.gpsUrl + url, this.httpOptions);
  }
  deleteGesData(url) {
    this.setGisHeader();
    return this.http.delete(this.gesUrl + url, this.httpOptions);
  }
  //Uploaded File action
  postUserAction(url, body) {
    this.setGisHeader();
    return this.http.post(this.gmusUrl + url, body, this.httpOptions);
  }

  postFile(url, body) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setNewUploadHeader();
    return this.http.post(this.gmusUrl + url, body, this.httpOptions);
  }

  //put GIS data
  // putGisData(url, body) {
  //   this.setGisHeader();
  //   return this.http.put(this.gisUrl + url, body, this.httpOptions);
  // }
  putGmusData(url, body) {
    this.setGisHeader();
    return this.http.put(this.gmusUrl + url, body, this.httpOptions);
  }
  putGmsData(url, body) {
    this.setGisHeader();
    return this.http.put(this.gmsUrl + url, body, this.httpOptions);
  }
  putGpsData(url, body) {
    this.setGisHeader();
    return this.http.put(this.gpsUrl + url, body, this.httpOptions);
  }
  putGesData(url, body) {
    this.setGisHeader();
    return this.http.put(this.gesUrl + url, body, this.httpOptions);
  }

  //Node function for upload
  postFileNode(url, body) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setNodeUploadHeader();
    return this.http.post(this.nodeUrl + url, body, this.httpOptions);
  }

  //Node function for get
  getNodeData(url) {
    if (url === null || url === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setNodeHeader();
    return this.http.get(this.nodeUrl + url, this.httpOptions);
  }
  //Node function for post
  postNodeData(url, body) {
    if (url === null || url === undefined) {
      throw new Error('Required parameter url was null or undefined when calling Api.');
    }
    this.setNodeHeader();
    return this.http.post(this.nodeUrl + url, body, this.httpOptions);
  }
  //Node function for patch
  patchNodeData(url, body) {
    if (url === null || url === undefined) {
      throw new Error('Required parameter url was null or undefined when calling Api.');
    }
    this.setNodeHeader();
    return this.http.patch(this.nodeUrl + url, body, this.httpOptions);
  }
  postFileUms(url, body, response_type = null) {
    // if (body === null || body === undefined) {
    //   throw new Error('Required parameter body was null or undefined when calling Api.');
    // }
    // this.setUploadHeaderUms('application/json', response_type);
    // return this.http.post(this.estUrl + url, body, this.httpOptions);
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setUploadHeaderUms('application/json', response_type);
    return this.http.post(this.umsUrl + url, body, this.httpOptions);
  }
  postFileEst(url, body, response_type = null) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setUploadHeaderEstater('application/json', response_type);
    return this.http.post(this.estUrl + url, body, this.httpOptions);
  }
  // send a POST request to the API to create a data object
  postUmsData(url, body) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setUmsHeader();
    return this.http.post(this.umsUrl + url, body, this.httpOptions);
  }
  postData(url, body, required = true) {
    if ((body === null || body === undefined) && required) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setHeader();
    return this.http.post(this.estUrl + url, body, this.httpOptions);
  }
  postEmsData(url, body, required = true) {
    if ((body === null || body === undefined) && required) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setHeader();
    return this.http.post(this.emsUrl + url, body, this.httpOptions);
  }

 // this function for Eps start
  getEpsData(url) {
    this.setHeader();
    return this.http.get(this.epsUrl + url, this.httpOptions)
  }
  postEpsData(url, body, required = true) {
    if ((body === null || body === undefined) && required) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setHeader();
    return this.http.post(this.epsUrl + url, body, this.httpOptions);
  }
  uploadEpsData(url, body, required = true) {
    if ((body === null || body === undefined) && required) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setUploadEpsHeader();
    return this.http.post(this.epsUrl + url, body, this.httpOptions);
  }

  patchEpsData(url, body, required = true) {
    if ((body === null || body === undefined) && required) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setHeader();
    return this.http.patch(this.epsUrl + url, body, this.httpOptions);
  }
// end

  // send a PATCH request to the API to update a data object
  patchUmsData(urlWithId, body) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setUmsHeader();
    return this.http.patch(this.umsUrl + urlWithId, body, this.httpOptions);
  }

  patchData(urlWithId, body) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setHeader();
    return this.http.patch(this.estUrl + urlWithId, body, this.httpOptions);
  }
  patchEmsData(urlWithId, body) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setHeader();
    return this.http.patch(this.emsUrl + urlWithId, body, this.httpOptions);
  }

  // send a DELETE request to the API to delete a data object
  deleteUmsData(urlWithId) {
    this.setUmsHeader();
    return this.http.delete(this.umsUrl + urlWithId, this.httpOptions);
  }

  // send a DELETE request to the API to delete a data object
  deleteData(urlWithId) {
    this.setHeader();
    return this.http.delete(this.estUrl + urlWithId, this.httpOptions);
  }

  // getting list of property type
  getPropertyType(sort_by = 'property_type_name', limit = 10000, offset = 0) {
    let url = "propertytypes?sort_by=" + sort_by + "&limit=" + limit + "&offset=" + offset + '&status=1';
    this.getEmsData(url)
      .subscribe(
        (data: any) => {
          this.property_type_list = data;
        }
      );
  }

  // getting list of entity type
  getEntityType() {
    this.getEmsData('entities')
      .subscribe(
        (data: any) => {
          this.entity_type_list = data;
        }
      );
  }

  // getting list of property type
  // getting list of property type
  getLanguageList() {
    this.getUmsData('language')
      .subscribe(
        (res: any) => {
          if (res && res.data) {
            let temp =[];
            res.data.forEach(element => {
              if (element.status == 1) {
                temp.push(element);
              }
            });
            this.language_list = temp;
            localStorage.setItem('language_list', JSON.stringify(temp))
          }
        }
      );
  }

  // getting allow resources
  getAllowResources() {
    let body = `user/resource?place_id=${this.city_id}&user_id=${this.user_id}`
    this.getUmsData(body)
      // .map(data => data.json())
      .subscribe(
        (data: any) => {
          this.allowResources = data;
        },
        err => {

        }
      )
  }

  postMedia(url, body, response_type = null) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling Api.');
    }
    this.setHeader('no-content', response_type);
    return this.http.post(this.estUrl + url, body, this.httpOptions);
  }

  logout(val) {
    if(environment.isLocalServe  || this.isMobile){
    this.getUmsData('logout?all_device='+val).subscribe(data => {});
      localStorage.clear();
      this.user_id = 'null';
      this.city_id = 'null';
      this.user_token = 'null';
      this.session_id = 'null';
      this.route.navigateByUrl('/admin/login');
    }else{
      localStorage.clear();
      this.user_id = 'null';
      this.city_id = 'null';
      this.user_token = 'null';
      this.session_id = 'null';
      let newwindow = window.open(`${environment.adminHost}/logout?continue=${environment.baseURL}&all_device=${val}`, '_self');
      if (window.focus) {newwindow.focus()}
    }


  }

  // logout() {
  //   this.getUmsData('user/logout')
  //     .subscribe(data => {
  //       localStorage.clear();
  //       this.user_id = null;
  //     });
  //   localStorage.clear();
  //   this.user_id = null;
  //   window.location.href = '/admin/login';
  //   //this.route.navigateByUrl('admin/login');
  // }
  checkRole(role) {
    let status = false;
    let roles = localStorage.getItem('roles');
    if (roles) {
      let roles_arr = [];
      roles_arr = roles.split(',');
      if (roles_arr.includes(role)) status = true;
    }
    return status;
  }
  getUserRole() {
    let url = `users?user_ids=${this.user_id}`;
    this.getUmsData(url)
      .subscribe((res: any) => {
        if (res) {
          let val = res.data;
          if(val){
            let roles = val[0].role_names;
            localStorage.setItem('roles', roles);
          }

        }
      })
  }


  getUserDetail(id) {
    this.getUmsData(`user?user_id=${id}`).subscribe((res: any) => {
      if (res.data) {
        this.setUser_info(res.data);
      }
    }, err => {
    })
  }

  setUser_info(userdata) {
    let image = 'assets/images/user.png';
      if (userdata.profile_image) {
        image = userdata.profile_image;
      }
      localStorage.setItem('profile_image', image);
      this.profile_image.next(image);
      let name='';
      if (userdata.user_details['1'].first_name) {
          name = userdata.user_details['1'].first_name;
      }
      if (userdata.user_details['1'].last_name) {
          name += " " +  userdata.user_details['1'].last_name;
      }
      localStorage.setItem('user_name', name);
      let user_info = {
          "fname": userdata.user_details['1'].first_name,
          "lname": userdata.user_details['1'].last_name,
          "contact": userdata.phone_number,
          "country": userdata.nationality,
          "email": userdata.primary_email_id,
          "language": 'en',
          "imageURL": userdata.profile_image
      }
      localStorage.setItem('user_info', JSON.stringify(user_info));
      this.user_name.next(name);
  }
}
