import { Component, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NotificationService } from "../../../../admin/services/notification.service";
import { ApiserviceService } from "../../../../apiservice.service";
import { CommonfunctionService } from "../../../../services/commonfunction.service";

//create Group component
@Component({
  selector: 'addgroup-dialog',
  templateUrl: './addgroup.component.html',
  styleUrls: ['../../admin-configuration.component.css']
})

export class AddGroupDialog {

  groupform: FormGroup;
  languages: Array<any>;
  property_list: Array<{}>;
  language_valueset: Array<{}> = [];
  group_list = [];
  property_type_init = [];
  progress: number = 0;
  message: string = '';
  constructor(
    public dialogRef: MatDialogRef<AddGroupDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _fb: FormBuilder, private api: ApiserviceService, private notify: NotificationService,
    private fnc: CommonfunctionService) {

    this.languages = this.api.language_list;
    this.property_list = this.api.property_type_list;
    this.group_list = this.data.parent.group_custom_list;
    this.property_type_init.push(this.data.parent.selected_property_type);
    /* #Form - Intial Form Definition */
    this.groupform = this._fb.group({
      group_name: ['', Validators.compose([Validators.required, Validators.pattern("^[a-zA-Z0-9_]*$")])],
      language: this._fb.array([]),
      description: this._fb.array([]),
      status: [true],
      // parent_group: ['']
    });
    /* End #Form - Intial Form Definition */
    this.addRow();
    this.groupform.patchValue({ 'language': [{ language: this.languages[0].language_id, value: '' }] });
  }

  conditionalValidation(field) {
    if (this.data.parent.is_property_dependent) {
      return Validators.required;
    } else {
      return Validators.nullValidator;
    }
  }

  showLabel(item) {
    if (item) {
      let label = (item.label && item.label[this.api.language]) ? item.label[this.api.language] : item.name;
      return label;
    }
  }

  /* #Form - Language multiple field init */

  saveform(groupform) {
    this.progress = 10;
    this.message = "Adding group..."
    let value = groupform.value;
    let parent_id = null;
    let status = 0;
    //if (value.parent_group) parent_id = value.parent_group;
    if (value.status) status = 1;

    let re = /\ /gi;
    let group_name = value.group_name.replace(re, "_");

    let group_id = value.id;
    let add_group = {
      "added_by": this.api.user_id,
      "updated_by": this.api.user_id,
      "label": {},
      "name": group_name,
      "parent_id": parent_id,
      "status": status,
      "type_id": this.data.parent.selectedTypeId
    }
    value.language.forEach((v, k) => {
      add_group.label[v.language] = v.value;
    });
    //this.data.parent.sortGroup(); // sort before adding new value
    this.api.postData('reportGroup', add_group)
      .subscribe(
        (data: any) => {
          this.progress = 30;
          this.message = "Fetching resources...";
          let reportTypeObj = this.fnc.getArrayValue('id', this.data.parent.selectedTypeId, this.data.parent.reportType);
          if (reportTypeObj) {
            let resname = reportTypeObj.name + '_' + group_name;
            this.getReourceDetail('report_groups', resname);
          }
          this.notify.notify('Group Created', 'success');
          this.data.parent.getGroups(this.data.parent.selectedTypeId);
        },
        err => {
          this.notify.notify(this.fnc.getErrorMessage(err), 'error');
          this.closeDialog();
        }
      );
  }

  getReourceDetail(res: string, resource_name: string) {
    this.api.getUmsData('resource?name=' + res + '&match_exact=true').subscribe((res: any) => {
      this.message = "Creating resource...";
      this.progress = 70;
      let resource_details: any;
      if (res) resource_details = res[0];
      if (resource_details && resource_name) {
        this.createResource(resource_name, resource_details);
      }
    }, err => {
      this.notify.notify(this.fnc.getErrorMessage(err), 'Dismiss');
      this.dialogRef.close();
    })
  }

  createResource(resource_name, resource_details) {
    let body = "resource_name=" + resource_name + "&resource_urls=" +
      "array[array['GET', 'a']," +
      "array['POST', 'b']," +
      "array['PATCH', 'c']," +
      "array['DELETE', 'd']]" +
      "&parent_resource_id=" + resource_details.resource_id;

    this.api.postUmsData('resource', body).subscribe((res: any) => {
      this.message = "Completed";
      setTimeout(() => {
        this.progress = 100;
        this.closeDialog();
      }, 100);

    }, err => {
      this.dialogRef.close();
    })
  }

  initLanguageFields() {
    return this._fb.group({
      language: [this.languages[0].language_id, Validators.required],
      value: ['', Validators.required]
    })
  }

  initDescriptionLanguageFields() {
    return this._fb.group({
      language: [this.languages[0].language_id],
      value: []
    })
  }
  /* End #Form - Language multiple field init */

  closeDialog(): void {
    this.progress = 0;
    this.dialogRef.close();
  }

  addRow() {
    if (this.language_valueset?.length == this.languages?.length) {
      this.notify.notify("No more Languages Available to Add",'warn',4000);
      return;
    }
    this.language_valueset.push({ language: '', value: '' });
    /* #Form */
    let dd = <FormArray>this.groupform.controls.language;
    dd.push(this.initLanguageFields());
    /* End - #Form */
  }

  removeRow(key) {
    if (this.language_valueset.length > 1) {
      this.language_valueset.splice(key, 1);
      let dd = <FormArray>this.groupform.controls.language;
      dd.removeAt(key);
    }
  }
}
