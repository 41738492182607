import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiserviceService } from '../../apiservice.service';
import { CommonfunctionService } from '../../services/commonfunction.service';
import { AdminMenusService } from '../admin-sidebar/admin-menus.service';
import { NotificationService } from '../services/notification.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm, Validators, FormGroup, UntypedFormControl, FormBuilder, FormArray, NgModel } from '@angular/forms';
import { ConfirmDialogBox } from '../confirm-dialog/confirm-dialogbox';
import { AddEditComponent } from './add-edit/add-edit.component';
import { CategoryDialogComponent } from './category-dialog/category-dialog.component';
// import { UserAction } from "../user-action/user-activity";
import { debounceTime } from 'rxjs';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  displayedColumns = ['sn', 'key', 'value', 'type', 'status', 'added_by', 'added_date', 'action'];
  dataSource: any = [];
  loader: boolean;
  selectedCellId: any;
  changed_value: string;
  status: string = "0,1";
  searchField: UntypedFormControl;
  search: any = '';
  deletetoggle: boolean = false;

  setting_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };

  public tableIndexList: any = [];
  public serverListCols=[
    "name",
    "options",
  ]
  limit: number = 10;
  offset: number = 0;
  total: number;
  sizeOptions: string = '5, 10, 15, 25';
  pageSizeOptions: any;
  defaultPageSize: number = 10;
  pageEvent: PageEvent;
  pageIndex = 0;
  @ViewChild('paginator') paginator: MatPaginator;

  is_count=false;
  constructor(
    private menus: AdminMenusService,
    private notify: NotificationService,
    private apiService: ApiserviceService,
    private fnc: CommonfunctionService,
    public dialog: MatDialog,
  ) {
    this.menus.setActive('settings');
    this.getAllowResources();
    this.pageSizeOptions = this.sizeOptions.split(',').map(str => +str);
  }

  /**
   * getAllowResources function getting allow resources
   * @returns Void
   */
  getAllowResources() {
    this.apiService.getUserRole();
    let body = `user/resource?place_id=${this.apiService.city_id}&user_id=${this.apiService.user_id}`
    console.log(body)
    this.apiService.getUmsData(body)
      .subscribe((res: any) => {
        this.apiService.allowResources = res.data;
        this.setting_access = this.fnc.checkResourceAccess("settings", false);
        this.getAllowResource();
        this.getSettings();
      }, err => {
      })

  }

  resetPagination(){
    this.offset=0;
    this.pageIndex=0;
    this.paginator.firstPage();
  }
  pageAction(e) {
    this.pageIndex = e.pageIndex;
    if (this.limit != e.pageSize) {
      this.limit = e.pageSize;
    } else {
      this.offset = this.pageIndex * e.pageSize;
    }
    this.getSettings();
  }

  getAllowResource() {
    this.setting_access = this.fnc.checkResourceAccess('settings', false);
  }

  editValue(ele, type) {
    this.selectedCellId = ele.id + '_' + type;
    this.changed_value = ele.value;
    // if (ele.key == 'property_categories') {
    //   this.selectedCellId = '';
    //   let _data = { ele: ele, info: this }
    //   const dialogRef = this.dialog.open(CategoryDialogComponent, {
    //     width: '600px',
    //     data: _data
    //   });
    //   dialogRef.afterClosed().subscribe(result => {
    //   });
    // } else {
    //   this.selectedCellId = ele.id + '_' + type;
    //   this.changed_value = ele.value;
    // }

  }

  cancelEdit(uid) {
    this.selectedCellId = '';
  }
  edit_setting(data) {
    if (!this.setting_access.PATCH) {
      this.notify.notify("You are not authorized to EDIT setting", "warn");
      return;
    }
    var body = {
      "id": data.id,
      "value": this.changed_value,
      "status": data.status,
      "updated_by": this.apiService.user_id
    }
    this.loader = true;

    this.apiService.patchEmsData('settings', body).subscribe({
      next: (res: any) => {
        this.selectedCellId = '';
        this.notify.notify(res.message, "success")
        this.getSettings();
        this.loader = false;
      },
      error: () => {
        this.notify.notify("settings not update", "error")
        this.loader = false;
      }
    })
  }

  getdateForm(date: any) {
    if (date) {
      date = new Date(date);
      return this.fnc.formatDate(date);
    } else {
      return '';
    }
  }

  openDailog(type: any, data: any = null) {
    if (!this.setting_access.POST) {
      this.notify.notify("You are not authorized to Add setting", "warn");
      return;
    }
    if (type == 'add') {
      var _data = {
        type: type,
        edit: data,
        pdata: this
      }
      const dialogRef = this.dialog.open(AddEditComponent, {
        width: '350px',
        panelClass: 'customPadding',
        data: _data
      });
      dialogRef.afterClosed().subscribe(result => {
      });
    }
  }

  public selected_setting;
  openConfirmation(value) {
    if (!this.setting_access.PATCH) {
      this.notify.notify("You are not authorized to Delete setting", "warn");
      return;
    }
    this.selected_setting = value;
    let _data = {
      parent_data: this,
      message: 'Do you want to delete setting?',
    }
    const dialogRef = this.dialog.open(ConfirmDialogBox, {
      panelClass: 'custom-dialog',
      width: '350px',
      data: _data
    });
    dialogRef.afterClosed().subscribe(result => {
      this.confirmDialogYes(result);
    });
  }

  confirmDialogYes(confirm) {
    if (confirm == 'YES') {
      let body = {
        "id": this.selected_setting.id,
        "status": -1,
        "updated_by": this.apiService.user_id
      }
      this.loader=true;
      this.apiService.patchEmsData('settings', body).subscribe({
        next: (res: any) => {
          this.loader=false;
          this.notify.notify("Setting has been deleted successfully", "success");
          this.is_count=true;
          this.getSettings();
        },
        error: () => {
          this.loader=false;
          this.notify.notify("Setting has been deleted failed", "error")
        }
      })
    }
  }

  ngOnInit() {
    this.searchField = new UntypedFormControl();
    this.searchField.valueChanges
      .pipe(debounceTime(500))
      .subscribe(term => {
        term = term.trim().toLowerCase();
        this.resetPagination();
        if (term) {
          this.getSettings(term)
        } else {
          this.getSettings(null)
        }
      })
  }


  /**
   * This is a function that used to getting setting from api
   * @param searchterm searchterm is a param
   * @returns
   */
  getSettings(searchterm = null) {
    if (!this.setting_access.GET) {
      this.notify.notify("You are not authorized to Access setting", "warn");
      return;
    }
    this.loader = true;
    let url = `settings?status=${this.status}&limit=${this.limit}&offset=${this.offset}`;
    if (searchterm != null) {
      url += `&search_text=${searchterm}`;
    }
    if(this.offset == 0 || this.is_count == true){
      url += `&is_count=true`;
    }
    this.apiService.getEmsData(url).subscribe({
      next: (res: any) => {
        this.loader = false;
        this.tableIndexList = [];
        if (res && res.server) {
          res.server.forEach((e) => {
            this.tableIndexList.push({ name: e.name, options: String(e.options) })
          });
        }
        if(this.offset == 0 || this.is_count == true){
          this.total = res.totalRecord;
        }
        if (res && res.data) {
          this.dataSource = res.data;
        } else if (res && res.status == 404) {
          this.dataSource = [];
          this.tableIndexList = [];
        } else {
          this.dataSource = [];
        }
      },
      error: () => {
        this.dataSource = [];
        this.loader = false;
      }
    })
  }

  /**
   * This is a function to use update status
   * @param row_id
   * @param event
   * @returns
   */
  updateStatus(row_id, event) {
    if (!this.setting_access.PATCH) {
      this.notify.notify("You are not authorized to update setting", "warn");
      return;
    }
    let body: any = {
      "id": row_id,
      "status": (event.checked == true) ? 1 : 0,
      "updated_by": this.apiService.user_id
    };
    this.loader = true;
    this.apiService.patchEmsData('settings', body).subscribe({
      next: (res) => {
        this.loader = false;
        this.notify.notify(`Setting has been updated successfully`, 'success');
        // this.status = "0,1";
        this.is_count=true;
        this.getSettings();
      },
      error: (err) => {
        console.log(err)
      }
    })
  }


  /**
   * API call to get Attributes with status=-1 and Data length for pagination
   */


  getDeleted(event) {
    if (!this.setting_access.GET) {
      this.notify.notify("You are not authorized to Access Settings", "warn");
      return;
    }
    if (event.checked == false) {
      this.status = "0,1";
    }
    else {
      this.status = "-1";
    }
    this.resetPagination();
    this.getSettings();
  }

}
