<button class="in-progress-btn" (click)="showProgress()" *ngIf="progress?.length>0">
    In Progress : {{progress?.length}}
  </button>
  <div class="tooltip-div" *ngIf="showTooltip && progress?.length > 0" [class.w-630]="progress?.length > 1" [class.w-315]="progress?.length == 1">
    <img src="assets/icon/w-close.svg" (click)="showProgress()" class="close" alt="" matTooltip="Close">
    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
        <div ngxSlickItem *ngFor="let slide of progress" class="p-2">
            <div class="description">
                <div class="p-2">
                  <div class="section-1">
                      <span class="title">{{slide?.module}}</span>
                      <span class="time">{{slide?.time_taken}}</span>
                  </div>
                  <div class="section-1">
                      <span style="text-transform: capitalize;">{{getName(slide?.process_name)}}</span>
                      <span></span>
                  </div>
                  <div class="section-1">
                      <span>
                          <p>Start time:</p>
                          <p>{{getDateFormat(slide?.added_date)}}</p>
                      </span>
                      <!-- <span class="text-right">
                          <p>End time:</p>
                          <p>{{getDateFormat(slide?.updated_date)}}</p>
                      </span> -->
                  </div>
                </div>
                <div class="section-2 p-2" style="border-top: 1.5px solid #ddd;">
                      <span>User ID: {{slide.user_id}}</span>
                  </div>
              </div>


        </div>

    </ngx-slick-carousel>
    
  </div>
  