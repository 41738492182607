import { Component, OnInit, Inject } from '@angular/core';
import { ApiserviceService } from '../../../apiservice.service';
import { CommonfunctionService } from '../../../services/commonfunction.service';
import { NotificationService } from '../../services/notification.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
// import { UserAction } from "../../user-action/user-activity";
@Component({
  selector: 'app-category-dialog',
  templateUrl: './category-dialog.component.html',
  styleUrls: ['./category-dialog.component.css']
})
export class CategoryDialogComponent implements OnInit {

  master_values: any = [];
  selected_values = [];
  mastervaluesLeft: any = [];
  mastervaluesright: any = [];
  _loader: boolean;
  search1: any;
  search2: any
  constructor(
    public dialogRef: MatDialogRef<CategoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public parentData: any,
    private notify: NotificationService,
    private fnc: CommonfunctionService,
    private apiService: ApiserviceService,
  ) {
    if (this.master_values.length <= 0) {
      this.getmastervalue();
    }
  }

  getmastervalue() {

    this.apiService.getEmsData(`property/masters/values?master_names=prop_sub_cat`).subscribe({
      next: (res: any) => {
        if (res) {
          let all = {
            master_value_name: 'all'
          }
          this.master_values = res;
          this.master_values.push(all);
          let mastervalues = this.parentData.ele.value.split(',');
          mastervalues.forEach(element => {
            for (var i = 0; i < this.master_values.length; i++) {
              if (this.master_values[i].master_value_name == element) {
                this.mastervaluesright.push(this.master_values[i]);
                this.master_values.splice(i, 1);
              }
            }
          });
          this.mastervaluesLeft = this.master_values;

          this.mastervaluesLeft.sort(this.fnc.GetSortOrder("master_value_name"));
          this.mastervaluesright.sort(this.fnc.GetSortOrder("master_value_name"));
        }
      },
      error: () => { }
    })
  }

  drop(event: CdkDragDrop<string[]>, direction: any = null) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }
  Update() {
    if (!this.parentData.info.setting_access.PATCH) {
      this.notify.notify("You are not authorized to add setting", "warn");
      return;
    }
    let selected_vals = [];
    this.mastervaluesright.forEach(element => {
      selected_vals.push(element.master_value_name);
    });
    var body = {
      "id": this.parentData.ele.id,
      "value": selected_vals.toString(),
      "status": this.parentData.ele.status,
      "user_id": this.apiService.user_id
    }
    this._loader = true;

    this.apiService.patchEmsData('settings', body).subscribe({
      next: (res: any) => {
        if (res) {
          this.notify.notify(res.message, "success")
          this.parentData.info.getSettings();
          this.cancel();
        }
        this._loader = false;
      },
      error: () => {
        this.notify.notify("settings not update", "error")
        this._loader = false;
      }
    })

  }
  ngOnInit() {
  }
  cancel() {
    this.dialogRef.close();
  }
}
