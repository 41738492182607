import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ApiserviceService } from "../../../../../../src/app/apiservice.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NotificationService } from "../../../../../../src/app/admin/services/notification.service";
import { minWordsValidator } from "../../validations/minWordsValidator";

@Component({
  selector: "app-admin-attribute-deletion-request-action",
  templateUrl: "./admin-attribute-deletion-request-action.component.html",
  styleUrls: ["./admin-attribute-deletion-request-action.component.scss"],
})
export class AdminAttributeDeletionRequestActionComponent {
  selectedEntityType: string = "property";
  deleteRequestGroup: FormGroup;
  title: string;
  isLoading: boolean = false;
  constructor(
    private api: ApiserviceService,
    private fb: FormBuilder,
    private notify: NotificationService,
    public dialogRef: MatDialogRef<AdminAttributeDeletionRequestActionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit(): void {
    this.title = this.data.admin_action;
    this.deleteRequestGroup = this.fb.group({
      comment: ["", [Validators.required, minWordsValidator(10)]],
    });
    //
  }
  close() {
    this.dialogRef.close();
  }

  ApproveOrReject() {
    let body = {
      action: this.data.admin_action,
      comment: this.deleteRequestGroup.value.comment,
    };
    let url = `approve-reject-request?id=${this.data.deleted_id}&user_id=${this.api.user_id}`;
    this.isLoading = true;
    this.api.patchEpsData(url, body).subscribe({
      next: (res) => {
        this.isLoading = false;
        console.log(res);
        let result: any = res;
        this.notify.notify(result.message, "success");
        this.dialogRef.close(true);
      },
      error: (error) => {
        this.isLoading = false;
      },
    });
  }
  get comment() {
    return this.deleteRequestGroup.get("comment");
  }
}
