

  <!-- card content starts here -------------------------------------------------- -->

    <div class="w-100 p-2" fxLayout="row" fxLayoutAlign="space-around center">
      <ngx-loading [show]="resolveLoader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
      <div class="p2 w-100">

        <!-- inner card -->
        <mat-card appearance="outlined" class="w-100 container-1">
          <ngx-loading [show]="loader_1" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
          <mat-card-header class="bb clr1">
            <div fxLayout="row" fxLayoutAlign="start center">
              <h2 class="mat-up clr1 upc">Legacy property list</h2>
            </div>
          </mat-card-header>
          <mat-card-content class="p2">

            <!-- bread crumb section -->
            <div class="h50p w-100 middle clr1">
              <h6 *ngIf="selected_property" class="mat-h6 ml-1 hvr-link" (click)="getProperty('legacy')">{{selected_property.legacy_property_uid}}</h6>
              <mat-icon *ngIf="entity_type == 'property_floor' || entity_type == 'property_unit'" class="ml-1">keyboard_arrow_right</mat-icon>
              <h6 *ngIf="entity_type == 'property_floor' || entity_type == 'property_unit'" class="mat-h6 ml-1 hvr-link"
                (click)="getFloors('legacy')">Floors <span *ngIf="selected_floor">({{selected_floor.floor_number}})</span></h6>
              <mat-icon *ngIf="entity_type == 'property_unit'" class="ml-1">keyboard_arrow_right</mat-icon>
              <h6 *ngIf="entity_type == 'property_unit'" class="mat-h6 ml-1 hvr-link">Units <span *ngIf="selected_unit">({{selected_unit.unit_number}})</span></h6>
            </div>
            <div class="h500p w-100 br middle bg-light">
              <div class="h-100p w-100 p2 bg-light">
                <!-- property card start -->
                <ng-container *ngFor="let item of legacyDataList; let k = index">
                  <data-list [parent]="this" [item]="item" [k]="k+1" [active]="isSelected('legacy',item)"></data-list>
                  <!-- property card ends here -->
                </ng-container>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <!-- inner card -->

      </div>
      <div class="p2 w-100">

        <!-- inner card -->
        <mat-card appearance="outlined" class="w-100 container-1">
            <ngx-loading [show]="loader_2" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
          <mat-card-header class="bb w-100 clr1">
            <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center">
              <div fxLayout="row" fxLayoutAlign="start center">
                <h6 class="mat-h5">
                  <h2 class="mat-up upc">Existing property list</h2>
                </h6>
              </div>
              <div fxLayout="row" fxLayoutAlign="end center">
                <button *ngIf="selected_property" class="back mr-1" mat-icon-button (click)="openMap()">
                  <mat-icon svgIcon="pin"></mat-icon>
                </button>
                <h6 *ngIf="selected_property" class="mat-h5 pr-1">
                  {{selected_property.property_name}}
                </h6>
              </div>
            </div>
          </mat-card-header>
          <mat-card-content class="p2">

            <!-- bread crumb section -->
            <div class="h50p w-100  middle clr1">
              <h6 *ngIf="selected_property_existing" (click)="getProperty('existing')" class="mat-h6 ml-1 hvr-link">{{selected_property_existing.property_uid}}</h6>
              <mat-icon *ngIf="entity_type_existing == 'property_floor_existing' || entity_type_existing == 'property_unit_existing'"
                class="ml-1">keyboard_arrow_right</mat-icon>
              <h6 (click)="getFloors('existing')" *ngIf="entity_type_existing == 'property_floor_existing' || entity_type_existing == 'property_unit_existing'" class="mat-h6 ml-1 hvr-link">Floors
                <span *ngIf="selected_floor_existing">({{selected_floor_existing.floor_number}})</span></h6>
              <mat-icon *ngIf="entity_type_existing == 'property_unit_existing'" class="ml-1">keyboard_arrow_right</mat-icon>
              <h6 *ngIf="entity_type_existing == 'property_unit_existing'" class="mat-h6 ml-1 hvr-link">Units <span *ngIf="selected_unit_existing">({{selected_unit_existing.unit_number}})</span></h6>
            </div>

            <div class="h500p w-100 br middle bg-light">
              <div class="h-100p w-100 p2 bg-light">
                <ng-container *ngFor="let item of existingDataList; let k = index">
                  <data-list [parent]="this" [item]="item" [k]="k+1" [active]="isSelected('existing',item)"></data-list>
                </ng-container>
              </div>
            </div>

          </mat-card-content>
        </mat-card>
        <!-- inner card -->

      </div>
    </div>
