import { Component, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { ApiserviceService } from "../../../../../../src/app/apiservice.service";
import { NotificationService } from "../../../../../../src/app/admin/services/notification.service";
import { MatDialog } from "@angular/material/dialog";
import { AttributeHistoryViewComponent } from "../attribute-history-view/attribute-history-view.component";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { AdminAttributeDeletionRequestActionComponent } from "../admin-attribute-deletion-request-action/admin-attribute-deletion-request-action.component";
import { CommonfunctionService } from "../../../../../../src/app/services/commonfunction.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-attribute-deletion-request-history",
  templateUrl: "./attribute-deletion-request-history.component.html",
  styleUrls: ["./attribute-deletion-request-history.component.scss"],
})
export class AttributeDeletionRequestHistoryComponent {
    // ViewChild for paginator
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = [
    "s_no",
    "id",
    "added_by",
    "updated_by",
    "submitted_date",
    "comment",
    "admin_comment",
    "status",
    "view",
    "action",
  ];
  dataSource: any;
  ELEMENT_DATA: any;
  deletedHistory: any;
  total: number;
  defaultPageSize: number = 10;
  pageSizeOptions;
  sizeOptions: string = "10, 15, 25, 50, 100";
  pageEvent: PageEvent;
  pageIndex = 0;
  limit: number = 10;
  offset: number = 0;
  isLoading: boolean = false;
  isDeletionAccess:boolean = false;
  attribute_deletion_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };

  constructor(
    private api: ApiserviceService,
    private notify: NotificationService,
    public dialog: MatDialog,
    private fnc: CommonfunctionService,
    private route: Router,
  ) {
    this.pageSizeOptions = this.sizeOptions.split(",").map((str) => +str);
  }
  ngOnInit(): void {
    this.getAllowResourcesApi()
  }
    // getting allow resources
    getAllowResourcesApi() {
      let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`
      this.api.getUmsData(body)
        .subscribe({next: (res: any) => {

          this.api.allowResources = res.data;
          this.getAllowResource();
        },error: (err) => {
        }})
    }
    getAllowResource() {
      this.attribute_deletion_access = this.fnc.checkResourceAccess('attribute_deletion', false);
      this.isDeletionAccess = this.attribute_deletion_access.PATCH;
      if (!this.attribute_deletion_access.GET) {
        this.route.navigateByUrl('');
        return;
      }
      this.deleteRequestHistory();
    }
  /**
   * Fetches and updates the history of deletion requests.
   *
   * This method constructs a URL with pagination parameters (`limit` and `offset`)
   * and makes an HTTP GET request to retrieve the deletion requests data from the server.
   * Once the data is received, it processes the response and updates the relevant properties
   * in the component, such as `deletedHistory`, `total`, and `ELEMENT_DATA`. The method also
   * initializes or updates the `MatTableDataSource` for displaying the data in an Angular Material table.
   *
   * The method handles the following:
   * - Constructs the URL with the current pagination settings.
   * - Sends an API request to fetch the deletion requests.
   * - Updates the `deletedHistory` property with the response.
   * - If the response status is 200 (success), it:
   *   - Updates the `total` count of deletion requests if `offset` is 0.
   *   - Assigns the retrieved data to `ELEMENT_DATA` and initializes or refreshes the table's data source.
   *
   * Note: Currently, there is no error handling implemented for cases where the response status is not 200.
   */
  deleteRequestHistory() {
    // Construct the URL with pagination parameters for the API request.
    let url = `get-delete-requests?limit=${this.limit}&offset=${this.offset}&is_count=true`;
    this.isLoading = true;
    // Make the API call to fetch the deletion request history.
    this.api.getEpsData(url).subscribe({
      next: (res) => {
        // Assign the response to the deletedHistory property.
        this.isLoading = false;
        this.deletedHistory = res;

        // Check if the response status is 200 (success).
        if (this.deletedHistory.status == 200) {
          // If the offset is 0, update the total count of deletion requests.
          if (this.offset == 0) {
            this.total = this.deletedHistory.count;
          }

          // Assign the data from the response to ELEMENT_DATA.
          this.ELEMENT_DATA = this.deletedHistory.data;

          // Initialize or update the MatTableDataSource with the fetched data.
          this.dataSource = new MatTableDataSource<any>(this.ELEMENT_DATA);
        } else {
          // Handle any additional logic for non-success responses (if needed).
        }
      },
      error(err) {
        this.isLoading = false;
      },
    });
  }

  /**
   * Opens a dialog to view the details of deleted data.
   *
   * This method is called when the user wants to view specific details of deleted data.
   * It first checks if the provided data is null. If no data is available, a notification
   * is displayed to inform the user, and the method returns without opening the dialog.
   *
   * If valid data is provided, a modal dialog containing the `AttributeHistoryViewComponent`
   * is opened, passing the selected data to the dialog. The dialog is configured with a fixed
   * width and custom styling via the `create-master-panel` class.
   *
   * After the dialog is closed, the method subscribes to the closing event and logs the result
   * (if any) to the console. If the dialog returns any data upon closing, it will be logged
   * to the console for further actions or debugging.
   *
   * @param data - The data object representing the deleted record to be viewed. If the data is null,
   *               a notification is shown and the dialog is not opened. Otherwise, this data
   *               is passed to the `AttributeHistoryViewComponent` for display in the dialog.
   */
  viewDeletedData(ele) {
    let view_id = ele.id;
    // Check if the provided data is null, and show a notification if true.
    // if (data == null) {
    //   this.notify.notify("No data available to display.", "warn");
    //   return;
    // }

    // Open the dialog with the specified component and configuration.
    let dialogRef = this.dialog.open(AttributeHistoryViewComponent, {
      // width: "1200px", // Set the width of the dialog.
      panelClass: "create-master-panel", // Apply custom styling to the dialog.
      disableClose: true,
      data: {
        view_id: view_id, // Pass the provided data object to the dialog component.
      },
    });

    // Subscribe to the dialog's afterClosed event to handle the result.
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result, "result"); // Log the result from the dialog.

      // If there is a result returned, log it to the console.
      if (result) {
        console.log(result);
      }
    });
  }

  /**
   * Handles the page change event for pagination.
   *
   * This method is triggered when the user interacts with the pagination controls.
   * It updates the `pageIndex`, `limit`, and `offset` values based on the current
   * page index and page size. If the page size (number of items per page) changes,
   * the offset is reset to 0. Otherwise, the offset is calculated based on the
   * current page index and page size.
   *
   * After updating the pagination values, the method calls `deleteRequestHistory()`
   * to fetch or update the data based on the new pagination settings.
   *
   * @param $event - The event object containing pagination data, including:
   *   - `pageIndex`: The current page index (0-based).
   *   - `pageSize`: The number of items per page (i.e., the page size).
   */
  pageAction($event) {
    // If the page size has changed
    if (this.limit !== $event.pageSize) {
      // Update the limit to the new page size
      this.limit = $event.pageSize;

      // Calculate the new page index to keep the current records in view
      this.paginator.pageIndex = 0;

      // Calculate the new offset based on the recalculated page index
      this.offset = 0;
    } else {
      // If the page size hasn't changed, just update the page index
      this.pageIndex = $event.pageIndex;

      // Calculate the offset based on the current page index and limit
      this.offset = this.pageIndex * this.limit;
    }

    // Fetch or update the data based on the new pagination settings
    this.deleteRequestHistory();
  }


  isApprovedOrReject(isDeletionApproved, element) {

    let dialogRef = this.dialog.open(AdminAttributeDeletionRequestActionComponent, {
      width: "420px",
      panelClass: "create-master-panel",
      disableClose:true,
      data: {
        deleted_id:element.id,
        admin_action:isDeletionApproved,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // this.notify.notify("Your attribute deletion request has been sent and is awaiting approval.", "success", 5000);
        // this.formReset();
        this.deleteRequestHistory();
        this.ELEMENT_DATA = [];
        this.total = 0;
        this.offset = 0;
        // this.resetPaginator();
        // this.tableInit();
      }
    });
  }

  confirmDialogYes(confirm) {
    if (confirm == 'YES') {

  }
}
}
