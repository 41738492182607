/// <reference types="@types/googlemaps" />
import { Component } from '@angular/core';
import { Layer } from './layer';
import { Feature } from './feature';
import { LatLon } from './latlon';

export class Map {
  api: string;
  id: string;
  map: any;
  markers = [];
  locationMarker: google.maps.Marker;
  constructor(options) {
    //Create id for map
    this.id = '_' + Math.random().toString(36).substr(2, 9);

    //Add 2D map
    if (!options) options = {};
    if (typeof options.api == 'undefined') options.api = 'googlemap';
    this.api = options.api;
    if (!options.element){
    	throw 'Element not provided';
    }
    if (!options.center){
    	throw 'Center not provided';
    }
    if (this.api == 'googlemap'){
	    let googleMapProp = {
	      center: new google.maps.LatLng(options.center.getLat(), options.center.getLon()),
	      zoom: (typeof options.zoom != 'undefined')? options.zoom: 18,
	      zoomControl: (typeof options.showZoomControl != 'undefined')? options.showZoomControl: false,
	      scaleControl: (typeof options.showScaleControl != 'undefined')? options.showScaleControl: false,
	      fullScreenControl: (typeof options.showFullScreenControl != 'undefined')? options.showFullScreenControl: false,
        streetViewControl: false,
        clickableIcons: false,
	      mapTypeControl: (typeof options.showBaseMapTypeControl != 'undefined')? options.showBaseMapTypeControl: true,
	      mapTypeControlOptions: {
	        position: google.maps.ControlPosition.TOP_RIGHT
	      },
	      mapTypeId: google.maps.MapTypeId.SATELLITE
	    };

	    this.map = new google.maps.Map(options.element, googleMapProp);
    } //googlemap ends
    
  }

  destroy(){
   
    if (this.api == 'googlemap'){
      
    }
    this.map = undefined;
    this.locationMarker = undefined;
  }

  addEventListener(name, callback){
  	if (this.api == 'googlemap'){
  		this.map.addListener(name, (e) => {
  			e.latlon = new LatLon(e.latLng.lat(), e.latLng.lng(), 0);
  			callback(e);
      });
      this.map.data.addListener(name, (e) => {
  			e.latlon = new LatLon(e.latLng.lat(), e.latLng.lng(), 0);
        let googleFeature = e.feature;
        let properties = [];
        googleFeature.forEachProperty(function(value,property) {
            properties[property] = value;
        });
        let feature = new Feature({
          'attributes': properties
        });
        feature.id = googleFeature.getId();
  			callback(e, feature);
  		});
  	}
    
  }

  panTo(latlon){
  	if (this.api == 'googlemap'){
  		this.map.panTo(new google.maps.LatLng(latlon.getLat(), latlon.getLon()));
  	}
    
  }

  zoomTo(latlon, zoomLevel){
  	if (this.api == 'googlemap'){
  		this.map.setZoom(zoomLevel);
  		this.map.panTo(new google.maps.LatLng(latlon.getLat(), latlon.getLon()));
  	}
   
  }

  zoomToFeature(feature){
    if (this.api == 'googlemap'){
      let googleBounds = new google.maps.LatLngBounds();
      
      this.map.map.getFeatureById(feature.id).getGeometry().forEachLatLng(function(latlng){
         googleBounds.extend(latlng);
      });
      this.map.fitBounds(googleBounds);
    }
   
  }

  fitBounds(north, south, east, west){
  	if (this.api == 'googlemap'){
  		var bounds = {
		    north: north,
		    south: south,
		    east: east,
		    west: west
		  };
		  this.map.fitBounds(bounds);
  	}
   
  }

  addMarker(latLon, options, is_draggable=false) {
  	if (!options) options = {};
  	if (this.api == 'googlemap'){
	  	var marker = new google.maps.Marker({
        position: new google.maps.LatLng(latLon.getLat(), latLon.getLon()),
        animation: google.maps.Animation.DROP,
		    map: this.map,
		    title: options.title,
        draggable : is_draggable,
        icon: {
          url: options.iconUrl,
          scaledSize: new google.maps.Size(options.iconWidth || 50, options.iconHeight || 50)
        }
	  	});
      this.markers.push(marker);

	  	if (options.info){
	  		var infowindow = new google.maps.InfoWindow({
			    content: options.info,
			    maxWidth: 200
  			});
  			infowindow.open(this.map, marker);
	  	}

      if (typeof options.dragend != 'undefined'){
        marker.addListener('dragend', (e:any) => {
          e.latlon = new LatLon(e.latLng.lat(), e.latLng.lng(), 0);
          delete e.latLng;
          options.dragend(e);
        });
      }
  	}
   
  }

  removeAllMarkers() {
    if (this.api == 'googlemap'){
      for (var i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
    }
   
    this.markers = [];
  }

  getLayer(name): Layer {
    return null;
  }

  showInfo(latLon, content, options) {

  }

  // WMS layer
  // addLayer(options){
  //   if(!options.url) return;
  //   if (options.format == 'wms'){
  //     var layer =
  //      new google.maps.ImageMapType(
  //      {
  //          getTileUrl: (coord:google.maps.Point, zoom:number) => {
  //              // Compose URL for overlay tile
  //              var s = Math.pow(2, zoom);
  //              var twidth = 256;
  //              var theight = 256;

  //              //latlng bounds of the 4 corners of the google tile
  //              //Note the coord passed in represents the top left hand (NW) corner of the tile.
  //              var gBl = this.map.getProjection().fromPointToLatLng(new google.maps.Point(coord.x * twidth / s, (coord.y + 1) * theight / s)); // bottom left / SW
  //              var gTr = this.map.getProjection().fromPointToLatLng(new google.maps.Point((coord.x + 1) * twidth / s, coord.y * theight / s)); // top right / NE

  //              // Bounding box coords for tile in WMS pre-1.3 format (x,y)

  //              var bbox = parseFloat(gBl.lng()) + "," + parseFloat(gBl.lat()) + "," + parseFloat(gTr.lng()) + "," + parseFloat(gTr.lat());

  //              //base WMS URL
  //              var url = options.url;

  //              url += "&service=WMS";           //WMS service
  //              url += "&version=1.1.0";         //WMS version
  //              url += "&request=GetMap";        //WMS operation
  //              url += "&layers=gisdb:" + options.layerName; //WMS layers to draw
  //              url += "&styles=";               //use default style
  //              url += "&format=image/png";      //image format
  //              url += "&TRANSPARENT=TRUE";      //only draw areas where we have data
  //              url += "&srs=EPSG:4326";         //projection WGS84
  //              url += "&bbox=" + bbox;          //set bounding box for tile
  //              url += "&width=256";             //tile size used by google
  //              url += "&height=256";
  //              url += "&tiled=true";
  //              if(options.assignment) url += "&viewparams=assignment_no:"+ options.assignment;
  //              //url = "http://51.15.221.10:8080/geoserver/gisdb/wms?service=WMS&version=1.1.0&request=GetMap&layers=gisdb:properties&styles=&
  //              //bbox=47.9728552938352,29.33170430618,48.0253671652929,29.379311241798&width=768&height=696&srs=EPSG:4326&format=image/png";
  //              return url;                 //return WMS URL for the tile
  //          },
  //          tileSize: new google.maps.Size(256, 256),
  //          opacity: 0.85,
  //          name: options.name
  //      });

  //     // add WMS layer to map
  //     this.map.overlayMapTypes.push(layer);
  //   }
  // }

// geoserver 
addGeoLayer(options){

    var layer =
    new google.maps.ImageMapType(
    {
        getTileUrl: (coord:google.maps.Point, zoom:number) => {
            // Compose URL for overlay tile
            var s = Math.pow(2, zoom);
            var twidth = 256;
            var theight = 256;

            //latlng bounds of the 4 corners of the google tile
            //Note the coord passed in represents the top left hand (NW) corner of the tile.
            var gBl = this.map.getProjection().fromPointToLatLng(new google.maps.Point(coord.x * twidth / s, (coord.y + 1) * theight / s)); // bottom left / SW
            var gTr = this.map.getProjection().fromPointToLatLng(new google.maps.Point((coord.x + 1) * twidth / s, coord.y * theight / s)); // top right / NE

            // Bounding box coords for tile in WMS pre-1.3 format (x,y)

            var bbox = parseFloat(gBl.lng()) + "," + parseFloat(gBl.lat()) + "," + parseFloat(gTr.lng()) + "," + parseFloat(gTr.lat());

            //base WMS URL=
            var url = `${options.url}?place_id=${options.city_id}&token=${options.user_token}&store=${options.city_code.toUpperCase()}_gisdb`;

            
            url += "&layers=gisdb:" + options.layerName; //WMS layers to draw
            // url += "&format=image/png";      //image format
            // url += "&transparent=TRUE";      //only draw areas where we have data
            // url += "&version=1.1.0";         //WMS version
            url += "&request=GetMap";        //WMS operation
            // url += "&service=WMS";           //WMS service
            url += "&styles=";               //use default style
            url += "&srs=EPSG:4326";         //projection WGS84
            url += "&bbox=" + bbox;          //set bounding box for tile
            url += "&width=256";             //tile size used by google
            url += "&height=256";
            // url += "&tiled=true";
            if(options.assignment) url += "&viewparams=assignment_no:"+ options.assignment;
            //url = "http://51.15.221.10:8080/geoserver/gisdb/wms?service=WMS&version=1.1.0&request=GetMap&layers=gisdb:properties&styles=&
            //bbox=47.9728552938352,29.33170430618,48.0253671652929,29.379311241798&width=768&height=696&srs=EPSG:4326&format=image/png";
            return url;                 //return WMS URL for the tile
        },
        tileSize: new google.maps.Size(256, 256),
        opacity: 0.85,
        name: options.name
    });

   // add WMS layer to map
   this.map.overlayMapTypes.push(layer);
  }
  
}
