import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { io } from "socket.io-client";
import { environment } from '../../environments/environment';
import { ApiserviceService } from '../apiservice.service';

@Injectable({
  providedIn: "root",
})
export class SocketService {
  socket = io(environment.csvUrl, {
    extraHeaders: {
      "user-city": this.apiservice.city_id,
    },
  });

  socket1 = io(environment.epsUrl, {
    extraHeaders: {
      "user-city": this.apiservice.city_id,
    },
  });


  public message$: BehaviorSubject<string> = new BehaviorSubject("");
  public progress$: BehaviorSubject<string> = new BehaviorSubject("");
  constructor(private apiservice: ApiserviceService) {
    this.socket.on("message", (message) => {
      this.message$.next(message);
    });

    this.socket1.on("sync_message", (progress) => {
      this.progress$.next(progress);
    });

  }
}
