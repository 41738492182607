import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiserviceService } from "../../../../apiservice.service";
import { CommonfunctionService } from "../../../../services/commonfunction.service";

@Component({
  selector: 'bulk-reject-dialog',
  templateUrl: 'bulk-reject-dialog.component.html',
  styleUrls: ['../../qa-qc.component.scss']
})
export class BulkRejectDialog {
  parent_entity: string;
  current_entity_label: string;
  resource_access: any;
  parent: any;
  user_comment: string = '';
  selectedIds: any;
  selected_rows: any;
  rejection_type: string = null;
  progress: number = 0;
  reject_options: any = [
    { label: 'Reject attributes for selected group', value: 'selected' },
    { label: 'Reject all attributes', value: 'all' },
    { label: 'Reject data for selected floor and its units', value: 'floor_unit' }
  ]

  constructor(public dialogRef: MatDialogRef<BulkRejectDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fnc: CommonfunctionService,
    private api: ApiserviceService) {
    this.parent = this.data.parent;
    this.resource_access = this.fnc.checkResourceAccess('qa_qc', false);
    this.parent_entity = this.parent.entity_type;
    this.selectedIds = this.parent.selectedIds;
    this.selected_rows = this.parent.selected_rows;
    if (this.parent_entity == 'property_floor') this.current_entity_label = 'Floors';
    if (this.parent_entity == 'property_unit') this.current_entity_label = 'Units';
  }

  ngOnInit() {
   }

  closeDialog() {
    this.dialogRef.close();
  }
  rejectionType(e) {
    this.rejection_type = e.value;
  }

  rejectFloorUnit(){
    let floor_ids = [];
    this.selected_rows.map(ele=>{
      floor_ids.push(ele.floor_id)
    })
    let body = {
      assignment_id: this.parent.selected_assignment.assignment_id,
      floor_id: floor_ids.toString(),
      updated_by: this.api.user_id
    }
    if (this.user_comment) {
      body['comment'] = this.user_comment;
    }
    let url = `property/reject`;
    this.api.patchEpsData(url, body)
        .subscribe({next:(data: any) => {
          this.parent.notify.notify('Floor ready for rework', 'warn');
          this.closeDialog();
          this.parent.getDataForPagin();
          this.parent.getFloors();
        }, error:(err) => {
          this.parent.notify.notify(this.fnc.getErrorMessage(err), 'error');
        }});

  }
  //function to initiate rejection of floors/units with attributes
  reject() {
    if (!this.resource_access.PATCH) {
      this.parent.notify.notify("You're not authorized to perform reject operation in qa/qc " + this.current_entity_label, 'warn');
      return;
    }
    if (!this.rejection_type) {
      this.parent.notify.notify("Please select a type of rejection for " + this.current_entity_label, 'warn');
      return;
    }
    if(this.rejection_type == 'floor_unit'){
      this.rejectFloorUnit();
    }else{
      this.progress = 10;
      let url;
      let floorData = { floor_sort_order_list: [], updated_by: this.api.user_id }, attributes = [];
      let unitData: any = [];
      let body: any;
      let attr = {};
      if (this.parent_entity == 'property_floor') {
        url = "deadmin/" + this.parent.selected_assignment.name + "/properties/" + this.parent.selected_property.id + '/floors';
      }
      if (this.parent_entity == 'property_unit') {
        url = "deadmin/" + this.parent.selected_assignment.name + "/properties/" + this.parent.selected_property.id + '/floors/' + this.parent.selected_floor.floor_id + '/units';
      }

      this.selected_rows.forEach(element => {
        if (this.rejection_type == 'selected') {
          attributes = this.fnc.getChildArray('group_id', this.parent.selected_group.id, this.parent.attribute_list);
        } else if(this.rejection_type == 'all'){
          attributes = this.parent.attribute_list;
        }
        if (attributes.length) {
          attributes.forEach((c) => {
            attr[c.id] = {
              "comment": this.user_comment,
              "status": -1
            }
          });
        }

        if (this.parent_entity == 'property_floor') {
          floorData.floor_sort_order_list.push({
            "attribute_info": attr,
            "floor_id": element.floor_id,
            "sort_order": element.sort_order,
            "status": 3,
            "updated_by": this.api.user_id
          })
        }
        if (this.parent_entity == 'property_unit') {
          unitData.push({
            "attribute_info": attr,
            "status": 3,
            "unit_id": element.id,
            "updated_by": this.api.user_id,
          })
        }
      });

      if (this.parent_entity == 'property_unit') {
        body = unitData;
      }
      if (this.parent_entity == 'property_floor') {
        body = floorData;
      }
      this.api.patchEpsData(url, body).subscribe({next:(res) => {
        this.parent.selectedIds = [];
        this.parent.selected_rows = [];
        this.progress = 98;
        this.parent.getDataForPagin();
        setTimeout(() => {
          this.progress = 100;
        }, 10);
        setTimeout(() => {
          this.dialogRef.close();
        }, 100);
        if (this.parent_entity == 'property_floor') {
          this.parent.getFloors();
        }
        if (this.parent_entity == 'property_unit') {
          this.parent.getUnits();
        }
      }, error:() => {
        this.progress = 0;
      }})
    }
  }

}
