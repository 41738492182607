import { Component , ViewChild, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LatLon } from '../../map_api/latlon';
import { Map } from '../../map_api/map';

@Component({
  selector: 'app-show-property-map',
  templateUrl: './show-property-map.component.html',
  styleUrls: ['./show-property-map.component.scss']
})
export class ShowPropertyMapComponent {


  @ViewChild('gmap') gmapElement: any;
  map: Map;
  constructor(public dialogRef: MatDialogRef<ShowPropertyMapComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  close(){
    this.dialogRef.close();
  }
  ngAfterViewInit() {
    this.renderMap();
  }

  renderMap() {
    let location_obj: any = new LatLon(this.data.property.lat, this.data.property.lon, 0);
    let mapProp = {
      center: new LatLon(this.data.property.lat, this.data.property.lon, 0),
      zoom: 15,
      zoomControl: false,
      scaleControl: false,
      streetViewControl: false,
      mapTypeControl: true,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT
      },
      mapTypeId: google.maps.MapTypeId.SATELLITE,
      element: this.gmapElement.nativeElement
    };

    this.map = new Map(mapProp);
    //remove all markers

    this.map.removeAllMarkers();
    this.map.panTo(location_obj);

    this.data.markers.forEach(prop => {
      this.map.addMarker(new LatLon(prop.lat, prop.lon, 0),
        {
          'iconUrl': prop.marker,
          'iconWidth': prop.w,
          'iconHeight': prop.h,
          'info': '<div id="content">' + prop.uid +'</div>',
        }, false);

    });
  }
}
