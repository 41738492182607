import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "field-submaster-contextmenu",
  templateUrl: "../../subelements/submaster-menu.component.html",
  styleUrls: ["./field-submaster-contextmenu.component.scss"],
})
export class FieldSubmasterContextMenu {
  @Input("item") item;
  @Input("attributegroups_access") groups_access;
  @Output() edit: EventEmitter<{}> = new EventEmitter<{}>();
  @Output() shuffle: EventEmitter<{}> = new EventEmitter<{}>();

  attributegroups_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };
  constructor() {}

  editGroup(item) {
    this.edit.emit({ item: item, form: "createattribute" });
  }

  shuffleGroup(item) {
    this.shuffle.emit({ item: item, form: "groupshuffledialog" });
  }
  ngOnInit() {
    if (this.groups_access) {
      this.attributegroups_access = this.groups_access;
    }
  }
}
