<div *ngIf="masters_access.PATCH || masters_access.DELETE" >
    <span *ngIf="masters_access.PATCH" class="mastergroup_menuactions" (click)="openDialog('master',{'master_val':masterlist,'edit':true}, $event)" title="Edit">
        <mat-icon>edit</mat-icon>
    </span>
    <!-- <div class="divider"></div> -->
    <span *ngIf="masters_access.DELETE" class="mastergroup_menuactions" title="Delete Master">
            <mat-icon>delete</mat-icon>
        </span>
    <div class="arrows"><img src="/assets/images/arr.png" /></div>
</div>
