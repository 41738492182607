<!-- loader start -->
<div  [ngClass]="{'fixed': isLoading}">
  <ngx-loading [show]="isLoading"></ngx-loading>
</div>
<!-- loader end -->
<div class="wrapper">
  <div class="container_wrapper">
    <div class="heading-content">
      <div class="d-flex"><h2>{{title}}</h2>
        <button type="button" (click)="switchRequestOrHistoty()" class="switch-btn"> <span *ngIf="!isRequestPage">View & Manage request</span> <span *ngIf="isRequestPage">Access Data Deletion</span></button>
      </div>
      <!--  -->
      <div *ngIf="!isRequestPage && selection.selected.length != 0">
        <button type="button" class="delete-btn" (click)="deleteRequest('selected')">Delete Selected <mat-icon (click)="$event.stopPropagation()" class="handle info" svgIcon="info-icon" [matTooltip]="deleteLogMesg"></mat-icon></button>
        <button type="button" class="delete-btn-all" (click)="deleteRequest('all')">Delete All <mat-icon (click)="$event.stopPropagation()" class="handle info" svgIcon="info-icon"  [matTooltip]="deleteAllMesg"></mat-icon></button>
      </div>
    </div>
   <ng-container *ngIf="!isRequestPage">
    <div class="custom-form-wrapper">

      <form [formGroup]="attrbute_deletion_group">
        <div class="custom-row">
          <div class="custom-form-control">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>Entity type</mat-label>
              <mat-select
                #SelectedType
                formControlName="entityTypeControl"
                (selectionChange)="getChangeEntity(SelectedType.value)"
                placeholder="Select Entity type"
              >
                <mat-option
                  *ngFor="let ent of entityTypeList"
                  [value]="ent.entityType"
                  >{{ ent.entityLabel }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            class="custom-form-control"
            *ngIf="
              attrbute_deletion_group.value.entityTypeControl == 'property_unit'
            "
          >
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>Property type</mat-label>
              <mat-select
                formControlName="propertyTypeControl"
                placeholder="Select property type"
                (selectionChange)="getTypeChange()"
                class=""
              >
                <mat-option
                  *ngFor="let ent of property_type_list"
                  [value]="ent.property_type_id"
                  >{{ ent.property_type_label[1] }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="custom-form-control">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>Select Attribute</mat-label>
              <!-- This placeholder will be visible when no item is selected -->
              <mat-select formControlName="attributeControl" placeholder="Select Attribute" (selectionChange)="selectedAttributeId($event.value)" (openedChange)="searchAttribute=''">

                <!-- Custom input for searching placed within a <mat-option> -->
                <!-- <mat-option disabled> -->
                <div class="search-input">
                  <input
                  matInput
                  #searchTextValue
                  [(ngModel)]="searchAttribute"
                  [ngModelOptions]="{ standalone: true }"
                  [placeholder]="placeHolder"
                  (keydown)="$event.stopPropagation()"
                  (click)="$event.stopPropagation()"
                />
                <span *ngIf="searchTextValue.value.length == 0">Type here to search...</span>
                </div>
                <!-- </mat-option> -->

                <!-- Loop through the filtered items -->
                <mat-option
                  *ngFor="let attribute of attributeList | search : searchAttribute"
                  [value]="attribute.attribute_name"
                >
                  {{ attribute.attribute_name }}
                </mat-option>

              </mat-select>
            </mat-form-field>
          </div>
          <div class="custom-form-control date-picker" (click)="campaignTwoPicker.open()">
            <mat-form-field class="example-form-field" appearance="outline" floatLabel="always">
              <mat-label>Submitted date range</mat-label>
              <mat-date-range-input [rangePicker]="campaignTwoPicker">
                <input
                #startDate
                  matStartDate
                  placeholder="Start date"
                  [min]="startYear"
                  [max]="currentYear"
                  formControlName="start"
                  readonly
                />
                <input
                #endDate
                  matEndDate
                  placeholder="End date"
                  [min]="startYear"
                  [max]="currentYear"
                  formControlName="end"
                  readonly
                />
              </mat-date-range-input>
              <button *ngIf="startDate.value != '' && endDate.value != ''" (click)="$event.stopPropagation(); clearSelectedDate();" type="button" class="cross-icon">
                <img src="../../../../../assets/icon/red-cross.svg" alt="">
              </button>
              <mat-datepicker-toggle matIconSuffix [for]="campaignTwoPicker"></mat-datepicker-toggle>
              <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
              <mat-date-range-picker #campaignTwoPicker></mat-date-range-picker>
            </mat-form-field>
          </div>


          <div class="custom-form-control">
            <mat-form-field appearance="outline" floatLabel="always">
              <mat-label>Enter UIDS</mat-label>
              <input matInput #uids placeholder="Enter UIDS" formControlName="entityUidsControl"/>
              <mat-hint>Enter UIDs up to 500.</mat-hint>
              <mat-error *ngIf="uids.value.length > 0 &&attrbute_deletion_group.get('entityUidsControl').hasError('whitespace')">
                UIDs should not contain any whitespace.
              </mat-error>
            </mat-form-field>

           <div>
           </div>
          </div>
          <div class="custom-form-control">
            <button type="button" class="apply-btn" (click)="getDeletionList()" [disabled]="attrbute_deletion_group.invalid">Apply</button>
          </div>
        </div>
      </form>
    </div>
    <div class="table-wrapper">
      <table mat-table [dataSource]="dataSource" class="">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? toggleAllRows() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          [aria-label]="checkboxLabel()"
                          [disabled]="!dataSource.data.length">
            </mat-checkbox>
            S. No
          </th>
          <td mat-cell *matCellDef="let row; let i = index;">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)"
                          [aria-label]="checkboxLabel(row)">
            </mat-checkbox>
            {{i+1+offset}}
          </td>
        </ng-container>

     <!-- S.No Name Column -->
     <!-- <ng-container matColumnDef="S_No">
      <th mat-header-cell *matHeaderCellDef> S. No </th>
      <td mat-cell *matCellDef="let element; let i = index;"> {{i+1+offset}} </td>
    </ng-container> -->
        <!-- Attribute Name Column -->
        <ng-container matColumnDef="Attribute_name">
          <th mat-header-cell *matHeaderCellDef> Attribute Name </th>
          <td mat-cell *matCellDef="let element"> {{element.attribute_name}} </td>
        </ng-container>

        <!-- value Column -->
        <ng-container matColumnDef="Value">
          <th mat-header-cell *matHeaderCellDef> Value </th>
          <td mat-cell *matCellDef="let element"> {{element.new_value}} </td>
        </ng-container>

        <!-- log id Column -->
        <ng-container matColumnDef="Log_ID">
          <th mat-header-cell *matHeaderCellDef> Log ID </th>
          <td mat-cell *matCellDef="let element"> {{element.log_id}} </td>
        </ng-container>

        <!-- Assignment_name  Column -->
        <ng-container matColumnDef="Assignment_name">
          <th mat-header-cell *matHeaderCellDef> Assignment Name </th>
          <td mat-cell *matCellDef="let element"> {{element.assignment_name}} </td>
        </ng-container>

      <!-- Assignment_name  Column -->
      <ng-container matColumnDef="Submit_date">
        <th mat-header-cell *matHeaderCellDef> Submit date </th>
        <td mat-cell *matCellDef="let element"> {{element.submitted_date}} </td>
      </ng-container>

        <!-- Assignment_name  Column -->
        <ng-container matColumnDef="Property_UID">
          <th mat-header-cell *matHeaderCellDef> Property UID </th>
          <td mat-cell *matCellDef="let element"> {{element.property_uid}} </td>
        </ng-container>
             <!-- Assignment_name  Column -->
             <!-- <ng-container matColumnDef="attribute_id">
              <th mat-header-cell *matHeaderCellDef> Property_UID </th>
              <td mat-cell *matCellDef="let element"> {{element.attribute_id}} </td>
            </ng-container> -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            (click)="selection.toggle(row)">
        </tr>
      </table>
      <div class="not-data-found" *ngIf="!total">
        Choose options and click 'Apply' to load the data. {{dataSource.length}}
      </div>
      <mat-paginator *ngIf="total"  [length]="total"  showFirstLastButtons [pageSizeOptions]="pageSizeOptions" (page)="pageAction($event)">
      </mat-paginator>
    </div>
   </ng-container>




    <div class="deletion-history-wrapper" *ngIf="isRequestPage">
      <app-attribute-deletion-request-history></app-attribute-deletion-request-history>
    </div>

  </div>

</div>
