<form [formGroup]="masterValueForm" autocomplete="off" novalidate>
    <div *ngIf="loader" class='loader-div'>
        <ngx-loading [show]="loader"></ngx-loading>
    </div>
    <mat-toolbar class="creategroup">
        <div *ngIf="!data.edit">Create Master Value</div>
        <div *ngIf="data.edit">Edit Master Value</div>
    </mat-toolbar>
    <mat-dialog-content>
        <mat-form-field floatLabel="never" class="w-100">
            <input [errorStateMatcher]="matcher" matInput type="text" placeholder="Master Value Name" maxlength="64" #master_value formControlName="master_name">
            <mat-hint align="end"> {{master_value.value.length}}/64 </mat-hint>
            <!-- <mat-error>Master is required</mat-error> -->
            <mat-error *ngIf="masterValueName.hasError('required')">Master Value name is required</mat-error>
            <mat-error *ngIf="masterValueName.hasError('pattern')"> Special Characters are not allowed </mat-error>
        </mat-form-field>
        <div formArrayName="language">
            <div *ngFor="let languagevalues of language_valueset;let kk = index">
                <div [formGroupName]="kk" fxLayout="row" fxLayoutAlign="space-between start">
                    <mat-form-field floatLabel="never" fxFlex="30%">
                        <mat-select placeholder="Language" formControlName="language">
                            <mat-option *ngFor="let language of languages" [value]="language.language_id">
                                {{ language.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field floatLabel="never" fxFlex="40%">
                        <input [errorStateMatcher]="matcher" matInput type="text" maxlength="64" placeholder="Label" #master_label formControlName="value">
                        <!--<mat-hint>Hint message goes here</mat-hint>-->
                        <mat-hint align="end"> {{master_label.value.length}}/64 </mat-hint>
                        <mat-error>Label is required</mat-error>
                    </mat-form-field>
                    <div class="addbtn">
                      <div (click)="removeRow(kk)" class="border iconTick-1">
                          <mat-icon>remove</mat-icon>
                      </div>
                      <div (click)="addRow()" class="border iconTick-1">
                          <mat-icon>add</mat-icon>
                      </div>
                  </div>
                </div>
            </div>
        </div>
        <div *ngIf="!data.edit">
            <mat-form-field floatLabel="never" *ngIf="data.pObj.is_property_dependent" class="w-100">
                <mat-select multiple formControlName="property_type" #selectionModel>
                    <app-select-check-all [model]="selectionModel" [values]="property_types">
                    </app-select-check-all>
                    <mat-option *ngFor="let property of property_types" [value]="property.property_type_id">{{property.property_type_label[api.language]}}</mat-option>
                </mat-select>
                <!-- <mat-error>Group is required</mat-error> -->
            </mat-form-field>

            <mat-form-field floatLabel="never" class="w-100">
                <mat-select placeholder="Group" formControlName="group_id" (selectionChange)="changeMaster($event)">
                    <mat-option *ngFor="let grp of groups" [value]="grp.id">
                  {{ grp.title }}
                    </mat-option>
                </mat-select>
                <!-- <mat-hint>Hint message goes here</mat-hint>-->
            </mat-form-field>

            <mat-form-field floatLabel="never" class="w-100">
                <mat-select placeholder="Master" formControlName="master_id">
                    <mat-option *ngFor="let mst of masters" [value]="mst.id">
                   {{ mst.title }}
                    </mat-option>
                </mat-select>
                <!-- <mat-hint>Hint message goes here</mat-hint> -->
                <mat-error>Master is required</mat-error>
            </mat-form-field>
        </div>
        <input id="image_input" (change)="uploadImage($event)" class="hidden" accept="image/jpeg,image/gif,image/png,image/jpg" type="file" />
        <div class="searchbar" fxLayout="row" fxLayoutAlign="space-between center">
            <button (click)="openExplorer('image')" mat-stroked-button color="primary" class="cent">
            <i class="material-icons">cloud_upload</i> &nbsp; Upload Icon
        </button>
            <!-- <mat-icon id="pPic" *ngIf="masterImage_svg" svgIcon="master_image"></mat-icon> -->
            <img *ngIf="masterImage" id="pPic" [src]="sanitizer.bypassSecurityTrustResourceUrl(masterImage)" alt="Picture">
        </div>
        <div class="searchbar">
            <mat-slide-toggle class="example-margin" [checked]="status" [(ngModel)]="status" [ngModelOptions]="{standalone: true}">
                Status
            </mat-slide-toggle>
            <mat-checkbox *ngIf="!isAddMore" class="" (change)="getCheck($event)" [checked]="status_more">Create More</mat-checkbox>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="dialog-actions">
        <button mat-raised-button [disabled]="masterValueForm.invalid" class="blue-button" (click)="uploadMasterFile(masterValueForm,$event)">Submit</button>
        <button mat-raised-button type="button" (click)="closeDial($event)">Cancel</button>
    </mat-dialog-actions>
</form>
