import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiserviceService } from '../../../app/apiservice.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private api: ApiserviceService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if(this.api.user_id && localStorage.getItem('user_id')){
      return true;
    }else{
      this.api.logout(false)
     // this.router.navigateByUrl('/admin/login');
      return false;
    }
  }
}