import { Component, OnInit, ViewChild } from "@angular/core";
import { CommonfunctionService } from "../../services/commonfunction.service";
import { ApiserviceService } from "../../apiservice.service";
import { NotificationService } from "../services/notification.service";
import { AdminMenusService } from "../admin-sidebar/admin-menus.service";
import { Router } from "@angular/router";
import { PageEvent } from "@angular/material/paginator";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-adm",
  templateUrl: "./adm.component.html",
  styleUrls: ["./adm.component.css"],
})
export class ADMComponent implements OnInit {
  eps_log_path =
    environment.epsUrl + "download-file?file_url=" + environment.eps_log_path;
  city_code: string;
  @ViewChild("myFileInput") myFileInput;

  loading: boolean;
  loader: boolean;
  fileName: string;

  total: number;
  defaultPageSize: number = 10;
  pageSizeOptions;
  sizeOptions: string = "10, 15, 25, 50";
  pageEvent: PageEvent;
  pageIndex = 0;

  DataADMSource = [];

  selected_assignment;
  searchAssignment: string = "";
  assignmentList = [];

  search_year: string = "";
  // yearList = [];
  // selected_year = new Date().getFullYear().toString();
  fileToUpload: any;
  limit: number = 10;
  offset: number = 0;
  adm_access: { GET: boolean; PATCH: boolean; POST: boolean; DELETE: boolean };

  status = {
    "-1": "Invalid",
    "1": "Valid",
    "2": "Accepted",
    "-2": "Accept invalid",
    "0": "Uploaded",
    "-3": "Rejected",
    "4": "Accept Progress",
    "3": "Validating",
  };

  constructor(
    private notify: NotificationService,
    private router: Router,
    private api: ApiserviceService,
    private fnc: CommonfunctionService,
    private menus: AdminMenusService
  ) {
    this.menus.setActive("adm-data");
    this.city_code = this.api.city_code.toLowerCase();
    this.pageSizeOptions = this.sizeOptions.split(",").map((str) => +str);
  }
  closeMatSelectEvent(event, type) {
    if (!event) {
      if (type == "year") {
        this.search_year = "";
      } else if (type == "assignment") {
        this.searchAssignment = "";
      }
    }
  }
  ngOnInit(): void {
    // this.getYears();
    this.getAllowResourcesApi();
  }

  getAllowResource() {
    this.adm_access = this.fnc.checkResourceAccess("adm_data", false);
    if (!this.adm_access.GET) {
      this.router.navigateByUrl("/admin/login");
      return;
    } else {
      this.getAssignments();
    }
  }
  // getting allow resources
  getAllowResourcesApi() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`;
    this.api.getUmsData(body).subscribe({
      next: (res: any) => {
        this.api.allowResources = res.data;
        this.getAllowResource();
      },
      error: (err) => {
      },
    });
  }

  //get all assignments
  getAssignments() {
    this.assignmentList = [];
    let url = "assignments?sort_by=assignment_name&status=1";
    this.api.getEmsData(url).subscribe((data: any) => {
      //change sort order
      data = this.fnc.assignmentSortOrder(data);
      this.assignmentList = data;
    });
  }

  getAssignmentLabel(id) {
    if (id) {
      let assign = this.assignmentList.find((e) => e.assignment_id == id);
      if (assign) {
        return assign.name;
      }
      return "";
    }
    return "";
  }
  getDate(dt) {
    if (dt) {
      let d = new Date(dt);
      return this.fnc.formatDateUTC(d, true);
    }
    return "";
  }
  //create year dropdown
  // getYears() {
  //   const year = new Date().getFullYear();
  //   this.yearList.push({ val: year.toString() });
  //   for (var i = 1; i < 13; i++) {
  //     this.yearList.push({ val: (year - i).toString() });
  //   }
  // }

  pageAction($event) {
    this.pageIndex = $event.pageIndex;
    if (this.limit != $event.pageSize) {
      this.limit = $event.pageSize;
    } else {
      this.offset = this.pageIndex * $event.pageSize;
    }
    this.getADMStatus();
  }

  uploadFile() {
    if (!this.adm_access.POST) {
      this.notify.notify("User not Authorized to Upload ADM Data", "warn");
      return;
    }

    if (!this.selected_assignment) {
      this.notify.notify("Assignment is required", "warn");
      return;
    } else if (!this.fileToUpload) {
      this.notify.notify("File is required", "warn");
      return;
    }

    let formData = new FormData();
    formData.append("file", this.fileToUpload, this.fileToUpload.name);
    this.loading = true;

    this.api
      .uploadEpsData(
        `unit-adm?layer_name=units&assignment_id=${this.selected_assignment}&user_id=${this.api.user_id}`,
        formData
      )
      .subscribe({
        next: (res: any) => {
          this.loading = false;
          this.notify.notify(res.message, "success");
          if (res.status == 1) {
            this.myFileInput.nativeElement.value = "";
            this.fileToUpload = null;
            this.fileName = "";
            this.getADMStatus();
          }
        },
        error: (err) => {
          this.loading = false;
          this.myFileInput.nativeElement.value = "";
          this.fileToUpload = null;
          this.fileName = "";
        },
      });
  }
  handleFileInput($event) {
    let fileList: FileList = $event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let pattern = /zip-*/;
      if (!file.type.match(pattern)) {
        this.notify.notify("Unsupported file type", "warn");
        return;
      }
      this.fileToUpload = file;
      this.fileName = this.fileToUpload.name;
    }
  }

  selectionChange() {
    this.limit = 10;
    this.offset = 0;
    this.getADMStatus();
  }

  getADMStatus() {
    if (!this.adm_access.GET) {
      this.notify.notify("User not Authorized to Get ADM Data", "warn");
      return;
    }

    this.loader = true;
    let url = `unit-adm?layer=units&limit=${this.limit}&offset=${this.offset}`;
    if (this.selected_assignment) {
      url += `&assignment_id=${this.selected_assignment}`;
    }
    if (this.offset == 0) {
      url += `&is_count=true`;
    }
    this.api.getEpsData(url).subscribe({
      next: (res: any) => {
        this.loader = false;
        if (res.data && res.data.length > 0) {
          this.DataADMSource = res.data;
        } else {
          this.DataADMSource = [];
          this.notify.notify(res.message, "warn");
        }
        if (this.offset == 0) {
          this.total = res?.totalRecord ? res.totalRecord : 0;
        }
      },
      error: (err) => {
        this.loader = false;
        this.DataADMSource = [];
      },
    });
  }

  accept_reject(upload_id, action) {
    if (!this.adm_access.PATCH) {
      this.notify.notify("User not Authorized to Update ADM Data", "warn");
      return;
    }

    this.loader = true;
    this.api
      .getEpsData(
        `unit-adm-accept-reject?upload_id=${upload_id}&action_type=${action}&user_id=${this.api.user_id}`
      )
      .subscribe({
        next: (res: any) => {
          if (res && res.status == 200) {
            this.loader = false;
            this.getADMStatus();
            this.notify.notify(res.message, "success");
          } else {
            this.notify.notify(res.message, "warn");
          }
        },
        error: (err) => {
          this.loader = false;
        },
      });
  }
}
