<div>
    <div>
        <mat-toolbar class="tool">Select Group</mat-toolbar>
    </div>
    <div class="message">No of selected unit -
        <strong>{{noSelectedunit}}</strong>
    </div>
    <div class="group-container">
        <ng-container *ngFor="let data of group_list">
            <div class="parent">
                <mat-checkbox (change)='selectGroup($event,data.id)'>{{data.group_name}}</mat-checkbox>
                <mat-icon *ngIf="data.childrens.length" (click)="isActive(data.id)" class="collapse" [ngClass]="{'active':show_child == data.id}">play_arrow</mat-icon>
            </div>
            <div *ngIf="data.childrens.length && data.id == show_child" class="child" id="child_1">
                <div *ngFor="let child of data.childrens">
                    <mat-checkbox (change)='selectGroup($event,data.id)'>{{child.group_name}}</mat-checkbox>
                </div>
            </div>

        </ng-container>
    </div>

    <div class="action">
        <div>
            <button (click)="pasteUnit()" class="orange-button" mat-button>Paste</button>
        </div>
        <div>
            <button (click)="closeDialog()" type="button" class="act" mat-button>Cancel</button>
        </div>
    </div>
</div>