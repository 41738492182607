import { Component } from "@angular/core";
import { ApiserviceService } from "../../../../apiservice.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { environment } from '../../../../../../src/environments/environment';
import { MatDialogRef } from "@angular/material/dialog";
import { NotificationService } from '../../../services/notification.service';
@Component({
  selector: "app-master-download",
  templateUrl: "./master-download.component.html",
  styleUrls: ["./master-download.component.scss"],
})
export class MasterDownloadComponent {
  property_type_list: [] = [];
  all_masters: any;
  downloadMasterGroup:FormGroup;
  search_master = '';
  constructor(
    public api: ApiserviceService,
     private fb : FormBuilder,
     public dialogRef: MatDialogRef<MasterDownloadComponent>,
     private notify: NotificationService,
    ) {}

  ngOnInit(): void {
    this.getPropertyType();
    this.getMasters();
    this.init()
  }

  /**
   * form getting init
   */
  init(){
    this.downloadMasterGroup = this.fb.group({
     masterValue:['', Validators.required],
     entityType:['', Validators.required],
     entity_type_id:['']
    })
  }
  /**
   * getting list of property type
   *
   */
  getPropertyType() {
    let url =
      "propertytypes?sort_by=property_type_name&limit=500&offset=0&status=1";
    this.api.getEmsData(url).subscribe({
      next: (data: any) => {
        this.property_type_list = data;
        console.log(data, this.property_type_list);
      },
      error: () => {
        console.log("error");
      },
    });
  }

  /**
   * get master list
   */
  getMasters() {
    let url = "masters?sort_by=master_name&sort_asc=true";
    this.api.getEmsData(url).subscribe({
      next: (res: any) => {
        if (res && res.length) {
          this.all_masters = res;
          res.map(result => {
            result['filterParm'] = result.master_name + ' ' + result.master_label['1']
          })
        }
      },
      error: () => {
        this.all_masters = [];
      },
    });
  }

  /**
   * Getting file url for download
   */
  downloadMasterRelation() {
    let master_value = this.downloadMasterGroup.value.masterValue;
    let property_type_id = this.downloadMasterGroup.value.entity_type_id; // property types
    let entity = this.downloadMasterGroup.value.entityType; // entity type
    let url = `master-relation-download?entity_id=${entity}`;
    let master_value_name = {'master_name':master_value}
    if(property_type_id !== null || property_type_id !== '') url += `&property_type_id=${property_type_id}`;
    if(master_value !== null || master_value !== '') url += '&filter='+ JSON.stringify(master_value_name)
    this.api.getEmsData(url).subscribe({
      next: (res: any) => {
        if (res.status == 200) {
          this.dialogRef.close(true);
          let exportFileUrl = `${environment.emsUrl}download-master-file?file_url=${res.data}&city_id=${this.api.city_id}`
          window.open(exportFileUrl, "_blank")
          this.notify.notify("File downloaded successfully", "success");
        }else{
          this.notify.notify(res.message, "warn");
        }
      },
      error: () => {},
    });
  }

  closeDial(e): void {
    this.dialogRef.close();
  }

}
