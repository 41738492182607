import { Injectable } from "@angular/core";
import { Actions, createEffect } from "@ngrx/effects"; //npm i -S @ngrx/effects
import { Action, Store } from "@ngrx/store";
import { map, switchMap, timer } from "rxjs";
import * as ApplicationActions from "./actions";

@Injectable()
export class ApplicationEffects {
//   APPLICATION_TIMEOUT_TIME = 1000 * 60 * 5; // 5 min
    // APPLICATION_TIMEOUT_TIME = 1000 * 60 * 30; // auto logout in 30 min (1800000 miliseconds)


  // extendApplicationTimeout$ = createEffect(() =>
  //   this.actions$
  //     .pipe(switchMap((action: Action) => timer(this.APPLICATION_TIMEOUT_TIME)))
  //     .pipe(map(() => new ApplicationActions.LogOut()))
  // );

  constructor(private actions$: Actions) {}
}
