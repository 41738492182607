import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "field-master-contextmenu",
  templateUrl: "../../subelements/master-menu.component.html",
  styleUrls: ["./field-master-contextmenu.component.scss"],
})
export class FieldMasterContextMenu {
  @Input("item") item;
  @Input("attributegroups_access") groups_access;
  @Output()
  editclick: EventEmitter<{}> = new EventEmitter<{}>();

  attributegroups_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };

  constructor() {}

  editme(item) {
    //1st emit from Context Menu
    this.editclick.emit({ item: item, form: "createattribute" });
  }

  ngOnInit() {
    if (this.groups_access) {
      this.attributegroups_access = this.groups_access;
    }
  }
}
