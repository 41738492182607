<div class="main_card">
  <div class="columns_2">
    <button (click)="close()" class="coseDialog">
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x"
        viewBox="0 0 16 16">
        <path
          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
      </svg>
    </button>


    <!-- delete confirm -->
    <div class="modal" *ngIf="showConfirm">
      <div class="addCent">
        <div class="description_div">
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
          xmlns:svgjs="http://svgjs.com/svgjs" width="50" height="50" x="0" y="0" viewBox="0 0 24 24" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><g fill="#000"><circle cx="12" cy="17" r="1" transform="rotate(180 12 17)" fill="#f89230" data-original="#000000" class=""></circle><path d="M11.25 14a.75.75 0 0 0 1.5 0zm1.5-7a.75.75 0 0 0-1.5 0zm8.5 5A9.25 9.25 0 0 1 12 21.25v1.5c5.937 0 10.75-4.813 10.75-10.75zM12 21.25A9.25 9.25 0 0 1 2.75 12h-1.5c0 5.937 4.813 10.75 10.75 10.75zM2.75 12A9.25 9.25 0 0 1 12 2.75v-1.5C6.063 1.25 1.25 6.063 1.25 12zM12 2.75A9.25 9.25 0 0 1 21.25 12h1.5c0-5.937-4.813-10.75-10.75-10.75zM12.75 14V7h-1.5v7z" fill="#f89230" data-original="#000000" class=""></path></g></g></svg>
         <h3 class="m-0">Confirm</h3>
          <p class="p-3">Do you want to delete </p>
          <div class="savebtn">
            <button mat-raised-button class="can btn0 mx-2" *ngIf="mode == 'images'" (click)="confirm('image')">Confirm</button>
            <button mat-raised-button class="can btn0 mx-2" *ngIf="mode == 'videos'" (click)="confirm('video')">Confirm</button>
            <button mat-raised-button class="orangebutton btn0 mx-2" (click)="cancel()">Cancel</button>
          </div>
        </div>
      </div>
    </div>


    <!-- Empty Image and video -->

      <div class="empty-section" *ngIf="imageGallery.length == 0 && videoGallery.length == 0">
        <div class="">
          <p *ngIf="imageGallery.length == 0 && mode == 'images'">No Images found</p>

          <button *ngIf="cellData.row.status != '5' && imageGallery.length == 0 && mode == 'images'" (click)="showModal('addImage')" mat-mini-fab class="add-btn mat-elevation-z6">
            <i class="material-icons">add</i>
          </button>
           <!-- <button *ngIf="cellData.row.status != '5' && imageGallery.length == 0 && mode == 'images'" (click)="showModal('addImage')" mat-raised-button
            color="primary" class="mat-elevation-z6">
            <i class="material-icons">add</i> Add Images
          </button> -->
          <p *ngIf="videoGallery.length == 0 && mode == 'videos'">No Videos found</p>
          <button *ngIf="cellData.row.status != '5' && videoGallery.length == 0 && mode == 'videos'" (click)="showModal('addVideo')" mat-mini-fab class="add-btn-v mat-elevation-z6">
            <i class="material-icons">add</i>
          </button>
          <!-- <button *ngIf="cellData.row.status != '5' && videoGallery.length == 0 && mode == 'videos'" (click)="showModal('addVideo')" mat-raised-button
            color="primary" class="mat-elevation-z6">
            <i class="material-icons">add</i>  Add Video
          </button> -->
        </div>
      </div>

    <form [formGroup]="imageForm">
      <!-- Image Upload -->
      <ng-container *ngIf="mode == 'images'">
        <button *ngIf="imageGallery.length > 0 && cellData.row.status != '5'" (click)="showModal('addImage', null)" mat-mini-fab class="add-btn mat-elevation-z6">
          <i class="material-icons">add</i>
        </button>

        <!-- Update description Box-->
        <div class="update-descrip" *ngIf="openDescriptionBox">
          <div style=" display: flex; align-items: center; justify-content: center; height: 100%;">
            <div style="padding: 7px; background: #d4d4d4; border-radius: 3px; position: relative; max-height: 400px;">
              <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}">
              </ngx-loading>
              <label for="">Update description </label>
              <textarea [(ngModel)]="description" [ngModelOptions]="{standalone: true}"
                placeholder="Type description" style="min-height: 140px; max-height: 180px;"></textarea>
              <div class="newDesc text-center">
                <button (click)="uploadDescription(true)" [disabled]="loader">
                  <mat-icon class="icn-fnt-normal blue">done</mat-icon>
                </button>
                <button (click)="uploadDescription(false)">
                  <mat-icon class="icn-fnt-normal danger">close</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Add image Box -->
        <div class="modal" *ngIf="addImageDiv">
          <div class="addCent mat-elevation-z6">
            <div class="description_div">
              <img src="../../../../assets/icon/Cross.svg" width="18" (click)="cancel()" alt="" class="cancel">
              <div class="p-3">
                <mat-chip-option *ngFor="let name of photoList" class="pChip animated fadeIn delay-2s" [selectable]="selectable"
                [removable]="removable" (removed)="remove(null,name)">
                <div>
                  <ngx-loading [show]="name.loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}">
                  </ngx-loading>
                  <img id="i-def" src="{{name.imageURL}}" alt="Image">
                </div>
              </mat-chip-option>
              </div>

              <div *ngIf="adddescrip" class="add-descrip">
                <div style="padding: 7px; background: #d4d4d4; border-radius: 3px; max-height: 400px;">
                  <label for="">Add description </label>
                  <textarea [(ngModel)]="description" [ngModelOptions]="{standalone: true}"
                  placeholder="Type description" style="min-height: 140px; max-height: 180px;"></textarea>
                  <div class="newDesc text-right">
                    <button (click)="uploadImagewithdescription(true)" ><mat-icon class="icn-fnt-normal blue">done</mat-icon></button>
                    <button (click)="uploadImagewithdescription(false)" ><mat-icon class="icn-fnt-normal danger">close</mat-icon></button>
                  </div>
                </div>
              </div>

              <div class="savebtn">
                <input id="image_input" #imageInput (change)="uploadExplorImage($event)" class="" accept="image/*"
                  type="file" formControlName="image" />
                <button [disabled]="uploading" class="upload-drag mx-1"
                  (click)="openExplorer('image')">Drag File <br> Or <br> Upload</button>
                <!--   <mat-checkbox id="default" formControlName="isDefault" [checked]="isDefault"></mat-checkbox>
                      <span>Set this image as default</span> -->
              </div>
              <!-- <div class="savebtn">
                    <button (click)="updateImage()" mat-raised-button>Save</button>
                    </div> -->
            </div>
          </div>
        </div>
        <div class="slider" *ngIf="imageGallery.length > 0">
          <!--Carousel Wrapper-->
          <div id="carousel-thumb" class="carousel slide carousel-thumbnails" data-ride="carousel">
            <!--Slides-->
            <div class="carousel-inner" role="listbox">
              <ng-container *ngFor="let slide of imageGallery; let i = index">
                <div class="carousel-item" [class.active]="i==0">
                  <img class="d-block w-100" [src]="slide.url" [alt]="" />
                  <div class="description">
                    <div fxLayout="row" fxLayoutAlign="start start">
                      <button *ngIf="cellData.row.status != '5'" matTooltip="Edit" (click)="add_description(slide)">
                        <mat-icon style="font-size: 17px;">edit</mat-icon>
                      </button>
                      <p>{{slide.caption}}</p>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <!--Controls-->
            <a class="carousel-control-prev hide" href="#carousel-thumb" role="button" data-slide="prev">
              <span class="fa fa-angle-left scroll_active" aria-hidden="true" id="scroll_active">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="20" height="20" x="0" y="0" viewBox="0 0 128 128" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path xmlns="http://www.w3.org/2000/svg" id="Left_Arrow_4_" d="m84 108c-1.023 0-2.047-.391-2.828-1.172l-40-40c-1.563-1.563-1.563-4.094 0-5.656l40-40c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656l-37.172 37.172 37.172 37.172c1.563 1.563 1.563 4.094 0 5.656-.781.781-1.805 1.172-2.828 1.172z" fill="#777777" data-original="#000000" class=""></path></g></svg>
              </span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carousel-thumb" role="button" data-slide="next">
              <span class="fa fa-angle-right scroll_active" aria-hidden="true" id="scroll_active">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="20" height="20" x="0" y="0" viewBox="0 0 128 128" style="enable-background:new 0 0 512 512" xml:space="preserve" class="hovered-paths"><g transform="matrix(-1,1.2246467991473532e-16,-1.2246467991473532e-16,-1,127.9999923706055,127.99976348876952)"><path xmlns="http://www.w3.org/2000/svg" id="Left_Arrow_4_" d="m84 108c-1.023 0-2.047-.391-2.828-1.172l-40-40c-1.563-1.563-1.563-4.094 0-5.656l40-40c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656l-37.172 37.172 37.172 37.172c1.563 1.563 1.563 4.094 0 5.656-.781.781-1.805 1.172-2.828 1.172z" fill="#777777" data-original="#000000" class="hovered-path"></path></g></svg>
              </span>
              <span class="sr-only">Next</span>
            </a>
            <!--/.Controls-->
            <ol class="carousel-indicators scroll_active" id="scroll_active"
              [class.thmbnailScroll]="imageGallery.length > 10">
              <ng-container *ngFor="let slide of imageGallery; let i = index">
                <li class="small-item" data-target="#carousel-thumb" [attr.data-slide-to]="i" [class.active]="i==0">
                  <div class="overlay_bg"></div>
                  <img class="d-block" [src]="slide.url" [alt]="" />
                  <button *ngIf="cellData.row.status != '5'" (click)="showModal('delele', slide.url);" class="del-btn">
                    <mat-icon svgIcon="delete-icon"></mat-icon>
                  </button>
                </li>
              </ng-container>
            </ol>
          </div>
          <!--/.Carousel Wrapper-->
        </div>
      </ng-container>
      <!-- image upload end-->

      <!-- video upload -->
      <ng-container *ngIf="mode == 'videos'">
        <button *ngIf="cellData.row.status != '5' && videoGallery.length > 0" (click)="showModal('addVideo')" mat-mini-fab class="add-btn-v mat-elevation-z6">
          <i class="material-icons">add</i>
        </button>
        <div class="modal" *ngIf="addVideoDiv">
          <div class="addCent mat-elevation-z6">
            <div class="description_div">
              <div class="upload-video">
                <div class="videoChips">
                  <mat-chip-option *ngFor="let data of videoList" class="pChip animated fadeIn delay-2s"
                    [selectable]="selectable" [removable]="removable" (removed)="remove('vid', data)">
                    <div id="selection">

                      <ngx-loading [show]="data.loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}">
                      </ngx-loading>
                      <img id="i-def" src="{{data.videoThumbURL}}" alt="Image">
                    </div>
                  </mat-chip-option>
                </div>
                <div class="input_custom">
                  <label for="">Upload video file </label>
                  <span>
                    <input id="video_input" (change)="uploadVideos($event)" accept="video/*" type="file" />
                    <button [disabled]="uploading" class="upload-drag" (click)="openExplorer('video')">Drag File <br> Or <br> Upload</button>
                  </span>

                </div>
                <div class="input_custom">
                  <label for="">Video URL </label>
                  <div><input id="video_url" class="url-input" type="text" name="url" placeholder="video url"> </div>
                  <button class="upload-video-btn" (click)="uploadVideoByUrl()" id="resize">
                    <mat-icon class="icon-upload">publish</mat-icon>
                  </button>
                </div>
              </div>

              <div class="savebtn">
                <button mat-raised-button class="can btn0 ml-1" (click)="cancel()">Close</button>
              </div>

            </div>
          </div>
        </div>

        <div class="slider" *ngIf="videoGallery.length > 0" style="padding-top: 12px;">
          <!--Carousel Wrapper-->
          <div id="carousel-thumb" class="carousel slide carousel-thumbnails" data-ride="carousel">
            <!--Slides-->
            <div class="carousel-inner" role="listbox">
              <ng-container *ngFor="let slide of videoGallery; let i = index">
                <div class="carousel-item" [class.active]="i==0">
                  <video #video controls class="player d-block mat-elevation-z6" controlsList="nodownload">
                    <source src="{{slide.url}}" type="video/mp4">
                    Your browser does not support HTML5 video.
                  </video>
                </div>
              </ng-container>
            </div>
            <!--Controls-->
            <a class="carousel-control-prev hide" href="#carousel-thumb" role="button" data-slide="prev">
              <span class="fa fa-angle-left scroll_active" aria-hidden="true" id="scroll_active">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="20" height="20" x="0" y="0" viewBox="0 0 128 128" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path xmlns="http://www.w3.org/2000/svg" id="Left_Arrow_4_" d="m84 108c-1.023 0-2.047-.391-2.828-1.172l-40-40c-1.563-1.563-1.563-4.094 0-5.656l40-40c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656l-37.172 37.172 37.172 37.172c1.563 1.563 1.563 4.094 0 5.656-.781.781-1.805 1.172-2.828 1.172z" fill="#777777" data-original="#000000" class=""></path></g></svg>
              </span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carousel-thumb" role="button" data-slide="next">
              <span class="fa fa-angle-right scroll_active" aria-hidden="true" id="scroll_active">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="20" height="20" x="0" y="0" viewBox="0 0 128 128" style="enable-background:new 0 0 512 512" xml:space="preserve" class="hovered-paths"><g transform="matrix(-1,1.2246467991473532e-16,-1.2246467991473532e-16,-1,127.9999923706055,127.99976348876952)"><path xmlns="http://www.w3.org/2000/svg" id="Left_Arrow_4_" d="m84 108c-1.023 0-2.047-.391-2.828-1.172l-40-40c-1.563-1.563-1.563-4.094 0-5.656l40-40c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656l-37.172 37.172 37.172 37.172c1.563 1.563 1.563 4.094 0 5.656-.781.781-1.805 1.172-2.828 1.172z" fill="#777777" data-original="#000000" class="hovered-path"></path></g></svg>
              </span>
              <span class="sr-only">Next</span>
            </a>
            <!--/.Controls-->
            <ol class="carousel-indicators scroll_active" id="scroll_active"
              [class.thmbnailScroll]="videoGallery.length > 10">
              <ng-container *ngFor="let slide of videoGallery; let i = index">
                <li class="small-item" data-target="#carousel-thumb" [attr.data-slide-to]="i" [class.active]="i==0">
                  <div class="overlay_bg"></div>
                  <img class="d-block" [src]="slide.url" [alt]="" />
                  <button  *ngIf="cellData.row.status != '5'" (click)="showModal('delele', slide.url);" class="del-btn">
                    <mat-icon svgIcon="delete-icon"></mat-icon>
                  </button>
                </li>
              </ng-container>
            </ol>
          </div>
          <!--/.Carousel Wrapper-->
        </div>
      </ng-container>
      <!-- video upload end -->
    </form>
  </div>
</div>
