<div class="row" style="margin: 0;">
    <div class="col-md-6" style=" border-right: 2px solid #ddd;">
        <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
        <div class="heading">
            <img (click)="close()" title="Back" class="close-icon" src="../../../../assets/icon/back-arrow.svg" alt="">
        <h2>New Properties ({{formatData(conflict_list.length)}})</h2>
        </div>
        <div class="dividers">            
            <div class="property-card" *ngFor="let prop of conflict_list" (click)="selectNewtProp(prop)" 
            [class.active]="select_property_uid == prop.legacy_property_uid">
                <span class="map-icon" title="Show on map" (click)="selectNewtProp(prop, true); $event.stopPropagation();">
                    <mat-icon svgIcon="pin"></mat-icon>
                </span>
                <h3>{{prop?.legacy_property_uid}}</h3>
                <div class="property-inner">
                    <div>
                        <span>Floor - {{prop?.floor_count || 0}} </span>
                        <span> Unit - {{prop?.unit_count || 0}}</span>
                    </div>
                    <div>
                        <button class="dl-btn reject" (click)="createNewProperty(prop,3, $event)">Remove</button>
                        <button class="dl-btn accept" (click)="createNewProperty(prop,1, $event)">Create New</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <ngx-loading [show]="loader2" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
        <div class="heading">
            <h2>Existing Properties ({{formatData(existing_list.length)}})</h2>
        </div>
        <div class="dividers">           
            <div class="property-card exists" *ngFor="let exist of existing_list">
                <h3>{{exist?.property_uid}}</h3>
                <div class="property-inner">
                    <div>
                        <span>Floor - {{exist?.floor_count || 0}} </span>
                        <span> Unit - {{exist?.unit_count || 0}}</span>
                    </div>
                    <button class="dl-btn accept" (click)="mergeProperty(exist, 2)">Merge</button>
                </div>
            </div>
        </div>
    </div>
</div>