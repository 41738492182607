<div style="position: relative;">

    <div *ngIf="newConflict" class="w-100 new-csv-header">
        <div class="d-flex align-items-center"><mat-icon (click)="resolveNewConfilct()" matTooltip="Back">arrow_back_ios</mat-icon>
          <div class="mat-h2 title">Conflict Resolution</div></div>
        <mat-icon *ngIf="conflictdata && conflictdata.status == '-3'" (click)="downloadConflict()" matTooltip="Download Conflict Data">cloud_download</mat-icon>
    </div>
    <div *ngIf="!newConflict" class="w-100 new-csv-header">
        <!-- <h1 mat-dialog-title>Legacy data</h1> -->
        <div class="mat-h2 title">Legacy data</div>
        <div fxLayout="row">
            <button class="refresh" [disabled]="isDisabled()" id="manual_validate" (click)="manualValidate()">  Manual Validate </button>
            <button matTooltip="Refresh" class="refresh" id="refresh_csv" (click)="refreshList()">
                <svg xmlns="http://www.w3.org/2000/svg"
                version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
                xmlns:svgjs="http://svgjs.com/svgjs" width="18px" height="18px" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g transform="matrix(1,-2.4492935982947064e-16,2.4492935982947064e-16,1,-1.1368683772161603e-13,1.4210854715202004e-13)"><path xmlns="http://www.w3.org/2000/svg" d="m504.554 233.704-76.447 91.467c-6.329 7.572-15.417 11.479-24.571 11.479-7.238 0-14.516-2.443-20.504-7.447l-91.467-76.447c-13.561-11.334-15.366-31.515-4.032-45.075s31.515-15.366 45.075-4.032l37.506 31.347c-10.274-74.891-74.668-132.774-152.337-132.774-84.793.001-153.777 68.985-153.777 153.778s68.984 153.777 153.777 153.777c17.673 0 32 14.327 32 32s-14.327 32-32 32c-58.17 0-112.859-22.653-153.991-63.785-41.133-41.133-63.786-95.822-63.786-153.992s22.653-112.859 63.786-153.992c41.132-41.132 95.821-63.785 153.991-63.785s112.859 22.653 153.992 63.785c32.517 32.516 53.471 73.508 60.829 117.991l22.849-27.339c11.334-13.56 31.515-15.364 45.075-4.032 13.56 11.335 15.365 31.516 4.032 45.076z" fill="#ffffff" data-original="#000000" class=""></path></g></svg> </button>
            <button (click)="closepopup()" class="closePopup" matTooltip="Close">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
                 xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs"
                 width="14px" height="14px" x="0" y="0" viewBox="0 0 311 311.07733" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><path xmlns="http://www.w3.org/2000/svg" d="m16.035156 311.078125c-4.097656 0-8.195312-1.558594-11.308594-4.695313-6.25-6.25-6.25-16.382812 0-22.632812l279.0625-279.0625c6.25-6.25 16.382813-6.25 22.632813 0s6.25 16.382812 0 22.636719l-279.058594 279.058593c-3.136719 3.117188-7.234375 4.695313-11.328125 4.695313zm0 0" fill="#ffffff" data-original="#000000" class=""></path><path xmlns="http://www.w3.org/2000/svg" d="m295.117188 311.078125c-4.097657 0-8.191407-1.558594-11.308594-4.695313l-279.082032-279.058593c-6.25-6.253907-6.25-16.386719 0-22.636719s16.382813-6.25 22.636719 0l279.058594 279.0625c6.25 6.25 6.25 16.382812 0 22.632812-3.136719 3.117188-7.230469 4.695313-11.304687 4.695313zm0 0" fill="#ffffff" data-original="#000000" class=""></path></g></svg>
            </button>
        </div>
    </div>
    <div class="bbar w-100 btm">
        <mat-progress-bar *ngIf="loader" mode="indeterminate" color="accent" class="btm"></mat-progress-bar>
    </div>
    <div class="example-container" *ngIf="!newConflict">
        <table mat-table [dataSource]="dataSource">
            <ng-container *ngFor="let columnName of displayedColumns">
                <ng-container matColumnDef="{{columnName}}">
                    <th class="cp view-theader" mat-header-cell *matHeaderCellDef>
                        <span class="cellspan text-center"><b>{{getLabel(columnName)}}</b></span>
                    </th>
                    <!-- <ng-container *ngIf="columnName == 'added_date'">
                        <td width="12%" mat-cell *matCellDef="let element">
                            <span class="cellspan text-center">{{getDate(element[columnName])}}</span>
                        </td>
                    </ng-container> -->
                    <ng-container *ngIf="columnName == 'status'">
                        <td mat-cell *matCellDef="let element">
                            <span class="cellspan text-center" [class.text-danger]="element.status < 0">  {{getuploadstatus(element[columnName])}}</span>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="columnName != 'status' && columnName != 'action'">
                        <td mat-cell *matCellDef="let element">
                            <span class="cellspan text-center" [class.w150]="columnName == 'remark'" title="{{element[columnName]}}">{{element[columnName]}}</span>
                        </td>
                    </ng-container>
                    <ng-container *ngIf="columnName == 'action'">
                        <td mat-cell *matCellDef="let element" class="d-flex">
                            <button class="dl-btn download" [disabled]="loader && selectedUploadId == element.id" (click)="getLogFile($event,element)">Download log </button>

                            <button class="dl-btn {{getBgColor(element.process_status)}}" [disabled]="acceptButton(element.status, element.conflict_status)" (click)="accept($event,element)">
                                {{getStatusLabel(element.process_status)}}
                            </button>
                            <button class="dl-btn resolve" [disabled]="resolveButton(element)" (click)="resolveNewConfilct(element)">
                                Resolve
                            </button>
                        </td>
                    </ng-container>
                </ng-container>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <div *ngIf="newConflict">
        <app-newcsv-legacy-component [conflictData]="conflictdata"></app-newcsv-legacy-component>
    </div>
</div>
