import { Component, EventEmitter, Input, Output } from "@angular/core";
import { PageEvent } from "@angular/material/paginator";
import { DatePipe } from "@angular/common";
import { CommonfunctionService } from "../../../../../../src/app/services/commonfunction.service";

@Component({
  selector: "app-admin-data-table",
  templateUrl: "./admin-data-table.component.html",
  styleUrls: ["./admin-data-table.component.scss"],
  providers: [DatePipe], // Add DatePipe as a provider
})
export class AdminDataTableComponent {
  @Input() data: any[] = [];
  @Input() columns: string[] = [];
  @Input() buttons: any = {}; // Input for buttons configuration
  @Input() totalCount: number;
  @Input() statusMap: { [key: string]: { label: string; color: string } };
  @Input() isNotShowing: boolean;
  @Output() pageChangeAction = new EventEmitter<any>();

  pageSizeOptions: number[] = [10, 25, 50, 100];
  defaultPageSize: number = 10;
  pageIndex = 0;
  limit: number = 10;
  offset: number = 0;

  constructor(private datePipe: DatePipe, private fnc: CommonfunctionService) {}

  ngOnInit(): void {}

  // Function to handle disabled state
  // isButtonDisabled(button: any): boolean {
  //   // Your logic to determine if the button should be disabled
  //   return false; // Replace with your condition
  // }

  /**
   * Checks if a value is in a valid date format using regex.
   */
  isDate(value: any): boolean {
    // Regex to match date formats like "YYYY-MM-DD HH:MM:SS" or similar
    const dateRegex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}(\.\d+)?$/;
    return typeof value === "string" && dateRegex.test(value);
  }

  /**
   * Formats the value for display.
   * If the value is a date, it formats it to 'd MMM yyyy HH:mm:ss'.
   * Limits to 25 characters and adds ellipsis if needed.
   * Replaces null, undefined, or empty values with a dash.
   */
  formatDisplayValue(value: any): string {
    if (value === null || value === undefined || value === "") {
      return "--";
    }
    // Check if the value is in the specific format 'YYYY-MM-DDTHH:mm:ss.ssssss'
    if (this.isSpecificDateFormat(value)) {
      return this.fnc.formatDateUTC(value, true)
    }
    return value.toString().length > 20
      ? value.toString().slice(0, 20) + "..."
      : value.toString();
  }
  // Function to check if the value matches the specific format 'YYYY-MM-DDTHH:mm:ss.ssssss'
  isSpecificDateFormat(value: string): boolean {
    const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}$/;
    return regex.test(value);
  }
  /**
   * Formats the value for the tooltip.
   * Replaces null, undefined, or empty values with a dash.
   */
  formatValue(value: any): string {
    return value === null || value === undefined || value === ""
      ? "--"
      : value.toString();
  }

  /**
   * Checks if a tooltip is needed based on the length of the value.
   */
  isTooltipNeeded(value: any): boolean {
    return value && value.toString().length > 25;
  }

  pageAction(e: PageEvent) {
    this.pageIndex = e.pageIndex;
    if (this.limit !== e.pageSize) {
      this.limit = e.pageSize;
      this.offset = 0;
    } else {
      this.offset = this.pageIndex * e.pageSize;
    }
    const pagedetails = {
      limit: this.limit,
      offset: this.offset,
    };
    this.pageChangeAction.emit(pagedetails);
  }
  isButtonDisabled(element: any, button: any) {
    // // Example condition based on element status and button label
    // if (button.label === 'Accept') {
    //   return element.status !== '1';  // Disable Accept if status is not 'Valid'
    // } else if (button.label === 'Reject') {
    //   return element.status !== '1';  // Disable Reject if status is not 'Valid'
    // }
    // // Add other conditions as needed
    // return false;  // Default: button is enabled
  }
}
