import { Injectable } from '@angular/core';
import { ApiserviceService } from '../apiservice.service';

@Injectable()
export class CommonfunctionService {
  constructor(private api: ApiserviceService) {
    //this.checkResourceAccess('attributegroups','GET',false);

  }

  // return index of oblect from object array, return number
  getArrayindex(key: any, val: any, arrayObj: any): any {
    let res = null;
    let flag = true;
    if (arrayObj) {
      arrayObj.forEach((obj, k) => {
        if (obj[key] == val && flag) {
          res = k;
          flag = false;
        }
      });
    }
    return res;
  }
  // move array element by changing it's index
  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  };
  // extract value by key from object array, return object
  getArrayValue(key: any, val: any, arrayObj: any): any {
    let res = {};
    let flag = true;
    arrayObj.forEach(obj => {
      if (obj[key] == val && flag) {
        res = obj;
        flag = false;
      }
    });
    return res;
  }

  removeDuplicateKey(arr: Array<any>) { //get unique array
    let uniqueArray = [];
    arr.forEach(function (element) {
      if (uniqueArray.indexOf(element) === -1) {
        uniqueArray.push(element);
      }
    });
    return uniqueArray;
  }
  // remove duplicate object key - val
  uniqueObject(key: any, arr: Array<Object>) { //get unique array
    let _this = this;
    let uniqueArray = [];
    arr.forEach(function (element: Object) {
      if (_this.getArrayindex(key, element[key], uniqueArray) == null) {
        uniqueArray.push(element);
      }
    });
    return uniqueArray;
  }

  getArrayValueNull(key: any, val: any, arrayObj: any): any {
    let res = null;
    let flag = true;
    if (arrayObj) {
      arrayObj.forEach(obj => {
        if (obj[key] == val && flag) {
          res = obj;
          flag = false;
        }
      });
    }
    return res;
  }

  // get array of child element from parent
  getChildArray(key: any, val: any, arrayObj: any): any {
    let res: any = [];
    let i = 0;
    arrayObj.forEach(obj => {
      if (obj[key] == val) {
        res[i] = obj;
        i++;
      }
    });
    return res;
  }

  // remove particular value from array of object
  getDeletedArray(key: any, val: any, arrayObj: any): any {
    if (Array.isArray(val)) { // accepting both array and single value
      val = val;
    } else {
      val = [val];
    }
    let res: any = [];
    let i = 0;
    if (arrayObj) {
      arrayObj.forEach(obj => {
        if (val.indexOf(obj[key]) == -1) {
          res[i] = obj;
          i++;
        }
      });
    }
    return res;
  }

  jsonLength(obj) {
    return Object.keys(obj).length;
  }
  //accepting only date Object
  formatDate(value) {
    value = new Date(value);
    return value.getFullYear() + "-" + (value.getMonth() + 1).toString().padStart(2, 0) + "-" + value.getDate().toString().padStart(2, 0);
  }

  getAddressText(address_obj: any) {
    let address = '';
    if (address_obj['neigh_name']) address += '' + address_obj.neigh_name + ", ";
    if (address_obj['block_name']) address += 'Block ' + address_obj.block_name + ", ";
    if (address_obj['street_name']) address += '' + address_obj.street_name + ", ";
    if (address_obj['lane_name']) address += '' + address_obj.lane_name + ", ";
    if (address_obj['parcel_no']) address += 'Parcel ' + address_obj.parcel_no + ", ";
    if (address_obj['house_no']) address += 'House No ' + address_obj.house_no + ", ";
    if (address_obj['building_name']) address += ' ' + address_obj.building_name;
    if (address) {
      return address;
    } else {
      return 'Invalid address';
    }
  }

  // copy json object without reference
  deepCopyJson(obj) {
    let copy = obj, k;

    if (obj && typeof obj === 'object') {
      copy = Object.prototype.toString.call(obj) === '[object Array]' ? [] : {};
      for (k in obj) {
        //copy[k] = this.deepCopyJson(obj[k]);
        copy[k] = obj[k];
      }
    }

    return copy;
  }

  getErrorMessage(err) {
    let msg = err.error;
    return msg.message;
  }


  checkResourceAccess(name, flag = true) {
    let resource = {
      GET: false,
      PATCH: false,
      POST: false,
      DELETE: false
    }
    if (flag) {
      //this.api.getAllowResources(); //call the api again
    }
    let resources = this.api.allowResources;
    if(resources && resources.length){
      resources.forEach(element => {
        if (element.resource_name == name) {
          if (element.methods.indexOf('GET') != -1) {
            resource.GET = true;
          }
          if (element.methods.indexOf('POST') != -1) {
            resource.POST = true;
          }
          if (element.methods.indexOf('PATCH') != -1) {
            resource.PATCH = true;
          }
          if (element.methods.indexOf('DELETE') != -1) {
            resource.DELETE = true;
          }
        }
      });
    }

    return resource;
  }



  assignmentSortOrder(data) {
    var newdata = [];
    let label = '';
    data.forEach(element => {
      if (element.assignment_label[this.api.language] == null) {
        label = element.assignment_name
      } else {
        label = element.assignment_label[this.api.language];
      }
      element.name = label;
      newdata.push(element);
    });
    newdata.sort(this.GetSortOrder("name"));
    return newdata;
  }

  //Comparer Function
  GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop].toLowerCase() > b[prop].toLowerCase()) {
        return 1;
      } else if (a[prop].toLowerCase() < b[prop].toLowerCase()) {
        return -1;
      }
      return 0;
    }
  }

  // get UTC date
  formatDateUTC(dt: any, getTime: boolean = false) {
    if(dt){
      dt = new Date(dt);
      let value;
      value = new Date(dt + ' UTC');
      if (getTime) {
        return  value.toLocaleDateString('en-GB', {day: 'numeric',month: 'short',year: 'numeric'}) + " " + value.getHours().toString().padStart(2, 0) + ":"
          + value.getMinutes().toString().padStart(2, 0) + ":" + value.getSeconds().toString().padStart(2, 0);
      } else {
        return (
          value.toLocaleDateString('en-GB', {day: 'numeric',month: 'short',year: 'numeric'})
        );
      }
    }
    return '';
  }


  downloadFile(filename, csvData) {
    let blob = new Blob([csvData], {
      type: 'text/csv;charset=utf-8,%EF%BB%BF',
    });
    let dwldLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf('Safari') != -1 &&
      navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '_' + +new Date() + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

// CONVER TO percent
  percentageConvertor(total:any, current:any): number{
    let val:number=0;
    if(total && current){
      let t = Number(total);
      let c = Number(current);
      val = Number(((c * 100)/t).toFixed(0));
      return val;
    }
    return val;
  }

  // get diffrence between start date and end date
  getDateDifference(startDate, endDate){
    let val='';
    if(startDate && endDate){
      const firstDate = new Date(this.formatDateUTC(startDate, true))
      const lastDate = new Date(this.formatDateUTC(endDate, true))
      const milliseconds = Math.floor((lastDate.getTime() - firstDate.getTime()));
      const seconds = Math.floor((milliseconds / 1000) % 60);
      const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
      const hours = Math.floor((milliseconds / 1000 / 60 / 60) % 24);
      val = this.str_pad_left(hours, '0', 2) + ':'+ this.str_pad_left(minutes, '0', 2) + ':' + this.str_pad_left(seconds, '0', 2);
      return val;
    }
    return val;
  }
  // date format in HH:MM:SS
  str_pad_left(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }

  isNumber(val){
    if(Number(val)){
      return true;
    }
    return false;
  }
  
  modifyInt(options:any) {
    const { value, roundUp, convertToLocaleString, floatingPoint } = options;
    let initialValue = value;
    
      if(this.isNumber(value)){
        if (value === 0) initialValue = 0;
        if (roundUp === true) initialValue = Math.ceil(+initialValue);
        if (roundUp === false) initialValue = Math.round(+initialValue);
        if (floatingPoint > 0) initialValue = initialValue.toFixed(floatingPoint);
        if (convertToLocaleString === true || convertToLocaleString === undefined) initialValue = Number(initialValue).toLocaleString('en-us');
      }
     
    return initialValue;
  }
}
