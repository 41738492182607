import { Component, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NotificationService } from "../../../../admin/services/notification.service";
import { ApiserviceService } from "../../../../apiservice.service";
import { CommonfunctionService } from "../../../../services/commonfunction.service";

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_report_post</li>
 * </ol>
 *
 */

//create dialog component
@Component({
  selector: "addreport-dialog",
  templateUrl: "./addreport.component.html",
  styleUrls: ["../../admin-configuration.component.css"],
})
export class AddReportDialog {
  reportform: FormGroup;
  languages: Array<any>;
  description_language: Array<{}> = [];
  language_valueset: Array<{}> = [];
  selected_group: any;
  constructor(
    public dialogRef: MatDialogRef<AddReportDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder,
    private api: ApiserviceService,
    private fnc: CommonfunctionService,
    private notify: NotificationService
  ) {
    this.selected_group = data.incomingData.selectedGroup;
    this.languages = this.api.language_list;
    const reg = "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?";
    this.reportform = this._fb.group({
      report_name: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9_]*$"),
        ]),
      ],
      language: this._fb.array([]),
      description: this._fb.array([]),
      url: ["", [Validators.required]],
    });
    this.addRow();
    this.addDescriptionRow();
    this.reportform.patchValue({
      language: [{ language: this.languages[0].language_id, value: "" }],
    });
    this.reportform.patchValue({
      description: [{ language: this.languages[0].language_id, value: "" }],
    });
  }

  initLanguageFields() {
    return this._fb.group({
      language: [this.languages[0].language_id, Validators.required],
      value: ["", Validators.required],
    });
  }

  initDescriptionLanguageFields() {
    return this._fb.group({
      language: [this.languages[0].language_id],
      value: [],
    });
  }
  addRow() {
    if (this.language_valueset?.length == this.languages?.length) {
      this.notify.notify("No more Languages Available to Add", "warn", 4000);
      return;
    }
    this.language_valueset.push({ language: "", value: "" });
    /* #Form */
    let dd = <FormArray>this.reportform.controls.language;
    dd.push(this.initLanguageFields());
    /* End - #Form */
  }

  removeRow(key) {
    if (this.language_valueset.length > 1) {
      this.language_valueset.splice(key, 1);
      let dd = <FormArray>this.reportform.controls.language;
      dd.removeAt(key);
    }
  }

  addDescriptionRow() {
    if (this.description_language?.length == this.languages?.length) {
      this.notify.notify("No more Languages Available to Add", "warn", 4000);
      return;
    }
    this.description_language.push({ language: "", value: "" });
    /* #Form */
    let dd = <FormArray>this.reportform.controls.description;
    dd.push(this.initDescriptionLanguageFields());
    /* End - #Form */
  }

  removeDescriptionRow(key) {
    if (this.description_language.length > 1) {
      this.description_language.splice(key, 1);
      let dd = <FormArray>this.reportform.controls.language;
      dd.removeAt(key);
    }
  }

  saveReport(reportform) {
    let value = reportform.value;
    let url,
      status = 1;
    let re = /\ /gi;
    let name = value.report_name.replace(re, "_");
    url = value.url;
    let report_body = {
      added_by: this.api.user_id,
      description: {},
      group_id: this.selected_group.id,
      label: {},
      name: name,
      status: status,
      type_id: this.data.parent.selectedTypeId,
      url: url,
    };

    value.language.forEach((v, k) => {
      report_body.label[v.language] = v.value;
    });

    value.description.forEach((v, k) => {
      report_body.description[v.language] = v.value;
    });
    this.api.postData("report", report_body).subscribe(
      (data) => {
        this.notify.notify("Report is added", "success");
        this.data.parent.getReportList(this.selected_group);
        this.dialogRef.close();
      },
      (err) => {
        this.notify.notify(this.fnc.getErrorMessage(err), "error");
      }
    );
  }

  closeDialog() {
    this.dialogRef.close();
  }
  ngOninit() {}
}
