import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: 'address-list-dialog',
  templateUrl: 'address-list-dialog.html',
  styleUrls: ['../../qa-qc.component.scss']
})

export class AddressListDialog {
  available_address_list: any = [];
  alert: boolean = false;
  constructor(public dialogRef: MatDialogRef<AddressListDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.alert = this.data.parent.alert;
    this.available_address_list = this.data.parent.available_address_list;
  }

  ngOninit() { }
  select(val) {
    this.data.parent.selected_addr = val;
    this.data.parent.addressForm.patchValue({
      'house_no': val.house_no,
      'parcel_no': val.parcel_no,
      //'building_name': val.building_name,
      'lane_name': val.lane_name,
      'street_name': val.street_name,
      // 'city_name': val.city_name,
      'block_name': val.block_name,
      'neigh_name': val.neigh_name
    })
    this.dialogRef.close();
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
