/**
 * Component for managing the deletion of attributes within the admin interface.
 * Provides functionality to filter and view attributes, submit deletion requests,
 * and manage pagination for attribute deletion records.
 */
import { Component, ViewChild, ViewEncapsulation } from "@angular/core";
import { AdminMenusService } from "../../../../../../src/app/admin/admin-sidebar/admin-menus.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ApiserviceService } from "../../../../../../src/app/apiservice.service";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from "@angular/cdk/collections";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { NotificationService } from "../../../../../../src/app/admin/services/notification.service";
import { AttributeDeletionConfirmationComponent } from "../attribute-deletion-confirmation/attribute-deletion-confirmation.component";
import { MatDialog } from "@angular/material/dialog";
import { noWhitespaceAllowedValidator } from "../../validations/white-space";
import { CommonfunctionService } from "../../../../../../src/app/services/commonfunction.service";
import { Router } from "@angular/router";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

// Define the PeriodicElement interface for the data table
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: "app-admin-attribute-deletion",
  templateUrl: "./admin-attribute-deletion.component.html",
  styleUrls: ["./admin-attribute-deletion.component.scss"],
})
export class AdminAttributeDeletionComponent {
  // ViewChild for paginator
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // Displayed columns for the data table
  displayedColumns: string[] = [
    "select",
    "Attribute_name",
    "Value",
    "Log_ID",
    "Assignment_name",
    "Submit_date",
    "Property_UID",
  ];

  // Variables for managing data and pagination
  ELEMENT_DATA: PeriodicElement[] = [];
  total: number;
  defaultPageSize: number = 10;
  pageSizeOptions: number[];
  sizeOptions: string = "10, 15, 25, 50, 100";
  pageEvent: PageEvent;
  pageIndex: number = 0;
  limit: number = 10;
  offset: number = 0;
  startYear: number;
  currentYear: number;
  readonly campaignTwo: FormGroup;
  selected_entity_type: string = "property";
  attributeList: any;
  attrbute_deletion_group: FormGroup;
  isLoading: boolean = false;
  searchAttribute: string = "";
  placeHolder: string = "Type Name here to search...";
  entityTypeList: any[] = [
    { entityLabel: "property", entityType: "property" },
    { entityLabel: "property_floor", entityType: "property_floor" },
    { entityLabel: "property_unit", entityType: "property_unit" },
  ];
  property_type_list: any;
  selected_date: any[] = [];
  deletionList: any;
  deleteLogMesg:string = `Clicking this button will delete
  only the attribute log details for the items selected within the
   specified date range. No actual data or attributes
  will be deleted, only the log entries associated with your selection`;
  deleteAllMesg:string = `Clicking this button will delete both the logs
  and the actual data for approved properties within the selected date range.
   This action will permanently remove all related information, including logs and
   data, for the approved items, so please proceed with caution`;
  dataSource: MatTableDataSource<PeriodicElement>;
  selection: SelectionModel<PeriodicElement>;
  isRequestPage: boolean = false;
  title: string = "Attribute Data Deletion";
  seleAttributeId: any;
  attribute_deletion_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  };

  constructor(
    private menus: AdminMenusService,
    private api: ApiserviceService,
    private fb: FormBuilder,
    private notify: NotificationService,
    public dialog: MatDialog,
    private fnc: CommonfunctionService,
    private route: Router,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
  ) {
    iconRegistry.addSvgIcon(
      "info-icon",
      sanitizer.bypassSecurityTrustResourceUrl("assets/icon/info.svg")
    );
    // Initialize page size options and set active menu
    this.pageSizeOptions = this.sizeOptions.split(",").map((str) => +str);
    this.menus.setActive("attribute_deletion");

    // Initialize data table
    this.dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
    this.selection = new SelectionModel<PeriodicElement>(true, []);
  }

  ngOnInit() {
    // Load allowed resources and set up form
    this.getAllowResourcesApi();
    this.startYear = 2009;
    this.currentYear = new Date().getFullYear();
    this.attrbute_deletion_group = this.fb.group({
      entityTypeControl: ["", Validators.required],
      attributeControl: ["", Validators.required],
      propertyTypeControl: [],
      entityUidsControl: ['', [noWhitespaceAllowedValidator()]],
      start: new FormControl("", Validators.required),
      end: new FormControl("", Validators.required),
    });
  }

  /**
   * Fetches allowed resources from the API and sets access rights.
   */
  getAllowResourcesApi() {
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`;
    this.api.getUmsData(body).subscribe({
      next: (res: any) => {
        this.api.allowResources = res.data;
        this.getAllowResource();
      },
      error: (err) => { }
    });
  }

  /**
   * Sets access rights for attribute deletion and navigates if access is denied.
   */
  getAllowResource() {
    this.attribute_deletion_access = this.fnc.checkResourceAccess('attribute_deletion', false);
    if (!this.attribute_deletion_access.GET) {
      this.route.navigateByUrl('');
      return;
    }
  }

  /**
   * Fetches the list of attributes based on the selected entity type.
   * @param type Optional parameter to filter by property unit type.
   */
  getAttributeList(type = null) {
    let url = `${this.attrbute_deletion_group.value.entityTypeControl}/attributes?sort_by=sort_order&limit=10000&offset=0`;
    if (type == 'property_unit') {
      url += `&property_type_ids=${this.attrbute_deletion_group.value.propertyTypeControl}`;
    }
    this.api.getEmsData(url).subscribe({
      next: (result) => {
        this.attributeList = result;
      },
      error: (err) => { }
    });
  }

  /**
   * Handles changes in the entity type and fetches relevant attributes.
   * @param type The type of entity to fetch attributes for.
   */
  getChangeEntity(type) {
    if (type == "property_unit") {
      this.getPropertyType();
      this.getAttributeList(type);
    } else {
      this.getAttributeList();
      this.attrbute_deletion_group.patchValue({
        attributeControl: '',
        propertyTypeControl: '',
      });
      this.ELEMENT_DATA = [];
      this.tableInit();
      this.total = 0;
      this.offset = 0;
      this.resetPaginator();
    }
  }

  /**
   * Fetches the list of property types.
   */
  getPropertyType() {
    let url = "propertytypes?sort_by=property_type_name&limit=500&offset=0&status=1";
    this.isLoading = true;
    this.api.getEmsData(url).subscribe({
      next: (data: any) => {
        this.isLoading = false;
        this.property_type_list = data;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  /**
   * Triggers change entity process for 'property_unit'.
   */
  getTypeChange() {
    this.getChangeEntity('property_unit');
  }

  /**
   * Fetches the list of attribute deletions based on filters.
   */
  getDeletionList() {
    if (!this.attribute_deletion_access.POST) {
      this.notify.notify('Access Denied: You are not authorized to view the log details', 'warn');
      return;
    }
    let getPropertyTypeName = null;
    let property_type_id = this.attrbute_deletion_group.value.propertyTypeControl;
    if(property_type_id){
      getPropertyTypeName = this.property_type_list.find(type => type.property_type_id == property_type_id);
    }
    let startDate = this.formatDateString(this.attrbute_deletion_group.value.start);
    let endDate = this.formatDateString(this.attrbute_deletion_group.value.end);
    this.selected_date.push(startDate, endDate);
    const selectedEntityType = this.attrbute_deletion_group.value.entityTypeControl;
    const selectedAttr = this.attrbute_deletion_group.value.attributeControl;
    let enteredUIDs = this.attrbute_deletion_group.value.entityUidsControl;
    let url = `${selectedEntityType}/attributes-for-deletion?attribute_name=${selectedAttr}&submitted_date=${this.selected_date}&limit=${this.limit}&offset=${this.offset}&is_count=true`;
    if (enteredUIDs != null) {
      url += `&uids=${enteredUIDs}`;
    }
    if(getPropertyTypeName != null){
      url += `&property_type_name=${getPropertyTypeName.property_type_name}`
    }else{
       url += `&property_type_name=null`
    }
    this.api.getEpsData(url).subscribe({
      next: (response) => {
        this.isLoading = false;
        this.selected_date = [];
        this.deletionList = response;
        if (!this.deletionList.data || (this.deletionList.data == "" && this.deletionList.status == 200)) {
          this.notify.notify("No data found", "warn");
          this.ELEMENT_DATA = this.deletionList.data;
          this.total = this.deletionList.count;
          this.tableInit();
          return;
        } else {
          this.ELEMENT_DATA = this.deletionList.data;
          this.total = this.deletionList.count;
          this.tableInit();
        }
      },
      error: (err) => {
        this.isLoading = false;
      }
    });
  }

  /**
   * Initializes the data table.
   */
  tableInit() {
    this.dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
    this.selection = new SelectionModel<PeriodicElement>(true, []);
  }

  /**
   * Checks if all rows are selected.
   * @returns True if all rows are selected, false otherwise.
   */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /**
   * Toggles the selection of all rows.
   */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /**
   * Returns the label for the checkbox on the passed row.
   * @param row Optional parameter for the row data.
   * @returns The label for the checkbox.
   */
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? "deselect" : "select"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.position + 1}`;
  }

  /**
   * Handles pagination actions.
   * @param $event The pagination event.
   */
  pageAction($event) {
    this.pageIndex = $event.pageIndex;
    if (this.limit != $event.pageSize) {
      this.limit = $event.pageSize;
      this.offset = 0;
      this.paginator.pageIndex = 0;
    } else {
      this.offset = this.pageIndex * $event.pageSize;
    }
    if (this.total > 0) {
      this.getDeletionList();
    }
  }

  /**
   * Formats a date string to YYYY-MM-DD format.
   * @param dateString The date string to format.
   * @returns The formatted date string.
   */
  formatDateString(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  /**
   * Switches between request and history views.
   */
  switchRequestOrHistoty() {
    if (this.isRequestPage) {
      this.isRequestPage = false;
      this.title = "Attribute Data Deletion";
    } else {
      this.isRequestPage = true;
      this.title = "Manage Deletion Requests";
    }
  }

  /**
   * Sends a deletion request and opens a confirmation dialog.
   * @param isAllDeletionRequest type indicating if the request is for all deletions.
   */
  deleteRequest(isAllDeletionRequest) {
    let startDate = this.formatDateString(this.attrbute_deletion_group.value.start);
    let endDate = this.formatDateString(this.attrbute_deletion_group.value.end);
    this.selected_date.push(startDate, endDate);
    const selectedAttribute = this.attributeList.find(attribute => attribute.attribute_id == this.seleAttributeId);
    let dialogRef = this.dialog.open(AttributeDeletionConfirmationComponent, {
      width: "420px",
      panelClass: "create-master-panel",
      disableClose: true,
      data: {
        selectedRows: this.selection.selected,
        attribute_id: this.seleAttributeId,
        deletion_type: isAllDeletionRequest,
        date_range: `${startDate},${endDate}`,
        selectedEntity: this.attrbute_deletion_group.value.entityTypeControl,
        attribute_name: selectedAttribute
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.notify.notify("Your attribute deletion request has been sent and is awaiting approval.", "success", 5000);
        this.formReset();
        this.ELEMENT_DATA = [];
        this.total = 0;
        this.offset = 0;
        this.tableInit();
        this.resetPaginator();

      }
    });
  }

  /**
   * Resets the form to its default state.
   */
  formReset() {
    this.attrbute_deletion_group.reset({
      entityTypeControl: "",
      attributeControl: "",
      propertyTypeControl: "",
      start: null,
      end: null
    });
  }

  /**
   * Sets the selected attribute ID based on the form value.
   */
  selectedAttributeId() {
    let selectedAttribute = this.attributeList.filter((x) =>
      x.attribute_name.includes(this.attrbute_deletion_group.value.attributeControl)
    );
    this.seleAttributeId = selectedAttribute[0]?.attribute_id;
  }

  /**
   * Resets the paginator to the first page.
   */
  resetPaginator() {
    this.paginator.pageIndex = 1;
    // this.paginator._changePageSize(this.paginator.pageSize);
  }

  /**
   * Clears the selected date range in the form.
   */
  clearSelectedDate() {
    this.attrbute_deletion_group.patchValue({
      start: null,
      end: null
    });
  }
}
