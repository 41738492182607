<mat-card appearance="outlined" class="example-card">
  <div class="title w-100" fxLayout="row" fxLayoutAlign="space-between center">
    <div>Sync</div>
  </div>
  <mat-card-content>
    <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
    <mat-tab-group class="tab-group">
      <mat-tab label="Property Attributes Values">
        <button mat-raised-button color="primary" class="btn_ex" matTooltip="Sync Property Log"
          (click)="sync('validate',0)">Execute</button>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
</mat-card>
<mat-card appearance="outlined" class="example-card">
  <div class="title w-100" fxLayout="row" fxLayoutAlign="space-between center">
    <div>Reports</div>
  </div>
  <mat-card-content class="tabs">
    <ngx-loading [show]="loader1" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
    <div class="bottom-border">
      <button class="margr5" mat-button (click)="getReports()">
        Property Attribute Values</button>
    </div>
    <ng-container>
      <table mat-table [dataSource]="dataSource" matSort class="language-table w-100">
        <ng-container *ngFor="let cols of displayedColumns">
          <ng-container matColumnDef="{{ cols }}">
            <th mat-header-cell *matHeaderCellDef>
              <span *ngIf="cols == 'sno'">S.No</span>
              <span *ngIf="cols!='sno'" class="header action cp">{{ cols }}</span>
            </th>
            <ng-container *ngIf=" dataSource.length>0">
              <td mat-cell *matCellDef="let element; let i=index;">
                <span *ngIf="cols == 'sno'"> {{offset+i+1}}.</span>
                <button class="view" *ngIf="cols == 'Parameter'" (click)="openView('parameter',element)" mat-button
                  [disabled]="element.parameter==null">View</button>
                <button class="view" *ngIf="cols == 'Process Log'" (click)="openView('process',element)" mat-button
                  [disabled]="element.process_log==null">View</button>

                <div *ngIf="cols=='Status'"> {{getStatus(element.status)}}</div>
                <p matTooltip={{returnVal(element,cols)}}
                  *ngIf="cols != 'sno' && cols != 'Parameter' && cols != 'Process Log' && cols!='Actions' && cols!='Status'"
                  class="more">
                  {{returnVal(element, cols)}}</p>
              </td>
            </ng-container>
          </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>
      </table>
      <mat-paginator [length]="totalCount" [pageSize]="limit" [pageSizeOptions]="pageSizeOptions"
        (page)="pageEvent = $event; handlePagination($event)">
      </mat-paginator>
    </ng-container>
  </mat-card-content>
</mat-card>