<div class="confirmation-wrapper">
  <mat-toolbar class="matpopup-header">
    <div>Add reason to delete the attribute</div>
    <button type="button" class="close-btn" (click)="close()">
      <img src="../../../../../assets/icon/Cross.svg" alt="">
    </button>
  </mat-toolbar>
  <p class="mesg-text">
    Please provide reason to delete the attribute.
  </p>
  <form [formGroup]="deleteRequestGroup">
    <mat-form-field class="example-full-width"  appearance="outline" floatLabel="always">
      <mat-label>Leave a comment</mat-label>
      <textarea matInput #commentMessage placeholder="Write comment..." formControlName="comment" maxlength="180"></textarea>
      <mat-error  *ngIf="comment.errors?.minWords">
        <!-- <div *ngIf="comment.invalid && (comment.dirty || comment.touched)">
          <div> -->
            You need to enter at least 10 words.
          <!-- </div>
        </div> -->
      </mat-error>
      <mat-hint> {{commentMessage.value.length}}/180</mat-hint>
    </mat-form-field>
  </form>
  <div class="center">
    <button class="cnfrm-btn" (click)="deleteAttributeRequest()" [disabled]="deleteRequestGroup.invalid">
        Yes
    </button>
    <button  class="cancel" (click)="close()">
        Cancel
    </button>
  </div>

</div>
