import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ApiserviceService } from '../../../apiservice.service';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs';
import { environment } from '../../../../../src/environments/environment';
import { CommonfunctionService } from '../../../../../src/app/services/commonfunction.service';
import { NotificationService } from '../../services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { EditMasterValueComponent } from './edit-master-value/edit-master-value.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';

@Component({
  selector: "app-masters-list-view",
  templateUrl: "./masters-list-view.component.html",
  styleUrls: ["./masters-list-view.component.scss"],
})
export class MastersListViewComponent {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  total: number;
  defaultPageSize: number = 10;
  pageSizeOptions;
  sizeOptions: string = "10, 15, 25, 50, 100";
  pageEvent: PageEvent;
  pageIndex = 0;
  limit: number = 10;
  offset: number = 0;

  @Output() changeUI = new EventEmitter();
  master_list = [];
  selected_master: any;
  search_master = '';
  sub_master_list = [];
  all_masters: any;
  displayedColumns = ['sno', 'master_value_name', 'master_value_label', 'image_url', 'status','action'];
  isChecked:boolean = false;
  selected_entity_type = "property";
  master_value_list: any = [];
  selected_property_type_id = null;
  sub_master = null;
  status_array = {
    '1': "Active",
    '0': "Inactive"
  }
  property_type_list: any = [];
  property_type_id: number;
  searchField: FormControl;
  selected_master_id: number;
  status = '0,1';
  status_list=[{ key: '0,1', val : "All"}, { key: '1', val : "Active"},
  {key : '0', val : "Inactive"}];
  constructor(public api: ApiserviceService, private fnc: CommonfunctionService, private notify: NotificationService,
    private dialog: MatDialog) {
      this.pageSizeOptions = this.sizeOptions.split(",").map((str) => +str);
    }

  ngOnInit(): void {
    this.getMasters();
    this.getPropertyType();
    this.searchField = new FormControl();
    this.searchField.valueChanges
      .pipe(debounceTime(500))
      .subscribe(term => {
        if(this.sub_master){
          this.getValueData(this.sub_master)
        }else{
          this.getValueData(this.selected_master_id)
        }
      })
  }
  resetpagination() {
    this.defaultPageSize = 10;
    if (this.paginator) this.paginator.firstPage();
  }
  pageAction($event) {
    this.pageIndex = $event.pageIndex;
    if (this.limit != $event.pageSize) {
      this.limit = $event.pageSize;
    } else {
      this.offset = this.pageIndex * $event.pageSize;
    }
    if(this.sub_master){
      this.getValueData(this.sub_master)
    }else{
      this.getValueData(this.selected_master_id)
    }
  }
  /**
   * get master list
   */
  getMasters() {
    let url = "masters?sort_by=master_name&sort_asc=true";
    this.api.getEmsData(url).subscribe({
      next: (res: any) => {
        if (res && res.length) {
          this.all_masters = res;
          res.map(result => {
            result['filterParm'] = result.master_name + ' ' + result.master_label['1']
          })
          this.master_list = res.filter(e => e.parent_master_id == null);
          this.selected_master_id = this.master_list[0].master_id;
          this.selected_master = this.master_list[0];
          this.getValueData(this.selected_master_id);
        }
      },
      error: () => {
        this.master_list = [];
        this.all_masters = [];
      },
    });
  }

  /**
   *
   */
  downloadMasterData() {
    let url = "master-download";
    if(!this.isChecked){
      url = `master-download?entity=${this.selected_entity_type}`;
      if(this.sub_master) {
        let submaster = this.sub_master_list.find(e=>e.master_id == this.sub_master);
        if(submaster) url += `&name=${submaster.master_name}`;
      }else{
        if(this.selected_master.master_name) url += `&name=${this.selected_master.master_name}`;
      }

      if (this.selected_property_type_id && this.selected_entity_type == 'property_unit') url += "&property_type_ids=" + this.selected_property_type_id;
    }

    this.api.getEmsData(url).subscribe(
      {
        next: (res: any) => {
          if (res?.data) {
            let exportFileUrl = `${environment.emsUrl}download-master-file?file_url=${res.data}&city_id=${this.api.city_id}`
            window.open(exportFileUrl, "_blank")
          } else {
            this.notify.notify(res.message, 'warn');
          }
        },
        error: () => {
          console.log('error');
        }
      }
    );
  }

  changeView(){
    this.changeUI.emit();
  }
  getStatus(val) {
    return this.status_array[val];
  }
  changeMasters() {
    this.sub_master = null;
    this.sub_master_list = this.all_masters.filter(e => e.parent_master_id == this.selected_master.master_id);
    this.selected_master_id = this.selected_master.master_id;
    this.getValueData(this.selected_master.master_id);
    this.resetpagination()
  }

  getChangeEntity(){
     this.sub_master = null;
     if (this.selected_entity_type == 'property_unit') {
      this.selected_property_type_id = this.property_type_list[0].property_type_id;
    } else {
      this.selected_property_type_id = null;
    }
    this.getValueData(this.selected_master_id)
}
  getTypeChange() {
    if(this.sub_master){
      this.getValueData(this.sub_master)
    }else{
      this.getValueData(this.selected_master_id)
    }
  }

  getValueData(master_id) {
    if(!this.selected_entity_type) return
    let url = this.selected_entity_type + "/masters/" + master_id + "/values?sort_by=master_value_name&sort_asc=true"+"&limit="+this.limit+"&offset="+this.offset

    if (this.selected_property_type_id) url += "&property_type_ids=" + this.selected_property_type_id;
    if (this.searchField.value) url += "&master_value_name=" + this.searchField.value;
    if(this.status) url +=`&status=${this.status}`;
    if(this.offset == 0) url +=  "&is_count=true";
    if(this.offset != 0) url += "&is_count=false";
    this.api.getEmsData(url).subscribe({
      next: (res: any) => {
        if (this.offset == 0) {
          this.total = res?.count ? res.count : 0;
        }

        if (res?.data?.length > 0) {
          this.master_value_list = res.data;
        }else {
          this.master_value_list = [];
        }
      }, error: () => {
        this.master_value_list = [];
      }
    })

  }
  // getting list of property type
  getPropertyType() {
    let url = "propertytypes?sort_by=property_type_name&limit=500&offset=0&status=1";
    this.api.getEmsData(url)
      .subscribe(
        {
          next: (data: any) => {
            this.property_type_list = data;
          },
          error: () => {
            console.log('error');
          }
        }
      );
  }

  editMasterValue(element){
    let submaster;
    if(this.sub_master){
      submaster = this.sub_master_list.find(e => e.master_id == this.sub_master)
    }
    let info = {
      selected_master: submaster ? submaster : this.selected_master,
      value : element,
      entity: this.selected_entity_type,
      property_type_id : this.selected_entity_type == 'property_unit' ? this.selected_property_type_id: null
    }
    let def = this.dialog.open( EditMasterValueComponent, {
        width: '450px',
        panelClass: 'create-master-panel',
        data: info
      })

      def.afterClosed().subscribe(res=>{
        if(res == true){
          let master_id = submaster ? submaster.master_id : this.selected_master.master_id
          this.getValueData(master_id);
        }
      })
  }
}
