<div class="dialog">
    <div><mat-toolbar class="tool"><span>Confirmation</span></mat-toolbar></div>
    <div class="message">
        <span *ngIf="no_of_property">Please unallocate all the properties assign to {{user.username}}. </span>
        <span *ngIf="!no_of_property">Do you want to remove {{user.username}} from assignment - {{assignment.label}} ?</span>
    </div>
    <div class="b-middle">
        <button [disabled]="no_of_property != 0" (click)="remove()" class="deletebutton" mat-button>Remove</button>
        <button (click)="closeDialog()" class="fix" mat-button>Cancel</button>
    </div>   
</div>