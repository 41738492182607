import { Component, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NotificationService } from "../../../../admin/services/notification.service";
import { ApiserviceService } from "../../../../apiservice.service";
import { CommonfunctionService } from "../../../../services/commonfunction.service";

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_reportGroup_patch</li>
 * </ol>
 *
 */

//edit Group component
@Component({
  selector: "editgroup-dialog",
  templateUrl: "./editgroup.component.html",
  styleUrls: ["../../admin-configuration.component.css"],
})
export class EditGroupDialog {
  editGroupform: FormGroup;
  languages: Array<any>;
  language_valueset: Array<{}> = [];
  selected_group: any;
  group_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };
  constructor(
    public dialogRef: MatDialogRef<EditGroupDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder,
    private api: ApiserviceService,
    private notify: NotificationService,
    private fnc: CommonfunctionService
  ) {
    this.selected_group = this.data.incomingData.selectedGroup;
    this.languages = this.api.language_list;
    this.group_access = this.fnc.checkResourceAccess("report_groups", false);
    /* #Form - Intial Form Definition */
    this.editGroupform = this._fb.group({
      language: this._fb.array([]),
      status: [this.selected_group.status ? true : false],
    });

    /* End #Form - Intial Form Definition */
    if (this.selected_group) {
      let labels: any = [];
      let index = 0;
      for (let prop in this.selected_group.label) {
        labels[index] = {
          language: +prop,
          value: this.selected_group.label[prop],
        }; // +prop to convert into number
        this.addRow();
        index++;
      }
      this.editGroupform.patchValue({ language: labels });
    } else {
      this.addRow();
      this.editGroupform.patchValue({
        language: [{ language: this.languages[0].language_id, value: "" }],
      });
    }
  }

  conditionalValidation(field) {
    if (this.data.parent.is_property_dependent) {
      return Validators.required;
    } else {
      return Validators.nullValidator;
    }
  }

  showLabel(item) {
    let label =
      item.label && item.label[this.api.language]
        ? item.label[this.api.language]
        : item.name;
    return label;
  }

  /* #Form - Language multiple field init */

  updateGroupform(editGroupform) {
    if (!this.group_access.PATCH) {
      this.notify.notify("You are not authorized to update group", "warn");
      return;
    }
    let value = editGroupform.value;
    let status = 0;
    if (value.status) status = 1;

    let group_id = this.selected_group.id;
    let edit_group = [
      {
        updated_by: this.api.user_id,
        label: {},
        status: status,
        id: group_id,
      },
    ];
    value.language.forEach((v, k) => {
      edit_group[0].label[v.language] = v.value;
    });

    this.api.patchData("reportGroup", edit_group).subscribe(
      (data: any) => {
        this.notify.notify("Group Updated", "success");
        this.data.parent.getGroups(this.data.parent.selectedTypeId);
        this.closeDialog();
      },
      (err) => {
        this.notify.notify(this.fnc.getErrorMessage(err), "error");
      }
    );
  }

  initLanguageFields() {
    return this._fb.group({
      language: [this.languages[0].language_id, Validators.required],
      value: ["", Validators.required],
    });
  }

  initDescriptionLanguageFields() {
    return this._fb.group({
      language: [this.languages[0].language_id],
      value: [],
    });
  }
  /* End #Form - Language multiple field init */

  closeDialog(): void {
    this.dialogRef.close();
  }

  addRow() {
    if (this.language_valueset?.length == this.languages?.length) {
      this.notify.notify("No more Languages Available to Add", "warn", 4000);
      return;
    }
    this.language_valueset.push({ language: "", value: "" });
    /* #Form */
    let dd = <FormArray>this.editGroupform.controls.language;
    dd.push(this.initLanguageFields());
    /* End - #Form */
  }

  removeRow(key) {
    if (this.language_valueset.length > 1) {
      this.language_valueset.splice(key, 1);
      let dd = <FormArray>this.editGroupform.controls.language;
      dd.removeAt(key);
    }
  }
}
