
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'searchFilter'
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], field: string, value: string, list:any[]): any[] {
        if (!items) return [];
        if (!value) return items;
        if(list) items = list;
        value = value.toString();
        return items.filter(item => item[field].toLowerCase().includes(value.toLowerCase()));
    }
}
