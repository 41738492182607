import { Component, OnInit } from "@angular/core";

@Component({
  selector : 'action-buttons',
  templateUrl : './admin-actions.component.html',
  styleUrls: ['../../admin-breadcrumb.component.css']
})
export class AdminActionbuttonsComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }
}
