import { Component, OnInit, HostListener, Input } from "@angular/core";
import { AdminMenusService } from "../admin-sidebar/admin-menus.service";
import { AdminBreadcrumbService } from "../admin-breadcrumb/services/admin-breadcrumb.service";
import { MatDialog } from "@angular/material/dialog";
import { MatIconRegistry } from "@angular/material/icon";
import { ApiserviceService } from "../../apiservice.service";
import { CommonfunctionService } from "../../services/commonfunction.service";
import { NotificationService } from "../services/notification.service";
import { DndDropEvent } from "ngx-drag-drop";
import { DomSanitizer } from "@angular/platform-browser";
import { Options } from "sortablejs";
import * as $ from "jquery";
import { Router } from "@angular/router";
import { AddGroupDialog } from "./components/add-group/add-group.component";
import { AddReportDialog } from "./components/add-report/add-report.component";
import { DeleteComponent } from "./components/delete/delete.component";
import { DeleteReportComponent } from "./components/delete-report/delete-report.component";
import { EditGroupDialog } from "./components/edit-group/edit-group.component";
import { GroupListDialog } from "./components/group-list-dialog/groupListDialog.component";

export interface Groups {
  name: string;
  status: number;
  id: string;
  label: any;
}

/**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_reportGroup_patch</li>
 * <li>estapi_report_patch</li>
 * <li>umsapi_user_resource_post</li>
 * <li>estapi_report_copy_post</li>
 * <li>estapi_reportType_get</li>
 * <li>estapi_reportGroups_get</li>
 * <li>estapi_report_get</li>
 * </ol>
 *
 */

@Component({
  selector: "app-admin-configuration",
  templateUrl: "./admin-configuration.component.html",
  styleUrls: ["./admin-configuration.component.css"],
})
export class AdminConfigurationComponent implements OnInit {
  displayedColumns = [
    "drag",
    "select",
    "sn",
    "name",
    "label",
    "url",
    "description",
    "status",
    "action",
  ];
  dataSource: any = [];
  selectedCellId: any;
  tempCellData: any = {
    name: "",
    label: {},
    url: "",
    description: {},
  };

  dialogFn: Function;
  group_list: any;
  height: number;
  inHeight: number;
  total_groups = 0;
  group_custom_list: any = [];
  loader: boolean = false;
  loaderReport: boolean = false;
  reports: any = [];
  submasterpop: boolean;
  updateLoader: boolean = false;
  selected: any;
  selected_sub: any;
  selected_item: any;
  selected_group: any;
  selected_group_name: any;
  selected_report: any;

  selected_ids: any = [];
  reportType: any = [];

  popwindow: number = -1;
  popsubwindow: number = -1;

  selectedTypeId: any;

  report_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };

  group_access = {
    GET: false,
    POST: false,
    PATCH: false,
    DELETE: false,
  };

  draggable = {
    data: "myDragData",
    effectAllowed: "all",
    disable: false,
    handle: false,
  };
  showListHeader: boolean;
  constructor(
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private menus: AdminMenusService,
    private _crumbs: AdminBreadcrumbService,
    private dialog: MatDialog,
    private api: ApiserviceService,
    private notify: NotificationService,
    private fnc: CommonfunctionService,
    private route: Router
  ) {
    iconRegistry.addSvgIcon(
      "dragx",
      sanitizer.bypassSecurityTrustResourceUrl("assets/icon/drag-v.svg")
    );
    this.menus.setActive("configuration");
    this._crumbs.clear();
    this._crumbs.addcrumb = { title: "Configuration" };
    this._crumbs.mobiletitle = "Configuration";
    this._crumbs.count = 1;
    this.onResize();
    this.dialogFn = this.openDialog.bind(this);
  }
  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.height = window.innerHeight - 220;
    this.inHeight = window.innerHeight - 288;
  }
  // groupoptions: SortablejsOptions = {
  //   draggable: '.dragx',
  //   handle: '.draghandle',
  //   onUpdate: (event: any) => this.sortGroups()
  // };
  reportoptions: Options = {
    draggable: ".dragx1",
    handle: ".draghandle1",
    scroll: true,
    onUpdate: (event: any) => this.sortReports(),
  };

  groupoptions(e) {
    this.sortGroups();
  }

  //sorting groups
  sortGroups() {
    let body: any = [];
    let ln = this.group_custom_list.length;
    this.group_custom_list.forEach((v, k) => {
      body.push({
        updated_by: this.api.user_id,
        sort_order: ln--,
        id: v.id,
      });
    });
    this.api.patchData("reportGroup", body).subscribe(
      (data: any) => {
        // this.notify.notify('Group order updated', 'Dismiss');
      },
      (err) => {
        this.notify.notify(this.fnc.getErrorMessage(err), "error");
      }
    );
  }
  //sorting reports
  sortReports() {
    let body: any = [];
    let ln = this.dataSource.length;
    this.dataSource.forEach((v, k) => {
      body.push({
        updated_by: this.api.user_id,
        sort_order: ln--,
        id: v.id,
      });
    });
    this.api.patchData("report", body).subscribe(
      (data: any) => {
        // this.notify.notify('Report order updated', 'Dismiss');
      },
      (err) => {
        this.notify.notify(this.fnc.getErrorMessage(err), "error");
      }
    );
  }
  //disable selected report
  disableReport(e, item) {
    if (e.checked) {
      let val,
        body = [
          {
            id: item.id,
            status: 1,
            updated_by: this.api.user_id,
          },
        ];
      this.api.patchData("report", body).subscribe(
        (data) => {
          this.notify.notify("Status updated", "success");
        },
        (err) => {
          this.notify.notify(this.fnc.getErrorMessage(err), "error");
        }
      );
    } else {
      let val,
        body = [
          {
            id: item.id,
            status: 0,
            updated_by: this.api.user_id,
          },
        ];
      this.api.patchData("report", body).subscribe(
        (data) => {
          this.notify.notify("Status updated", "success");
        },
        (err) => {
          this.notify.notify(this.fnc.getErrorMessage(err), "error");
        }
      );
    }
  }
  showLabel(item) {
    if (item) {
      let label =
        item.label && item.label[this.api.language]
          ? item.label[this.api.language]
          : item.name;
      return label;
    }
  }
  isChecked(val) {
    if (val) {
      this.selected_ids.forEach((id) => {
        if (val == id) {
          return true;
        } else {
          return false;
        }
      });
    }
  }
  //
  getAllowResourcesApi() {
    this.api.getUserRole();
    let body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`;
    this.api.getUmsData(body).subscribe(
      (res: any) => {
        this.api.allowResources = res.data;
        this.getReportType();
        this.report_access = this.fnc.checkResourceAccess("reports", false);
        this.group_access = this.fnc.checkResourceAccess(
          "report_groups",
          false
        );
      },
      (err) => {
      }
    );
  }
  // getAllowResourcesApi() {
  //   this.api.getUserRole(); // getting role assign to this user
  //   let body = "place_id=" + this.api.city_id;
  //   this.api.postUmsData('user/resource', body)
  //     .subscribe(
  //       (data: any) => {
  //         this.api.allowResources = data;
  //         this.getReportType();
  //         this.report_access = this.fnc.checkResourceAccess('reports', false);
  //         this.group_access = this.fnc.checkResourceAccess('report_groups', false);
  //       },
  //       err => {
  //       }
  //     )
  // }

  // drag drop functions------------------------------------------------------------------------------------------
  dragStart(e: DragEvent, data: any) {
    if (!this.selected_ids.length) this.selected_ids.push(data.id);
  }
  dragCanceled(e: DragEvent, data: any) {
    this.selected_ids = [];
  }
  onDrop(e: DndDropEvent, groupId) {
    if (!this.report_access.PATCH) {
      this.notify.notify("You are not authorized to copy report", "warn");
      return;
    }

    if (this.selected_ids.length) {
      if (groupId == this.selected_group.id) {
        this.notify.notify("Report can not copy same group", "warn");
        return;
      }
      let group = this.fnc.getArrayValue("id", groupId, this.group_custom_list);
      let gid = [];
      gid.push(groupId);
      let body = {
        report_ids: this.selected_ids,
        status: 1,
        target_group_ids: gid,
        updated_by: this.api.user_id,
      };
      this.api.postData("report/copy", body).subscribe(
        (data) => {
          this.notify.notify(
            "report copied in " + group.name + " group",
            "success"
          );
          this.selected_ids = [];
          this.getReportList(this.selected_group);
        },
        (err) => {
          this.selected_ids = [];
          //this.notify.notify('Something is Wrong', 'Dismiss');
          this.notify.notify(this.fnc.getErrorMessage(err), "error");
        }
      );
    } else {
      this.notify.notify("Please select report", "warn");
    }
  }
  // drag drop functions------------------------------------------------------------------------------------------

  getReportType() {
    let limit, offset, asc, status;
    limit = 20;
    offset = 0;
    status = 1;
    asc = true;
    this.api
      .getEpsData(
        "reportType?order_by=sort_order&asc=" +
          asc +
          "&offset=" +
          offset +
          "&status=" +
          status
      )
      .subscribe(
        (data) => {
          this.reportType = data;
          if (this.reportType) {
            let selected = this.reportType[0];
            this.selectedTypeId = selected.id;
            this.getGroups(selected.id);
          }
        },
        (err) => {
          this.notify.notify(this.fnc.getErrorMessage(err), "error");
        }
      );
  }

  getGroups(val) {
    if (!this.group_access.GET) {
      this.notify.notify("You are not authorized to access group", "warn");
      return;
    }
    this.loader = true;
    this.dataSource = [];
    this.api
      .getData("reportGroups?order_by=sort_order&report_type_id=" + val)
      .subscribe(
        (data: any) => {
          let parent = [],
            childrens = [];
          this.loader = false;
          this.group_custom_list = [];
          this.group_list = data;
          this.total_groups = this.group_list.length;
          data.forEach((element) => {
            if (element.parent_id == null || element.parent_id == 0) {
              parent.push(element);
            } else {
              childrens.push(element);
            }
          });
          parent.forEach((p) => {
            let pr_data = {
              added_by: p.added_by,
              added_date: p.added_date,
              id: p.id,
              label: p.label,
              name: p.name,
              parent_id: p.parent_id,
              status: p.status,
              type_id: p.type_id,
              updatedDate: p.updatedDate,
              sort_order: p.sort_order,
              updated_by: p.updated_by,
              childrens: [],
            };
            childrens.forEach((child) => {
              if (p.id == child.parent_id) {
                pr_data.childrens.push(child);
              }
            });
            this.group_custom_list.push(pr_data);
          });
        },
        (err) => {
          this.loader = false;
          this.group_custom_list = [];
          this.notify.notify(this.fnc.getErrorMessage(err), "error");
        }
      );
  }

  getReportList(val, k?) {
    if (!this.report_access.GET) {
      this.notify.notify("You are not authorized to access report", "warn");
      return;
    }
    this.selected_ids = [];
    this.selectedCellId = "";
    this.loaderReport = true;
    this.selected = this.selected === k ? null : k;
    this.selected_item = val;
    this.selected_group = val;
    this.selected_group_name = val.name;
    let limit, offset, status, group_id, url;
    limit = 50;
    offset = 0;
    group_id = val.id;

    url =
      "report?order_by=sort_order&group_id=" +
      group_id +
      "&limit=" +
      limit +
      "&offset=" +
      offset;
    this.api.getData(url).subscribe(
      (data) => {
        this.dataSource = [];
        this.updateLoader = false;
        this.dataSource = data;
        this.loaderReport = false;
      },
      (err) => {
        this.dataSource = [];
        this.loaderReport = false;
        this.updateLoader = false;
        this.notify.notify(this.fnc.getErrorMessage(err), "error");
      }
    );
  }

  showsmallpop(index: number, submaster: boolean) {
    this.popsubwindow = null;
    this.popwindow = index;
    this.submasterpop = submaster;
  }
  mouseLeave(index) {
    this.popsubwindow = null;
    this.submasterpop = true;
  }
  showsmallsubpop(event, classname: string, index: number, position?: boolean) {
    this.popwindow = null;
    this.submasterpop = null;
    this.popsubwindow = index;
    if (position) {
      this.positionsubpopup(event, classname);
    }
  }

  positionsubpopup(event, classname) {
    $("." + classname).css({
      top: event.y - 80,
      left: event.x - $(".mobilesubmastergroups").outerWidth(),
    });
  }

  updateReport(val) {
    if (!this.report_access.PATCH) {
      this.notify.notify("You are not authorized to update report", "warn");
      return;
    }

    if (!this.updateLoader) {
      this.updateLoader = true;
      if (val) {
        let body = [
          {
            id: this.selectedCellId,
            description: {},
            group_id: this.selected_group.id,
            label: {},
            updated_by: this.api.user_id,
            url: val.url,
          },
        ];
        body[0].description[this.api.language] = val.description;
        body[0].label[this.api.language] = val.label;
        this.api.patchData("report", body).subscribe(
          (data) => {
            this.getReportList(this.selected_group);
            this.notify.notify("Report is updated", "success");
            this.cancelEdit(this.selectedCellId);
          },
          (err) => {
            this.updateLoader = false;
            this.notify.notify(this.fnc.getErrorMessage(err), "error");
          }
        );
      } else {
        return;
      }
    }
  }

  changeSelectedIds(e: any, value: any) {
    if (e.checked == true) {
      this.selected_ids.push(value.id);
    } else {
      this.selected_ids.splice(this.selected_ids.indexOf(value.id), 1);
    }
    // console.log(this.selected_ids);
  }

  deteleReport(val) {
    if (!this.report_access.DELETE) {
      this.notify.notify("You are not authorized to delete report", "warn");
      return;
    }
    if (val) {
      let data = val;
      this.selected_report = val;
      let dialogRef = this.dialog.open(DeleteReportComponent, {
        width: "450px",
        panelClass: "create-master-panel",
        data: { parent: this, incomingData: data }, //optional
      });
    }
  }
  updateGroup(val) {
    this.selected_group = val;
    this.openDialog("UPDATE_GROUP");
  }

  getDiscript(item) {
    if (item) {
      let label =
        item.description && item.description[this.api.language]
          ? item.description[this.api.language]
          : item.name;
      return label;
    }
  }
  getLabel(item) {
    if (item) {
      let label =
        item.label && item.label[this.api.language]
          ? item.label[this.api.language]
          : item.name;
      return label;
    }
  }
  //table functions
  cancelEdit(id) {
    if (id == this.selectedCellId) {
      this.selectedCellId = "";
    }
  }
  edit(element) {
    if (!this.report_access.PATCH) {
      this.notify.notify("You are not authorized to update report", "warn");
      return;
    }
    this.selectedCellId = element.id;
    this.tempCellData.name = element.name;
    this.tempCellData.label =
      element.label && element.label[this.api.language]
        ? element.label[this.api.language]
        : element.name;
    this.tempCellData.url = element.url;
    this.tempCellData.description =
      element.description && element.description[this.api.language]
        ? element.description[this.api.language]
        : element.name;
  }

  openDialog(action, data = null) {
    if (this.selectedTypeId == undefined) {
      this.notify.notify("Please select report type", "warn");
      return;
    }
    let actions;
    if (action == "ADD_REPORT") {
      actions = AddReportDialog;
    } else if (action == "ADD_GROUP") {
      actions = AddGroupDialog;
    } else if (action == "DELETE_GROUP") {
      actions = DeleteComponent;
    } else if (action == "UPDATE_GROUP") {
      actions = EditGroupDialog;
    } else if (action == "GROUP_LiST") {
      actions = GroupListDialog;
    }
    let dialogRef = this.dialog.open(actions, {
      width: "450px",
      panelClass: "create-master-panel",
      data: { parent: this, incomingData: data }, //optional
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  isActive(item) {
    return this.selected === item;
  }
  ngOnInit() {
    this.getAllowResourcesApi();
  }
}
