import { Component, OnInit, Inject, Input, ViewChild, SimpleChanges, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonfunctionService } from '../../../services/commonfunction.service';
import { Map } from '../../map_api/map';
import { LatLon } from '../../map_api/latlon';
import { ApiserviceService } from '../../../apiservice.service';
import { ShareService } from '../../services/share.service';
import { NotificationService } from '../../services/notification.service';
import { environment } from '../../../../environments/environment';
import { ConfirmationDialog } from '../confirmation-box/onfirmation-dialog';
export interface FloorList {
  legacy_property_uid: string,
  floor_number: number,
  floor_type: string,
  no_of_unit: number,
  entity_type: string
}
export interface FloorListExisting {
  floor_uid: string,
  floor_id: number,
  floor_number: number,
  floor_type: string,
  no_of_unit: number,
  entity_type: string
}
export interface UnitList {
  unit_area: string,
  unit_number: string,
  legacy_property_uid: string,
  floor_number: number,
  unit_type: string,
  unit_property_type: string,
  entity_type: string
}
export interface UnitListExisting {
  unit_area: string,
  unit_number: string,
  unit_uid: string,
  floor_number: number,
  unit_type: string,
  unit_property_type: string,
  entity_type: string
}
export interface ExistingPropertyList {
  property_uid: string,
  property_id: string,
  property_name: string,
  legacy_property_uid: string,
  lon: number,
  lat: number,
  no_of_unit: number,
  no_of_floor: number,
  entity_type: string
}
export interface LegacyPropertyList {
  legacy_property_uid: string,
  lat: number,
  lon: number,
  property_name: string,
  no_of_unit: number,
  no_of_floor: number,
  floor: Array<FloorList>,
  unit: Array<UnitList>,
  entity_type: string
}

  /**
 *
 * <strong>List of API using</strong>
 * <ol>
 * <li>estapi_upload_type_uploads_status_get</li>
 * <li>estapi_properties_property_id_floors_get</li>
 * <li>estapi_properties_property_id_floors_floor_id_units_get</li>
 * <li>estapi_upload_type_uploads_upload_id_resolution_get</li>
 * </ol>
 *
 */

@Component({
  selector: 'app-legacy-component',
  templateUrl: './legacy-component.component.html',
  styleUrls: ['./legacy-component.component.css']
})

export class LegacyComponentComponent implements OnInit {
  loader_1: boolean = false;
  loader_2: boolean = false;
  entity_type: any;
  entity_type_existing: any;

  legacy_property_list: Array<LegacyPropertyList> = [];
  existing_property_list: Array<ExistingPropertyList> = [];

  legacyDataList: any = [];
  existingDataList: any = [];
  filteredPropertyList: any = [];
  floor_list_existing: any = [];

  selected_property: any;
  selected_floor: FloorList;
  selected_unit: UnitList;

  selected_property_existing: ExistingPropertyList;
  selected_floor_existing: FloorList;
  selected_unit_existing: UnitList;

  selected_assignment:any;

  data: any;
  constructor(private dialog: MatDialog,
    private fnc: CommonfunctionService,
    private zone: NgZone, private share: ShareService,
    private api: ApiserviceService,
    private notify: NotificationService) {
    this.data = this.share.csv_obj;
    this.getLegacyData();
    this.selected_assignment = this.fnc.getArrayValue('assignment_id', this.data.selected_assignment, this.data.assignmentList);

  }

  select(data) {
    if (data) {
      if (data.entity_type == 'property') {
        this.selected_property_existing = null;
        let arr = [];
        if (data) {
          this.selected_property = data;
        }
        if (this.selected_property) {
          this.existing_property_list.forEach((prop) => {
            if (prop.legacy_property_uid == this.selected_property.legacy_property_uid) {
              arr.push(prop);
            }
          })
          this.existingDataList = arr;
          this.filteredPropertyList = arr;
          this.entity_type_existing = null;
          this.selected_floor_existing = null;
          this.selected_unit_existing = null;
        }
      }
      if (data.entity_type == 'property_floor') {
        this.selected_floor = data;
      }
      if (data.entity_type == 'property_unit') {
        this.selected_unit = data;
      }
      if (data.entity_type == 'property_existing') {
        this.selected_property_existing = data;
      }
      if (data.entity_type == 'property_floor_existing') {
        this.selected_floor_existing = data;
      }
      if (data.entity_type == 'property_unit_existing') {
        this.selected_unit_existing = data;
      }
    }
  }

  isSelected(type, data) {
    if (type == 'existing' && data.entity_type == 'property_unit_existing') {
      if (this.selected_unit_existing) {
        if (this.selected_unit_existing.unit_number == data.unit_number) {
          return true;
        } else {
          return false;
        }
      }
    }
    if (type == 'existing' && data.entity_type == 'property_floor_existing') {
      if (this.selected_floor_existing) {
        if (this.selected_floor_existing.floor_number == data.floor_number) {
          return true;
        } else {
          return false;
        }
      }
    }
    if (type == 'legacy' && data.entity_type == 'property_floor') {
      if (this.selected_floor) {
        if (this.selected_floor.floor_number == data.floor_number) {
          return true;
        } else {
          return false;
        }
      }
    }
    if (type == 'legacy' && data.entity_type == 'property_unit') {
      if (this.selected_unit) {
        if (this.selected_unit.unit_number == data.unit_number) {
          return true;
        } else {
          return false;
        }
      }
    }
    if (type == 'legacy' && data.entity_type == 'property') {
      if (this.selected_property) {
        if (data.legacy_property_uid == this.selected_property.legacy_property_uid) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
    if (this.selected_property_existing && data.entity_type == 'property_existing') {
      if (data.property_id == this.selected_property_existing.property_id) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  select_existing(data) {
    if (data) {
      this.selected_property_existing = data.property_uid;
    }
  }

  getProperty(type) {
    if (type == 'legacy') {
      this.legacyDataList = this.legacy_property_list;
      this.selected_floor = null;
      this.selected_unit = null;
      this.entity_type = 'property';
    } else {
      if(this.selected_property){
        this.select(this.selected_property);
      }else{
        this.existingDataList = [];
      }
      this.selected_floor_existing = null;
      this.entity_type_existing = null;
    }

  }

  //Get legacy and existing properties--------------------------------------
  getLegacyData() {
    if (this.data.conflict_id) {
      this.loader_1 = true;
      let url = this.data.selected_File_Type + '/uploads/status/' + this.data.conflict_id;
      this.api.getData(url).subscribe({next: (res:any) => {
        if(!res.conflict_data){
          this.data.showConflict = false;
          this.data.notify.notify('All conflicts resolved', 'success');
          this.data.getUploadStatus();
          return;
        }
        let legacy_arr = res.conflict_data.legacy_properties, temp_arr = [];
        let existing_arr = res.conflict_data.existing_properties, temp_ex_arr = [];
        existing_arr.forEach(ex_prop => {
          let ex_obj: ExistingPropertyList = {
            property_uid: ex_prop.property_uid,
            property_id: ex_prop.property_id,
            property_name: ex_prop.property_name,
            legacy_property_uid: ex_prop.legacy_property_uid,
            lat: ex_prop.lat,
            lon: ex_prop.lon,
            no_of_unit: ex_prop.no_of_unit,
            no_of_floor: ex_prop.no_of_floor,
            entity_type: 'property_existing'
          }
          temp_ex_arr.push(ex_obj);
        });
        this.existing_property_list = temp_ex_arr;
        legacy_arr.forEach(prop => {
          let temp_floor = [], temp_unit = [], floor_count = 0, unit_count = 0;

          prop.unit.forEach(un => {
            unit_count++;
            let un_obj: UnitList = {
              unit_area: un.unit_area,
              unit_number: un.unit_number,
              legacy_property_uid: un.legacy_property_uid,
              floor_number: un.floor_number,
              unit_type: un.unit_type,
              unit_property_type: un.unit_property_type,
              entity_type: 'property_unit'
            }
            temp_unit.push(un_obj);
          });
          prop.floor.forEach(fl => {
            let floor_unit_count = 0;
            floor_count++;
            temp_unit.forEach((u) => {
              if (u.floor_number == fl.floor_number) {
                floor_unit_count++;
              }
            })
            let fl_obj: FloorList = {
              legacy_property_uid: fl.legacy_property_uid,
              floor_number: fl.floor_number,
              floor_type: fl.floor_type,
              no_of_unit: floor_unit_count,
              entity_type: 'property_floor'
            }
            temp_floor.push(fl_obj);
          });

          let obj: LegacyPropertyList = {
            legacy_property_uid: prop.legacy_property_uid,
            lat: prop.lat,
            lon: prop.lon,
            property_name: prop.property_name,
            no_of_unit: unit_count,
            no_of_floor: floor_count,
            floor: temp_floor,
            unit: temp_unit,
            entity_type: 'property'
          }
          temp_arr.push(obj);
        });
        this.legacy_property_list = temp_arr;
        this.legacyDataList = this.legacy_property_list;
        this.loader_1 = false;
      }, error:() => {
        this.loader_1 = false;
      }})
    }
  }

  //Get legacy floors--------------------------------------
  getFloors(type, data?) {
    if (type == 'existing') {
      if (data) {
        this.selected_property_existing = data;
        this.selected_unit_existing = null;
        this.getExistingFloors(data);
      } else {
        this.selected_unit_existing = null;
        this.existingDataList = this.floor_list_existing;
        this.entity_type_existing = 'property_floor_existing'
      }

    } else {
      if (data) this.selected_property = data;
      this.selected_unit = null;
      this.legacyDataList = this.selected_property.floor;
      this.entity_type = 'property_floor';
    }
  }


  //Get legacy and existing units--------------------------------------
  getUnits(data?) {
    if (data.entity_type == 'property_floor_existing') {
      if (data.no_of_unit != 0) {
        this.getExistingUnits(data);
      } else {
        this.notify.notify('No Units found', 'Dismiss');
      }
      return;
    }
    if (data.entity_type == 'property_floor') {
      let arr = [];
      let units = this.selected_property.unit;
      this.selected_floor = data;
      units.forEach(u => {
        if (this.selected_floor.floor_number == u.floor_number) {
          arr.push(u);
        }
      });
      this.legacyDataList = arr;
      this.entity_type = 'property_unit';
      return;
    }
  }

  //Get existing floors-------------------------------
  getExistingFloors(data) {

    let fl_arr = [];
    this.loader_2 = true;
    let url = `properties/${data.property_id}/floors`;
    this.api.getEpsData(url).subscribe({next:(res:any) => {
      this.loader_2 = false;
      res.forEach(floor => {
        let obj: FloorListExisting = {
          floor_uid: floor.floor_uid,
          floor_id: floor.floor_id,
          floor_number: floor.sort_order,
          floor_type: floor.floor_type_name,
          no_of_unit: floor.no_of_units,
          entity_type: 'property_floor_existing'
        }
        fl_arr.push(obj);
      });
      this.floor_list_existing = fl_arr;
      this.existingDataList = this.floor_list_existing;
      this.entity_type_existing = 'property_floor_existing';
    }, error:() => {
      this.loader_2 = false;
      this.existingDataList = [];
    }})
  }

  //Get existing units-------------------------------
  getExistingUnits(data) {
    this.selected_floor_existing = data;
    let un_arr = [];
    this.loader_2 = true;
    let url = `properties/${this.selected_property_existing.property_id}/floors/${data.floor_id}/units?return_measure_units=true`;
    this.api.getEpsData(url).subscribe({next: (res:any) => {
      this.loader_2 = false;
      res.forEach(unit => {
        let obj: UnitListExisting = {
          unit_area: unit.data.unit_area,
          unit_number: unit.data.unit_number,
          unit_uid: unit.unit_uid,
          floor_number: unit.floor_id,
          unit_type: unit.unit_type_name,
          unit_property_type: unit.property_type_name,
          entity_type: 'property_unit_existing'
        }
        un_arr.push(obj);
      });
      this.existingDataList = un_arr;
      this.entity_type_existing = 'property_unit_existing';
      this.loader_2 = false;
    }, error:() => {
      this.loader_2 = false;
    }})
  }

  createProperty(e) {
    let body, url = `${this.data.selected_File_Type}/uploads/${this.data.conflict_id}/resolution`;
    body = {
      "action": 1,
      "legacy_property_uid": e.legacy_property_uid,
      "property_uid": 'null',
      "updated_by": this.api.user_id
    }
    this.api.patchData(url, body).subscribe({next: (res) => {
      this.notify.notify('Property successfully created', 'success');
      this.legacyDataList = this.fnc.getDeletedArray('legacy_property_uid', e.legacy_property_uid, this.legacyDataList);
      this.existingDataList = [];
      this.selected_property = null;
      this.selected_property_existing = null;
      this.entity_type_existing = '';
      this.entity_type = '';
      this.getLegacyData();
    }, error:(err)=> {
      this.notify.notify(err, 'error');
    }})
  }

  removeProperty(e) {
    let body, url = `${this.data.selected_File_Type}/uploads/${this.data.conflict_id}/resolution`;
    body = {
      "action": 3,
      "legacy_property_uid": e.legacy_property_uid,
      "property_uid": 'null',
      "updated_by": this.api.user_id
    }
    this.api.patchData(url, body).subscribe({next:(res) => {
      this.notify.notify('Property successfully removed', 'success');
      this.legacyDataList = this.fnc.getDeletedArray('legacy_property_uid', e.legacy_property_uid, this.legacyDataList);
      this.existingDataList = [];
      this.selected_property_existing = null;
      this.selected_property = null;
      this.entity_type_existing = '';
      this.entity_type = '';
      this.getLegacyData();
    }, error:(err) => {
      this.notify.notify(err, 'error');
    }})
  }

  mergeProperty(e) {
    let body, url = `${this.data.selected_File_Type}/uploads/${this.data.conflict_id}/resolution`;
    body = {
      "action": 2,
      "legacy_property_uid": e.legacy_property_uid,
      "property_uid": e.property_uid,
      "updated_by": this.api.user_id
    }
    this.api.patchData(url, body).subscribe({
      next: (res) => {
        this.notify.notify("Property successfully merged", "success");
        this.legacyDataList = this.fnc.getDeletedArray(
          "legacy_property_uid",
          this.selected_property.legacy_property_uid,
          this.legacyDataList
        );
        this.existingDataList = this.fnc.getDeletedArray(
          "legacy_property_uid",
          e.legacy_property_uid,
          this.existingDataList
        );
        this.existingDataList = [];
        this.selected_property_existing = null;
        this.selected_property = null;
        this.entity_type_existing = "";
        this.entity_type = "";
        this.getLegacyData();
      },
      error: (err) => {
        this.notify.notify(err, "error");
      },
    });
  }

  back() {
    this.data.resolveConflict();
  }
  confirm(t,e) {
    let dialogRef = this.dialog.open(ConfirmationDialog, {
      width: '350px',
      panelClass: 'create-master-panel',
      data: { parent: this, elem: e, type: t }//optional
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }
  openMap() {
    let dialogRef = this.dialog.open(PropertyMap, {
      width: '550px',
      panelClass: 'create-master-panel',
      data: { parent: this }//optional
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  ngOnInit() {

  }
}



//MAP COMPONENT STARTS HERE
@Component({
  selector: 'property-map',
  templateUrl: './property-map.html',
  styleUrls: ['./legacy-component.component.css']
})

export class PropertyMap {
  google: any;
  map: Map;
  marker: any;
  loader_map: boolean = false;
  location: any = {
    lat: '',
    lon: ''
  };
  selected_assignment:any;
  @ViewChild('gmap') gmapElement: any;
  constructor(public dialogRef: MatDialogRef<PropertyMap>,
    @Inject(MAT_DIALOG_DATA) public data: any, private api: ApiserviceService, private share:ShareService, private notify: NotificationService) {
    this.location.lat = this.data.parent.selected_property.lat;
    this.location.lon = this.data.parent.selected_property.lon;
    this.selected_assignment = this.share.csv_obj.selected_assignment;
  }

  ngOninit() {
  }

  ngAfterViewInit() {
    this.renderMap();
  }

  renderMap() {
    let location_obj: any = new LatLon(this.location.lat, this.location.lon, 0);
    let mapProp = {
      center: new LatLon(this.location.lat, this.location.lon, 0),
      zoom: 14,
      zoomControl: false,
      scaleControl: false,
      streetViewControl: false,
      mapTypeControl: true,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT
      },
      mapTypeId: google.maps.MapTypeId.SATELLITE,
      element: this.gmapElement.nativeElement
    };

    this.map = new Map(mapProp);
    //remove all markers

    this.map.removeAllMarkers();
    this.map.panTo(location_obj);

    this.map.addMarker(new LatLon(this.location.lat, this.location.lon, 0),
      {
        'iconUrl': '../assets/icon/Pin.svg',
        'iconWidth': 50,
        'iconHeight': 50
      }, false);

    this.data.parent.filteredPropertyList.forEach(prop => {
      this.map.addMarker(new LatLon(prop.lat, prop.lon, 0),
        {
          'iconUrl': '../assets/icon/Pin1.svg',
          'iconWidth': 25,
          'iconHeight': 25,
          'info': '<div id="content">' +
        prop.property_uid +
        '</div>',
        }, false);

    });

    if(this.selected_assignment){
      // this.map.addLayer({
      //   'format': 'wms',
      //   'layerName': 'parcels',
      //   'name': 'parcels',
      //   'url': this.api.wms_url,
      //   'assignment': this.selected_assignment
      // });
      this.map.addGeoLayer({
        'format': 'wms',
        'layerName': 'parcels',
        'name': 'parcels',
        'url': environment.geoestater+'wms-layer',
        'assignment': this.selected_assignment,
        'city_code': this.api.city_code,
        'city_id': this.api.city_id,
        'user_token': environment.user_token
      });

    }else{
      this.notify.notify('Please select assignment for show the parcel layer', 'warn', 3000)
    }

  }
}

@Component({
  selector: 'data-list',
  templateUrl: './data-list.html',
  styleUrls: ['./legacy-component.component.css']
})

export class DataList {
  @Input() item;
  @Input() k = 0;
  @Input() active = false;
  @Input() parent = null;

  constructor() { }

  select(item) {
    if (parent) {
      this.parent.select(item);
    }
  }

  confirm(t,e) {
    if (parent) {
      this.parent.confirm(t,e);
    }
  }

  getFloors(data) {
    if (parent) {
      if (data.entity_type == 'property') {
        this.parent.getFloors('legacy', data);
      } else {
        this.parent.getFloors('existing', data);
      }

    }
  }
  getUnits(data) {
    if (data.entity_type == 'property_floor') {
      if (parent) {
        this.parent.getUnits(data);
      }
    }
    if (data.entity_type == 'property_floor_existing') {
      if (parent) {
        this.parent.getUnits(data);
      }
    }
  }

  ngOninit() { }
}
