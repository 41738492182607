import { NgModule } from '@angular/core';
import { SearchFilterPipe } from './search-filter.pipe';
import { FilterPipeCat } from './filterPipeCat';
import { SearchPipe } from '../admin/admin-attribute-deletion/search-filter/search.pipe';
import { RemoveUnderscorePipe } from '../admin/shared/pipes/removeUnderscore.pipe';

const PIPES = [
    SearchFilterPipe,
    FilterPipeCat,
    SearchPipe,
    RemoveUnderscorePipe
];

@NgModule({
    declarations: [...PIPES],
    exports: [...PIPES],
})
export class PipesModule {}
