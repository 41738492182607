import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ApiserviceService } from "../../../../../../src/app/apiservice.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NotificationService } from "../../../../../../src/app/admin/services/notification.service";
import { minWordsValidator } from "../../validations/minWordsValidator";

@Component({
  selector: "app-attribute-deletion-confirmation",
  templateUrl: "./attribute-deletion-confirmation.component.html",
  styleUrls: ["./attribute-deletion-confirmation.component.scss"],
})
export class AttributeDeletionConfirmationComponent {
  selectedEntityType: string = "";
  deleteRequestGroup: FormGroup;
  logId = [];
  constructor(
    private api: ApiserviceService,
    private fb: FormBuilder,
    private notify: NotificationService,
    public dialogRef: MatDialogRef<AttributeDeletionConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit(): void {
    this.selectedEntityType = this.data.selectedEntity
    console.log(this.data.attribute_name.attribute_name);
    this.deleteRequestGroup = this.fb.group({
      comment: ["", [Validators.required,  minWordsValidator(10)]],
    });
    //
  }
  close() {
    this.dialogRef.close();
  }

  deleteAttributeRequest() {
    let log_id_values = [];
    this.data.selectedRows.forEach(element => {
      log_id_values.push(element.log_id);
    });
    console.log(this.data.attribute_name);

    this.logId.push(...log_id_values);
    let url = this.selectedEntityType + `/add-attribute-delete-request`;
    let body: {
      added_by: any;
      comment: string;
      data: any;
      attribute_id: number;
      delete_type: any;
      date_range: string;
      log_ids?:any; // Optional property
      attribute_name:any;
    } = {
      added_by: this.api.user_id,
      comment: this.deleteRequestGroup.value.comment,
      data: null,
      attribute_id: this.data.attribute_id,
      delete_type: this.data.deletion_type,
      date_range: this.data.date_range,
      attribute_name:this.data.attribute_name.attribute_name
    };

    if (this.data.deletion_type === 'selected') {
      body.log_ids = this.logId; // Now this won't cause a TypeScript error
    }
    this.api.postEpsData(url, body).subscribe((res) => {
      console.log(res);
      let result:any = res;

      this.dialogRef.close(true);

    });
  }
  get comment() {
    return this.deleteRequestGroup.get('comment');
  }
}
