<div class="parent-container">
    <mat-card appearance="outlined" class="example-card">
        <div class="highlighsection">
            <h2 class="title">Historical Sync</h2>
        </div>
        <mat-card-content>
            <div class="gis_data_form">
                <div class="d-flex align-items-center">
                    <div class="ml-10 d-flex align-items-center">
                        <label for="">Type <span class="mandatory">*</span> : </label>
                        <mat-select (selectionChange)="selectionChange()" placeholder="Select Type" [(ngModel)]="selected_type" class="form-select-l">
                            <mat-option class="cp" *ngFor="let data of types" [value]="data">
                                {{data}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="ml-10 d-flex align-items-center">
                        <label for="">Year <span class="mandatory">*</span> : </label>
                        <mat-select (selectionChange)="selectionChange()" (openedChange)="closeMatSelectEvent($event, 'year')" placeholder="Select Year" [(ngModel)]="selected_year" class="form-select-l">
                            <div class="search_bar"><input [(ngModel)]="search_year" placeholder="Search..."   maxlength="4" (keydown)="$event.stopPropagation()" /></div>
                            <mat-option class="cp" *ngFor="let data of yearList | searchfilter:'val':search_year:yearList" [value]="data.val">
                                {{data.val}}
                            </mat-option>
                        </mat-select>
                    </div>
                    <div class="ml-10 d-flex align-items-center">
                        <label for="">Date: </label>
                        <span class="d-flex align-items-center mx-2 position-relative">
                            <span class="">
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                            </span>
                            <input [min]="mindate" [max]="today" class="form-select-l" style="padding :8px 8px !important;" [matDatepicker]="picker1" [(ngModel)]="selected_date" disabled>
                        </span>
                    </div>
                </div>
                <div>
                    <button class="submit sync-disabled" (click)="syncData()" [disabled]="isRequestSent">Sync</button>
                </div>
            </div>
        </mat-card-content>
        <div class="bbar w-100" *ngIf="loader">
            <mat-progress-bar mode="indeterminate" class="btm"></mat-progress-bar>
        </div>

    </mat-card>
    <mat-card appearance="outlined" class="example-card">
        <div class="highlighsection d-flex justify-content-between align-items-center">
            <div class="title">
                Historical Sync Data Status
            </div>
            <div class="text-right mr-1">
                <button mat-icon-button  matTooltip="Refresh list" class="refresh" (click)="getSynctatus()">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>
        </div>
        <mat-card-content class="tabs">
            <div class="table-estater table" style="position: relative">
                <ng-container *ngIf="DataSource.length > 0">
                    <table class="attr-table">
                        <thead>
                            <tr>
                               <th>Id</th>
                               <th>Table name</th>
                               <th>Year</th>
                               <th>Record processed</th>
                               <th>From sync date</th>
                               <th>To sync date</th>
                               <th>Execution time</th>
                               <th>Execution date</th>
                               <th>Status</th>
                               <th>Remarks</th>
                               <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of DataSource; let k = index">
                                <td>{{data?.id}}</td>
                                <td class="table-name" [matTooltip]="data?.table_name">{{data?.table_name}} </td>
                                <td>{{data?.year}}</td>
                                <td width="12%">
                                  <div style="width: 90%; margin: auto;"><app-progress-bar [percent]="data?.percent" [min]="data?.record_processed" [max]="data?.record_to_be_processed"></app-progress-bar></div>
                                </td>
                                <td>{{data?.min_time_stamp}}</td>
                                <td>{{(data?.max_time_stamp) == '' ? '--' : data?.max_time_stamp }}</td>
                                <td><span [matTooltip]="getTooltip(data)">{{data?.time_difference}}</span></td>
                                <td>{{data?.added_date}}</td>
                                <td> <span [class.text-danger]="data.text_status == -1 || data.text_status == -2"
                                  [class.text-success]="data.text_status == 2" >{{data?.status}}</span></td>
                                <td>{{(data?.remarks)  == null ? '--' : data?.remarks}}</td>
                                <td>    <div class="d-flex justify-content-center">
                                    <button class="action-icon" (click)="stopProcess(data)" [disabled]="data.text_status != 1">
                                        Stop process
                                     </button>
                                   </div></td>
                            </tr>
                        </tbody>
                        <ngx-loading [show]="table_loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
                    </table>
                </ng-container>

            </div>
            <mat-paginator  [length]="total" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageAction($event)">
            </mat-paginator>
        </mat-card-content>
    </mat-card>
</div>
