import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiserviceService } from '../../apiservice.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguageComponent implements OnInit {

  @Output() valueChange = new EventEmitter();

  language:any = [];

  selected_lang: any;
  constructor( private api: ApiserviceService) {
       }

  ngOnInit() {
    if(this.api.language_list){
      this.language = this.api.language_list;
    }

  }

change(val){
  this.selected_lang = val;
  this.valueChange.emit(this.selected_lang);
}

}
