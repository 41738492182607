<mat-toolbar class="matpopup-header">
  <div>Delete Group</div>
</mat-toolbar>
<mat-dialog-content>
  <div fxLayout="column" class="w-100">
    <mat-progress-bar class="w-100" *ngIf="progress != 0" mode="determinate" value="{{ progress }}"></mat-progress-bar>
    <small *ngIf="progress != 0">{{ message }}</small>
  </div>
  <p>
    Do you want to delete <em class="warn"> {{ group_name }} </em> group
  </p>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="dialog-actions" *ngIf="progress == 0">
  <button mat-button class="blue-button" (click)="deleteGroup(group_id)">
    Yes
  </button>
  <button mat-raised-button (click)="close()">No</button>
</mat-dialog-actions>
