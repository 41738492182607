import { Component, Input, OnInit } from "@angular/core";
import { ShareService } from "../services/share.service";
import { AdminBreadcrumbService } from "./services/admin-breadcrumb.service";

@Component({
  selector: "admin-breadcrumb",
  templateUrl: "./admin-breadcrumb.component.html",
  styleUrls: ["./admin-breadcrumb.component.css"],
})
export class AdminBreadcrumbComponent implements OnInit {
  @Input() total_records: any;
  constructor(
    private _crumbs: AdminBreadcrumbService,
    private share: ShareService
  ) {}

  ngOnInit() {}

  getCrumbs() {
    return this._crumbs.crumbs;
  }

  getMobileLastCrumb() {
    let totalcrumb = this.getCrumbs().length;
    return this._crumbs.crumbs[totalcrumb - 1];
  }

  getCount() {
    return this._crumbs.count;
  }

  callFunction(name) {
    if (name == "floor") {
      this.share.qaqc_obj.switchEntity("property_floor");
    }
    if (name == "property") {
      this.share.qaqc_obj.switchEntity("property");
    } else {
      return;
    }
  }
}
