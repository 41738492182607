<div *ngIf="mastergroups_access.PATCH || mastergroups_access.DELETE || masters_access.POST">
    <a *ngIf="mastergroups_access.PATCH" (click)="openDialog('mastergroup',{'group':grouplist,'edit':true}, $event)"
         mat-menu-item class="mastergroup_menuactions min-heig-list">
        <mat-icon>edit</mat-icon>
        Edit Group
    </a>
    <div *ngIf="masters_access.POST" class="divider"></div>
    <a *ngIf="masters_access.POST" mat-menu-item class="mastergroup_menuactions min-heig-list" (click)="openDialog('master',{'id':0,'group_val':grouplist,'edit':false}, $event)">
        <mat-icon>add</mat-icon>
        Create Master
    </a>
    <div class="divider"></div>
    <a *ngIf="mastergroups_access.DELETE" mat-menu-item class="mastergroup_menuactions min-heig-list">
            <mat-icon>delete</mat-icon>
            Delete Group
        </a>
    <div class="arrows"><img src="/assets/images/arr.png" /></div>
</div>
