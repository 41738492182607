import { Component, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AdminMenusService } from '../../../../../../src/app/admin/admin-sidebar/admin-menus.service';
import { NotificationService } from '../../../../../../src/app/admin/services/notification.service';
import { ApiserviceService } from '../../../../../../src/app/apiservice.service';
import { CommonfunctionService } from '../../../../../../src/app/services/commonfunction.service';

@Component({
  selector: 'app-admin-file-upload',
  templateUrl: './admin-file-upload.component.html',
  styleUrls: ['./admin-file-upload.component.scss']
})
export class AdminFileUploadComponent {
  observer_adm_upload: { GET: boolean; PATCH: boolean; POST: boolean; DELETE: boolean };
  fileToUpload: File;
  fileName: string;
  loading: boolean = false;
  city_code: string;

  @ViewChild('myFileInput') myFileInput: ElementRef; // Accessing file input element
  @Output() getAfterUpload = new EventEmitter<any>();

  constructor(
    private notify: NotificationService,
    private router: Router,
    private api: ApiserviceService,
    private fnc: CommonfunctionService,
    private menus: AdminMenusService
  ) {
    this.city_code = this.api.city_code.toLowerCase();
  }

  ngOnInit(): void {
    this.getAllowResourcesApi();
  }

  getAllowResource() {
    this.observer_adm_upload = this.fnc.checkResourceAccess("observer_adm_upload", false);
    if (!this.observer_adm_upload.GET) {
      // this.router.navigateByUrl("/admin/login");
      return;
    }
  }

  getAllowResourcesApi() {
    this.api.getUserRole();
    const body = `user/resource?place_id=${this.api.city_id}&user_id=${this.api.user_id}`;
    this.api.getUmsData(body).subscribe({
      next: (res: any) => {
        this.api.allowResources = res.data;
        this.getAllowResource();
      },
      error: (err) => {
        console.error("Error fetching resources", err);
      },
    });
  }

  handleFileInput($event: Event) {
    const input = $event.target as HTMLInputElement;
    const fileList: FileList | null = input.files;

    if (fileList && fileList.length > 0) {
      const file: File = fileList[0];
      const pattern = /zip-*/;

      if (!file.type.match(pattern)) {
        this.notify.notify("Unsupported file type", "warn");
        return;
      }

      this.fileToUpload = file;
      this.fileName = this.fileToUpload.name;
    }
  }

  uploadFile() {
    if (!this.observer_adm_upload.POST) {
      this.notify.notify("User not Authorized to Upload Data", "warn");
      return;
    }

    if (!this.fileToUpload) {
      this.notify.notify("File is required", "warn");
      return;
    }

    const formData = new FormData();
    formData.append("file", this.fileToUpload, this.fileToUpload.name);
    this.loading = true;

    this.api.uploadEpsData(
      `unit-adm?layer_name=observer&user_id=${this.api.user_id}`,
      formData
    ).subscribe({
      next: (res: any) => {
        this.loading = false;
        this.notify.notify(res.message, "success");
        this.getAfterUpload.emit();
        this.resetFileInput(); // Reset file input and filename
      },
      error: (err) => {
        this.loading = false;
        this.notify.notify("File upload failed", "error");
        this.resetFileInput(); // Ensure input reset on error as well
      },
    });
  }

  // Method to reset file input and filename
  private resetFileInput() {
    if (this.myFileInput && this.myFileInput.nativeElement) {
      this.myFileInput.nativeElement.value = ""; // Reset the file input element
    }
    this.fileToUpload = null;
    this.fileName = null; // Clear the fileName variable
  }
}
