<div *ngIf="!alert">
    <mat-toolbar class="tool-b">Available Addresses</mat-toolbar>
    <div class="over400p">
        <mat-list role="list">
            <mat-list-item *ngFor="let data of available_address_list" role="listitem" class="l-item" (click)="select(data)">
                <div>
                    <span class="label highlight">
                        Address:
                    </span>
                    <span class="val">{{data.address_text}}</span>
                </div>
            </mat-list-item>
        </mat-list>
    </div>
</div>
<div *ngIf="alert">
    <mat-toolbar class="tool-b">Unsupported area</mat-toolbar>
    <div class="alert-div">
        <span class="fnt-alert">This location is unsupported. Please choose a different location.</span>
    </div>
    <div class="bott">
        <button class="est-bg" mat-raised-button (click)="closeDialog()">Dismiss</button>
    </div>
</div>