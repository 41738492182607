<!-- #1 For Web view-->
<div>
  <div fxHide="true" fxShow.gt-sm="true" fxLayout="row" class="w-100 crumb-c" fxLayoutAlign="space-between center">
    <div fxLayout="row" class="w-100 bleft" fxLayoutAlign="space-between center">
      <admin-breadcrumb></admin-breadcrumb>
      <div fxLayout="row" fxLayoutAlign="end center">
        <div fxLayout="row" fxShow="false" fxShow.gt-xs="true" fxLayoutAlign="start center" fxLayoutGap="10px"
          class="search-bar">
          <mat-select [(ngModel)]="selected_module" (selectionChange)="selectionChange(false)" placeholder="Module"
            class="form-select-l">
            <mat-option *ngFor="let mdl of module_list"
              [value]="mdl.module_type_name">{{mdl.module_type_name}}</mat-option>
          </mat-select>
          <mat-select [(ngModel)]="selected_assignment" [disabled]="!is_assignment_dependent"
            (selectionChange)="selectionChange(false)" class="form-select-l" (openedChange)="searchAssignment=''">
            <div class="search_bar"> <input mat-input [(ngModel)]="searchAssignment"
                placeholder="Type here to search name..." (keydown)="$event.stopPropagation()" /></div>
            <mat-option *ngFor="let assign of assignment_list | searchfilter:'name':searchAssignment:assignment_list"
              [value]="assign.assignment_id">{{assign.label}}_{{assign?.year}}</mat-option>
          </mat-select>
          <mat-select [(ngModel)]="selected_entity_type" (selectionChange)="selectionChange(false)"
            class="form-select-l">
            <mat-option *ngFor="let ent of api.entity_type_list"
              [value]="ent.entity_name">{{ent.entity_name}}</mat-option>
          </mat-select>
          <mat-select *ngIf="is_property_dependent" [(ngModel)]="selected_property_type" (openedChange)="searchType=''"
            (selectionChange)="selectionChange(false)" [disabled]="!is_property_dependent" class="form-select-l">
            <div class="search_bar"> <input mat-input [(ngModel)]="searchType" placeholder="Type here to search name..."
                (keydown)="$event.stopPropagation()" /></div>
            <mat-option
              *ngFor="let prp of api.property_type_list | searchfilter:'property_type_name':searchType:api.property_type_list"
              [value]="prp.property_type_id">{{prp.property_type_label[api.language] == undefined ?
              prp.property_type_name : prp.property_type_label[api.language]}}</mat-option>
          </mat-select>
          <input [formControl]="searchField" class="form-input-l search-inputbox" placeholder="Type to search..."
            type="text">
          <!-- <button mat-icon-button>
            <mat-icon>search</mat-icon>
          </button> -->
        </div>
      </div>
    </div>
    <div fxLayout="row" class="cleft" fxLayoutAlign="end center">
      <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px" class="button-b">
        <button *ngIf="attributegroups_access.POST" [disabled]="!selected_module || !selected_entity_type" mat-button
          class="orange-button" (click)="openDialog('createattribute')">Create Group / Sub Group</button>
      </div>
    </div>
  </div>
  <div class="newh" fxLayout="column" fxHide="true" fxShow.gt-sm="true">
    <div fxLayout="row">
      <div class="l-c">
        <div class="left-t">
          <mat-nav-list class="listf w-100">
            <mat-list-item class="light-green-button w-100" (click)="getAttributUndefine()" fxLayout="row"
              fxLayoutAlign="space-between center">
              <div fxFlex="90%">Undefined Attributes</div>
              <div fxFlex="10%">{{undefined_length}}</div>
            </mat-list-item>
          </mat-nav-list>
        </div>
        <h4 class="l-mg">Group Details
          <span style="padding-left: 3%;color: #f69230;font-weight: bold;">({{total_groups}})</span>
        </h4>
        <div class="newscroll">
          <group-list [entity]="selected_entity_type" [assignment]="selected_assignment" [module]="selected_module"
            [list]="group_custom_list" [group_access]="attributegroups_access" [attributes_access]="attributes_access"
            (listFields)="selectGroup($event)" (eventtrigger)="loadlistevent($event)"></group-list>
        </div>

      </div>
      <div fxFlex="calc3cols" class="col3 r-c">
        <div fxLayout="row" class="tophe" fxLayoutAlign="space-between center">
          <h4 *ngIf="!selected_group" class="r-mv">Undefined Attributes</h4>
          <h4 *ngIf="selected_group" class="r-mv">{{selected_group.group_name}}</h4>
          <div *ngIf="selected_attributes_id.length">
            <button *ngIf="showRemove" title="Remove Attribute" [disabled]="!selected_attributes_id.length"
              [style.border]="!selected_attributes_id.length > 0 ? '' : '1px solid #f69433'"
              (click)="removeFromQuickAction()" mat-button class="shuffleiconbutton3">
              <mat-icon [style.color]="!selected_attributes_id.length > 0 ? '' : '#f69433'"
                style="font-size: 10px;">remove_circle_outline</mat-icon>
            </button>
            <button *ngIf="!showRemove" title="Shuffle Group" [disabled]="!selected_attributes_id.length" mat-button
              class="shuffleiconbutton2" (click)="openDialog('groupshuffledialog')">
              <mat-icon svgIcon="shuffle-icon"></mat-icon>
            </button>
          </div>
        </div>

        <!-- #6 Field group table list -->
        <div class="table-estater" style="min-height: 65vh;background: white; position: relative;">
          <span class="no-data" *ngIf="field_list.length === 0">No Data Available</span>
          <mat-table #table [sortablejs]="field_list" [sortablejsOptions]="tableoptions" [dataSource]="field_list">
            <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->
            <!-- Serial No Column (Sno) -->


            <!-- Serial No Column (Sno) -->
            <ng-container matColumnDef="checkboxes">
              <mat-header-cell *matHeaderCellDef fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="center center"
                style="background-color: #24cb6b;">
                <mat-icon class="drag-handle drag_handle_heading" svgIcon="drag-handle"></mat-icon>
              </mat-header-cell>
              <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center" [dndDraggable]="element"
                [dndEffectAllowed]="draggable.effectAllowed" (dndStart)="onDragStart($event)"
                (dndCanceled)="onDragCanceled($event)">
                <mat-icon class="dragi drag_handle_cell" svgIcon="drag1"></mat-icon>

              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="sno">
              <mat-header-cell *matHeaderCellDef fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="center center"
                style="background-color: #24cb6b;">S.No. </mat-header-cell>
              <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center">
                <mat-checkbox [checked]="isChecked(element.id)" [disabled]="!attributes_access.PATCH" (change)="setAttributeId($event)"
                  [value]="element.id"></mat-checkbox>
                <span class="w-10">{{element.sno}}</span>
              </mat-cell>
            </ng-container>
            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef fxLayout="row" fxFlexAlign="stretch" fxLayoutGap="20px"
                fxLayoutAlign="center center">
                Attribute Name </mat-header-cell>
              <mat-cell *matCellDef="let element" [title]="element.name" fxFlexAlign="stretch" fxLayout="row"
                fxLayoutAlign="center center">
                <span class="ellipsis">{{element.name}}</span>
              </mat-cell>
            </ng-container>

            <!-- Attribute language Column -->
            <ng-container matColumnDef="language">
              <mat-header-cell *matHeaderCellDef fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="center center">
                Attribute
                Label </mat-header-cell>
              <mat-cell *matCellDef="let element;let i = index" [title]="element.label" fxLayout="row"
                fxLayoutAlign="center center" [shAttachMenu]="element.label_right">
                <div class="ellipsis">
                  {{element.label}}
                </div>
                <button *ngIf="attributes_access.PATCH" mat-icon-button class="in-field-edit"
                  (click)="changelanguage(element,'label')">
                  <mat-icon *ngIf="hovertablecellindex == i">edit</mat-icon>
                </button>
              </mat-cell>
            </ng-container>


            <!-- Attribute Description Column -->
            <ng-container matColumnDef="description_language">
              <mat-header-cell *matHeaderCellDef fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="center center">
                Description
              </mat-header-cell>
              <mat-cell *matCellDef="let element;let i = index" fxLayout="row" fxLayoutAlign="center center"
                [shAttachMenu]="element.description_right">
                <div class="description_limit">
                  {{element.description}}</div>
                <button *ngIf="attributes_access.PATCH" mat-icon-button class="in-field-edit"
                  (click)="changelanguage(element,'description')">
                  <mat-icon *ngIf="hovertablecellindex == i">edit</mat-icon>
                </button>
              </mat-cell>
            </ng-container>
            <!-- Show Hide value Column -->
            <ng-container matColumnDef="status">
              <mat-header-cell *matHeaderCellDef fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="center center">
               Status</mat-header-cell>
              <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center">
                <mat-checkbox [disabled]="!attributes_access.PATCH" (change)="updateFields('status',element,$event)"
                  [checked]="element.status == 1"></mat-checkbox>
              </mat-cell>
            </ng-container>

            <!-- Show Hide value Column -->
            <ng-container matColumnDef="show_hide">
              <mat-header-cell *matHeaderCellDef fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="center center">
                Hidden</mat-header-cell>
              <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center">
                <mat-checkbox [disabled]="!attributes_access.PATCH"
                  (change)="updateFields('is_hidden',element,$event)" [checked]="element.is_hidden"></mat-checkbox>
              </mat-cell>
            </ng-container>

            <!-- Mandatory Column -->
            <ng-container matColumnDef="mandatory">
              <mat-header-cell *matHeaderCellDef fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="center center">
                Mandatory
              </mat-header-cell>
              <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center">
                <mat-checkbox [disabled]="!attributes_access.PATCH"
                  (change)="updateFields('is_mandatory',element,$event)"
                  [checked]="element.is_mandatory"></mat-checkbox>
              </mat-cell>
            </ng-container>

            <!-- Listable Column -->
            <ng-container matColumnDef="listable">
              <mat-header-cell *matHeaderCellDef fxLayout="row" fxFlexAlign="stretch" fxLayoutAlign="center center">
                Listable
              </mat-header-cell>
              <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center">
                <mat-checkbox [disabled]="!attributes_access.PATCH"
                  (change)="updateFields('is_listable',element,$event)"
                  [checked]="element.is_listable"></mat-checkbox>
              </mat-cell>
            </ng-container>

            <!-- Editable Column -->
            <ng-container matColumnDef="editable">
              <mat-header-cell *matHeaderCellDef fxLayout="row" fxFlexAlign="stretch"
                fxLayoutAlign="center center">Editable</mat-header-cell>
              <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center">
                <mat-checkbox [disabled]="!attributes_access.PATCH"
                  (change)="updateFields('is_editable',element,$event)"
                  [checked]="element.is_editable"></mat-checkbox>
              </mat-cell>
            </ng-container>


            <mat-header-row class="skipdrag" *matHeaderRowDef="displayedColumns"></mat-header-row>
            <div class="rowss">
              <mat-row class="rowset draggableset" *matRowDef="let row;let i = index; columns: displayedColumns;"
                [class.disabled-row]="!row.status" (mouseover)="hoverTableCell(i)" (mouseout)="hoverOutTableCell()"
                (click)="selectField(row)"></mat-row>
            </div>
          </mat-table>



          <!-- loader start -->
          <div *ngIf="loader" class='loader-div'>
            <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
          </div>
          <!-- loader end -->
        </div>
        <div class="pagindiv">
          <mat-paginator [length]="total" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions"
            (page)="pageAction($event);">
          </mat-paginator>
        </div>
        <!-- End of #6 Master group table list -->
      </div>
    </div>

  </div>
</div>
<!-- loader start -->
<div *ngIf="ready_load">
  <ngx-loading [show]="ready_load" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
</div>
<!-- loader end -->

<!-- end of #1 For Web view-->


<!-- #2 For Mobile Screen (Search and Add)-->
<div fxLayout="column" class="topb" fxShow="true" fxShow.gt-sm="false">
  <div fxLayout="row" class="w-100" fxLayoutAlign="space-between center">
    <button mat-button class="bluebutton">
      <mat-icon svgIcon="property-listing-icon" (click)="showMasterGroup()"></mat-icon>
    </button>
    <div class="w-100" fxLayout="row" fxLayoutAlign="start center">
      <admin-breadcrumb fxFlex="70%"></admin-breadcrumb>
      <div fxFlex="30%" class="additional-options" fxLayout="row" fxLayout="end end">
        <button mat-icon-button class="small-white-button">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button class="small-white-button" (click)="openDialog('groupshuffledialog')">
          <mat-icon svgIcon="shuffle-icon"></mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div fxLayout="row" *ngIf="!showmobilesearchbar" class="w-100 master-middle-bar" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="start center">

      <div fxLayout="column" fxLayoutGap="2px" fxLayoutAlign="space-around start">
        <label class="small-search-label">Module</label>
        <select class="form-select-white">
          <option>Module</option>
        </select>
      </div>
      <div fxLayout="column" fxLayoutGap="2px" fxLayoutAlign="space-around start">
        <label class="small-search-label">Property type</label>
        <select class="form-select-white">
          <option>Apartment</option>
        </select>
      </div>
      <div fxLayout="column" fxLayoutGap="2px" fxLayoutAlign="space-around start">
        <label class="small-search-label">Entity type</label>
        <select class="form-select-white">
          <option>Property</option>
        </select>
      </div>
    </div>
    <div fxLayout="row" *ngIf="!showsubmas" fxLayoutGap="5px" fxLayoutAlign="end center">
      <button mat-icon-button (click)="openDialog('createattribute')" class="greenbutton">
        <mat-icon>add</mat-icon>
      </button>
      <button mat-icon-button class="obutton" (click)="showMobileSearchBar()">
        <mat-icon>search</mat-icon>
      </button>
    </div>
  </div>

  <div fxLayout="row" *ngIf="showmobilesearchbar" [ngClass]="{'animated fadeIn': showmobilesearchbar}"
    class="w-100 master-middle-bar" fxLayoutAlign="space-between center">
    <div fxFlex="90%">
      <input type="text" class="mobile-searchbar" placeholder="Search attribute by name" />
    </div>
    <div fxFlex="10%">
      <button mat-icon-button (click)="showMobileSearchBar()" class="obutton">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>


<!-- For Mobile Screen-->

<div class="container listview" fxShow="true" fxShow.gt-sm="false" fxLayout="row">
  <div fxLayout="column" class="w-100" fxLayoutGap="15px" fxLayoutAlign="space-between center">
    <div class="pack w-100">
      <div class="draggableset sets" *ngFor="let source of field_list;let s = index">

        <div fxLayout="column" fxLayoutAlign="center start" class="les" id="les{{s}}"
          (swipeleft)="swipeleftactions(source,s)" (swiperight)="swiperightactions(source,s)">

          <div class="heading" fxFlex="100%" [ngClass]="{active : mobiletablistselect == s}">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <div>
                {{s+1}}. {{source.name}}
              </div>
              <div>
                <button mat-icon-button class="m-small-edit" (click)="changelanguage(source)">
                  <mat-icon>edit</mat-icon>
                </button>
                <mat-checkbox class="white-checkbox"></mat-checkbox>
              </div>
            </div>
          </div>
          <div class="middlec" fxLayout="row" fxLayoutAlign="space-around center">
            <div fxLayout="column" fxFlex="100%">
              <div class="subtext">
                <span class="label">Property Type : </span>
                <span class="value">{{source.master_language}}</span>
              </div>
              <div class="subtext" fxLayout="row" fxLayoutAlign="space-between center">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <span class="label">Show Hide </span>
                  <span class="value">
                    <mat-checkbox></mat-checkbox>
                  </span>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <span class="label">Mandatory </span>
                  <span class="value">
                    <mat-checkbox></mat-checkbox>
                  </span>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <span class="label">Listable </span>
                  <span class="value">
                    <mat-checkbox></mat-checkbox>
                  </span>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <span class="label">Editable </span>
                  <span class="value">
                    <mat-checkbox></mat-checkbox>
                  </span>
                </div>
              </div>
            </div>

          </div>
          <div class="swipeactions">
            <div fxLayout="row" fxLayoutAlign="space-between center">
              <button mat-button class="swipe-button">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-button class="swipe-button">
                <mat-icon svgIcon="delete-icon"></mat-icon>
              </button>
            </div>
            <!-- <button mat-button class="submaster">Submaster</button> -->
          </div>
        </div>

      </div>
    </div>


    <div class="addmastergrouppopup"
      [ngClass]="{'showme animated slideInLeft' : mobilemasterpopup,'slideOutLeft': mobilemasterpopupslideout}"
      (swipeleft)="swiped()">
      <div fxLayout="row" class="w-100 bar-c" fxLayoutAlign="space-between center">
        <button mat-button class="bluebutton ">
          <mat-icon svgIcon="property-listing-icon"></mat-icon>
        </button>
        <button mat-button class="center button-master" (click)="openDialog('createattribute')">
          Create group / Sub group
        </button>
        <button mat-icon-button class="master-closeicon" (click)="swiped()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="w-100">
        <group-list [list]="group_custom_list" (triggerswipe)="triggerswipe($event)"
          (eventtrigger)="loadlistevent($event)"></group-list>
      </div>
    </div>

  </div>
</div>

<!-- For Mobile Screen-->

<!-- End of #2 Mobile Screen -->
