import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

//CONFIRM DIALOG COMPONENT STARTS HERE
@Component({
  selector: 'confirmation-dialog',
  templateUrl: './confirmation-dialog.html',
  styleUrls: ['./confirmation-dialog.scss']
})

export class ConfirmationDialog {
  mode:any;
  constructor(public dialogRef: MatDialogRef<ConfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.mode = this.data.type;
     }

  ngOninit() { }

  confirm(){
    let type = this.data.type;
    if(type == 'remove') this.data.parent.removeProperty(this.data.elem);
    if(type == 'create') this.data.parent.createProperty(this.data.elem);
    if(type == 'merge') this.data.parent.mergeProperty(this.data.elem);
    this.dialogRef.close();
  }
}
