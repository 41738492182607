<mat-progress-bar *ngIf="_loader" class="mtc-progress" [color]="color" [mode]="mode" [value]="value">
</mat-progress-bar>
<div class="mtc-header position-relative" fxLayout="row" fxLayoutAlign="center center">
  <div><h5>Update Values</h5></div>  
</div>

  <div class="row m-0">
    <div class="col text-center_1">Values</div>
    <div class="col text-center_1">Selected Values</div>
  </div>
  <div class="row">
    <div class="col-md-6">      
      <input type="text" class="_input" placeholder="Search..." [(ngModel)]="search1">
      <div class="ovr-auto">
        <div cdkDropList #valuesList="cdkDropList" [cdkDropListData]="mastervaluesLeft"
          [cdkDropListConnectedTo]="[selectedValuesList]" class="item-list" (cdkDropListDropped)="drop($event, 'left')">
          <ng-container *ngFor="let item of mastervaluesLeft | categoryfilter : search1">
            <div class="vals selectionbox" cdkDrag cdkDragLockAxis="x" #tooltip1="matTooltip" matTooltip="{{item.master_value_name}}"
              matTooltipPosition="above">
              <span class="inl">{{item.master_value_name}}</span>
            </div>
          </ng-container>

        </div>
      </div>
    </div>
    <div class="col-md-6">
      <input type="text" class="_input" placeholder="Search..." [(ngModel)]="search2">
      <div class="ovr-auto">
        <div cdkDropList #selectedValuesList="cdkDropList" [cdkDropListData]="mastervaluesright"
          [cdkDropListConnectedTo]="[valuesList]" class="item-list" (cdkDropListDropped)="drop($event, 'right')">
          <ng-container *ngFor="let item of mastervaluesright | categoryfilter : search2">
            <div class="vals-selected selectionbox" cdkDrag cdkDragLockAxis="x" #tooltip2="matTooltip"
              matTooltip="{{item.master_value_name}}" matTooltipPosition="above">
              <span class="inl">{{item.master_value_name}}</span></div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="footer" fxLayout="row" fxLayoutAlign="center center">
    <div class="pr-2">
        <button mat-raised-button color="primary"
            (click)="Update()">
            Update
        </button>
    </div>
    <div class="pl-2">
        <button mat-raised-button (click)="cancel()">Cancel</button>
    </div>
</div>