import { Component, Input } from "@angular/core";
import { MatErrorStateMatcher } from "../../../admin-error-state-macher";
import { CommonfunctionService } from "../../../../services/commonfunction.service";

@Component({
  selector: 'submaster-contextmenu',
  templateUrl: '../../subelements/submaster-menu.component.html'
})
export class SubmasterContextMenu {
  @Input('item') grouplist;
  @Input('openDialog') openDialog;
  @Input('master') masterlist;
  @Input('masters_access') masters_a;

  masters_access = {
    'GET': false,
    'POST': false,
    'PATCH': false,
    'DELETE': false
  }
  matcher = new MatErrorStateMatcher();
  constructor(private fnc: CommonfunctionService) { }
  ngOnInit() {
    if (this.masters_a) {
      this.masters_access = this.masters_a;
    }
  }


}



