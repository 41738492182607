<div class="gridV">
  <div class="btnGrid">
      <div class="upload-btn-wrapper">
          <div class="wrapper">
              <button class="sel">Select file</button>
              <p> or Drag & drop file here</p>
          </div>
          <input type="file" #myFileInput name="myfile" (change)="handleFileInput($event)" accept=".zip" />
          <div *ngIf="fileName" class="file-url" [matTooltip]="fileName">{{fileName}}</div>
      </div>
      <div class="fileName">
          <button class="submit" type="submit" [disabled]="loading" mat-raised-button color="primary" (click)="uploadFile()">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="#fff" height="20" viewBox="0 0 24 24" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                  <path d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path>
              </svg>
              <span *ngIf="!loading">Upload</span>
              <span *ngIf="loading">Uploading...</span>
          </button>
      </div>
  </div>
</div>
