<mat-toolbar class="dialog-toolbar">Available Data Types</mat-toolbar>
<div>
    <div class="note"><small class="txt"><strong>NOTE:&nbsp;</strong>Available data types are case sensitive</small></div>
    <div class="ovr-auto">
        <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
        <mat-list *ngIf="!loader">
            <mat-list-item *ngFor="let data of dataTypesList; let k = index;">
                <span class="fnt-sm">{{k+1}}.&nbsp;</span>
                <span class="txt">{{data}}</span>
            </mat-list-item>
        </mat-list>
    </div>
</div>