<div class="dialog-container size-large">
    <mat-drawer-container class="example-container" autosize>
        <mat-drawer #drawer class="example-sidenav" mode="over">
            <div class="inner">
                <mat-toolbar class="toolbr mat-elevation-z6">
                    <h1 class="mat-h1">Saved Geometries</h1>
                </mat-toolbar>
                <div class="outer-cont">
                    <div class="chip-cont">
                        <div class="chip-list">
                            <mat-chip-option class="mx-2" (click)="currentArea(poly)" *ngFor="let poly of polygons"
                                [removable]="removable" [selectable]="selectable" (removed)="confirm(poly)">
                                {{poly.label[api.language]}}
                                <mat-icon class="rem" matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip-option>
                        </div>
                    </div>
                </div>
            </div>
        </mat-drawer>

        <div class="example-sidenav-content">
            <mat-toolbar class="customizeMap">
                <div class="w-100 wrap-on" fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div class="">
                            <button class="close-can" (click)="openMenu()">
                                <mat-icon aria-label="Toggle menu">menu</mat-icon>
                                <b *ngIf="polygons.length>0" class="badge bg-back up">{{polygons.length}}</b>
                            </button>
                        </div>

                        <div class="fixed-width m0" title="Draw area">
                            <button mat-button class="border btn-fix-size" [ngClass]="{'bluebutton': mode == 'Circle'}"
                                (click)="drawCircle()">Circle<mat-icon class="iconfx" svgIcon="circle"></mat-icon>
                            </button>
                        </div>
                        <div class="fixed-width m0" title="Draw area">
                            <button mat-button class="border btn-fix-size" [ngClass]="{'bluebutton': mode == 'Polygon'}"
                                (click)="drawPolygon()">Polygon<mat-icon class="iconfx" svgIcon="polygon"></mat-icon>
                            </button>
                        </div>
                        <div class="fixed-width cnt">
                            <button (click)="reset()" [disabled]="mode == ''" mat-button
                                class="alertbutton btnSmall">Reset</button>
                        </div>
                        <div class="fixed-width cnt">
                            <mat-select (selectionChange)="selectNeighbourhood(selectedNeightbour)"
                                placeholder="Select Neighbourhood" [(ngModel)]="selectedNeightbour"
                                class="form-select-l">
                                <mat-option *ngFor="let neigh of neighbourhoods" [value]="neigh.neigh_uid">
                                    {{neigh.neigh_name}}</mat-option>
                            </mat-select>
                        </div>

                        <div class="fixed-width cnt search-bar-name" title="Search Property Name or UID">
                            <input type="text" class="form-select-l form-select-length"
                                placeholder="Search Property Name or UID" [(ngModel)]="property_uids">

                        </div>

                        <div class="fixed-width cnt">
                            <mat-select class="form-select-l" placeholder="Filter by type"
                                (selectionChange)="filterByType($event)">
                                <mat-option [value]="'All'">All</mat-option>
                                <ng-container *ngFor="let type of property_types">
                                    <mat-option [value]="type.property_type_id">{{type.property_type_label}}
                                    </mat-option>
                                </ng-container>
                            </mat-select>
                        </div>
                        <div class="fixed-width">
                            <span class="" *ngIf="selectedpolygon" title="{{getLabel(selectedpolygon)}}">
                                {{getLabel(selectedpolygon)}}</span>
                        </div>
                    </div>
                    <div class="grid-2" fxLayout="row" fxLayoutAlign="end center">
                        <button [disabled]="getPropertyLoader" (click)="getProperty()" mat-button class="alertbutton btnSmall mr-1">
                            <span *ngIf="getPropertyLoader">Getting...</span>
                            <span *ngIf="!getPropertyLoader">Get Property</span>
                        </button>
                        <button (click)="closeDialog()" title="Close" class="close-can">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g><g data-name="02 User"><path d="M25 512a25 25 0 0 1-17.68-42.68l462-462a25 25 0 0 1 35.36 35.36l-462 462A24.93 24.93 0 0 1 25 512z" fill="#717171" opacity="1" data-original="#000000" class=""></path><path d="M487 512a24.93 24.93 0 0 1-17.68-7.32l-462-462A25 25 0 0 1 42.68 7.32l462 462A25 25 0 0 1 487 512z" fill="#717171" opacity="1" data-original="#000000" class=""></path></g></g></svg>
                        </button>
                    </div>
                </div>
            </mat-toolbar>
            <div #gmap class="mapArea"></div>
        </div>
    </mat-drawer-container>
</div>
