<div  [ngClass]="{'fixed': isLoading}">
  <ngx-loading [show]="isLoading"></ngx-loading>
</div>
<!-- loader end -->
<div class="confirmation-wrapper">
  <mat-toolbar class="matpopup-header">
    <div><span *ngIf="title == 'reject'"> Reject</span><span *ngIf="title != 'reject'"> Approve</span> delete request</div>
    <button type="button" class="close-btn" (click)="close()">
      <img src="../../../../../assets/icon/Cross.svg" alt="">
    </button>
  </mat-toolbar>
  <p class="mesg-text">
    <span *ngIf="title != 'reject'"><strong>Are you sure you want to delete this attribute?</strong> <br/>Once approved, this action is irreversible, and the data will be permanently deleted.</span>
    <span *ngIf="title == 'reject'" style="padding: 10px;">
      <strong>Are you sure you want to reject this attribute deletion request?</strong><br/> <span style="padding:0 24px;display: inline-block;">Once rejected, this action is irreversible, and the data will not be deleted</span></span>
  </p>
  <form [formGroup]="deleteRequestGroup">
    <mat-form-field class="example-full-width"  appearance="outline" floatLabel="always">
      <mat-label>Leave a comment</mat-label>
      <textarea matInput #commentMessage placeholder="Write comment..." maxlength="180" formControlName="comment" max="100"></textarea>
      <mat-hint align="right" class="hint"> {{commentMessage.value.length}}/180</mat-hint>
      <mat-error  *ngIf="comment.errors?.minWords">
        <!-- <div *ngIf="comment.invalid && (comment.dirty || comment.touched)">
          <div> -->
            You need to enter at least 10 words.
          <!-- </div>
        </div> -->
      </mat-error>
    </mat-form-field>
  </form>
  <div class="center">
    <button class="cnfrm-btn" (click)="ApproveOrReject()" [disabled]="deleteRequestGroup.invalid">
        Yes
    </button>
    <button  class="cancel" (click)="close()">
        Cancel
    </button>
  </div>

</div>
