export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyC58On0h_c59jMtJ4vqfgFnptcfIFKngnU",
    authDomain: "notify-43ece.firebaseapp.com",
    databaseURL: "https://notify-43ece.firebaseio.com",
    projectId: "notify-43ece",
    storageBucket: "notify-43ece.appspot.com",
    messagingSenderId: "610400916616"
  },
  
  baseURL : 'https://admin2.test10.in',
  adminHost: "https://umsnew.test10.in",

  estUrl : "https://estapi.test10.in/estater/api/",
  emsUrl: "https://ems.test10.in/",
  epsUrl: "https://eps.test10.in/",
  csvUrl:"https://csvupload.test10.in/",
  umsUrl : "https://umsapinode.test10.in/",
  // gisUrl : "https://gisapi.test10.in/gis/api/",

  ges : "https://ges.test10.in/",
  gms : "https://gms.test10.in/",
  gps : "https://gps.test10.in/",
  gmus : "https://gmus.test10.in/",
  

  wms_url : "https://admin.test10.in/geoserver/",
  syncUrl : "https://sync.test10.in/",
  nodeUrl : "https://csvupload.test10.in/",
  geoestater : 'https://get.gisworld.in/',
  user_token: 'TXpjPU0yTTJaVEJpT0dFNVl6RTFNakkwWVRneU1qaGlPV0U1T0dOaE1UVXpNV1E9TlRjNE1HVXdPRE00Wm1KaFpHSTRNMlF3WXpjeFpUQm1aV1ZqTmpSbU5EZz1NMk0yWlRCaU9HRTVZekUxTWpJMFlUZ3lNamhpT1dFNU9HTmhNVFV6TVdRPTE1Njk0NzgyMTg=',
  api_key:'38db605ce6126a305901f58abc3efa9b',
  eps_log_path:"eps.test10.in/data/eps_api/uploads/",
  isLocalServe: false
};
