
<!-- add/edit setting -->
<div class="form">
    <div class="mtc-header pt-2 pb-2 pr-2 pl-2 position-relative" fxLayout="row" fxLayoutAlign="center center">
        <div>
            <h5 *ngIf="mode == 'add'">Add setting</h5>
        </div>
        <mat-progress-bar *ngIf="_loader" class="mtc-progress" mode="indeterminate" color="accent">
        </mat-progress-bar>
    </div>
    <div>
        <form [formGroup]="SettingForm" autocomplete="off" novalidate>
            <div class="calc-f">
                <div class="pl-4 pr-4">
                    <mat-form-field floatLabel="never" class="w-100">
                        <input matInput type="text" placeholder="Key" formControlName="key" (keypress)="blockSpecialChar($event)" />
                    </mat-form-field>
                    <mat-form-field floatLabel="never" class="w-100">
                      <mat-select placeholder="Type" formControlName="type">
                        <mat-option *ngFor="let type of types" [value]="type">{{type}}</mat-option>
                      </mat-select>
                        <!-- <input matInput type="text" placeholder="Type" formControlName="type" (keypress)="blockSpecialChar($event)" /> -->
                    </mat-form-field>
                    <mat-form-field floatLabel="never" class="w-100">
                        <input matInput type="text" placeholder="Value" formControlName="value" />
                        <mat-error *ngIf="SettingForm.controls['value'].hasError('minlength')"> Value Must ​Contain At Least ‍2⁢ Characters</mat-error>
                        <mat-error *ngIf="SettingForm.controls['value'].hasError('maxlength')"> Value cannot be more than 60 characters</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div class="footer" fxLayout="row" fxLayoutAlign="center center">
                <div class="pr-2">
                    <button mat-raised-button color="primary" [disabled]="SettingForm.invalid" *ngIf="mode == 'add'"
                        (click)="add_setting(SettingForm,$event)">
                        Submit
                    </button>
                </div>
                <div class="pl-2">
                    <button mat-raised-button (click)="cancel()">Cancel</button>
                </div>
            </div>
        </form>

    </div>
</div>
