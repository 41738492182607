import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export interface Count {
  deleted: number;
  demolished: number;
}

@Component({
  selector: 'app-step-toggle',
  templateUrl: './step-toggle.component.html',
  styleUrls: ['./step-toggle.component.css']
})

export class StepToggleComponent implements OnInit {
  selected: string = '';
  @Input() deleted_count: number;
  @Input() demolished_count: number;
  @Input() multi_parcel: number;
  @Input() review_count: number;
  @Input() entity_type: string;
  @Output() selectedOption = new EventEmitter();
  @Output() changeOption = new EventEmitter();
  constructor(iconRegistry: MatIconRegistry, public sanitizer: DomSanitizer, private cdRef: ChangeDetectorRef) {
    iconRegistry.addSvgIcon('demolished', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/demolished.svg'))
      .addSvgIcon('active', sanitizer.bypassSecurityTrustResourceUrl('assets/images/svgicons/active_building.svg'));
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.cdRef.detectChanges();
    }, 0);
  }

  changeStatus(type: string) {
    if (this.selected == type) {
      this.changeOption.emit(false);
    }else{
      this.changeOption.emit(true);
    }

    if(type == 'unit_map'){
      if (this.selected == type) {
        this.selected = '';
      }else{
        this.selected = type;
        this.selectedOption.emit('unit_map');
      }
      
      return;
    }
    if (this.selected == type) {
      this.selected = '';
      if(type == 'review_later'){
        this.selectedOption.emit(type);
      }else{
        this.selectedOption.emit(null);
      }
    } else {
      this.selected = type;
      this.selectedOption.emit(this.selected);
    }
  }
}
