<mat-toolbar class="matpopup-header">
  <div>Edit Group</div>
</mat-toolbar>
<!--<p>Validation status: {{ editGroupform.status }}</p>-->
<form [formGroup]="editGroupform" (ngSubmit)="updateGroupform(editGroupform)">
  <mat-dialog-content>
    <div class="form-container">
      <div class="form-input-container" fxLayout="row" fxLayoutAlign="space-between start">
        <label fxFlex="30%" style="padding-top: 2%">Label</label>
        <div fxFlex="70%" fxLayout="column" formArrayName="language">
          <div *ngFor="let languagevalues of language_valueset; let kk = index">
            <div [formGroupName]="kk" fxLayout="row" fxLayoutAlign="space-between start">
              <mat-form-field floatLabel="never" fxFlex="30%">
                <mat-select formControlName="language" name="language">
                  <mat-option *ngFor="let language of languages" [value]="language.language_id">
                    {{ language.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field floatLabel="never" fxFlex="45%">
                <input type="text" matInput formControlName="value" name="value" />
                <mat-error *ngIf="
                    !editGroupform.controls.language.controls[kk].controls.value
                      .valid
                  ">
                  This field is <strong>required</strong>
                </mat-error>
              </mat-form-field>
              <div class="addbtn">
                <div (click)="removeRow(kk)" class="border iconTick-1">
                  <mat-icon>remove</mat-icon>
                </div>
                <div (click)="addRow()" class="border iconTick-1">
                  <mat-icon>add</mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <mat-slide-toggle class="example-margin" formControlName="status">
        Status
      </mat-slide-toggle>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="dialog-actions">
    <button [disabled]="editGroupform.invalid" type="submit" mat-button class="blue-button">
      Submit
    </button>
    <button type="button" mat-raised-button (click)="closeDialog()">
      Cancel
    </button>
  </mat-dialog-actions>
</form>
