
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'categoryfilter'
})
export class FilterPipeCat implements PipeTransform {
    transform(items: any[], searchText: string): any[] {
        if (!items) return [];
        if (!searchText) return items;
        searchText = searchText.toLowerCase();
        return items.filter(it => {
            let name = it.master_value_name;
            return name.toLowerCase().includes(searchText);
        });
    }
}