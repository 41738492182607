<div class="dialog-container">
    <div>
        <mat-toolbar class="w-100 clr" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <div class="pad10">
                <h3>Submit Property - {{this.selected_row.uid}}</h3>
            </div>
        </mat-toolbar>
        <div>
            <div class="margt">
                <mat-radio-group [(ngModel)]="reject_option" (change)="reject_type($event)">
                    <mat-radio-button class="radio_text align" *ngFor="let option of option_primary" [value]="option.value">
                        <span class="fnt-md inline">
                            {{option.label}}
                        </span>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="bg2" *ngIf="selected_rejection_type == 8 || selected_rejection_type == 9 || selected_rejection_type == 11">
                <div class="pad10">
                    <mat-form-field floatLabel="never" class="w-100 bg2">
                        <textarea class="resnone" [(ngModel)]="user_comment" matInput placeholder="Type comment here..." rows="3"></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div class="margt bg1">
                <mat-radio-group [(ngModel)]="user_option">
                    <mat-radio-button class="radio_text align" *ngFor="let option of option_secondary" [value]="option.value" [disabled]="selected_row.status == 3 && option.value == 5 || selected_rejection_type && option.value == 5 || selected_rejection_type == -1 && option.value == 4">
                        <span class="fnt-md">
                            {{option.label}}
                        </span>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div class="cent1 mt" *ngIf="user_option == 4">
            <div class="grid50ph">
                <span class="fntM">Assign to user:</span>
                <mat-select (selectionChange)="assign_user($event)" [(value)]="selected_user_id" class="form-select-l nw1">
                    <ng-container *ngFor="let user of user_list">
                        <mat-option *ngIf="user.id != 0" [value]="user.id">{{user.username}}</mat-option>
                    </ng-container>
                </mat-select>
            </div>
        </div>
    </div>
    <div class="h50">
        <div class="ml10 grid50ph-nogap">
            <button mat-button type="submit" (click)="openConfirmation()" class="orange-button w-100 br0" [disabled]="!user_option && !reject_option">Submit</button>
            <button mat-button type="button" (click)="closeDialog()" class="grey-button w-100 br0">Cancel</button>
        </div>
    </div>
    <!-- </form> -->
</div>
