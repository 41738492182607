<mat-toolbar class="matpopup-header"><div>Edit Description</div></mat-toolbar>


<form [formGroup]="languageform" (ngSubmit)="saveDescription(languageform)">
    <mat-dialog-content>
        <div class="form-input-container" fxLayout="row" fxLayoutAlign="space-between start">
            <div fxFlex="100%" fxLayout="column" formArrayName="description">
                <div  *ngFor="let languagevalues of language_valueset;let kk = index">
                    <div [formGroupName]="kk"  fxLayout="row" fxLayoutAlign="space-between start" class="gap-10">
                        <mat-form-field floatLabel="never">
                            <mat-select formControlName="language" name="language">
                                <mat-option *ngFor="let language of languages" [value]="language.language_id">
                                    {{ language.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field floatLabel="never">
                            <textarea type="text" matInput formControlName="value" name="value"></textarea>
                                <mat-error *ngIf="!languageform.controls.description.controls[kk].controls.value.valid">
                                    This field is <strong>required</strong>
                                </mat-error>
                        </mat-form-field>
                        <div class="addbtn">
                          <div (click)="removeRow(kk)" class="border iconTick-1">
                              <mat-icon>remove</mat-icon>
                          </div>
                          <div (click)="addRow()" class="border iconTick-1">
                              <mat-icon>add</mat-icon>
                          </div>
                      </div>
                    </div>
                </div>
                <div>

                </div>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="dialog-actions">
        <button mat-raised-button type="submit" [disabled]="languageform.invalid" class="blue-button">Update</button>
        <button  type="button"  mat-raised-button (click)="closeDialog()">Cancel</button>
    </mat-dialog-actions>
</form>
