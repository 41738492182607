<mat-toolbar class="h45p">
    <span>
        Location
    </span>
    <small class="fnt-slim">
        &nbsp;
    </small>
</mat-toolbar>
<div class="rel">
    <ngx-loading [show]="loader_map" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.8)'}"></ngx-loading>
    <div #gmap class="map"></div>
</div>