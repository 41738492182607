<div class="wide-1">
    <mat-toolbar class="h45p w-100 rel">
        <div class="w-100" fxFlex fxLayout="row" fxLayoutAlign="space-between center">
            <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                <button mat-icon-button (click)="toggle()">
                    <mat-icon>arrow_forward</mat-icon>
                </button>
                <h1 class="mat-h1">Location & Parcel</h1>
            </div>
            <div fxFlex fxLayout="row" fxLayoutAlign="end center">
                <button class="save-btn" mat-raised-button color="primary" (click)="openConfirmation(true)">Merge
                    Parcels</button>
                <button class="save-btn" mat-raised-button color="primary" (click)="openConfirmation(false)">De-Merge
                    Parcels</button>
            </div>
        </div>
    </mat-toolbar>

    <div class="w-100 map-form-grid">
        <div class="rel">
            <ngx-loading [show]="loader_map || parcelLoader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.8)'}">
            </ngx-loading>
            <div #gmap class="map"></div>
        </div>
    </div>
</div>