<mat-tab-group>
  <mat-tab label="ADM Upload">
    <div class="parent-container">
      <mat-card appearance="outlined" class="example-card">
          <div class="highlighsection">
              <h2 class="title">ADM</h2>
          </div>
          <mat-card-content>
              <div class="gis_data_form">
                  <div class="d-flex align-items-center">
                      <div class="ml-10 d-flex align-items-center">
                          <label for="">Assignment <span class="mandatory">*</span> : </label>
                          <mat-select (selectionChange)="selectionChange()" (openedChange)="closeMatSelectEvent($event, 'assignment')" placeholder="Select Assignment" [(ngModel)]="selected_assignment" class="form-select-l">
                              <div class="search_bar"><input [(ngModel)]="searchAssignment" placeholder="Type here to search..." (keydown)="$event.stopPropagation()" /></div>
                              <mat-option class="cp" *ngFor="let data of assignmentList | searchfilter:'name':searchAssignment:assignmentList" [value]="data.assignment_id">
                                  {{ (data.assignment_label && data.assignment_label[api.language]) ? data.assignment_label[api.language] :''}}_{{data?.year}}
                              </mat-option>
                          </mat-select>
                      </div>
                      <!-- <div class="ml-10 d-flex align-items-center">
                          <label for="">Year <span class="mandatory">*</span> : </label>
                          <mat-select (selectionChange)="selectionChange()" (openedChange)="closeMatSelectEvent($event, 'year')" placeholder="Select Year" [(ngModel)]="selected_year" class="form-select-l">
                              <div class="search_bar"><input [(ngModel)]="search_year" placeholder="Type here to search..."   maxlength="4" (keydown)="$event.stopPropagation()" /></div>
                              <mat-option class="cp" *ngFor="let data of yearList | searchfilter:'val':search_year:yearList" [value]="data.val">
                                  {{data.val}}
                              </mat-option>
                          </mat-select>
                      </div> -->
                  </div>

                  <div class="gridV">
                      <div class="btnGrid">
                          <div class="upload-btn-wrapper">
                              <div class="wrapper">
                                  <button class="sel">Select file</button>
                                  <p> or Drag & drop file here</p>
                              </div>
                              <input type="file" #myFileInput name="myfile" (change)="handleFileInput($event)" accept=".zip" />
                          </div>
                          <div class="fileName">
                              <p> {{fileName}} </p>
                              <button class="submit" type="submit" [disabled]="loading" mat-raised-button color="primary" (click)="uploadFile()">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                                      <path d="M0 0h24v24H0z" fill="none"></path>
                                      <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path>
                                  </svg>
                                  <span *ngIf="!loading">Upload</span>
                                  <span *ngIf="loading">Uploading...</span>
                              </button>
                          </div>
                      </div>
                  </div>

              </div>
          </mat-card-content>
          <div class="bbar w-100" *ngIf="loading">
              <mat-progress-bar mode="indeterminate" class="btm"></mat-progress-bar>
          </div>

      </mat-card>
      <mat-card appearance="outlined" class="example-card">
          <div class="highlighsection d-flex justify-content-between align-items-center">
              <div class="title">
                  ADM Upload Status
              </div>
              <div class="text-right mr-1">
                  <button mat-icon-button  matTooltip="Refresh list" class="refresh" (click)="getADMStatus()">
                      <mat-icon>refresh</mat-icon>
                  </button>
              </div>
          </div>
          <mat-card-content class="tabs">
              <div class="table-estater table" style="position: relative">
                  <ng-container *ngIf="DataADMSource.length > 0">
                      <table class="attr-table">
                          <thead>
                              <tr>
                                 <th>Upload Id</th>
                                 <th>File Name</th>
                                 <th>Assignment</th>
                                 <th>Year</th>
                                 <th>Add Count</th>
                                 <th>Update Count</th>
                                 <th>Delete Count</th>
                                 <th>Existing Count</th>
                                 <th>Status</th>
                                 <th>Added Date</th>
                                 <th>Added By</th>
                                 <th>Updated By</th>
                                 <th>Validate End Date</th>
                                 <th>Accept Start</th>
                                 <th>Accept End</th>
                                 <th>Actions</th>
                                 <th>Log</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr *ngFor="let data of DataADMSource; let k = index">
                                  <td>{{data?.id}}</td>
                                  <td>{{data?.file_name}}</td>
                                  <td>{{getAssignmentLabel(data?.assignment_id)}}</td>
                                  <td>{{data?.year}}</td>
                                  <td>{{data?.add_count}}</td>
                                  <td>{{data?.update_count}}</td>
                                  <td>{{data?.delete_count}}</td>
                                  <td>{{data?.existing_count}}</td>
                                  <td>{{status[data?.status]}}</td>
                                  <td>{{getDate(data?.added_date)}}</td>
                                  <td>{{data?.added_by}}</td>
                                  <td>{{data?.updated_by}}</td>
                                  <td>{{getDate(data?.validate_end_date)}}</td>
                                  <td>{{getDate(data?.accept_start_date)}}</td>
                                  <td>{{getDate(data?.accept_end_date)}}</td>
                                  <td><button class="dl-btn accept mr-1" [disabled]="data?.status != 1" (click)="accept_reject(data.id , 'accept')">Accept</button>
                                      <button class="dl-btn reject mr-1"  [disabled]="data?.status == -3 || data?.status == 2" (click)="accept_reject(data.id , 'reject')">Reject</button>
                                  </td>
                                  <td>
                                      <button class="dl-btn bordered download"><a href="{{eps_log_path}}{{city_code}}_validate_{{data.id}}.log" target="_blank" class="">Log</a></button>
                                      <button class="dl-btn bordered download"><a href="{{eps_log_path}}{{city_code}}_accept_{{data.id}}.log" target="_blank" class="">Accept Log</a></button>
                                      <button class="dl-btn bordered download"><a href="{{eps_log_path}}{{city_code}}_accept_{{data.id}}_err.log"  target="_blank" class="">Accept Err Log</a></button>
                                      <button class="dl-btn bordered download"><a href="{{eps_log_path}}{{city_code}}_validate_{{data.id}}_err.log"  target="_blank" class="">Validate Log</a></button>
                                  </td>
                              </tr>
                          </tbody>
                          <ngx-loading [show]="loader" [config]="{backdropBackgroundColour: 'rgba(255,255,255,0.3)'}"></ngx-loading>
                      </table>
                  </ng-container>

              </div>
              <mat-paginator  [length]="total" [pageSize]="defaultPageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageAction($event)">
              </mat-paginator>
          </mat-card-content>
      </mat-card>
  </div>
  </mat-tab>
  <mat-tab label="Observer ADM Upload">
  <app-admin-observer-adm-upload></app-admin-observer-adm-upload>
  </mat-tab>
</mat-tab-group>

