
import { Component, OnInit, Inject } from '@angular/core';
import { ApiserviceService } from '../../../apiservice.service';
import { NotificationService } from '../../services/notification.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm, Validators, FormGroup, FormControl, FormBuilder, FormArray, NgModel } from '@angular/forms';
// import { UserAction } from "../../user-action/user-activity";
@Component({
    selector: 'app-add-edit',
    templateUrl: './add-edit.component.html',
    styleUrls: ['../settings.component.css']
})
export class AddEditComponent implements OnInit {

    public parent_data;
    public mode;
    public SettingForm: any;
    public _loader: boolean;
    public types=['db', 'general']
    constructor(
        public dialogRef: MatDialogRef<AddEditComponent>,
        @Inject(MAT_DIALOG_DATA) public info: any,
        private notify: NotificationService,
        private apiService: ApiserviceService,
        private _fb: FormBuilder
    ) {
        this.parent_data = this.info;
        this.mode = this.parent_data.type;
        if (this.mode == 'add') {
            this.SettingForm = this._fb.group({
                key: ['', Validators.required],
                type: ['', Validators.required],
                value: ['',  [Validators.required, Validators.minLength(2), Validators.maxLength(60)]]
            });
        }
    }

    ngOnInit() {
    }
    cancel() {
        this.dialogRef.close();
    }
    add_setting(data) {
        var val = data.value;
        let regExp = /[a-z]/i;
        if(!regExp.test(val.key) || !regExp.test(val.type)){
            this.notify.notify("Special Character not allowed", "warn");
            return;
        }
        var body = {
            "key": val.key.toLowerCase().trim(),
            "type": val.type,
            "value": val.value,
            "user_id": this.apiService.user_id,
            "added_by": this.apiService.user_id
        }
        this._loader = true;
        this.apiService.postEmsData('settings', body).subscribe({
            next: (res: any) => {
                this._loader = false;
                if (res && res.status == 201) {
                  this.notify.notify(res.message, "success");
                  this.parent_data.pdata.getSettings();
                  this.dialogRef.close();
                } else {
                  this.notify.notify(res.message, "warn");
                }
            },
            error: (err) => {
                this.notify.notify(err.error.message, "error");
                this._loader = false;
            }
        })
    }

    blockSpecialChar(event){
        // console.log(event.key," = ",event.keyCode);
        if(event.keyCode == 95 || event.keyCode == 45 ) {
            event.returnValue = true;
        } else if(!((event.keyCode >= 65) && (event.keyCode <= 90) || (event.keyCode >= 97) && (event.keyCode <= 122) || (event.keyCode >= 48) && (event.keyCode <= 57))){
           event.returnValue = false;
           return;
        }
        event.returnValue = true;
     }
}
